import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';

// antd
import { Grid, Button, Form, Input, message, Select, Skeleton, Descriptions, Row, Col } from 'antd';
import { DatePicker as DatePickerMobile } from 'antd-mobile';
import DatePicker from '../../../components/customAntd/DatePicker';
import { PickerDate } from 'antd-mobile/es/components/date-picker/util';

import { getPreArbitrationTableContent, IPreArbitrationCoverTableData, IPreArbitrationCoverTableForm, savePreArbitrationCoverTable } from '../../../apis/preArbitration';

const { useBreakpoint } = Grid;


interface CollectionCreateFormProps {
    data?: { id: number },
    onFinish?: () => void;
}

function CoverTableFormModal({
    data,
    onFinish,
}: CollectionCreateFormProps) {
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 表单数据
    const [form] = Form.useForm<IPreArbitrationCoverTableForm>();
    const [detail, setDetail] = useState<IPreArbitrationCoverTableData>();

    // 初始化加载
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        form.resetFields();

        let pre_worksheet_id = data?.id ?? Number(searchParams.get('id'));
        if (pre_worksheet_id) {
            form.setFieldValue('pre_worksheet_id', pre_worksheet_id);
            setLoading(true);
            getPreArbitrationTableContent({
                pre_worksheet_id,
                form_type: 2,
            }).then(res => {
                console.log(res);
                let data: IPreArbitrationCoverTableData = res.data.detail!;
                setDetail(data);

                form.setFieldsValue({
                    ...data,
                    receive_at: data.receive_at ? dayjs(data.receive_at) as any : undefined,
                    closing_date: data.closing_date ? dayjs(data.closing_date) as any : undefined,
                });
            }).finally(() => {
                setLoading(false);
            })
        }

    }, [data, searchParams]);

    // 接件日期
    const [receiveAtVisible, setReceiveAtVisible] = useState(false);

    // 结案日期
    const [closingDateVisible, setClosingDateVisible] = useState(false);

    // 提交
    const submit = async (print = false) => {
        try {
            await form.validateFields();
        } catch (e: any) {
            message.warning(e.errorFields[0].errors[0]);
            return;
        }

        setConfirmLoading(true);
        let _form = form.getFieldsValue(true);
        try {
            let data = {
                ..._form,
            }
            if (_form.receive_at) data.receive_at = (_form.receive_at as dayjs.Dayjs).format('YYYY-MM-DD');
            if (_form.closing_date) data.closing_date = (_form.closing_date as dayjs.Dayjs).format('YYYY-MM-DD');

            let res = await savePreArbitrationCoverTable(data);
            message.success(res.msg);

            if (print) {
                setTimeout(() => {
                    window.open(`/preArbitration/caseFrontCover/print?id=${_form.pre_worksheet_id}`, '_blank');
                }, 0);
            } else {
                if (onFinish) {
                    onFinish();
                } else {
                    navigate(-1);
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    const gutter = 16 + 8 * 2;

    const FormItem = (
        <Form
            form={form}
            layout="vertical"
            autoComplete="off"
        >
            <Form.Item
                label="机构名称"
                name="archive_name"
            >
                <Input maxLength={100} />
            </Form.Item>
            <Row gutter={gutter}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="调解员"
                        name="mediator"
                    >
                        <Input maxLength={10} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="记录员"
                        name="recorder"
                    >
                        <Input maxLength={10} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    {
                        screens.xs ?
                            <Form.Item
                                label="收件日期"
                                shouldUpdate={(prevValues, curValues) => prevValues.receive_at !== curValues.receive_at}
                            >
                                <DatePickerMobile
                                    visible={receiveAtVisible}
                                    onClose={() => {
                                        setReceiveAtVisible(false)
                                    }}
                                    value={(form.getFieldValue('receive_at') as Dayjs)?.toDate()}
                                    onConfirm={(value: PickerDate) => {
                                        form.setFieldValue('receive_at', dayjs(value));
                                    }}
                                >
                                    {value =>
                                        <>
                                            <Button
                                                style={{ marginRight: 10 }}
                                                onClick={() => {
                                                    setReceiveAtVisible(true)
                                                }}
                                            >选择</Button>
                                            {value ? dayjs(value).format('YYYY年MM月DD日') : '请选择时间'}
                                        </>
                                    }
                                </DatePickerMobile>
                            </Form.Item>
                            : <Form.Item
                                label="收件日期"
                                name="receive_at"
                            >
                                <DatePicker
                                    format='YYYY-MM-DD'
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                    }
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    {
                        screens.xs ?
                            <Form.Item
                                label="结案日期"
                                shouldUpdate={(prevValues, curValues) => prevValues.closing_date !== curValues.closing_date}
                            >
                                <DatePickerMobile
                                    visible={closingDateVisible}
                                    onClose={() => {
                                        setClosingDateVisible(false)
                                    }}
                                    value={(form.getFieldValue('closing_date') as Dayjs)?.toDate()}
                                    onConfirm={(value: PickerDate) => {
                                        form.setFieldValue('closing_date', dayjs(value));
                                    }}
                                >

                                    {value =>
                                        <>
                                            <Button
                                                style={{ marginRight: 10 }}
                                                onClick={() => {
                                                    setClosingDateVisible(true)
                                                }}
                                            >选择</Button>
                                            {value ? dayjs(value).format('YYYY年MM月DD日') : '请选择时间'}
                                        </>
                                    }
                                </DatePickerMobile>
                            </Form.Item>
                            : <Form.Item
                                label="结案日期"
                                name="closing_date"
                            >
                                <DatePicker
                                    format='YYYY-MM-DD'
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                    }
                </Col>
            </Row>

            <Form.Item>
                <Button block type='primary' size='large' loading={confirmLoading} onClick={() => submit()}>提交</Button>
            </Form.Item>
        </Form>
    )

    const infoItem = () => {
        return (
            <>
                <Descriptions
                    size='small'
                    column={2}
                    title={`基础信息`}
                    extra={<Button onClick={() => {
                        submit(true);
                    }}>预览打印</Button >}
                    style={{ marginBottom: 20 }}
                >
                    <Descriptions.Item label="案号">{detail?.case_number}</Descriptions.Item>
                    <Descriptions.Item label="案由">{detail?.case_cause}</Descriptions.Item>
                </Descriptions>
                <Descriptions size='small' title="当事人" style={{ marginBottom: 20 }}>
                    <Descriptions.Item label="申请人">{detail?.plaintiff}</Descriptions.Item>
                    <Descriptions.Item label="被申请人">{detail?.defendant}</Descriptions.Item>
                    <Descriptions.Item label="第三人">{detail?.third_party_data}</Descriptions.Item>
                </Descriptions>
            </>
        )
    };

    return (
        <div className={screens.xs ? 'inner-page mobile' : ''}>
            {
                loading
                    ? <Skeleton />
                    : <>
                        {detail && infoItem()}
                        {FormItem}
                    </>
            }
        </div>
    );
}

export default CoverTableFormModal;