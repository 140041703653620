import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// antd
import { Grid, Button, Cascader, Form, Input, message, Modal, Descriptions, Typography, FormInstance, Tree } from 'antd';
import { CloseCircleOutlined, SmileOutlined } from '@ant-design/icons';
import { DefaultOptionType } from "antd/lib/cascader";
import type { DataNode } from 'antd/es/tree';

// antd-mobile
import { Cascader as CascaderMobile } from 'antd-mobile'

import { getRoleMenuTree, getRoleRegionTree, getRolesDetail, IAddRoleForm, IEditRoleForm, IRoleMenuTreeData, toAddRole, toEditRole } from '../../../../apis/auth';
import { DictItem } from '../../../../apis/common';


const { useBreakpoint } = Grid;


interface CollectionCreateFormProps {
    data?: { id: number },
    onFinish?: () => void;
}

type IRoleForm = IAddRoleForm | IEditRoleForm;

function RoleFormModal({
    data,
    onFinish,
}: CollectionCreateFormProps) {
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 表单数据
    const [form] = Form.useForm<IRoleForm>();

    useEffect(() => {
        form.resetFields();
        if (data?.id || searchParams.get('id')) {
            getRolesDetail({ id: data?.id ?? Number(searchParams.get('id')) }).then(res => {
                let data = res.data.detail;
                form.setFieldsValue({
                    ...data,
                    region_list: data.region.map(item => ({
                        id: [item.province_id, item.city_id, item.area_id, item.street_id].join('_'),
                        name: [item.province_text, item.city_text, item.area_text, item.street_text].join('_'),
                    })),
                    menu_ids: data.menu.map(item => item.id),
                });
            });
        }
    }, [data, searchParams]);

    // 提交
    const submit = async () => {
        await form.validateFields();

        setConfirmLoading(true);
        let _form = form.getFieldsValue(true);
        try {
            let data = {
                ..._form,
                region_list: _form.region_list ?? [],
                menu_ids: _form.menu_ids ?? []
            }
            let res = data?.id ? await toEditRole(data) : await toAddRole(data);
            message.success(res.msg);
            if (onFinish) {
                onFinish();
            } else {
                navigate('/auth/roles');
            }
        } catch (e) {
            console.error(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    // 添加选项弹窗
    const [optionOpen, setOptionOpen] = useState(false);
    const showOptionModal = async () => {
        if (screens.xs) {
            // 移动端使用mobile级联弹窗
            setCascaderVisible(true);
        } else {
            // pc端唤起副表单
            setOptionOpen(true);
        }
    };
    const hideOptionModal = () => {
        setOptionOpen(false);
    };
    const onOptionDelClick = (index: number) => {
        let times: DictItem[] = form.getFieldValue('region_list');
        form.setFieldValue('region_list', times.filter((_, i) => index !== i));
    }

    // 移动端选项弹窗
    type CascaderOption = {
        label: string
        value: string
        disabled?: boolean
        children?: CascaderOption[]
    }
    const [cascaderVisible, setCascaderVisible] = useState(false)
    const [options, setOptions] = useState<CascaderOption[]>([]);
    useEffect(() => {
        let nodes: IRoleMenuTreeData[] = [];
        const build = (list: IRoleMenuTreeData[]): CascaderOption[] => {
            return list.map(item => ({
                label: item.name,
                value: String(item.id),
                children: item.children.length > 0 ? build(item.children) : undefined
            }));
        }
        getRoleRegionTree().then(res => {
            nodes.push(...res.data.list);
            setOptions(build(nodes));
        });
    }, []);
    type CascaderValueExtend = {
        items: (CascaderOption | null)[]
        isLeaf: boolean
    }
    const onCascaderConfirm = (value: string[], extend: CascaderValueExtend) => {
        const regionListData = form.getFieldValue('region_list') || [];
        form.setFieldsValue({
            region_list: [...regionListData, {
                id: extend.items.map(item => item!.value).join('_'),
                name: extend.items.map(item => item!.label).join('_'),
            }]
        });
    }

    // 树权限选择
    const [treeData, setTreeData] = useState<DataNode[]>();
    useEffect(() => {
        let nodes: IRoleMenuTreeData[] = [];
        const build = (list: IRoleMenuTreeData[]): DataNode[] => {
            return list.map(item => ({
                title: item.name,
                key: item.id,
                children: item.children.length > 0 ? build(item.children) : undefined
            }));
        }
        getRoleMenuTree().then(res => {
            nodes.push(...res.data.list);
            setTreeData(build(nodes));
        });
    }, []);

    return (
        <div className={screens.xs ? 'inner-page mobile' : ''}>
            <Form.Provider
                onFormFinish={(name, { values, forms }) => {
                    if (name === 'itemForm') {
                        const { itemForm } = forms;
                        const itemData = itemForm.getFieldsValue(true);
                        const regionListData = form.getFieldValue('region_list') || [];
                        form.setFieldsValue({ region_list: [...regionListData, itemData] });

                        setOptionOpen(false);
                    }
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                >
                    <Form.Item
                        label="角色名称"
                        name="name"
                        rules={[{ required: true, message: '请填写角色名称' }]}
                    >
                        <Input maxLength={20} />
                    </Form.Item>

                    <Form.Item
                        label="备注"
                        name="remark"
                    >
                        <Input maxLength={100} />
                    </Form.Item>

                    <Form.Item
                        label="数据范围"
                        shouldUpdate={(prevValues, curValues) => prevValues.region_list !== curValues.region_list}
                    >
                        {({ getFieldValue }) => {
                            const itemsData: DictItem[] = getFieldValue('region_list') || [];
                            return itemsData.length ? (
                                <Descriptions size='small' bordered column={1}>
                                    {itemsData.map((item, index) => (
                                        <Descriptions.Item>
                                            <div className="ding__code">
                                                <div>{item.name}</div>
                                                <Button
                                                    type="text"
                                                    danger
                                                    icon={<CloseCircleOutlined />}
                                                    onClick={() => onOptionDelClick(index)}
                                                />
                                            </div>
                                        </Descriptions.Item>
                                    ))}
                                </Descriptions>
                            ) : (
                                <Typography.Text className="ant-form-text" type="secondary">
                                    ( <SmileOutlined /> 暂无添加数据范围。 )
                                </Typography.Text>
                            );
                        }}
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="button" onClick={showOptionModal}>
                            添加数据范围
                        </Button>
                    </Form.Item>

                    <Form.Item
                        label="权限"
                        name='menu_ids'
                        valuePropName='checkedKeys'
                    >
                        {treeData
                            ? <Tree
                                checkable
                                checkStrictly
                                defaultExpandAll
                                selectable={false}
                                treeData={treeData}
                                onCheck={(checked, info) => {
                                    form.setFieldValue('menu_ids', (checked as any).checked)
                                }}
                            />
                            : null
                        }
                    </Form.Item>

                    <Button block type='primary' size='large' loading={confirmLoading} onClick={submit}>提交</Button>
                </Form>

                {/* 数据范围 */}
                <OptionModalForm open={optionOpen} onCancel={hideOptionModal} />
                <CascaderMobile
                    options={options}
                    visible={cascaderVisible}
                    destroyOnClose
                    onConfirm={onCascaderConfirm}
                    onClose={() => {
                        setCascaderVisible(false)
                    }}
                />
            </Form.Provider>

        </div>
    );
}

// 添加选项弹窗
const useResetFormOnCloseModal = ({ form, open }: { form: FormInstance; open: boolean }) => {
    const prevOpenRef = useRef<boolean>();
    useEffect(() => {
        prevOpenRef.current = open;
    }, [open]);
    const prevOpen = prevOpenRef.current;

    useEffect(() => {
        if (!open && prevOpen) {
            form.resetFields();
        }
    }, [form, prevOpen, open]);
};

export interface OptionModalFormProps {
    open: boolean;
    onCancel: () => void;
}
const OptionModalForm: React.FC<OptionModalFormProps> = ({ open, onCancel }) => {
    const [form] = Form.useForm();

    interface Option {
        value: string | number;
        label: string;
        children?: Option[];
    }
    const [options, setOptions] = useState<Option[]>([]);
    useEffect(() => {
        let nodes: IRoleMenuTreeData[] = [];
        const build = (list: IRoleMenuTreeData[]): Option[] => {
            return list.map(item => ({
                label: item.name,
                value: item.id,
                children: item.children.length > 0 ? build(item.children) : undefined
            }));
        }
        getRoleRegionTree().then(res => {
            nodes.push(...res.data.list);
            setOptions(build(nodes));
        });
    }, []);

    useResetFormOnCloseModal({
        form,
        open,
    });

    const onOk = () => {
        form.submit();
    };

    const onChange = (value: any, items: DefaultOptionType[]) => {
        let name: Array<string> = [];
        let id: Array<number> = [];
        items.forEach(item => {
            name.push(item.label!.toString());
            id.push(item.value as number);
        });
        form.setFieldsValue({
            id: id.join('_'),
            name: name.join('_')
        })
        form.setFieldValue('cur_id', value);
    }

    return (
        <Modal title="添加数据范围" open={open} onOk={onOk} onCancel={onCancel}>
            <Form form={form} layout="vertical" name="itemForm">
                <Form.Item
                    name="cur_id"
                    rules={[{ required: true, message: '请选择范围' }]}
                >
                    <Cascader options={options} onChange={onChange} placeholder="请选择范围" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default RoleFormModal;