import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// antd
import { Skeleton } from 'antd';

import { getPreArbitrationTableContent, IProofOfDeliveryTableData } from '../../../../apis/preArbitration';
import dayjs from 'dayjs';


function ProofOfDeliveryTablePrint() {
    const [searchParams, setSearchParams] = useSearchParams();

    // 表单数据
    const [detail, setDetail] = useState<IProofOfDeliveryTableData>();

    // 初始化加载
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let pre_worksheet_id = Number(searchParams.get('id'));
        if (pre_worksheet_id) {
            setLoading(true);

            getPreArbitrationTableContent({
                pre_worksheet_id,
                form_type: 10,
            }).then(res => {
                console.log(res);
                let data: IProofOfDeliveryTableData = res.data.detail!;
                setDetail(data);
            }).finally(() => {
                setLoading(false);
            })
        }

    }, [searchParams]);

    return (
        <div>
            {
                loading
                    ? <Skeleton />
                    : <>
                        <div style={{ width: 794, height: 1123, margin: 'auto', textAlign: 'center', fontSize: 16, position: 'relative' }}>
                            <h3 style={{ margin: '20px 0' }}>{detail?.archive_name}</h3>
                            <h2 style={{ letterSpacing: '1em' }}>送达回证</h2>

                            <table className='preArbitration_table' border={1} style={{ width: 794, margin: 'auto' }}>
                                <tr>
                                    <td>收件方</td>
                                    <td colSpan={5}>{detail?.plaintiff_name}</td>
                                </tr>
                                <tr>
                                    <td>送达地点</td>
                                    <td colSpan={5}>
                                        <div style={{ minHeight: '3em', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{detail?.delivery_address}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>案由</td>
                                    <td colSpan={5}>{detail?.case_cause}</td>
                                </tr>
                                <tr>
                                    <td style={{ height: '5em' }}>送达文件</td>
                                    <td>签发人</td>
                                    <td>送达人</td>
                                    <td>收到日期</td>
                                    <td>接收人姓名</td>
                                    <td>不能送达理由</td>
                                </tr>
                                {detail?.delivery_data?.map(item => (
                                    <tr>
                                        <td style={{ height: "3em" }}>{item.delivery_file}</td>
                                        <td>{item.signer}</td>
                                        <td>{item.deliverer}</td>
                                        <td>{item.receive_at && dayjs(item.receive_at).format('YYYY年MM月DD日HH时')}</td>
                                        <td>{item.receiver}</td>
                                        <td>{item.reason}</td>
                                    </tr>
                                ))}


                                {[1, 2, 3, 4, 5, 6].slice(0, 5 - (detail?.delivery_data?.length ?? 0)).map((item, index) => (
                                    <tr>
                                        <td style={{ height: "3em" }}></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                ))}

                                <tr>
                                    <td>备注</td>
                                    <td colSpan={5}>
                                        <div style={{ minHeight: '3em', whiteSpace: 'pre-wrap', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{detail?.remark}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={6}>
                                        <div>
                                            {/* <div style={{ whiteSpace: 'pre-wrap', textAlign: 'start' }}>
                                            {detail?.other_reason}
                                        </div> */}

                                            <div style={{ textAlign: 'end', margin: '20px 0', marginTop: 80 }}>{detail?.archive_name}</div>
                                            <div style={{ textAlign: 'end', margin: '20px 0', marginTop: 80 }}><span>年</span><span style={{ marginLeft: 50 }}>月</span><span style={{ marginLeft: 50 }}>日</span></div>
                                        </div>
                                    </td>
                                </tr>
                            </table>

                            <div style={{ textAlign: 'start', display: 'flex', height: 'max-content' }}>
                                说明：
                                <div>
                                    1、送达文件，应直接送交受达人。<br />
                                    2、此证收件人签名盖章后，由中心保全。
                                </div>
                            </div>
                        </div>
                        <div style={{ width: 794, height: 1123, margin: 'auto', textAlign: 'center', fontSize: 16, position: 'relative' }}>
                            <h3 style={{ margin: '20px 0' }}>{detail?.archive_name}</h3>
                            <h2 style={{ letterSpacing: '1em' }}>送达回证</h2>

                            <table className='preArbitration_table' border={1} style={{ width: 794, margin: 'auto' }}>
                                <tr>
                                    <td>收件方</td>
                                    <td colSpan={5}>{detail?.defendant_name}</td>
                                </tr>
                                <tr>
                                    <td>送达地点</td>
                                    <td colSpan={5}>
                                        <div style={{ minHeight: '3em', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{detail?.delivery_address}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>案由</td>
                                    <td colSpan={5}>{detail?.case_cause}</td>
                                </tr>
                                <tr>
                                    <td style={{ height: '5em' }}>送达文件</td>
                                    <td>签发人</td>
                                    <td>送达人</td>
                                    <td>收到日期</td>
                                    <td>接收人姓名</td>
                                    <td>不能送达理由</td>
                                </tr>
                                {detail?.delivery_data?.map(item => (
                                    <tr>
                                        <td style={{ height: "3em" }}>{item.delivery_file}</td>
                                        <td>{item.signer}</td>
                                        <td>{item.deliverer}</td>
                                        <td>{item.receive_at && dayjs(item.receive_at).format('YYYY年MM月DD日HH时')}</td>
                                        <td>{item.receiver}</td>
                                        <td>{item.reason}</td>
                                    </tr>
                                ))}

                                {[1, 2, 3, 4, 5, 6].slice(0, 5 - (detail?.delivery_data?.length ?? 0)).map((item, index) => (
                                    <tr>
                                        <td style={{ height: "3em" }}></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                ))}

                                <tr>
                                    <td>备注</td>
                                    <td colSpan={5}>
                                        <div style={{ minHeight: '3em', whiteSpace: 'pre-wrap', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{detail?.remark}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={6}>
                                        <div>
                                            {/* <div style={{ whiteSpace: 'pre-wrap', textAlign: 'start' }}>
                                            {detail?.other_reason}
                                        </div> */}

                                            <div style={{ textAlign: 'end', margin: '20px 0', marginTop: 80 }}>{detail?.archive_name}</div>
                                            <div style={{ textAlign: 'end', margin: '20px 0', marginTop: 80 }}><span>年</span><span style={{ marginLeft: 50 }}>月</span><span style={{ marginLeft: 50 }}>日</span></div>
                                        </div>
                                    </td>
                                </tr>
                            </table>

                            <div style={{ textAlign: 'start', display: 'flex' }}>
                                说明：
                                <div>
                                    1、送达文件，应直接送交受达人。<br />
                                    2、此证收件人签名盖章后，由中心保全。
                                </div>
                            </div>
                        </div>
                    </>
            }
        </div>
    );
}

export default ProofOfDeliveryTablePrint;