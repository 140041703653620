import { useEffect, useState } from 'react';

// antd
import { Grid, Card, Table, Empty } from 'antd';
import DatePicker from '../../../components/customAntd/DatePicker';

import dayjs, { Dayjs } from 'dayjs';

import { getPreArbitrationStatisticsList, IPreArbitrationStatisticsListData, IPreArbitrationStatisticsListSearchForm } from '../../../apis/preArbitration';

const { Column } = Table;
const { useBreakpoint } = Grid;
const { RangePicker } = DatePicker;

const disabledDate = (current: Dayjs) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf('month');
};

function PreArbitrationStatisticsListModal() {
    const screens = useBreakpoint();

    // 搜索表单
    const [form, setForm] = useState<IPreArbitrationStatisticsListSearchForm>({
        begin_month: dayjs().format('YYYY-MM'),
        end_month: dayjs().format('YYYY-MM'),
    });

    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState<(IPreArbitrationStatisticsListData)[]>([]);

    const loadData = async (newForm?: IPreArbitrationStatisticsListSearchForm) => {
        let tempForm = newForm ?? form;
        setLoading(true);
        try {
            let res = await getPreArbitrationStatisticsList(tempForm);
            setListData(res.data.list);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    // 月份筛选
    const handleReceiveChange = (_: any, formatString: [string, string]) => {
        if (formatString[0] && formatString[1]) {
            setForm((form) => {
                let newForm = {
                    ...form,
                    page: 1,
                    begin_month: formatString[0],
                    end_month: formatString[1],
                };
                loadData(newForm);
                return newForm;
            });
        }
    }

    return (
        <div className={screens.xs ? 'inner-page mobile' : ''}>
            <div className='search'>
                <RangePicker
                    picker="month"
                    disabledDate={disabledDate}
                    className="search__item"
                    style={{ 'width': '300px' }}
                    value={[
                        form.begin_month && form.begin_month ? dayjs(form.begin_month) : null,
                        form.end_month && form.end_month ? dayjs(form.end_month) : null,
                    ]}
                    onChange={handleReceiveChange}
                    format='YYYY-MM'
                    placeholder={['开始年月', '结束年月']}
                    allowClear={false}
                />
            </div>

            {
                screens.xs
                    ? <div>
                        {
                            listData.length > 0 ? listData.map(item =>
                                <Card className='m-card'>
                                    <div className='m-card_item'>
                                        <div>调解员姓名：</div>
                                        <div>{item.real_name}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>接案数量：</div>
                                        <div>{item.total_count} 宗</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>受理案件数：</div>
                                        <div>{item.acceptance_count} 宗</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>拒绝调解数：</div>
                                        <div>{item.refuse_count} 宗</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>调解成功：</div>
                                        <div>{item.success_count} 宗</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>成功率：</div>
                                        <div>{item.success_percent} %</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>调解失败：</div>
                                        <div>{item.fail_count} 宗</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>调解进行中：</div>
                                        <div>{item.disposing_count} 宗</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>办结案件：</div>
                                        <div>{item.conclude_count} 宗</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>为职工挽回经济损失：</div>
                                        <div>{item.involved_amount_total} 万元</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>涉案金额：</div>
                                        <div>{item.lawsuit_money_total} 万元</div>
                                    </div>
                                </Card>
                            )
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                        }
                    </div>
                    : <Table
                        dataSource={listData}
                        size='small'
                        loading={loading}
                        scroll={{ x: 800 }}
                        rowKey={(record) => record?.id}
                        pagination={{
                            position: ['bottomRight'],
                            size: "small",
                            showTotal: total => `共 ${total} 项`,
                            showSizeChanger: true,
                            showQuickJumper: true,
                        }}>
                        <Column title="调解员姓名" dataIndex="real_name" key="real_name" />
                        <Column title="接案数量（宗）" dataIndex="total_count" key="total_count" />
                        <Column title="受理案件数" dataIndex="acceptance_count" key="acceptance_count" />
                        <Column title="拒绝调解数" dataIndex="refuse_count" key="refuse_count" />
                        <Column title="调解成功（宗）" dataIndex="success_count" key="success_count" />
                        <Column title="成功率（%）" dataIndex="success_percent" key="success_percent" />
                        <Column title="调解失败（宗）" dataIndex="fail_count" key="fail_count" />
                        <Column title="调解进行中（宗）" dataIndex="disposing_count" key="disposing_count" />
                        <Column title="办结案件（宗）" dataIndex="conclude_count" key="conclude_count" />
                        <Column title="为职工挽回经济损失（万元）" dataIndex="involved_amount_total" key="involved_amount_total" />
                        <Column title="涉案金额（万元）" dataIndex="lawsuit_money_total" key="lawsuit_money_total" />
                    </Table>
            }
        </div>
    );
}

export default PreArbitrationStatisticsListModal;