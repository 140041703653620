import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";
import DefaultLayout from "../layout/defaultLayout";
import AccountFormModal from "../views/auth/actions/accountForm";
import RoleFormModal from "../views/auth/roles/actions/roleForm";
import RoleList from "../views/auth/roles/roleList";
import UserList from "../views/auth/userList";
import { NotFoundPage } from "../views/common/404";
import { NothingPage } from "../views/common/nothing";
import ConciliationDetailModal from "../views/conciliation/actions/conciliationDetail";
import ConciliationDetailFormModal from "../views/conciliation/actions/conciliationForm";
import ConciliationList from "../views/conciliation/conciliationList";
import GuidePage from "../views/guide";
import LoginForm from "../views/login/login";
import RegisterOrganization from "../views/login/register/registerOrganization";
import RegisterOrganizationPending from "../views/login/register/registerOrganizationPending";
import RegisterStaff from "../views/login/register/registerStaff";
import RegisterStaffPending from "../views/login/register/registerStaffPending";
import Wizard from "../views/login/register/wizard";
import ChangePasswordFormPage from "../views/mine/password";
import OrderDetailModal from "../views/order/actions/orderDetail";
import OrderList from "../views/order/orderList";
import PreArbitrationConciliationDetailFormModal from "../views/preArbitration/conciliation/preArbitrationConciliationForm";
import PreArbitrationFormModal from "../views/preArbitration/actions/preArbitrationForm";
import PreArbitrationList from "../views/preArbitration/preArbitrationList";
import FileIndexTableFormModal from "../views/preArbitration/tables/fileIndexTable";
import CoverTableFormModal from "../views/preArbitration/tables/coverTable";
import LaborDisputeHandlingTableFormModal from "../views/preArbitration/tables/laborDisputeHandlingTable";
import LaborDisputeRecordTableFormModal from "../views/preArbitration/tables/laborDisputeRecordTable";
import LaborDisputeMediationApplicationTableFormModal from "../views/preArbitration/tables/laborDisputeMediationApplicationTable";
import LaborDisputeMediationAgreementTableFormModal from "../views/preArbitration/tables/laborDisputeMediationAgreementTable";
import PowerOfAttorneyTableFormModal from "../views/preArbitration/tables/powerOfAttorneyTable";
import CaseBackCoverTableFormModal from "../views/preArbitration/tables/backCoverTable";
import ProofOfDeliveryTableFormModal from "../views/preArbitration/tables/proofOfDeliveryTable";
import SocialIssuesRegistryTableFormModal from "../views/preArbitration/tables/socialIssuesRegistryTable";
import PreArbitrationTablesModal from "../views/preArbitration/tables/tableList";
import PreArbitrationPostponeFormModal from "../views/preArbitration/actions/preArbitrationPostponeForm";
import PreArbitrationPlaintiffFormModal from "../views/preArbitration/actions/preArbitrationPlaintiffForm";
import PreArbitrationDefendantFormModal from "../views/preArbitration/actions/preArbitrationDefendantForm";
import PreArbitrationThirdPartyFormModal from "../views/preArbitration/actions/preArbitrationThirdPartyForm";
import ApprovalListPage from "../views/approval/approvalList";
import PreArbitrationAttachmentFormModal from "../views/preArbitration/actions/preArbitrationAttachmentForm";
import PreArbitrationStatisticsListModal from "../views/preArbitration/actions/preArbitrationStatistics";
import PreArbitrationRegionStatisticsListModal from "../views/preArbitration/actions/preArbitrationRegionStatistics";
import CoverTablePrint from "../views/preArbitration/tables/print/coverTable";
import LaborDisputeHandlingTablePrint from "../views/preArbitration/tables/print/laborDisputeHandlingTable";
import LaborDisputeRecordTablePrint from "../views/preArbitration/tables/print/laborDisputeRecordTable";
import ProofOfDeliveryTablePrint from "../views/preArbitration/tables/print/proofOfDeliveryTable";
import LaborDisputeMediationApplicationTablePrint from "../views/preArbitration/tables/print/laborDisputeMediationApplicationTable";
import LaborDisputeMediationAgreementTablePrint from "../views/preArbitration/tables/print/laborDisputeMediationAgreementTable";
import PreArbitrationRefuseAnalysisListModal from "../views/preArbitration/actions/preArbitrationRefuseAnalysis";
interface IRoute {
    path: string | undefined;
    name: string;
    element: JSX.Element;
    children?: IRoute[];
}

/**
 * 路由统一配置
 */
export const routes: IRoute[] = [
    {
        path: '/',
        name: '首页',
        element: <GuidePage />,
    }, {
        path: '/auth',
        name: '权限设置',
        element: <DefaultLayout><UserList /></DefaultLayout>,
    }, {
        path: '/auth/roles',
        name: '角色管理',
        element: <DefaultLayout><RoleList /></DefaultLayout>,
    }, {
        path: '/auth/roles/settings',
        name: '角色设置',
        element: <DefaultLayout><RoleFormModal /></DefaultLayout>,
    }, {
        path: '/auth/account',
        name: '账号管理',
        element: <DefaultLayout><AccountFormModal /></DefaultLayout>,
    }, {
        path: '/order',
        name: '业务管理',
        element: <DefaultLayout><OrderList /></DefaultLayout>,
    }, {
        path: '/order/detail',
        name: '工单详情',
        element: <DefaultLayout><OrderDetailModal /></DefaultLayout>,
    }, {
        path: '/conciliation',
        name: '调解管理',
        element: <DefaultLayout><ConciliationList /></DefaultLayout>,
    }, {
        path: '/conciliation/detail',
        name: '工单详情',
        element: <DefaultLayout><ConciliationDetailModal /></DefaultLayout>,
    }, {
        path: '/conciliation/do',
        name: '工单调解',
        element: <DefaultLayout><ConciliationDetailFormModal /></DefaultLayout>,
    }, {
        path: '/preArbitration',
        name: '裁前管理',
        element: <DefaultLayout><PreArbitrationList /></DefaultLayout>,
    }, {
        path: '/preArbitration/form',
        name: '登记案件',
        element: <DefaultLayout><PreArbitrationFormModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/plaintiff',
        name: '登记申请人管理',
        element: <DefaultLayout><PreArbitrationPlaintiffFormModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/defendant',
        name: '登记被申请人管理',
        element: <DefaultLayout><PreArbitrationDefendantFormModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/thirdParty',
        name: '登记第三人管理',
        element: <DefaultLayout><PreArbitrationThirdPartyFormModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/attachment',
        name: '补充登记附件',
        element: <DefaultLayout><PreArbitrationAttachmentFormModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/conciliation',
        name: '裁前调解',
        element: <DefaultLayout><PreArbitrationConciliationDetailFormModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/tables',
        name: '表单列表',
        element: <DefaultLayout><PreArbitrationTablesModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/fileIndex',
        name: '卷内目录',
        element: <DefaultLayout><FileIndexTableFormModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/caseFrontCover',
        name: '案卷封面',
        element: <DefaultLayout><CoverTableFormModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/caseFrontCover/print',
        name: '案卷封面预览',
        element: <CoverTablePrint />,
    }, {
        path: '/preArbitration/laborDisputeHandling',
        name: '劳动人事争议调解登记处理表',
        element: <DefaultLayout><LaborDisputeHandlingTableFormModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/laborDisputeHandling/print',
        name: '劳动人事争议调解登记处理表预览',
        element: <LaborDisputeHandlingTablePrint />,
    }, {
        path: '/preArbitration/laborDisputeRecord',
        name: '劳动人事争议调解情况记录（双面）',
        element: <DefaultLayout><LaborDisputeRecordTableFormModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/laborDisputeRecord/print',
        name: '劳动人事争议调解情况记录预览',
        element: <LaborDisputeRecordTablePrint />,
    }, {
        path: '/preArbitration/laborDisputeMediationApplication',
        name: '劳动人事争议调解申请书',
        element: <DefaultLayout><LaborDisputeMediationApplicationTableFormModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/laborDisputeMediationApplication/print',
        name: '劳动人事争议调解申请书预览',
        element: <LaborDisputeMediationApplicationTablePrint />,
    }, {
        path: '/preArbitration/laborDisputeMediationAgreement',
        name: '劳动人事争议调解协议书',
        element: <DefaultLayout><LaborDisputeMediationAgreementTableFormModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/laborDisputeMediationAgreement/print',
        name: '劳动人事争议调解协议书预览',
        element: <LaborDisputeMediationAgreementTablePrint />,
    }, {
        path: '/preArbitration/powerOfAttorney',
        name: '授权委托书',
        element: <DefaultLayout><PowerOfAttorneyTableFormModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/caseBackCover',
        name: '案卷封底',
        element: <DefaultLayout><CaseBackCoverTableFormModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/proofOfDelivery',
        name: '送达回证',
        element: <DefaultLayout><ProofOfDeliveryTableFormModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/proofOfDelivery/print',
        name: '送达回证',
        element: <ProofOfDeliveryTablePrint />,
    }, {
        path: '/preArbitration/socialIssuesRegistry',
        name: '涉人社领域问题登记表',
        element: <DefaultLayout><SocialIssuesRegistryTableFormModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/postpone',
        name: '延期',
        element: <DefaultLayout><PreArbitrationPostponeFormModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/statistics',
        name: '调解员案件处理统计',
        element: <DefaultLayout><PreArbitrationStatisticsListModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/regionStatistics',
        name: '地区统计信息',
        element: <DefaultLayout><PreArbitrationRegionStatisticsListModal /></DefaultLayout>,
    },  {
        path: '/preArbitration/refuseAnalysis',
        name: '地区统计拒绝分析',
        element: <DefaultLayout><PreArbitrationRefuseAnalysisListModal /></DefaultLayout>,
    }, {
        path: '/approval',
        name: '审核记录',
        element: <DefaultLayout><ApprovalListPage /></DefaultLayout>,
    }, {
        path: '/password',
        name: '密码修改',
        element: <DefaultLayout><ChangePasswordFormPage /></DefaultLayout>,
    }, {
        path: '/login',
        name: '登录',
        element: <Wizard />,
        children: [
            {
                path: undefined,
                name: 'index',
                element: <LoginForm />,
            },
        ]
    }, {
        path: '/register',
        name: '注册',
        element: <Wizard />,
        children: [
            {
                path: undefined,
                name: 'index',
                element: <RegisterStaff />,
            },
            {
                path: 'pending',
                name: '注册待审批',
                element: <RegisterStaffPending />,
            },
        ]
    }, {
        path: '/registerOrganization',
        name: '机构入驻',
        element: <Wizard />,
        children: [
            {
                path: undefined,
                name: 'index',
                element: <RegisterOrganization />,
            },
            {
                path: 'pending',
                name: '注册待审批',
                element: <RegisterOrganizationPending />,
            },
        ]
    }, {
        path: '*',
        name: '无页面',
        element: <NotFoundPage />
    }
]

// 遍历路由树生成route列表
function getRouteList(routes: IRoute[] | undefined): RouteObject[] {
    if (!routes) return [];

    return routes.map(route => {
        let item: any = {
            path: route.path,
            element: route.element,
        };
        if (route.name === 'index') {
            item.index = true;
        } else {
            item.children = getRouteList(route.children);
        }
        return item;
    });
}

// 构建router
export const router = createBrowserRouter(getRouteList(routes));