import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// antd
import { Grid, Button, Col, Form, Input, message, Row, Select, Upload, Modal } from 'antd';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { UploadRequestOption } from "rc-upload/lib/interface";
import { PlusOutlined, MinusCircleOutlined, UploadOutlined } from '@ant-design/icons';

import { getRolesAllList, getUserDetail, IAddUserForm, IEditUserForm, toAddUser, toEditUser } from '../../../apis/auth';
import { DictItem, FileUpload, getDictList, IDictData, uploadFile } from '../../../apis/common';
import { getBase64 } from '../../../utils/base64';
import { Attachment } from '../../../apis/attachment';
import TextArea from 'antd/lib/input/TextArea';
import { getOssImageThumbUrl } from '../../../utils/oss';

const { Option } = Select;
const { useBreakpoint } = Grid;


interface CollectionCreateFormProps {
    data?: { id: number },
    onFinish?: () => void;
}

let tempFileList: UploadFile<any>[] = [];


function AccountFormModal({
    data,
    onFinish,
}: CollectionCreateFormProps) {
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 表单数据
    const [form] = Form.useForm<IAddUserForm | IEditUserForm>();

    const [workType, setWorkType] = useState<IDictData>();
    const [roles, setRoles] = useState<DictItem[]>();
    useEffect(() => {
        form.resetFields();
        getDictList(['work_type']).then(res => {
            setWorkType(res.data.work_type);
        });
        getRolesAllList().then(res => {
            setRoles(res.data.list);
        });
        if (data?.id || searchParams.get('id')) {
            getUserDetail({ id: data?.id ?? Number(searchParams.get('id')) }).then(res => {
                let data = res.data.detail;
                form.setFieldsValue({
                    ...data,
                    role_ids: data.roles
                });

                // 头像
                if (data.avatar_link) {
                    setAttachAvatar([
                        {
                            uid: `Avatar${data.avatar}`,
                            name: `Avatar${data.avatar}`,
                            type: "image/*",
                            percent: 100,
                            status: "done",
                            response: {
                                attach_name: `Avatar${data.avatar}`,
                                attach_url: data.avatar_link,
                                attach_url_link: data.avatar_link,
                                disk: "oss",
                                id: data.avatar,
                            },
                            thumbUrl: getOssImageThumbUrl(data.avatar_link),
                            preview: data.avatar_link
                        }
                    ]);
                }

                // 附件
                if (data.attach_files_info) {
                    setAttachments(data.attach_files_info.map(attachment => {
                        return {
                            uid: attachment.id.toString(),
                            name: attachment.attach_name ?? '未知文件',
                            response: attachment,
                            status: 'done'
                        }
                    }));
                }

            })
        }
    }, [data, searchParams]);

    // 上传统一方法
    const checkFileSize = (file: RcFile) => {
        if ((file.size / 1024 / 1024) > 50) {
            message.error(`${file.name} 文件超出限制大小`);
            return Upload.LIST_IGNORE;
        }

        return true;
    };
    const customRequest = (options: UploadRequestOption<any>) => {
        const { onSuccess, onError, file, onProgress } = options;
        uploadFile({ file: file as Blob, type: 'default' })
            .then(res => {
                onSuccess!(res.data);
            });
    }
    // 上传头像
    const [attachAvatar, setAttachAvatar] = useState<UploadFile<any>[]>([]);
    const onAvatarChange = (info: UploadChangeParam<UploadFile<any>>) => {
        console.log(info.fileList);
        setAttachAvatar(info.fileList);
    }
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    // 上传附件
    const [attachments, setAttachments] = useState<UploadFile<any>[]>([]);
    const onFileChange = (info: UploadChangeParam<UploadFile<any>>) => {
        console.log(info.fileList, attachments);
        if (tempFileList.length === 0) {
            tempFileList = info.fileList;
        } else if (info.file.status === 'done') {
            tempFileList = [info.file, ...tempFileList.filter((item) => item.uid !== info.file.uid)];
        }
        setAttachments(tempFileList);
    }
    const onAttachmentDownload = (file: UploadFile<any>) => {
        console.log(file);
        let res: FileUpload | Attachment = file.response;
        window.open((res as FileUpload).full_link || (res as Attachment).attach_url_link, "_blank");
    }

    // 提交
    const submit = async () => {
        await form.validateFields();

        setConfirmLoading(true);
        let _form = form.getFieldsValue(true);
        try {
            let data = {
                ..._form,
                avatar: attachAvatar.length > 0 ? attachAvatar
                    .filter(file => file.response)
                    .map(file => {
                        let res: FileUpload | Attachment = file.response;
                        return (res as FileUpload).file_id || (res as Attachment).id;
                    })[0] : undefined,
                attach_files: attachments.length > 0 ? attachments
                    .filter(file => file.response)
                    .map(file => {
                        let res: FileUpload | Attachment = file.response;
                        return (res as FileUpload).file_id || (res as Attachment).id;
                    }) : undefined,
            }
            let res = data?.id ? await toEditUser(data) : await toAddUser(data);
            message.success(res.msg);
            if (onFinish) {
                onFinish();
            } else {
                navigate('/auth');
            }
        } catch (e) {
            console.error(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    const gutter = 16 + 8 * 2;

    return (
        <div className={screens.xs ? 'inner-page mobile' : ''}>
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item label="头像">
                    <div style={{ position: 'relative', width: 'fit-content' }}>
                        {
                            screens.xs && attachAvatar.length > 0 &&
                            <MinusCircleOutlined style={{
                                fontSize: 20,
                                color: 'red',
                                position: 'absolute',
                                right: 0,
                                top: -8
                            }} onClick={
                                () => setAttachAvatar([])
                            } />
                        }
                        <Upload
                            listType="picture-card"
                            fileList={attachAvatar}
                            beforeUpload={checkFileSize}
                            onChange={onAvatarChange}
                            customRequest={customRequest}
                            onPreview={handlePreview}
                            accept='image/jpeg,image/jpg,image/png'
                        >
                            {attachAvatar.length >= 1 ? null : <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>上传图片</div>
                            </div>}
                        </Upload>
                    </div>
                </Form.Item>

                <Form.Item
                    label="用户账户"
                    name="username"
                    rules={[{ required: true, message: '请填写用户账户' }]}
                    extra="初始密码统一：888888 第一次登录需要强制修改密码"
                >
                    <Input maxLength={20} />
                </Form.Item>

                <Row gutter={gutter}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>

                        <Form.Item
                            label="真实姓名"
                            name="real_name"
                            rules={[{ required: true, message: '请填写真实姓名' }]}
                        >
                            <Input maxLength={20} />
                        </Form.Item>

                        <Form.Item
                            label="部门"
                            name="dept_name"
                        >
                            <Input maxLength={20} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="单位"
                            name="company_name"
                        >
                            <Input maxLength={50} />
                        </Form.Item>


                        <Form.Item
                            label="岗位"
                            name="post_name"
                        >
                            <Input maxLength={20} />
                        </Form.Item>
                    </Col>
                </Row>


                <Form.Item
                    label="标识"
                    name="group_ids"
                >
                    <Select
                        mode="multiple"
                        showArrow
                    >
                        {
                            workType?.options.map(item => (

                                <Option key={item.value} value={item.value}>{item.name}</Option>
                            ))
                        }
                    </Select>
                </Form.Item>

                <Form.Item
                    label="角色选择"
                    name="role_ids"
                >
                    <Select
                        mode="multiple"
                        showArrow
                    >
                        {
                            roles?.map(item => (
                                <Option key={item.id} value={item.id}>{item.name}</Option>
                            ))
                        }
                    </Select>
                </Form.Item>

                <Form.Item
                    label="简介"
                    name='introduction'
                >
                    <TextArea rows={2} />
                </Form.Item>

                <Form.Item
                    label="附件上传"
                    name='attach_files'
                >
                    <Upload
                        multiple
                        fileList={attachments}
                        beforeUpload={checkFileSize}
                        onChange={onFileChange}
                        customRequest={customRequest}
                        showUploadList={{ showDownloadIcon: true }}
                        onDownload={onAttachmentDownload}
                    >
                        <Button icon={<UploadOutlined />}>点击上传附件</Button>
                    </Upload>
                </Form.Item>

                <Button block type='primary' size='large' loading={confirmLoading} onClick={submit}>提交</Button>
            </Form>

            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={() => setPreviewOpen(false)}>
                <img alt="logo" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </div>
    );
}

export default AccountFormModal;