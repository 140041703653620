import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// antd
import { Grid, Button, Col, Form, Input, message, Row, Select, Upload, Modal, InputNumber, Divider, Skeleton, List, Card, Carousel, Image, Cascader, Radio, Descriptions, Typography } from 'antd';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { EditOutlined, CloseCircleOutlined, UploadOutlined, SmileOutlined, RightCircleOutlined } from '@ant-design/icons';
import DatePicker from '../../../components/customAntd/DatePicker';
import { DatePicker as DatePickerMobile } from 'antd-mobile';

import { getDictList, IDictData, uploadFile } from '../../../apis/common';
import TextArea from 'antd/lib/input/TextArea';
import dayjs, { Dayjs } from 'dayjs';

import { Cascader as CascaderMobile, CascaderOption, ImageUploader, ImageUploadItem, Popup } from 'antd-mobile';

import { getPreArbitrationFormUserList, getPreArbitrationRegionCaseRule, getPreArbitrationSelectRegion, IAttachFilesData, INaturalPeople, IPreArbitrationCompany, IPreArbitrationForm, IPreArbitrationFormUserListData, IPreArbitrationRegionCaseRuleForm, IPreArbitrationSelectRegionData, IThirdPartyCompany, IThirdPartyNaturalPeople, savePreArbitrationForm } from '../../../apis/preArbitration';
import PreArbitrationNaturalPeopleFormModal, { INaturalPeopleForm, IThirdPartyNaturalPeopleForm } from './preArbitrationNaturalPeopleForm';
import PreArbitrationCompanyFormModal, { IPreArbitrationCompanyForm, IThirdPartyCompanyForm } from './preArbitrationCompanyForm';
import { PickerDate } from 'antd-mobile/es/components/date-picker/util';

const { Option } = Select;
const { useBreakpoint } = Grid;

/**
 * plaintiff 申请人
 * defendant 被申请人
 */
enum formNameTypes {
    plaintiffDataNaturalPeopleForm = 'plaintiffDataNaturalPeopleForm',
    plaintiffDataCompanyForm = 'plaintiffDataCompanyForm',
    defendantDataNaturalPeopleForm = 'defendantDataNaturalPeopleForm',
    defendantDataCompanyForm = 'defendantDataCompanyForm',
    thirdPartyNaturalPeopleForm = 'thirdPartyNaturalPeopleForm',
    thirdPartyCompanyForm = 'thirdPartyCompanyForm',
}

interface CollectionCreateFormProps {
    data?: { id: number },
    onFinish?: () => void;
}

function PreArbitrationFormModal({
    data,
    onFinish,
}: CollectionCreateFormProps) {
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 表单数据
    const [form] = Form.useForm<IPreArbitrationForm>();


    const [lawsuitType, setLawsuitType] = useState<IDictData>();
    const [questionType, setQuestionType] = useState<IDictData>();
    const [disputeType, setDisputeType] = useState<IDictData>();
    const [sourceType, setSourceType] = useState<IDictData>();
    const [type, setType] = useState<IDictData>();
    const [caseCategory, setCaseCategory] = useState<IDictData>();

    // 当前机构所在地区下所有人员列表
    const [staffs, setStaffs] = useState<IPreArbitrationFormUserListData[]>([]);
    useEffect(() => {
        getPreArbitrationFormUserList().then(res => {
            setStaffs(res.data.list);
        })
    }, []);

    // 接件日期
    const [receiveAtVisible, setReceiveAtVisible] = useState(false);

    // 受理时间
    const [acceptanceVisible, setAcceptanceVisible] = useState(false);

    // 初始化加载
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        form.resetFields();
        setLoading(true);

        getDictList([
            'pre_worksheet_source_type',
            'worksheet_lawsuit_type',
            'worksheet_question_type',
            'pre_worksheet_type',
            'pre_worksheet_case_category',
            'pre_worksheet_dispute_type'
        ]).then(res => {
            setSourceType(res.data.pre_worksheet_source_type);
            setLawsuitType(res.data.worksheet_lawsuit_type);
            setQuestionType(res.data.worksheet_question_type);
            setType(res.data.pre_worksheet_type);
            setCaseCategory(res.data.pre_worksheet_case_category);
            setDisputeType(res.data.pre_worksheet_dispute_type);
        }).finally(() => {
            setLoading(false);
        });
    }, [data, searchParams]);

    // 获取案号
    const getCaseData = async ({ location }: {
        location: IPreArbitrationRegionCaseRuleForm
    }) => {
        try {
            let res = await getPreArbitrationRegionCaseRule(location);
            form.setFieldValue('case_number', res.data.case_number);
            form.setFieldValue('attach_files_data', res.data.attachment_tags_data?.map(item => ({
                ...item,
                files: []
            })) ?? []);
        } catch (e) {
            console.error(e);
        }
    };

    // 上传附件
    const [fileListMap, setFileListMap] = useState<Record<number, ImageUploadItem[]>>({});
    const beforeUpload = (file: File) => {
        if (file.size > 1024 * 1024 * 50) {
            message.warning('请选择小于 50M 的图片');
            return null;
        }
        return file;
    }
    const imageUploaderUpload = (name: string, value: number) => {
        return async (file: File) => {
            let res = await uploadFile({ file: file as Blob, type: 'pre_worksheet' });
            return {
                key: res.data.file_id,
                extra: {
                    name,
                    value,
                    data: res.data
                },
                url: res.data.full_link,
            };
        }
    };
    const onImageUploaderChange = (value: number) => {
        return (items: ImageUploadItem[]) => {
            let attachFilesData: IAttachFilesData[] = form.getFieldValue('attach_files_data');
            attachFilesData.find(item => item.value === value)!.files = items.map(item => item.key as number);
            form.setFieldValue('attach_files_data', attachFilesData);
            setFileListMap(data => {
                data[value] = items;
                return data;
            });
        };
    };

    // 提交
    const submit = async () => {
        try {
            await form.validateFields();
        } catch (e: any) {
            message.warning(e.errorFields[0].errors[0]);
            return;
        }
        let _form: IPreArbitrationForm = form.getFieldsValue(true);
        if (
            !_form.plaintiff_data ||
            (!_form.plaintiff_data.natural_people_list && !_form.plaintiff_data.company_list) ||
            ((_form.plaintiff_data.natural_people_list ?? []).length === 0 && (_form.plaintiff_data.company_list ?? []).length === 0)
        ) {
            message.warning('请至少添加一个申请人');
            return;
        }
        if (
            !_form.defendant_data ||
            (!_form.defendant_data.natural_people_list && !_form.defendant_data.company_list) ||
            ((_form.defendant_data.natural_people_list ?? []).length === 0 && (_form.defendant_data.company_list ?? []).length === 0)
        ) {
            message.warning('请至少添加一个被申请人');
            return;
        }

        setConfirmLoading(true);
        try {
            let data = {
                ..._form,
                receive_at: _form.receive_at ? (_form.receive_at as unknown as Dayjs).format('YYYY-MM-DD') : undefined,
                acceptance_date: _form.acceptance_date ? (_form.acceptance_date as unknown as Dayjs).format('YYYY-MM-DD') : undefined,
            }

            let res = await savePreArbitrationForm(data);
            message.success(res.msg);
            if (onFinish) {
                onFinish();
            } else {
                navigate('/preArbitration');
            }
        } catch (e) {
            console.error(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    // 省/市/区/街道
    interface Option {
        value: string | number;
        label: string;
        children?: Option[];
    }
    const [options, setOptions] = useState<Option[]>([]);
    useEffect(() => {
        let nodes: IPreArbitrationSelectRegionData[] = [];
        const build = (list: IPreArbitrationSelectRegionData[]): Option[] => {
            return list.map(item => ({
                label: item.name,
                value: item.id,
                children: item.children.length > 0 ? build(item.children) : undefined
            }));
        }
        getPreArbitrationSelectRegion().then(res => {
            nodes.push(...res.data.list);
            setOptions(build(nodes));

            try {
                let province = nodes[0];
                let city = province.children[0];
                let area = city.children[0];
                let street = area.children[0];
                let locations = [province.id, city.id, area.id, street.id];
                form.setFieldValue('cur_id', locations);
                onCascaderChange(locations);
            } catch (e) {
                message.error('无可用地区');
            }
        });
    }, []);
    type CascaderValueExtend = {
        items: (CascaderOption | null)[]
        isLeaf: boolean
    }
    const onCascaderMobileConfirm = (value: string[], extend: CascaderValueExtend) => {
        if (extend.items.length !== 4) {
            message.warning('地址请选择完整');
            return;
        }

        onCascaderChange(extend.items.map(item => Number(item!.value)));
    }
    const onCascaderChange = (value: any[]) => {
        let location = {
            province_id: value[0],
            city_id: value[1],
            area_id: value[2],
            street_id: value[3],
        };
        form.setFieldsValue(location);

        // 选择地区后获取地区数据
        getCaseData({ location });
    }

    // 添加人员弹窗
    const [addEditNaturalPeopleOpen, setAddEditNaturalPeopleOpen] = useState(false);
    const [addEditNaturalPeopleFormName, setAddEditNaturalPeopleFormName] = useState('');
    const [addEditNaturalPeopleData, setAddEditNaturalPeopleData] = useState<INaturalPeopleForm>();
    const showAddEditNaturalPeopleModal = (formName: string, data?: INaturalPeople, index?: number) => {
        setAddEditNaturalPeopleFormName(formName);
        setAddEditNaturalPeopleOpen(true);
        if (data && typeof index === 'number') {
            setAddEditNaturalPeopleData({
                ...data,
                index
            });
        } else {
            setAddEditNaturalPeopleData(undefined);
        }
    };
    const hideAddEditNaturalPeopleModal = () => {
        setAddEditNaturalPeopleOpen(false);
    };
    const onAddEditNaturalPeopleDelClick = (index: number, path: string) => {
        let naturalPeople: INaturalPeople[] = form.getFieldValue([path, 'natural_people_list']);
        console.log(naturalPeople);
        form.setFieldValue([path, 'natural_people_list'], naturalPeople.filter((_, i) => index !== i));
    }
    // 添加企业弹窗
    const [addEditCompanyOpen, setAddEditCompanyOpen] = useState(false);
    const [addEditCompanyFormName, setAddEditCompanyFormName] = useState('');
    const [addEditCompanyData, setAddEditCompanyData] = useState<IPreArbitrationCompanyForm>();
    const showAddEditCompanyModal = (formName: string, data?: IPreArbitrationCompanyForm, index?: number) => {
        setAddEditCompanyFormName(formName);
        if (data && typeof index === 'number') {
            setAddEditCompanyData({
                ...data,
                index
            });
        } else {
            setAddEditCompanyData(undefined);
        }
        setAddEditCompanyOpen(true);
    };
    const hideAddEditCompanyModal = () => {
        setAddEditCompanyOpen(false);
    };
    const onAddEditCompanyDelClick = (index: number, path: string) => {
        let company: IPreArbitrationCompanyForm[] = form.getFieldValue([path, 'company_list']);
        console.log(company);
        form.setFieldValue([path, 'company_list'], company.filter((_, i) => index !== i));
    }

    // 添加第三方弹窗
    const [thirdPartyType, setThirdPartyType] = useState(1);
    type IThirdPartyForm = IThirdPartyCompanyForm | IThirdPartyNaturalPeopleForm;
    const [addEditThirdPartyOpen, setAddEditThirdPartyOpen] = useState(false);
    const [addEditThirdPartyData, setAddEditThirdPartyData] = useState<IThirdPartyForm>();
    const showAddEditThirdPartyModal = (type: number, data?: IThirdPartyForm, index?: number) => {
        setThirdPartyType(type);
        setAddEditThirdPartyOpen(true);
        if (data && typeof index === 'number') {
            setAddEditThirdPartyData({
                ...data,
                index
            });
        } else {
            setAddEditThirdPartyData(undefined);
        }
    };
    const hideAddEditThirdPartyModal = () => {
        setAddEditThirdPartyOpen(false);
    };
    const onAddEditThirdPartyDelClick = (index: number) => {
        let item: IThirdPartyForm[] = form.getFieldValue('third_party');
        form.setFieldValue('third_party', item.filter((_, i) => index !== i));
    }

    const thirdPartyComponent = (
        <>
            <Form.Item>
                <Radio.Group value={thirdPartyType} onChange={(e) => setThirdPartyType(e.target.value)}>
                    <Radio value={1}>个人</Radio>
                    <Radio value={2}>企业</Radio>
                </Radio.Group>
            </Form.Item>

            {
                thirdPartyType === 1 &&
                <PreArbitrationNaturalPeopleFormModal
                    key={Math.random()}
                    thirdParty
                    formName={formNameTypes.thirdPartyNaturalPeopleForm}
                    data={addEditThirdPartyData as IThirdPartyNaturalPeopleForm}
                ></PreArbitrationNaturalPeopleFormModal>
            }
            {
                thirdPartyType === 2 &&
                <PreArbitrationCompanyFormModal
                    key={Math.random()}
                    thirdParty
                    formName={formNameTypes.thirdPartyCompanyForm}
                    data={addEditThirdPartyData as IThirdPartyCompanyForm}
                ></PreArbitrationCompanyFormModal>
            }
        </>
    );


    // style
    const gutter = 16 + 8 * 2;

    const FormItem = (
        <Form.Provider
            onFormFinish={(name, { values, forms }) => {
                // =================== 个人 =====================
                // 申请人
                if (name === formNameTypes.plaintiffDataNaturalPeopleForm) {
                    const { plaintiffDataNaturalPeopleForm } = forms;
                    const naturalPeople = plaintiffDataNaturalPeopleForm.getFieldsValue(true);
                    const naturalPeopleListData = form.getFieldValue(['plaintiff_data', 'natural_people_list']) || [];

                    // 默认有且只有一个
                    if (naturalPeople.is_default) {
                        naturalPeopleListData.forEach((item: INaturalPeopleForm) => item.is_default = false);
                    } else if (naturalPeopleListData.every((item: INaturalPeopleForm) => item.is_default === false)) {
                        naturalPeople.is_default = true;
                    }

                    console.log(naturalPeople);
                    if (naturalPeople.index !== undefined) {
                        let newNaturalPeopleList = [...naturalPeopleListData];
                        newNaturalPeopleList.splice(naturalPeople.index, 1, naturalPeople);
                        form.setFieldValue(['plaintiff_data', 'natural_people_list'], newNaturalPeopleList);
                    } else {
                        form.setFieldValue(['plaintiff_data', 'natural_people_list'], [...naturalPeopleListData, naturalPeople]);
                    }
                    setAddEditNaturalPeopleOpen(false);
                }
                // 被申请人
                if (name === formNameTypes.defendantDataNaturalPeopleForm) {
                    const { defendantDataNaturalPeopleForm } = forms;
                    const naturalPeople = defendantDataNaturalPeopleForm.getFieldsValue(true);
                    const naturalPeopleListData = form.getFieldValue(['defendant_data', 'natural_people_list']) || [];

                    // 默认有且只有一个
                    if (naturalPeople.is_default) {
                        naturalPeopleListData.forEach((item: INaturalPeopleForm) => item.is_default = false);
                    } else if (naturalPeopleListData.every((item: INaturalPeopleForm) => item.is_default === false)) {
                        naturalPeople.is_default = true;
                    }

                    console.log(naturalPeople);
                    if (naturalPeople.index !== undefined) {
                        let newNaturalPeopleList = [...naturalPeopleListData];
                        newNaturalPeopleList.splice(naturalPeople.index, 1, naturalPeople);
                        form.setFieldValue(['defendant_data', 'natural_people_list'], newNaturalPeopleList);
                    } else {
                        form.setFieldValue(['defendant_data', 'natural_people_list'], [...naturalPeopleListData, naturalPeople]);
                    }
                    setAddEditNaturalPeopleOpen(false);
                }

                // =================== 企业 =====================
                // 申请人
                if (name === formNameTypes.plaintiffDataCompanyForm) {
                    const { plaintiffDataCompanyForm } = forms;
                    const company = plaintiffDataCompanyForm.getFieldsValue(true);
                    const companyListData = form.getFieldValue(['plaintiff_data', 'company_list']) || [];

                    // 默认有且只有一个
                    if (company.is_default) {
                        companyListData.forEach((item: IPreArbitrationCompanyForm) => item.is_default = false);
                    } else if (companyListData.every((item: IPreArbitrationCompanyForm) => item.is_default === false)) {
                        company.is_default = true;
                    }

                    console.log(company);
                    if (company.index !== undefined) {
                        let newCompanyList = [...companyListData];
                        newCompanyList.splice(company.index, 1, company);
                        form.setFieldValue(['plaintiff_data', 'company_list'], newCompanyList);
                    } else {
                        form.setFieldValue(['plaintiff_data', 'company_list'], [...companyListData, company]);
                    }
                    setAddEditCompanyOpen(false);
                }
                // 被申请人
                if (name === formNameTypes.defendantDataCompanyForm) {
                    const { defendantDataCompanyForm } = forms;
                    const company = defendantDataCompanyForm.getFieldsValue(true);
                    const companyListData = form.getFieldValue(['defendant_data', 'company_list']) || [];

                    // 默认有且只有一个
                    if (company.is_default) {
                        companyListData.forEach((item: IPreArbitrationCompanyForm) => item.is_default = false);
                    } else if (companyListData.every((item: IPreArbitrationCompanyForm) => item.is_default === false)) {
                        company.is_default = true;
                    }

                    console.log(company);
                    if (company.index !== undefined) {
                        let newCompanyList = [...companyListData];
                        newCompanyList.splice(company.index, 1, company);
                        form.setFieldValue(['defendant_data', 'company_list'], newCompanyList);
                    } else {
                        form.setFieldValue(['defendant_data', 'company_list'], [...companyListData, company]);
                    }
                    setAddEditCompanyOpen(false);
                }
                // =================== 第三方 =====================
                // 个人
                if (name === formNameTypes.thirdPartyNaturalPeopleForm) {
                    const { thirdPartyNaturalPeopleForm } = forms;
                    const naturalPeople: INaturalPeopleForm = thirdPartyNaturalPeopleForm.getFieldsValue(true);
                    const thirdPartyListData: IThirdPartyForm[] = form.getFieldValue('third_party') || [];
                    console.log(naturalPeople);

                    let thirdPartyCompany: IThirdPartyNaturalPeopleForm = {
                        subject_type: 1,
                        index: naturalPeople.index,
                        natural_people: naturalPeople
                    };


                    if (thirdPartyCompany.index !== undefined) {
                        let newThirdPartyList = [...thirdPartyListData];
                        newThirdPartyList.splice(thirdPartyCompany.index, 1, thirdPartyCompany);
                        form.setFieldValue('third_party', newThirdPartyList);
                    } else {
                        form.setFieldValue('third_party', [...thirdPartyListData, thirdPartyCompany]);
                    }
                    setAddEditThirdPartyOpen(false);
                }

                // 企业
                if (name === formNameTypes.thirdPartyCompanyForm) {
                    const { thirdPartyCompanyForm } = forms;
                    const company: IPreArbitrationCompanyForm = thirdPartyCompanyForm.getFieldsValue(true);
                    const thirdPartyListData: IThirdPartyForm[] = form.getFieldValue('third_party') || [];
                    console.log(company);

                    let thirdPartyCompany: IThirdPartyCompanyForm = {
                        subject_type: 2,
                        index: company.index,
                        company: company
                    };

                    if (thirdPartyCompany.index !== undefined) {
                        let newThirdPartyList = [...thirdPartyListData];
                        newThirdPartyList.splice(thirdPartyCompany.index, 1, thirdPartyCompany);
                        form.setFieldValue('third_party', newThirdPartyList);
                    } else {
                        form.setFieldValue('third_party', [...thirdPartyListData, thirdPartyCompany]);
                    }
                    setAddEditThirdPartyOpen(false);
                }
            }}
        >
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                initialValues={{
                    receive_at: dayjs(),
                    plaintiff_subject_type: 1,
                    defendant_subject_type: 2,
                    is_soldier: false,
                    is_disability: false,
                }}
            >
                <Form.Item
                    label="记录员"
                    name="organ_user_id"
                    rules={[{ required: true, message: '请选择记录员' }]}
                >
                    <Select showArrow>
                        {staffs?.map(option => (
                            <Option key={option.id} value={option.id}>{option.username}({option.real_name})</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Row gutter={gutter}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        {
                            screens.xs ?
                                <Form.Item
                                    label="接件日期"
                                    shouldUpdate={(prevValues, curValues) => prevValues.receive_at !== curValues.receive_at}
                                >
                                    <DatePickerMobile
                                        visible={receiveAtVisible}
                                        onClose={() => {
                                            setReceiveAtVisible(false)
                                        }}
                                        value={(form.getFieldValue('receive_at') as Dayjs)?.toDate()}
                                        onConfirm={(value: PickerDate) => {
                                            form.setFieldValue('receive_at', dayjs(value));
                                        }}
                                    >

                                        {value =>
                                            <>
                                                <Button
                                                    style={{ marginRight: 10 }}
                                                    onClick={() => {
                                                        setReceiveAtVisible(true)
                                                    }}
                                                >选择</Button>
                                                {value ? dayjs(value).format('YYYY年MM月DD日') : '请选择时间'}
                                            </>
                                        }
                                    </DatePickerMobile>
                                </Form.Item>
                                : <Form.Item
                                    label="接件日期"
                                    name="receive_at"
                                >
                                    <DatePicker
                                        format='YYYY-MM-DD'
                                    />
                                </Form.Item>
                        }
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        {
                            screens.xs ?
                                <Form.Item
                                    label="受理时间"
                                    rules={[{ required: true, message: '请选择受理时间' }]}
                                    shouldUpdate={(prevValues, curValues) => prevValues.acceptance_date !== curValues.acceptance_date}
                                >
                                    <DatePickerMobile
                                        visible={acceptanceVisible}
                                        onClose={() => {
                                            setAcceptanceVisible(false)
                                        }}
                                        value={(form.getFieldValue('acceptance_date') as Dayjs)?.toDate()}
                                        onConfirm={(value: PickerDate) => {
                                            form.setFieldValue('acceptance_date', dayjs(value));
                                        }}
                                    >

                                        {value =>
                                            <>
                                                <Button
                                                    style={{ marginRight: 10 }}
                                                    onClick={() => {
                                                        setAcceptanceVisible(true)
                                                    }}
                                                >选择</Button>
                                                {value ? dayjs(value).format('YYYY年MM月DD日') : '请选择时间'}
                                            </>
                                        }
                                    </DatePickerMobile>
                                </Form.Item>
                                : <Form.Item
                                    label="受理时间"
                                    rules={[{ required: true, message: '请选择受理时间' }]}
                                    name="acceptance_date"
                                >
                                    <DatePicker
                                        format='YYYY-MM-DD'
                                    />
                                </Form.Item>
                        }
                    </Col>
                </Row>
                <Row gutter={gutter}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item label="案号" name="case_number">
                            <Input maxLength={100} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item label="工单号" name="order_no">
                            <Input maxLength={100} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="省/市/区/街道"
                            name="cur_id"
                            rules={[{ required: true, message: '请选择省/市/区/街道' }]}
                            trigger={screens.xs ? 'onConfirm' : undefined}
                        >
                            {
                                screens.xs
                                    ? <CascaderMobile
                                        options={options as any}
                                        onConfirm={onCascaderMobileConfirm}
                                    >
                                        {(items, actions) => (
                                            <>
                                                <Button onClick={actions.open} style={{ marginRight: 10 }}>选择</Button>
                                                {
                                                    items.every(item => item === null)
                                                        ? '请选择处理街道'
                                                        : items.map(item => item?.label ?? '请选择处理街道').join('-')
                                                }
                                            </>
                                        )}
                                    </CascaderMobile>
                                    : <Cascader options={options} placeholder="请选择处理街道" onChange={onCascaderChange} />
                            }
                        </Form.Item>
                    </Col>
                    {/* <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="问题类别"
                            name="question_type"
                            rules={[{ required: true, message: '请选择问题类别' }]}
                        >
                            <Select showArrow>
                                {questionType?.options.map(option => (
                                    <Option key={option.value} value={option.value}>{option.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col> */}
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="争议类型"
                            name="dispute_type"
                            rules={[{ required: true, message: '请选择争议类型' }]}
                        >
                            <Select showArrow>
                                {disputeType?.options.map(option => (
                                    <Option key={option.value} value={option.value}>{option.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    {/* <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="来源方式"
                            name="source_type"
                            rules={[{ required: true, message: '请选择来源方式' }]}
                        >
                            <Select showArrow>
                                {sourceType?.options.map(option => (
                                    <Option key={option.value}>{option.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col> */}
                    {/* <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="案件类别"
                            name="case_category"
                            rules={[{ required: true, message: '请选择案件类别' }]}
                        >
                            <Select showArrow>
                                {caseCategory?.options.map(option => (
                                    <Option key={option.value} value={option.value}>{option.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col> */}
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="案件类型"
                            name="type"
                            rules={[{ required: true, message: '请选择案件类型' }]}
                        >
                            <Select showArrow>
                                {type?.options.map(option => (
                                    <Option key={option.value} value={option.value}>{option.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    {/* <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="投诉类别"
                            name="lawsuit_type"
                            rules={[{ required: true, message: '请选择投诉类别' }]}
                        >
                            <Select showArrow>
                                {lawsuitType?.options.map(option => (
                                    <Option key={option.value}>{option.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col> */}
                    {/* <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="涉案人数"
                            name="involve_people"
                            rules={[{ required: true, message: '请填写涉案人数' }]}
                        >
                            <InputNumber max={10000} min={0} addonAfter="人" />
                        </Form.Item>
                    </Col> */}
                </Row>

                {/* <Row gutter={gutter}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="是否涉军"
                            name="is_soldier"
                            rules={[{ required: true, message: '请选择是否涉军' }]}
                        >
                            <Radio.Group>
                                <Radio value={false}>否</Radio>
                                <Radio value={true}>是</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="是否残疾"
                            name="is_disability"
                            rules={[{ required: true, message: '请选择是否残疾' }]}
                        >
                            <Radio.Group>
                                <Radio value={false}>否</Radio>
                                <Radio value={true}>是</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row> */}

                {/* <Row gutter={gutter}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="原案号"
                            name="order_no"
                        >
                            <Input maxLength={50} />
                        </Form.Item>
                    </Col>
                </Row> */}

                <Form.Item
                    label="案由"
                    name='case_cause'
                    rules={[{ required: true, message: '请输入案由' }]}
                >
                    <TextArea rows={1} />
                </Form.Item>

                <Form.Item
                    label="诉求"
                    name='issue_appeal'
                    rules={[{ required: true, message: '请输入诉求' }]}
                >
                    <TextArea rows={3} />
                </Form.Item>

                <Row gutter={gutter}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="涉案金额"
                            name="lawsuit_money"
                            rules={[{ required: true, message: '请输入涉案金额' }]}
                        >
                            <InputNumber style={{ width: '100%' }} min={0} precision={2} addonAfter="元" />
                        </Form.Item>
                    </Col>
                    {/* <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="接案人"
                            name="receiver"
                            rules={[{ required: true, message: '请输入接案人' }]}
                        >
                            <Input maxLength={10} />
                        </Form.Item>
                    </Col> */}
                </Row>

                <Divider orientation="left" plain>申请人</Divider>
                <Form.Item
                    label="申请人类型"
                    name="plaintiff_subject_type"
                    rules={[{ required: true, message: '请选择申请人类型' }]}
                >
                    <Radio.Group>
                        <Radio value={1}>自然人</Radio>
                        <Radio value={2}>企业</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => prevValues.plaintiff_subject_type !== curValues.plaintiff_subject_type}
                >
                    {({ getFieldValue }) => {
                        let plaintiffSubjectType = getFieldValue('plaintiff_subject_type');
                        return (
                            <>
                                <Form.Item
                                    label="添加申请人"
                                    required
                                    shouldUpdate={(prevValues, curValues) => prevValues.plaintiff_data !== curValues.plaintiff_data}
                                >
                                    {({ getFieldValue }) => {
                                        var itemData: INaturalPeople[] | IPreArbitrationCompany[];
                                        switch (plaintiffSubjectType) {
                                            case 1:
                                                itemData = getFieldValue(['plaintiff_data', 'natural_people_list']) || [];
                                                break;
                                            case 2:
                                                itemData = getFieldValue(['plaintiff_data', 'company_list']) || [];
                                                break;

                                            default:
                                                return;
                                        }

                                        return itemData.length ? (
                                            <Descriptions size='small' bordered column={1}>
                                                {itemData.map((item, index) => (
                                                    <Descriptions.Item>
                                                        <div className="ding__code">
                                                            {
                                                                plaintiffSubjectType === 1 &&
                                                                <div>{`${(item as INaturalPeople).real_name}(${(item as INaturalPeople).mobile})`}{item.is_default ? '(默认)' : ''}</div>
                                                            }
                                                            {
                                                                plaintiffSubjectType === 2 &&
                                                                <div>{`${(item as IPreArbitrationCompany).company_name}`}{item.is_default ? '(默认)' : ''}</div>
                                                            }
                                                            <div style={{ minWidth: 64 }}>
                                                                <Button
                                                                    type="text"
                                                                    icon={<EditOutlined />}
                                                                    onClick={() => {
                                                                        switch (plaintiffSubjectType) {
                                                                            case 1:
                                                                                showAddEditNaturalPeopleModal(formNameTypes.plaintiffDataNaturalPeopleForm, (item as any), index);
                                                                                break;

                                                                            case 2:
                                                                                showAddEditCompanyModal(formNameTypes.plaintiffDataCompanyForm, (item as any), index);
                                                                                break;
                                                                        }
                                                                    }}
                                                                />
                                                                <Button
                                                                    type="text"
                                                                    danger
                                                                    icon={<CloseCircleOutlined />}
                                                                    onClick={() => {
                                                                        switch (plaintiffSubjectType) {
                                                                            case 1:
                                                                                onAddEditNaturalPeopleDelClick(index, 'plaintiff_data');
                                                                                break;

                                                                            case 2:
                                                                                onAddEditCompanyDelClick(index, 'plaintiff_data');
                                                                                break;
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Descriptions.Item>
                                                ))}
                                            </Descriptions>
                                        ) : (
                                            <Typography.Text className="ant-form-text" type="secondary">
                                                ( <SmileOutlined /> 暂无添加数据。 )
                                            </Typography.Text>
                                        );
                                    }}
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        htmlType="button"
                                        onClick={() => {
                                            switch (plaintiffSubjectType) {
                                                case 1:
                                                    showAddEditNaturalPeopleModal(formNameTypes.plaintiffDataNaturalPeopleForm);
                                                    break;

                                                case 2:
                                                    showAddEditCompanyModal(formNameTypes.plaintiffDataCompanyForm);
                                                    break;
                                            }
                                        }}
                                    >
                                        添加申请人
                                    </Button>
                                </Form.Item>
                            </>
                        );
                    }}
                </Form.Item>

                <Divider orientation="left" plain>被申请人</Divider>
                <Form.Item
                    label="被申请人类型"
                    name="defendant_subject_type"
                    rules={[{ required: true, message: '请选择被申请人类型' }]}
                >
                    <Radio.Group>
                        <Radio value={1}>自然人</Radio>
                        <Radio value={2}>企业</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => prevValues.defendant_subject_type !== curValues.defendant_subject_type}
                >
                    {({ getFieldValue }) => {
                        let defendantSubjectType = getFieldValue('defendant_subject_type');
                        return (
                            <>
                                <Form.Item
                                    label="添加被申请人"
                                    required
                                    shouldUpdate={(prevValues, curValues) => prevValues.defendant_data !== curValues.defendant_data}
                                >
                                    {({ getFieldValue }) => {
                                        var itemData: INaturalPeople[] | IPreArbitrationCompany[];
                                        switch (defendantSubjectType) {
                                            case 1:
                                                itemData = getFieldValue(['defendant_data', 'natural_people_list']) || [];
                                                break;
                                            case 2:
                                                itemData = getFieldValue(['defendant_data', 'company_list']) || [];
                                                break;

                                            default:
                                                return;
                                        }

                                        return itemData.length ? (
                                            <Descriptions size='small' bordered column={1}>
                                                {itemData.map((item, index) => (
                                                    <Descriptions.Item>
                                                        <div className="ding__code">
                                                            {
                                                                defendantSubjectType === 1 &&
                                                                <div>{`${(item as INaturalPeople).real_name}(${(item as INaturalPeople).mobile})`}{item.is_default ? '(默认)' : ''}</div>
                                                            }
                                                            {
                                                                defendantSubjectType === 2 &&
                                                                <div>{`${(item as IPreArbitrationCompany).company_name}`}{item.is_default ? '(默认)' : ''}</div>
                                                            }
                                                            <div style={{ minWidth: 64 }}>
                                                                <Button
                                                                    type="text"
                                                                    icon={<EditOutlined />}
                                                                    onClick={() => {
                                                                        switch (defendantSubjectType) {
                                                                            case 1:
                                                                                showAddEditNaturalPeopleModal(formNameTypes.defendantDataNaturalPeopleForm, (item as any), index);
                                                                                break;

                                                                            case 2:
                                                                                showAddEditCompanyModal(formNameTypes.defendantDataCompanyForm, (item as any), index);
                                                                                break;
                                                                        }
                                                                    }}
                                                                />
                                                                <Button
                                                                    type="text"
                                                                    danger
                                                                    icon={<CloseCircleOutlined />}
                                                                    onClick={() => {
                                                                        switch (defendantSubjectType) {
                                                                            case 1:
                                                                                onAddEditNaturalPeopleDelClick(index, 'defendant_data');
                                                                                break;

                                                                            case 2:
                                                                                onAddEditCompanyDelClick(index, 'defendant_data');
                                                                                break;
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Descriptions.Item>
                                                ))}
                                            </Descriptions>
                                        ) : (
                                            <Typography.Text className="ant-form-text" type="secondary">
                                                ( <SmileOutlined /> 暂无添加数据。 )
                                            </Typography.Text>
                                        );
                                    }}
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        htmlType="button"
                                        onClick={() => {
                                            switch (defendantSubjectType) {
                                                case 1:
                                                    showAddEditNaturalPeopleModal(formNameTypes.defendantDataNaturalPeopleForm);
                                                    break;

                                                case 2:
                                                    showAddEditCompanyModal(formNameTypes.defendantDataCompanyForm);
                                                    break;
                                            }
                                        }}
                                    >
                                        添加被申请人
                                    </Button>
                                </Form.Item>
                            </>
                        );
                    }}
                </Form.Item>

                <Divider orientation="left" plain>第三人</Divider>
                <Form.Item
                    label="添加第三人"
                    shouldUpdate={(prevValues, curValues) => prevValues.third_party !== curValues.third_party}
                >
                    {({ getFieldValue }) => {
                        var itemData: IThirdPartyForm[];
                        itemData = getFieldValue('third_party') || [];

                        return itemData.length ? (
                            <Descriptions size='small' bordered column={1}>
                                {itemData.map((item, index) => (
                                    <Descriptions.Item>
                                        <div className="ding__code">
                                            {
                                                item.subject_type === 1 &&
                                                <div>
                                                    {
                                                        `${(item as IThirdPartyNaturalPeopleForm).natural_people.real_name}
                                                        (${(item as IThirdPartyNaturalPeopleForm).natural_people.mobile})`
                                                    }
                                                    {
                                                        (item as IThirdPartyNaturalPeopleForm).natural_people.is_default ? '(默认)' : ''
                                                    }
                                                </div>
                                            }
                                            {
                                                item.subject_type === 2 &&
                                                <div>
                                                    {
                                                        `${(item as IThirdPartyCompanyForm).company.company_name}`
                                                    }
                                                    {
                                                        (item as IThirdPartyCompanyForm).company.is_default ? '(默认)' : ''
                                                    }
                                                </div>
                                            }
                                            <div style={{ minWidth: 64 }}>
                                                <Button
                                                    type="text"
                                                    icon={<EditOutlined />}
                                                    onClick={() => {
                                                        showAddEditThirdPartyModal(item.subject_type, item, index);
                                                    }}
                                                />
                                                <Button
                                                    type="text"
                                                    danger
                                                    icon={<CloseCircleOutlined />}
                                                    onClick={() => {
                                                        onAddEditThirdPartyDelClick(index);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Descriptions.Item>
                                ))}
                            </Descriptions>
                        ) : (
                            <Typography.Text className="ant-form-text" type="secondary">
                                ( <SmileOutlined /> 暂无添加数据。 )
                            </Typography.Text>
                        );
                    }}
                </Form.Item>
                <Form.Item>
                    <Button
                        htmlType="button"
                        onClick={() => {
                            showAddEditThirdPartyModal(1);
                        }}
                    >
                        添加第三人
                    </Button>
                </Form.Item>

                <Divider orientation="left" plain>登记附件</Divider>
                <Form.Item
                    noStyle
                    shouldUpdate
                >
                    {({ getFieldValue }) => {
                        let attachFilesData: IAttachFilesData[] = getFieldValue('attach_files_data') ?? [];
                        return (
                            <>
                                {
                                    attachFilesData.map(item => (
                                        <Form.Item
                                            label={item.name}
                                        >
                                            <ImageUploader
                                                multiple
                                                value={fileListMap[item.value] ?? []}
                                                onChange={onImageUploaderChange(item.value)}
                                                upload={imageUploaderUpload(item.name, item.value)}
                                                beforeUpload={beforeUpload}
                                            />
                                        </Form.Item>
                                    ))
                                }
                            </>
                        );
                    }}
                </Form.Item>

                <Form.Item>
                    <Button block type='primary' size='large' loading={confirmLoading} onClick={submit}>提交</Button>
                </Form.Item>

                {/* 添加人员弹窗 */}
                {
                    screens.xs
                        ? <Popup
                            visible={addEditNaturalPeopleOpen}
                            showCloseButton
                            position='right'
                            onClose={hideAddEditNaturalPeopleModal}
                            bodyStyle={{ overflowY: 'auto' }}
                        >
                            <div style={{ margin: '40px 20px' }}>
                                <PreArbitrationNaturalPeopleFormModal key={Math.random()} formName={addEditNaturalPeopleFormName} data={addEditNaturalPeopleData}></PreArbitrationNaturalPeopleFormModal>
                            </div>
                        </Popup>
                        : <Modal
                            visible={addEditNaturalPeopleOpen}
                            title={`添加人员`}
                            maskClosable={false}
                            onCancel={hideAddEditNaturalPeopleModal}
                            footer={null}
                        >
                            <PreArbitrationNaturalPeopleFormModal key={Math.random()} formName={addEditNaturalPeopleFormName} data={addEditNaturalPeopleData}></PreArbitrationNaturalPeopleFormModal>
                        </Modal>
                }

                {/* 添加企业弹窗 */}
                {
                    screens.xs
                        ? <Popup
                            visible={addEditCompanyOpen}
                            showCloseButton
                            position='right'
                            onClose={hideAddEditCompanyModal}
                            bodyStyle={{ overflowY: 'auto' }}
                        >
                            <div style={{ margin: '40px 20px' }}>
                                <PreArbitrationCompanyFormModal key={Math.random()} formName={addEditCompanyFormName} data={addEditCompanyData}></PreArbitrationCompanyFormModal>
                            </div>
                        </Popup>
                        : <Modal
                            visible={addEditCompanyOpen}
                            title={`添加企业`}
                            maskClosable={false}
                            onCancel={hideAddEditCompanyModal}
                            footer={null}
                        >
                            <PreArbitrationCompanyFormModal key={Math.random()} formName={addEditCompanyFormName} data={addEditCompanyData}></PreArbitrationCompanyFormModal>
                        </Modal>
                }


                {/* 添加第三人弹窗 */}
                {
                    screens.xs
                        ? <Popup
                            visible={addEditThirdPartyOpen}
                            showCloseButton
                            position='right'
                            onClose={hideAddEditThirdPartyModal}
                            bodyStyle={{ overflowY: 'auto' }}
                        >
                            <div style={{ margin: '40px 20px' }}>
                                {thirdPartyComponent}
                            </div>
                        </Popup>
                        : <Modal
                            visible={addEditThirdPartyOpen}
                            title={`添加第三人`}
                            maskClosable={false}
                            onCancel={hideAddEditThirdPartyModal}
                            footer={null}
                        >
                            {thirdPartyComponent}
                        </Modal>
                }
            </Form>
        </Form.Provider>
    )


    return (
        <div className={screens.xs ? 'inner-page mobile' : ''}>
            {
                loading
                    ? <Skeleton />
                    : FormItem
            }
        </div>
    );
}

export default PreArbitrationFormModal;