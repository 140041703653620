import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// antd
import { Skeleton } from 'antd';

import { getPreArbitrationTableContent, IFileIndexTableForm, ILaborDisputeMediationApplicationData, ILaborDisputeMediationApplicationTableForm, INaturalPeople, IPreArbitrationCompany, IThirdPartyCompany, IThirdPartyNaturalPeople, saveFileIndexTable, saveLaborDisputeMediationApplicationTable } from '../../../../apis/preArbitration';
import dayjs from 'dayjs';


function LaborDisputeMediationApplicationTablePrint() {
    const [searchParams, setSearchParams] = useSearchParams();


    // 表单数据
    const [detail, setDetail] = useState<ILaborDisputeMediationApplicationData>();

    // 初始化加载
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let pre_worksheet_id = Number(searchParams.get('id'));
        if (pre_worksheet_id) {
            setLoading(true);

            getPreArbitrationTableContent({
                pre_worksheet_id,
                form_type: 5,
            }).then(res => {
                console.log(res);
                let data: ILaborDisputeMediationApplicationData = res.data.detail!;
                setDetail(data);
            }).finally(() => {
                setLoading(false);
            })
        }
    }, [searchParams]);

    return (
        <div>
            {
                loading
                    ? <Skeleton />
                    : <div style={{ width: "707px", height: 1000, margin: 'auto', textAlign: 'start', fontSize: 16, position: 'relative' }}>
                        <h2 style={{ margin: '20px 0', textAlign: 'center' }}>劳动人事争议调解申请书</h2>



                        <table className='preArbitration_table left' style={{ width: "707px", margin: 'auto' }}>
                            {
                                detail?.plaintiff_subject_type === 1 && <>
                                    <tr>
                                        <td style={{ width: 300 }}>申请人：（姓名）{((detail?.plaintiff_data as IThirdPartyNaturalPeople)?.natural_people ?? detail?.plaintiff_default as INaturalPeople)?.real_name}</td>
                                        <td>性别：{((detail?.plaintiff_data as IThirdPartyNaturalPeople)?.natural_people ?? detail?.plaintiff_default as INaturalPeople)?.sex_text}</td>
                                        <td style={{ width: 250 }}>民族：{((detail?.plaintiff_data as IThirdPartyNaturalPeople)?.natural_people ?? detail?.plaintiff_default as INaturalPeople)?.nation_dict_text}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>出生年月：{
                                            ((detail?.plaintiff_data as IThirdPartyNaturalPeople)?.natural_people ?? detail?.plaintiff_default as INaturalPeople)?.birthday
                                                ? dayjs(((detail?.plaintiff_data as IThirdPartyNaturalPeople)?.natural_people ?? detail?.plaintiff_default as INaturalPeople)?.birthday).format('YYYY年MM月DD日')
                                                : ''
                                        }</td>
                                        <td>原籍：{((detail?.plaintiff_data as IThirdPartyNaturalPeople)?.natural_people ?? detail?.plaintiff_default as INaturalPeople)?.native_place}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>现住址：{((detail?.plaintiff_data as IThirdPartyNaturalPeople)?.natural_people ?? detail?.plaintiff_default as INaturalPeople)?.effect_service_address}</td>
                                        <td>联系电话：{((detail?.plaintiff_data as IThirdPartyNaturalPeople)?.natural_people ?? detail?.plaintiff_default as INaturalPeople)?.mobile}</td>
                                    </tr>
                                </>
                            }
                            {
                                detail?.plaintiff_subject_type === 2 && <>
                                    <tr>
                                        <td colSpan={3}>申请人：（姓名）：{((detail?.plaintiff_data as IThirdPartyCompany)?.company ?? detail?.plaintiff_default as IPreArbitrationCompany)?.company_name}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>法定代表人：{((detail?.plaintiff_data as IThirdPartyCompany)?.company ?? detail?.plaintiff_default as IPreArbitrationCompany)?.legal_person}</td>
                                        <td>职务：{((detail?.plaintiff_data as IThirdPartyCompany)?.company ?? detail?.plaintiff_default as IPreArbitrationCompany)?.duty}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>地址：{((detail?.plaintiff_data as IThirdPartyCompany)?.company ?? detail?.plaintiff_default as IPreArbitrationCompany)?.address}</td>
                                        <td>联系电话：{((detail?.plaintiff_data as IThirdPartyCompany)?.company ?? detail?.plaintiff_default as IPreArbitrationCompany)?.contact_mobile}</td>
                                    </tr>
                                </>
                            }

                            <tr style={{ height: 30 }}></tr>

                            <tr>
                                <td>委托代理人：（姓名）{detail?.agent_real_name}</td>
                                <td>性别：{detail?.agent_sex_text}</td>
                                <td>民族：{detail?.agent_nation}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>出生年月：{detail?.agent_birthday}</td>
                                <td>原籍：{detail?.agent_native_place}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>工作单位：{detail?.agent_company_name}</td>
                                <td>职务：{detail?.agent_duty}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>现住址：{detail?.agent_current_address}</td>
                                <td>联系电话：{detail?.agent_mobile}</td>
                            </tr>

                            <tr style={{ height: 30 }}></tr>

                            {
                                detail?.defendant_subject_type === 2 && <>
                                    <tr>
                                        <td colSpan={3}>被申请人：{((detail?.defendant_data as IThirdPartyCompany)?.company ?? detail?.defendant_default as IPreArbitrationCompany)?.company_name}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>法定代表人：{((detail?.defendant_data as IThirdPartyCompany)?.company ?? detail?.defendant_default as IPreArbitrationCompany)?.legal_person}</td>
                                        <td>职务：{((detail?.defendant_data as IThirdPartyCompany)?.company ?? detail?.defendant_default as IPreArbitrationCompany)?.duty}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>地址：{((detail?.defendant_data as IThirdPartyCompany)?.company ?? detail?.defendant_default as IPreArbitrationCompany)?.address}</td>
                                        <td>联系电话：{((detail?.defendant_data as IThirdPartyCompany)?.company ?? detail?.defendant_default as IPreArbitrationCompany)?.contact_mobile}</td>
                                    </tr>
                                </>
                            }
                            {
                                detail?.defendant_subject_type === 1 && <>
                                    <tr>
                                        <td style={{ width: 300 }}>被申请人：{((detail?.defendant_data as IThirdPartyNaturalPeople)?.natural_people ?? detail?.defendant_default as INaturalPeople)?.real_name}</td>
                                        <td>性别：{((detail?.defendant_data as IThirdPartyNaturalPeople)?.natural_people ?? detail?.defendant_default as INaturalPeople)?.sex_text}</td>
                                        <td style={{ width: 250 }}>民族：{((detail?.defendant_data as IThirdPartyNaturalPeople)?.natural_people ?? detail?.defendant_default as INaturalPeople)?.nation_dict_text}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>出生年月：{dayjs(((detail?.defendant_data as IThirdPartyNaturalPeople)?.natural_people ?? detail?.defendant_default as INaturalPeople)?.birthday).format('YYYY年MM月DD日')}</td>
                                        <td>原籍：{((detail?.defendant_data as IThirdPartyNaturalPeople)?.natural_people ?? detail?.defendant_default as INaturalPeople)?.native_place}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>现住址：{((detail?.defendant_data as IThirdPartyNaturalPeople)?.natural_people ?? detail?.defendant_default as INaturalPeople)?.effect_service_address}</td>
                                        <td>联系电话：{((detail?.defendant_data as IThirdPartyNaturalPeople)?.natural_people ?? detail?.defendant_default as INaturalPeople)?.mobile}</td>
                                    </tr>
                                </>

                            }
                        </table>

                        <div style={{ marginTop: 50, marginLeft: 10, whiteSpace: 'pre-wrap' }}>
                            <span style={{ fontWeight: 'bold' }}>争议事项、理由及诉求：</span>{detail?.reason}
                        </div>

                        <div style={{ position: 'absolute', right: 50, bottom: 50 }}>
                            <div style={{ marginBottom: 20 }}>申请人：（签名）</div>
                            <div>申请日期：<span style={{ marginLeft: 50 }}>年</span><span style={{ marginLeft: 30 }}>月</span><span style={{ marginLeft: 30 }}>日</span></div>
                        </div>
                    </div>
            }
        </div>
    );
}

export default LaborDisputeMediationApplicationTablePrint;