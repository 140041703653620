import { axiosInstance, CommonResponse } from ".";
import { Attachment } from "./attachment";


// 10 注册 20 登录 30 修改密码 40 绑定 50 转移管理员 60 更换手机
type codeTypeItems = 10 | 20 | 30 | 40 | 50 | 60
export const codeType: {
    [key: string]: codeTypeItems,
} = {
    register: 10, // 注册
    login: 20, // 登录
    password: 30, // 修改密码
    bind: 40, // 绑定
    transfer: 50, // 转移管理员
    changeMobile: 60 // 更换手机
};

export interface ISendVCodeForm {
    mobile: string,
    type: codeTypeItems
}

/**
 * 发送验证码
 * @param data 
 * @returns 
 */
export const sendVCode = (data: ISendVCodeForm): Promise<CommonResponse> => {
    return axiosInstance.post('/api/shares/sms_send', data).then(res => res.data);
};

export interface ILoginForm {
    username: string,
    password: string
}

export interface ILoginData {
    token: string
}

/**
 * @description: 用户登录
 * @params {ILoginForm} params
 * @return {Promise}
 */
export const login = (data: ILoginForm): Promise<CommonResponse<ILoginData>> => {
    return axiosInstance.post('/organ/auth/login', data).then(res => res.data);
};

export interface IRegisterForm {
    name: string,
    work_type: number,
    idcard: string,
    is_idcard_long_time: boolean,
    idcard_begin_date: string,
    idcard_expire_date: string,
    province_id: number,
    city_id: number,
    area_id: number,
    street_id: number,
    address: string,
    attach_certification: number[],
    certification_remark: string
}

/**
 * 认证申请
 * @param data 
 * @returns 
 */
export const register = (data: IRegisterForm): Promise<CommonResponse> => {
    return axiosInstance.post('/order/talent_apply/index', data).then(res => res.data);
};

export interface IRegisterOrganizationForm {
    attach_business_license: number,
    name: string,
    social_no: string,
    register_date: string,
    enterprise_type: string,
    registered_capital: string,
    province_id: string,
    city_id: string,
    area_id: string,
    street_id: string,
    address: string,
    business_scope: string,
    legal_person: string,
    legal_person_idcard: string,
    is_idcard_long_time: boolean,
    idcard_begin_date: string,
    idcard_expire_date: string,
    idcard_front_img: number,
    idcard_back_img: number,
    attach_material: number[],
    apply_explain: string,
    username: string,
    password: string,
    mobile: string
}

export const registerOrganization = (data: IRegisterOrganizationForm): Promise<CommonResponse> => {
    return axiosInstance.post('/organ/auth/register', data).then(res => res.data);
};

export interface IResetPasswordForm {
    old_password: string,
    password: string,
    confirm_password: string
}

/**
 * 修改密码
 * @param data 
 * @returns 
 */
export const changePassword = (data: IResetPasswordForm): Promise<CommonResponse> => {
    return axiosInstance.post('organ/profile/change_password', data).then(res => res.data);
};

export interface IUpdateUserInfoForm {
    name: string,
    avatar_id?: number,
    dept_name?: string,
    contact: string,
    bank_no?: string,
    bank_name?: string,
    bank_deposit?: string,
}

export const updateUserInfo = (data: IUpdateUserInfoForm): Promise<CommonResponse> => {
    return axiosInstance.post('/company/user/edit_profile', data).then(res => res.data);
};

export interface IAllUserData {
    id: number,
    username: string,
    real_name: string
}

export interface IAllUser {
    list: IAllUserData[],
    total: number
}

/**
 * 获取所有用户
 */
export const getAllUser = (): Promise<CommonResponse<IAllUser>> => {
    return axiosInstance.post('/organ/user/all').then(res => res.data);
};
