import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// antd
import { Grid, Button, Form, Input, message, Select, Skeleton, Space, Divider, InputRef, Typography, Row, Col, Card } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { DatePicker as DatePickerMobile } from 'antd-mobile';
import { PickerDate } from 'antd-mobile/es/components/date-picker/util';

import { getPreArbitrationTableContent, IFileIndexTableForm, IPowerOfAttorneyTableData, IPowerOfAttorneyTableForm, IPowerOfAttorneyTableFormPersonnelData, saveFileIndexTable, savePowerOfAttorneyTable } from '../../../apis/preArbitration';
import { getDictList, IDictData } from '../../../apis/common';
import DatePicker from '../../../components/customAntd/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { json } from 'stream/consumers';

const { useBreakpoint } = Grid;
const { Option } = Select;
const { Title, Paragraph, Text } = Typography;

interface CollectionCreateFormProps {
    data?: { id: number },
    onFinish?: () => void;
}

function PowerOfAttorneyTableFormModal({
    data,
    onFinish,
}: CollectionCreateFormProps) {
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 表单数据
    const [form] = Form.useForm<IPowerOfAttorneyTableForm>();

    const [nation, setNation] = useState<IDictData>();
    const [sex, setSex] = useState<IDictData>();
    useEffect(() => {
        getDictList(['sys_nation', 'global_sex']).then(res => {
            setNation(res.data.sys_nation);
            setSex(res.data.global_sex);
        });
    }, []);

    // 出生年月日
    const [birthdayVisible, setBirthdayVisible] = useState(false);

    // 初始化加载
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        form.resetFields();

        let pre_worksheet_id = data?.id ?? Number(searchParams.get('id'));
        if (pre_worksheet_id) {
            form.setFieldValue('pre_worksheet_id', pre_worksheet_id);
            setLoading(true);

            getPreArbitrationTableContent({
                pre_worksheet_id,
                form_type: 7,
            }).then(res => {
                console.log(res);
                let data: IPowerOfAttorneyTableData = res.data.detail!;

                data.personnel_data?.forEach(item => {
                    item.birthday = item.birthday ? dayjs(item.birthday) as any : undefined;
                })
                form.setFieldsValue({
                    ...data,
                });
            }).finally(() => {
                setLoading(false);
            })
        }

    }, [data, searchParams]);

    // 提交
    const submit = async () => {
        try {
            await form.validateFields();
        } catch (e: any) {
            message.warning(e.errorFields[0].errors[0]);
            return;
        }

        setConfirmLoading(true);
        let _form = form.getFieldsValue(true);
        try {
            let data = JSON.parse(JSON.stringify(_form));

            data.personnel_data?.forEach((item: IPowerOfAttorneyTableFormPersonnelData) => {
                item.birthday = item.birthday ? dayjs(item.birthday).format('YYYY-MM-DD') : undefined;
            });

            let res = await savePowerOfAttorneyTable(data);
            message.success(res.msg);
            if (onFinish) {
                onFinish();
            } else {
                navigate(-1);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    const gutter = 16 + 8 * 2;

    const FormItem = (
        <Form
            form={form}
            layout="vertical"
            autoComplete="off"
        >
            <Typography>
                <Paragraph>
                    劳动人事争议调解中心：
                    <br />
                    你中心受理
                    <Form.Item
                        name="name"
                    >
                        <Input maxLength={100} />
                    </Form.Item>
                    与我（单位）的劳动争议一案，依照法律规定，特委托下列人员为我（单位）的代理人：
                </Paragraph>
            </Typography>
            <Form.List
                name="personnel_data"
                rules={[
                    {
                        validator: async (_, names) => {
                            if (!names || names.length < 1) {
                                return Promise.reject(new Error('请至少添加一位委托人'));
                            }
                        },
                    },
                ]}
            >
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }, index) => (
                            <Card>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3>({index + 1})</h3>
                                    <Button
                                        type='text'
                                        size='small'
                                        danger
                                        onClick={() => remove(name)}
                                    >删除</Button>
                                </div>
                                <Row gutter={gutter}>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            {...restField}
                                            label="姓名"
                                            name={[name, 'real_name']}
                                        >
                                            <Input maxLength={10} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            {...restField}
                                            label="性别"
                                            name={[name, 'sex']}
                                        >
                                            <Select showArrow showSearch>
                                                {sex?.options.map(option => (
                                                    <Option key={option.value} value={option.name}>{option.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            {...restField}
                                            label="民族"
                                            name={[name, 'nation']}
                                        >
                                            <Select showArrow showSearch>
                                                {nation?.options.map(option => (
                                                    <Option key={option.value} value={option.name}>{option.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        {
                                            screens.xs ?
                                                <Form.Item
                                                    label="出生年月"
                                                    shouldUpdate
                                                >
                                                    <DatePickerMobile
                                                        visible={birthdayVisible}
                                                        onClose={() => {
                                                            setBirthdayVisible(false)
                                                        }}
                                                        value={(form.getFieldValue([name, 'birthday']) as Dayjs)?.toDate()}
                                                        onConfirm={(value: PickerDate) => {
                                                            form.setFieldValue([name, 'birthday'], dayjs(value));
                                                        }}
                                                    >
                                                        {value =>
                                                            <>
                                                                <Button
                                                                    style={{ marginRight: 10 }}
                                                                    onClick={() => {
                                                                        setBirthdayVisible(true)
                                                                    }}
                                                                >选择</Button>
                                                                {value ? dayjs(value).format('YYYY年MM月DD日') : '请选择时间'}
                                                            </>
                                                        }
                                                    </DatePickerMobile>
                                                </Form.Item>
                                                : <Form.Item
                                                    label="出生年月"
                                                    name={[name, 'birthday']}
                                                >
                                                    <DatePicker
                                                        format='YYYY-MM-DD'
                                                        style={{ width: '100%' }}
                                                    />
                                                </Form.Item>
                                        }
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            {...restField}
                                            label="工作单位"
                                            name={[name, 'company']}
                                        >
                                            <Input maxLength={50} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            {...restField}
                                            label="职务"
                                            name={[name, 'duty']}
                                        >
                                            <Input maxLength={50} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            {...restField}
                                            label="电话"
                                            name={[name, 'mobile']}
                                        >
                                            <Input maxLength={20} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            {...restField}
                                            label="与委托人关系"
                                            name={[name, 'relationship']}
                                        >
                                            <Input maxLength={50} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                添加委托人
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>

            <Form.Item
                label="委托事项和权限如下"
                name='reason'
            >
                <TextArea rows={3} />
            </Form.Item>

            <Form.Item>
                <Button block type='primary' size='large' loading={confirmLoading} onClick={submit}>提交</Button>
            </Form.Item>
        </Form>
    )


    return (
        <div className={screens.xs ? 'inner-page mobile' : ''}>
            {
                loading
                    ? <Skeleton />
                    : <>
                        {FormItem}
                    </>
            }
        </div>
    );
}

export default PowerOfAttorneyTableFormModal;