import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// antd
import { Grid, Button, Form, message, Select, Modal, Skeleton, Radio, Descriptions, Typography } from 'antd';
import { EditOutlined, CloseCircleOutlined, SmileOutlined } from '@ant-design/icons';


import { Popup } from 'antd-mobile';

import { getPreArbitrationThirdPartyDetail, IPreArbitrationThirdPartyForm, editPreArbitrationThirdParty } from '../../../apis/preArbitration';
import PreArbitrationNaturalPeopleFormModal, { INaturalPeopleForm, IThirdPartyNaturalPeopleForm } from './preArbitrationNaturalPeopleForm';
import PreArbitrationCompanyFormModal, { IPreArbitrationCompanyForm, IThirdPartyCompanyForm } from './preArbitrationCompanyForm';

const { Option } = Select;
const { useBreakpoint } = Grid;

/**
 * plaintiff 申请人
 * defendant 被申请人
 */
enum formNameTypes {
    plaintiffDataNaturalPeopleForm = 'plaintiffDataNaturalPeopleForm',
    plaintiffDataCompanyForm = 'plaintiffDataCompanyForm',
    defendantDataNaturalPeopleForm = 'defendantDataNaturalPeopleForm',
    defendantDataCompanyForm = 'defendantDataCompanyForm',
    thirdPartyNaturalPeopleForm = 'thirdPartyNaturalPeopleForm',
    thirdPartyCompanyForm = 'thirdPartyCompanyForm',
}

interface CollectionCreateFormProps {
    data?: { id: number },
    onFinish?: () => void;
}

function PreArbitrationThirdPartyFormModal({
    data,
    onFinish,
}: CollectionCreateFormProps) {
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 表单数据
    const [form] = Form.useForm<IPreArbitrationThirdPartyForm>();

    // 初始化加载
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        form.resetFields();
        setLoading(true);

        let id = data?.id ?? Number(searchParams.get('id'));
        form.setFieldValue('pre_worksheet_id', id);

        getPreArbitrationThirdPartyDetail({ pre_worksheet_id: id }).then(res => {
            form.setFieldsValue(res.data);
        }).finally(() => {
            setLoading(false);
        });
    }, [data, searchParams]);

    // 提交
    const submit = async () => {
        try {
            await form.validateFields();
        } catch (e: any) {
            message.warning(e.errorFields[0].errors[0]);
            return;
        }
        let _form: IPreArbitrationThirdPartyForm = form.getFieldsValue(true);

        setConfirmLoading(true);
        try {
            let data = {
                ..._form,
            }

            let res = await editPreArbitrationThirdParty(data);
            message.success(res.msg);
            if (onFinish) {
                onFinish();
            } else {
                navigate('/preArbitration');
            }
        } catch (e) {
            console.error(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    // 添加第三方弹窗
    const [thirdPartyType, setThirdPartyType] = useState(1);
    type IThirdPartyForm = IThirdPartyCompanyForm | IThirdPartyNaturalPeopleForm;
    const [addEditThirdPartyOpen, setAddEditThirdPartyOpen] = useState(false);
    const [addEditThirdPartyData, setAddEditThirdPartyData] = useState<IThirdPartyForm>();
    const showAddEditThirdPartyModal = (type: number, data?: IThirdPartyForm, index?: number) => {
        setThirdPartyType(type);
        setAddEditThirdPartyOpen(true);
        if (data && typeof index === 'number') {
            setAddEditThirdPartyData({
                ...data,
                index
            });
        } else {
            setAddEditThirdPartyData(undefined);
        }
    };
    const hideAddEditThirdPartyModal = () => {
        setAddEditThirdPartyOpen(false);
    };
    const onAddEditThirdPartyDelClick = (index: number) => {
        let item: IThirdPartyForm[] = form.getFieldValue('third_party');
        form.setFieldValue('third_party', item.filter((_, i) => index !== i));
    }

    const thirdPartyComponent = (
        <>
            <Form.Item>
                <Radio.Group disabled={!!addEditThirdPartyData} value={thirdPartyType} onChange={(e) => setThirdPartyType(e.target.value)}>
                    <Radio value={1}>个人</Radio>
                    <Radio value={2}>企业</Radio>
                </Radio.Group>
            </Form.Item>

            {
                thirdPartyType === 1 &&
                <PreArbitrationNaturalPeopleFormModal
                    key={Math.random()}
                    thirdParty
                    formName={formNameTypes.thirdPartyNaturalPeopleForm}
                    data={addEditThirdPartyData as IThirdPartyNaturalPeopleForm}
                ></PreArbitrationNaturalPeopleFormModal>
            }
            {
                thirdPartyType === 2 &&
                <PreArbitrationCompanyFormModal
                    key={Math.random()}
                    thirdParty
                    formName={formNameTypes.thirdPartyCompanyForm}
                    data={addEditThirdPartyData as IThirdPartyCompanyForm}
                ></PreArbitrationCompanyFormModal>
            }
        </>
    );


    // style
    const gutter = 16 + 8 * 2;

    const FormItem = (
        <Form.Provider
            onFormFinish={(name, { values, forms }) => {
                // 个人
                if (name === formNameTypes.thirdPartyNaturalPeopleForm) {
                    const { thirdPartyNaturalPeopleForm } = forms;
                    const naturalPeople: INaturalPeopleForm = thirdPartyNaturalPeopleForm.getFieldsValue(true);
                    const thirdPartyListData: IThirdPartyForm[] = form.getFieldValue('third_party') || [];
                    console.log(naturalPeople);

                    let thirdPartyCompany: IThirdPartyNaturalPeopleForm = {
                        subject_type: 1,
                        index: naturalPeople.index,
                        natural_people: naturalPeople
                    };


                    if (thirdPartyCompany.index !== undefined) {
                        let newThirdPartyList = [...thirdPartyListData];
                        newThirdPartyList.splice(thirdPartyCompany.index, 1, thirdPartyCompany);
                        form.setFieldValue('third_party', newThirdPartyList);
                    } else {
                        form.setFieldValue('third_party', [...thirdPartyListData, thirdPartyCompany]);
                    }
                    setAddEditThirdPartyOpen(false);
                }

                // 企业
                if (name === formNameTypes.thirdPartyCompanyForm) {
                    const { thirdPartyCompanyForm } = forms;
                    const company: IPreArbitrationCompanyForm = thirdPartyCompanyForm.getFieldsValue(true);
                    const thirdPartyListData: IThirdPartyForm[] = form.getFieldValue('third_party') || [];
                    console.log(company);

                    let thirdPartyCompany: IThirdPartyCompanyForm = {
                        subject_type: 2,
                        index: company.index,
                        company: company
                    };

                    if (thirdPartyCompany.index !== undefined) {
                        let newThirdPartyList = [...thirdPartyListData];
                        newThirdPartyList.splice(thirdPartyCompany.index, 1, thirdPartyCompany);
                        form.setFieldValue('third_party', newThirdPartyList);
                    } else {
                        form.setFieldValue('third_party', [...thirdPartyListData, thirdPartyCompany]);
                    }
                    setAddEditThirdPartyOpen(false);
                }
            }}
        >
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    label="添加第三人"
                    shouldUpdate={(prevValues, curValues) => prevValues.third_party !== curValues.third_party}
                >
                    {({ getFieldValue }) => {
                        var itemData: IThirdPartyForm[];
                        itemData = getFieldValue('third_party') || [];

                        return itemData.length ? (
                            <Descriptions size='small' bordered column={1}>
                                {itemData.map((item, index) => (
                                    <Descriptions.Item>
                                        <div className="ding__code">
                                            {
                                                item.subject_type === 1 &&
                                                <div>
                                                    {
                                                        `${(item as IThirdPartyNaturalPeopleForm).natural_people.real_name}
                                                        (${(item as IThirdPartyNaturalPeopleForm).natural_people.mobile})`
                                                    }
                                                    {
                                                        (item as IThirdPartyNaturalPeopleForm).natural_people.is_default ? '(默认)' : ''
                                                    }
                                                </div>
                                            }
                                            {
                                                item.subject_type === 2 &&
                                                <div>
                                                    {
                                                        `${(item as IThirdPartyCompanyForm).company.company_name}`
                                                    }
                                                    {
                                                        (item as IThirdPartyCompanyForm).company.is_default ? '(默认)' : ''
                                                    }
                                                </div>
                                            }
                                            <div style={{ minWidth: 64 }}>
                                                <Button
                                                    type="text"
                                                    icon={<EditOutlined />}
                                                    onClick={() => {
                                                        showAddEditThirdPartyModal(item.subject_type, item, index);
                                                    }}
                                                />
                                                <Button
                                                    type="text"
                                                    danger
                                                    icon={<CloseCircleOutlined />}
                                                    onClick={() => {
                                                        onAddEditThirdPartyDelClick(index);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Descriptions.Item>
                                ))}
                            </Descriptions>
                        ) : (
                            <Typography.Text className="ant-form-text" type="secondary">
                                ( <SmileOutlined /> 暂无添加数据。 )
                            </Typography.Text>
                        );
                    }}
                </Form.Item>
                <Form.Item>
                    <Button
                        htmlType="button"
                        onClick={() => {
                            showAddEditThirdPartyModal(1);
                        }}
                    >
                        添加第三人
                    </Button>
                </Form.Item>

                <Form.Item>
                    <Button block type='primary' size='large' loading={confirmLoading} onClick={submit}>提交</Button>
                </Form.Item>

                {/* 添加第三人弹窗 */}
                {
                    screens.xs
                        ? <Popup
                            visible={addEditThirdPartyOpen}
                            showCloseButton
                            position='right'
                            onClose={hideAddEditThirdPartyModal}
                            bodyStyle={{ overflowY: 'auto' }}
                        >
                            <div style={{ margin: '40px 20px' }}>
                                {thirdPartyComponent}
                            </div>
                        </Popup>
                        : <Modal
                            visible={addEditThirdPartyOpen}
                            title={`添加第三人`}
                            maskClosable={false}
                            onCancel={hideAddEditThirdPartyModal}
                            footer={null}
                        >
                            {thirdPartyComponent}
                        </Modal>
                }
            </Form>
        </Form.Provider>
    )


    return (
        <div className={screens.xs ? 'inner-page mobile' : ''}>
            {
                loading
                    ? <Skeleton />
                    : FormItem
            }
        </div>
    );
}

export default PreArbitrationThirdPartyFormModal;