import { useEffect, useState } from 'react';

// antd
import { Grid, Comment, Skeleton, List, Form, Button, Divider } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { commitConciliationComment, getConciliationCommentLogs, getConciliationDetail, ICommitConciliationCommentForm, IConciliationCommentLogsData, IConciliationDetailData } from '../../../apis/conciliation';
import TextArea from 'antd/lib/input/TextArea';


const { useBreakpoint } = Grid;


interface CollectionCreateFormProps {
    data?: { id: number },
}

function ConciliationCommentLogsModal({
    data
}: CollectionCreateFormProps) {
    const screens = useBreakpoint();
    const [searchParams, setSearchParams] = useSearchParams();
    const [detail, setDetail] = useState<IConciliationCommentLogsData[]>();
    const loadData = () => {
        form.resetFields();
        getConciliationCommentLogs({ worksheet_id: data?.id ?? Number(searchParams.get('id')) }).then(res => {
            setDetail(res.data.list);
        })
    }
    useEffect(() => {
        loadData();
    }, [data, searchParams]);

    const [form] = Form.useForm<ICommitConciliationCommentForm>();
    const [submitting, setSubmitting] = useState(false);
    const onSubmit = async () => {
        await form.validateFields();

        let _form = form.getFieldsValue();
        setSubmitting(true);
        try {
            await commitConciliationComment({
                ..._form,
                worksheet_id: data?.id ?? Number(searchParams.get('id'))
            });
            loadData();
        } catch (e) {
            console.error(e);
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <div>
            {
                detail
                    ? <List
                        style={{ maxHeight: '50vh', overflow: 'auto' }}
                        itemLayout="horizontal"
                        dataSource={detail}
                        renderItem={item => (
                            <li>
                                <Comment
                                    author={item.organ_user.real_name}
                                    content={item.content}
                                    datetime={item.created_at}
                                />
                            </li>
                        )}
                    />
                    : <Skeleton active />
            }
            <Divider></Divider>
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    name='content'
                    rules={[{ required: true, message: '请填写回访记录' }]}
                >
                    <TextArea
                        rows={4}
                    />
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
                        添加回访记录
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default ConciliationCommentLogsModal;