import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// antd
import { Grid, Button, Form, Input, message, Select, Skeleton, Space, Divider, InputRef, Row, Col, Descriptions } from 'antd';
import { DatePicker as DatePickerMobile } from 'antd-mobile';
import DatePicker from '../../../components/customAntd/DatePicker';

import { getPreArbitrationTableContent, IFileIndexTableForm, ILaborDisputeMediationApplicationData, ILaborDisputeMediationApplicationTableForm, INaturalPeople, IPreArbitrationCompany, saveFileIndexTable, saveLaborDisputeMediationApplicationTable } from '../../../apis/preArbitration';
import { getDictList, IDictData } from '../../../apis/common';
import { PickerDate } from 'antd-mobile/es/components/date-picker/util';
import dayjs, { Dayjs } from 'dayjs';
import TextArea from 'antd/lib/input/TextArea';
import { DefaultOptionType } from 'antd/lib/select';

const { useBreakpoint } = Grid;
const { Option } = Select;

interface CollectionCreateFormProps {
    data?: { id: number },
    onFinish?: () => void;
}

function LaborDisputeMediationApplicationTableFormModal({
    data,
    onFinish,
}: CollectionCreateFormProps) {
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 表单数据
    const [form] = Form.useForm<ILaborDisputeMediationApplicationTableForm>();

    const [nation, setNation] = useState<IDictData>();
    const [sex, setSex] = useState<IDictData>();
    useEffect(() => {
        getDictList(['sys_nation', 'global_sex']).then(res => {
            setNation(res.data.sys_nation);
            setSex(res.data.global_sex);
        });
    }, []);

    // 出生年月日
    const [birthdayVisible, setBirthdayVisible] = useState(false);
    const [agentBirthdayVisible, setAgentBirthdayVisible] = useState(false);

    // 初始化加载
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        form.resetFields();

        let pre_worksheet_id = data?.id ?? Number(searchParams.get('id'));
        if (pre_worksheet_id) {
            form.setFieldValue('pre_worksheet_id', pre_worksheet_id);
            setLoading(true);

            getPreArbitrationTableContent({
                pre_worksheet_id,
                form_type: 5,
            }).then(res => {
                console.log(res);
                let data: ILaborDisputeMediationApplicationData = res.data.detail!;
                if (!data.plaintiff_data) {
                    if (data.plaintiff_subject_type === 1) {
                        data.plaintiff_data = {
                            subject_type: 1,
                            natural_people: (data.plaintiff_default as INaturalPeople),
                        }
                    }
                    if (data.plaintiff_subject_type === 2) {
                        data.plaintiff_data = {
                            subject_type: 2,
                            company: (data.plaintiff_default as IPreArbitrationCompany),
                        }
                    }
                }

                if (!data.defendant_data) {
                    if (data.defendant_subject_type === 1) {
                        data.defendant_data = {
                            subject_type: 1,
                            natural_people: (data.defendant_default as INaturalPeople),
                        }
                    }
                    if (data.defendant_subject_type === 2) {
                        data.defendant_data = {
                            subject_type: 2,
                            company: (data.defendant_default as IPreArbitrationCompany),
                        }
                    }
                }

                if (data.plaintiff_data?.subject_type === 1 && data.plaintiff_data.natural_people.birthday) {
                    data.plaintiff_data.natural_people.birthday = dayjs(data.plaintiff_data.natural_people.birthday) as any;
                }
                if (data.defendant_data?.subject_type === 1 && data.defendant_data.natural_people.birthday) {
                    data.defendant_data.natural_people.birthday = dayjs(data.defendant_data.natural_people.birthday) as any;
                }

                if (data.agent_birthday) {
                    data.agent_birthday = dayjs(data.agent_birthday) as any;
                }

                console.log(data.plaintiff_data, data.defendant_data);

                form.setFieldsValue({
                    plaintiff_data: data.plaintiff_data,
                    defendant_data: data.defendant_data,
                    agent_real_name: data.agent_real_name,
                    agent_sex: data.agent_sex,
                    agent_nation: data.agent_nation,
                    agent_native_place: data.agent_native_place,
                    agent_company_name: data.agent_company_name,
                    agent_current_address: data.agent_current_address,
                    agent_mobile: data.agent_mobile,
                    agent_duty: data.agent_duty,
                    agent_birthday: data.agent_birthday,
                    reason: data.reason
                });
            }).finally(() => {
                setLoading(false);
            })
        }

    }, [data, searchParams]);

    // 提交
    const submit = async (print = false) => {
        try {
            await form.validateFields();
        } catch (e: any) {
            message.warning(e.errorFields[0].errors[0]);
            return;
        }

        setConfirmLoading(true);
        let _form = form.getFieldsValue(true);

        try {
            let data = JSON.parse(JSON.stringify({
                ..._form,
                agent_birthday: _form.agent_birthday ? dayjs(_form.agent_birthday).format('YYYY-MM-DD') : undefined,
            }))

            if (data.plaintiff_data?.subject_type === 1 && data.plaintiff_data.natural_people.birthday) {
                data.plaintiff_data.natural_people.birthday = dayjs(data.plaintiff_data.natural_people.birthday).format('YYYY-MM-DD');
            }
            if (data.defendant_data?.subject_type === 1 && data.defendant_data.natural_people.birthday) {
                data.defendant_data.natural_people.birthday = dayjs(data.defendant_data.natural_people.birthday).format('YYYY-MM-DD');
            }

            let res = await saveLaborDisputeMediationApplicationTable(data);
            message.success(res.msg);
            if (print) {
                setTimeout(() => {
                    window.open(`/preArbitration/laborDisputeMediationApplication/print?id=${_form.pre_worksheet_id}`, '_blank');
                }, 0);
            } else {
                if (onFinish) {
                    onFinish();
                } else {
                    navigate(-1);
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    const gutter = 16 + 8 * 2;

    const naturalPeopleForm = (path: string) => {
        return (
            <>
                <Row gutter={gutter}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="姓名"
                            name={[path, 'natural_people', 'real_name']}
                            rules={[{ required: true, message: '请输入姓名' }]}
                        >
                            <Input maxLength={20} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="性别"
                            name={[path, 'natural_people', 'sex']}
                        >
                            <Select showArrow showSearch>
                                {sex?.options.map(option => (
                                    <Option key={option.value} value={option.value}>{option.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="民族"
                            name={[path, 'natural_people', 'nation_dict']}
                        >
                            <Select showArrow showSearch onChange={(_, opt) => {
                                form.setFieldValue([path, 'natural_people', 'nation_dict_text'], (opt as DefaultOptionType).children)
                            }}>
                                {nation?.options.map(option => (
                                    <Option key={option.value} value={option.value}>{option.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        {
                            screens.xs ?
                                <Form.Item
                                    label="出生年月"
                                    shouldUpdate
                                >
                                    <DatePickerMobile
                                        visible={birthdayVisible}
                                        onClose={() => {
                                            setBirthdayVisible(false)
                                        }}
                                        value={(form.getFieldValue([path, 'natural_people', 'birthday']) as Dayjs)?.toDate()}
                                        onConfirm={(value: PickerDate) => {
                                            form.setFieldValue([path, 'natural_people', 'birthday'], dayjs(value));
                                        }}
                                    >

                                        {value =>
                                            <>
                                                <Button
                                                    style={{ marginRight: 10 }}
                                                    onClick={() => {
                                                        setBirthdayVisible(true)
                                                    }}
                                                >选择</Button>
                                                {value ? dayjs(value).format('YYYY年MM月DD日') : '请选择时间'}
                                            </>
                                        }
                                    </DatePickerMobile>
                                </Form.Item>
                                : <Form.Item
                                    label="出生年月"
                                    name={[path, 'natural_people', 'birthday']}
                                >
                                    <DatePicker
                                        format='YYYY-MM-DD'
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                        }
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="原籍"
                            name={[path, 'natural_people', 'native_place']}
                        >
                            <Input maxLength={200} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="现住址"
                            name={[path, 'natural_people', 'effect_service_address']}
                        >
                            <Input maxLength={200} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="联系电话"
                            name={[path, 'natural_people', 'mobile']}
                            rules={[{ required: true, message: '请输入联系电话' }]}
                        >
                            <Input maxLength={20} />
                        </Form.Item>
                    </Col>
                </Row>
            </>
        )
    }

    const companyForm = (path: string) => {
        return (
            <>
                <Row gutter={gutter}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="用人单位"
                            name={[path, 'company', 'company_name']}
                            rules={[{ required: true, message: '请输入用人单位' }]}
                        >
                            <Input maxLength={20} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="法定代表人"
                            name={[path, 'company', 'legal_person']}
                        >
                            <Input maxLength={20} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="职务"
                            name={[path, 'company', 'duty']}
                        >
                            <Input maxLength={50} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="地址"
                            name={[path, 'company', 'address']}
                            rules={[{ required: true, message: '请输入地址' }]}
                        >
                            <Input maxLength={200} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="联系电话"
                            name={[path, 'company', 'contact_mobile']}
                        >
                            <Input maxLength={20} />
                        </Form.Item>
                    </Col>
                </Row>
            </>
        );
    }
    const FormItem = (
        <Form
            form={form}
            layout="vertical"
            autoComplete="off"
        >
            <Divider orientation="left" plain>申请人</Divider>
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => prevValues.plaintiff_data !== curValues.plaintiff_data}
            >
                {({ getFieldValue }) => {
                    const type = getFieldValue('plaintiff_data')?.subject_type;

                    switch (type) {
                        case 1:
                            return naturalPeopleForm('plaintiff_data');

                        case 2:
                            return companyForm('plaintiff_data');
                    }
                }}
            </Form.Item>


            <Divider orientation="left" plain>委托代理人</Divider>
            <Row gutter={gutter}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="姓名"
                        name="agent_real_name"
                    >
                        <Input maxLength={20} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="性别"
                        name="agent_sex"
                    >
                        <Select showArrow showSearch>
                            {sex?.options.map(option => (
                                <Option key={option.value} value={option.value}>{option.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="民族"
                        name="agent_nation"
                    >
                        <Select showArrow showSearch onChange={(_, opt) => {
                            form.setFieldValue("agent_nation", (opt as DefaultOptionType).children)
                        }}>
                            {nation?.options.map(option => (
                                <Option key={option.value} value={option.value}>{option.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    {
                        screens.xs ?
                            <Form.Item
                                label="出生年月"
                                shouldUpdate={(prevValues, curValues) => prevValues.agent_birthday !== curValues.agent_birthday}
                            >
                                <DatePickerMobile
                                    visible={agentBirthdayVisible}
                                    onClose={() => {
                                        setAgentBirthdayVisible(false)
                                    }}
                                    value={(form.getFieldValue('agent_birthday') as Dayjs)?.toDate()}
                                    onConfirm={(value: PickerDate) => {
                                        form.setFieldValue('agent_birthday', dayjs(value));
                                    }}
                                >
                                    {value =>
                                        <>
                                            <Button
                                                style={{ marginRight: 10 }}
                                                onClick={() => {
                                                    setAgentBirthdayVisible(true)
                                                }}
                                            >选择</Button>
                                            {value ? dayjs(value).format('YYYY年MM月DD日') : '请选择时间'}
                                        </>
                                    }
                                </DatePickerMobile>
                            </Form.Item>
                            : <Form.Item
                                label="出生年月"
                                name='agent_birthday'
                            >
                                <DatePicker
                                    format='YYYY-MM-DD'
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                    }
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="原籍"
                        name="agent_native_place"
                    >
                        <Input maxLength={200} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="工作单位"
                        name="agent_company_name"
                    >
                        <Input maxLength={100} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="职务"
                        name="agent_duty"
                    >
                        <Input maxLength={100} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="现住址"
                        name="agent_current_address"
                    >
                        <Input maxLength={200} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="联系电话"
                        name="agent_mobile"
                    >
                        <Input maxLength={20} />
                    </Form.Item>
                </Col>
            </Row>

            <Divider orientation="left" plain>被申请人</Divider>
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => prevValues.defendant_data !== curValues.defendant_data}
            >
                {({ getFieldValue }) => {
                    const type = getFieldValue('defendant_data')?.subject_type;

                    switch (type) {
                        case 1:
                            return naturalPeopleForm('defendant_data');

                        case 2:
                            return companyForm('defendant_data');
                    }
                }}
            </Form.Item>

            <Form.Item
                label="争议事项、理由及请求"
                name='reason'
            >
                <TextArea rows={3} />
            </Form.Item>

            <Form.Item>
                <Button block type='primary' size='large' loading={confirmLoading} onClick={() => submit()}>提交</Button>
            </Form.Item>
        </Form>
    )


    return (
        <div className={screens.xs ? 'inner-page mobile' : ''}>
            {
                loading
                    ? <Skeleton />
                    : <>
                        <Descriptions
                            size='small'
                            extra={<Button onClick={() => {
                                submit(true);
                            }}>预览打印</Button >}
                        ></Descriptions>
                        {FormItem}
                    </>
            }
        </div>
    );
}

export default LaborDisputeMediationApplicationTableFormModal;