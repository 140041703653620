import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// antd
import { Grid, Descriptions, Skeleton, List, Card, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

// apis
import { getConciliationDetail, IConciliationDetailData } from '../../../apis/conciliation';


const { useBreakpoint } = Grid;


interface CollectionCreateFormProps {
    data?: { id: number },
}

function ConciliationDetailModal({
    data
}: CollectionCreateFormProps) {
    const screens = useBreakpoint();
    const [searchParams, setSearchParams] = useSearchParams();
    const [detail, setDetail] = useState<IConciliationDetailData>();
    useEffect(() => {
        if (data || searchParams.get('id')) {
            getConciliationDetail({ id: data?.id ?? Number(searchParams.get('id')) }).then(res => {
                setDetail(res.data.detail);
            })
        }
    }, [data, searchParams]);

    return (
        <div className={screens.xs ? 'inner-page mobile' : ''}>
            {
                detail
                    ?
                    <Descriptions column={2} bordered layout="vertical">
                        <Descriptions.Item label="工单号" span={2}>
                            {detail.order_no ?? '无'}
                        </Descriptions.Item>
                        <Descriptions.Item label="接单日期">
                            {detail.receive_at ?? '无'}
                        </Descriptions.Item>
                        <Descriptions.Item label="时间段">
                            {detail.time_type_text ?? '无'}
                        </Descriptions.Item>
                        <Descriptions.Item label="工单所处街道">
                            {detail.street_text ?? '未知'}
                        </Descriptions.Item>
                        <Descriptions.Item label="工单标题">
                            {detail.title ?? '无'}
                        </Descriptions.Item>
                        <Descriptions.Item label="工单描述" span={2}>
                            {detail.describe ?? '无'}
                        </Descriptions.Item>
                        <Descriptions.Item label="附件" span={2}>
                            {detail.attach_files_info.length > 0
                                ? <List
                                    className='order__attachment'
                                    itemLayout="horizontal"
                                    dataSource={detail.attach_files_info}
                                    renderItem={item => (
                                        <li>
                                            <Card bodyStyle={{ padding: 10 }}>
                                                <div className='order__attachment__card'>
                                                    <div>{item.attach_name}</div>
                                                    <Button type="text" icon={<DownloadOutlined />} href={item.attach_url_link} target="_blank" />
                                                </div>
                                            </Card>
                                        </li>
                                    )}
                                />
                                : '无'}
                        </Descriptions.Item>
                        <Descriptions.Item label="调解附件" span={2}>
                            {detail.mediate_attach_files_info.length > 0
                                ? <List
                                    className='order__attachment'
                                    itemLayout="horizontal"
                                    dataSource={detail.mediate_attach_files_info}
                                    renderItem={item => (
                                        <li>
                                            <Card bodyStyle={{ padding: 10 }}>
                                                <div className='order__attachment__card'>
                                                    <div>{item.attach_name}</div>
                                                    <Button type="text" icon={<DownloadOutlined />} href={item.attach_url_link} target="_blank" />
                                                </div>
                                            </Card>
                                        </li>
                                    )}
                                />
                                : '无'}
                        </Descriptions.Item>
                        <Descriptions.Item label="姓名">
                            {detail.name ?? '无'}
                        </Descriptions.Item>
                        <Descriptions.Item label="电话">
                            {detail.mobile ?? '无'}
                        </Descriptions.Item>
                        <Descriptions.Item label="年龄">
                            {detail.age ?? '无'}
                        </Descriptions.Item>
                        <Descriptions.Item label="身份证">
                            {detail.idcard ?? '无'}
                        </Descriptions.Item>
                        <Descriptions.Item label="具体情况" span={2}>
                            {detail.specific_case ?? '无'}
                        </Descriptions.Item>
                        <Descriptions.Item label="员工态度" span={2}>
                            {detail.staff_attitude ?? '无'}
                        </Descriptions.Item>
                        <Descriptions.Item label="企业态度" span={2}>
                            {detail.company_attitude ?? '无'}
                        </Descriptions.Item>
                        <Descriptions.Item label="跟进结果" span={2}>
                            {detail.trail_result ?? '无'}
                        </Descriptions.Item>
                        <Descriptions.Item label="问题类型" span={2}>
                            {detail.question_type_text ?? '无'}
                        </Descriptions.Item>
                        <Descriptions.Item label="员工诉求类型">
                            {detail.lawsuit_type_text ?? '无'}
                        </Descriptions.Item>
                        <Descriptions.Item label="员工诉求金额">
                            {detail.lawsuit_money ?? '无'}
                        </Descriptions.Item>
                        <Descriptions.Item label="调解结果" span={2}>
                            {detail.status_text ?? '无'}
                        </Descriptions.Item>
                        <Descriptions.Item label="预约上门时间">
                            {detail.visit_appointment_at ?? '无'}
                        </Descriptions.Item>
                        <Descriptions.Item label="实际上门时间">
                            {detail.visit_practical_at ?? '无'}
                        </Descriptions.Item>
                        <Descriptions.Item label="上门调解人" span={2}>
                            {detail.peacemaker ?? '无'}
                        </Descriptions.Item>
                        <Descriptions.Item label="是否上门调解成功" span={2}>
                            {
                                detail.is_visit_conciliation === true ? '是'
                                    : detail.is_visit_conciliation === false ? '否'
                                        : '无'
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="上门调解结果" span={2}>
                            {detail.conciliation_result ?? '无'}
                        </Descriptions.Item>
                    </Descriptions>
                    : <Skeleton active />
            }
        </div>
    );
}

export default ConciliationDetailModal;