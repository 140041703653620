import { Cascader, Grid } from "antd";
import { DefaultOptionType } from "antd/lib/cascader";
import { useEffect, useState } from "react";
import { getRegionByPid } from "../../apis/common";

const { useBreakpoint } = Grid;

/**
 * 省市区选择器
 * mode: text用于兼容纯文本值value
 */
export function LocationCascader({
    value = [],
    onChange = (value: number[]) => { },
    deep = 4,
    placeholder,
    mode = 'default'
}: {
    value?: number[] | string,
    deep: number,
    onChange?: (value: any, items: DefaultOptionType[]) => void,
    placeholder?: string,
    mode?: 'default' | 'text'
}) {
    const screens = useBreakpoint();

    // 内建value（只受控于外部参数）
    const [buildInValue, setBuildInValue] = useState<number[]>([]);
    const [buildInTextValue, setBuildInTextValue] = useState<string>('');
    useEffect(() => {
        if (mode === 'default') {
            setBuildInValue(value as number[]);
            if (value.length > 0) {
                Promise.all([0, ...((value as number[]).slice(0, value.length - 1))]
                    .map(e => getRegionByPid({ pid: e })))
                    .then(res => {
                        getOptions(res).then(options => {
                            setOptions(options);
                        })
                    })
            }
        } else {
            setBuildInValue([0]);
            setBuildInTextValue(value as string);
        }
    }, [value]);

    // 选项
    const [options, setOptions] = useState<DefaultOptionType[]>([]);

    // 初始化
    useEffect(() => {
        getRegionByPid({}).then((res) => {
            setOptions(
                res.data.list.map(item => {
                    let temp: DefaultOptionType = {
                        value: item.id,
                        label: item.name,
                        isLeaf: false
                    };
                    return temp;
                })
            )
        });
    }, []);

    const getOptions = async (values: any): Promise<DefaultOptionType[]> => {
        let res = values[0];
        let arr: DefaultOptionType[] = [];
        for (let i = 0; i < res.data.data.items.length; i++) {
            const item = res.data.data.items[i];
            arr.push({
                value: item.id,
                label: item.name,
                isLeaf: values.length === 1,
                children: values.length > 1 && item.id === values[1].originalArgs.pid ? (await getOptions(values.slice(1))) : []
            });
        }
        return arr;
    }

    // 点击加载
    const loadData = (selectedOptions: DefaultOptionType[]) => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;

        console.log('===================', targetOption);

        getRegionByPid({ pid: Number(targetOption.value) }).then((res) => {
            targetOption.children = res.data.list.map(item => ({
                value: item.id,
                label: item.name,
                isLeaf: selectedOptions.length + 1 >= deep // 层数deep
            }));
            setOptions([...options]);
        }).catch(e => {
            console.error(e);
        }).finally(() => {
            targetOption.loading = false;
        });
    };

    // Emit
    const _onChange = (values: (string | number)[], selectedOptions: DefaultOptionType[]) => {
        console.log(values, selectedOptions);

        if (values.length === deep) {
            if (mode === 'default') {
                onChange(values as number[], selectedOptions);
            } else {
                onChange(selectedOptions.map(item => item.label).join(' / '), selectedOptions);
            }
        }

    };

    const displayRender = (label: string[]) => {
        return (mode === 'default' ? label.join(' / ') : buildInTextValue);
    }

    return (
        <Cascader
            displayRender={displayRender}
            placeholder={placeholder}
            allowClear={false}
            value={buildInValue}
            options={options}
            loadData={loadData}
            onChange={_onChange}
            changeOnSelect
        />
    );
}