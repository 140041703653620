import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';

// antd
import { Grid, Button, Form, Input, message, Select, Skeleton, Descriptions, Row, Col, Radio, Checkbox } from 'antd';

import { getPreArbitrationTableContent, ICaseBackCoverTableData, ICaseBackCoverTableForm, IPreArbitrationCoverTableData, IPreArbitrationCoverTableForm, saveCaseBackCoverTable, savePreArbitrationCoverTable } from '../../../apis/preArbitration';
import { getDictList, IDictData } from '../../../apis/common';
import TextArea from 'antd/lib/input/TextArea';

const { useBreakpoint } = Grid;
const { Option } = Select;


interface CollectionCreateFormProps {
    data?: { id: number },
    onFinish?: () => void;
}

function CaseBackCoverTableFormModal({
    data,
    onFinish,
}: CollectionCreateFormProps) {
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 表单数据
    const [form] = Form.useForm<ICaseBackCoverTableForm>();

    // 初始化加载
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        form.resetFields();

        let pre_worksheet_id = data?.id ?? Number(searchParams.get('id'));
        if (pre_worksheet_id) {
            form.setFieldValue('pre_worksheet_id', pre_worksheet_id);
            setLoading(true);
            getPreArbitrationTableContent({
                pre_worksheet_id,
                form_type: 9,
            }).then(res => {
                console.log(res);
                let data: ICaseBackCoverTableData = res.data.detail!;
                form.setFieldsValue({
                    ...data,
                });
            }).finally(() => {
                setLoading(false);
            })
        }

    }, [data, searchParams]);

    // 当事人
    const [partyType, setPartyType] = useState<{
        label: string;
        value: string | number;
        disabled?: boolean;
    }[]>();
    useEffect(() => {
        getDictList(['pre_worksheet_form_type']).then(res => {
            setPartyType(res.data.pre_worksheet_form_type?.options.map(item => ({
                label: item.name,
                value: item.value,
            })));
        });
    }, []);

    // 提交
    const submit = async () => {
        try {
            await form.validateFields();
        } catch (e: any) {
            message.warning(e.errorFields[0].errors[0]);
            return;
        }

        setConfirmLoading(true);
        let _form = form.getFieldsValue(true);
        try {
            let data = {
                ..._form,
            }

            let res = await saveCaseBackCoverTable(data);
            message.success(res.msg);
            if (onFinish) {
                onFinish();
            } else {
                navigate(-1);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    const gutter = 16 + 8 * 2;

    const FormItem = (
        <Form
            form={form}
            layout="vertical"
            autoComplete="off"
        >
            <Form.Item
                label="有无申请仲裁审查确认"
                name="is_apply_arbitration_review"
            >
                <Radio.Group>
                    <Radio value={true}>有</Radio>
                    <Radio value={false}>无</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label="当事人投诉情况"
                name="party_complaint_status"
            >
                <Checkbox.Group options={partyType} />
            </Form.Item>
            <Form.Item
                label="当事人申请查阅情况"
                name="party_apply_refer_status"
            >
                <Checkbox.Group options={partyType} />
            </Form.Item>
            <Form.Item
                label="当事人申请复印情况"
                name="party_apply_copy_status"
            >
                <Checkbox.Group options={partyType} />
            </Form.Item>
            <Form.Item
                label="有无仲裁机构调阅情形"
                name="is_arbitration_consult"
            >
                <Radio.Group>
                    <Radio value={true}>有</Radio>
                    <Radio value={false}>无</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item
                label="其他需要说明的事项"
                name='other_reason'
            >
                <TextArea rows={3} />
            </Form.Item>

            <Form.Item>
                <Button block type='primary' size='large' loading={confirmLoading} onClick={submit}>提交</Button>
            </Form.Item>
        </Form>
    )

    return (
        <div className={screens.xs ? 'inner-page mobile' : ''}>
            {
                loading
                    ? <Skeleton />
                    : <>
                        {FormItem}
                    </>
            }
        </div>
    );
}

export default CaseBackCoverTableFormModal;