import { axiosInstance, CommonResponse } from ".";
import { Attachment } from "./attachment";
import { DictItem, Pager } from "./common";

export interface IUserListData {
    id: number,
    username: string,
    real_name?: string,
    roles?: number[],
    updated_at?: string,
    created_at: string,
    roles_info: {
        id: number,
        name: string
    }[],
    avatar?: number,
    group_ids: number[],
    group_info: DictItem[],
    company_name?: string,
    dept_name?: string,
    post_name?: string,
    introduction?: string,
    attach_files?: number[],
    attach_files_info?: Attachment[]
}

export interface IUserList {
    list: IUserListData[],
    total: number
}

export interface IUserListSearchForm extends Pager {
    keywords?: string
}

/**
 * 用户列表
 * @returns 
 */
export const getUserList = (data: IUserListSearchForm): Promise<CommonResponse<IUserList>> => {
    return axiosInstance.post('/organ/user/list', data).then(res => res.data);
};

export interface IAddUserForm {
    username: string,
    real_name: string,
    role_ids: number[],
    groups: string[],
    company_name: string,
    dept_name: string,
    post_name: string,
    introduction: string,
    avatar: number,
    attach_files: number[]
}

export interface IEditUserForm extends IAddUserForm {
    id: number,
}

/**
 * 添加用户
 * @param data 
 * @returns 
 */
export const toAddUser = (data: IAddUserForm): Promise<CommonResponse> => {
    return axiosInstance.post('/organ/user/add', data).then(res => res.data);
};

/**
 * 编辑用户
 * @param data 
 * @returns 
 */
export const toEditUser = (data: IEditUserForm): Promise<CommonResponse> => {
    return axiosInstance.post('/organ/user/edit', data).then(res => res.data);
};

/**
 * 删除用户
 * @param data 
 * @returns 
 */
export const toDelUser = (data: { id: number }): Promise<CommonResponse> => {
    return axiosInstance.post('/organ/user/del', data).then(res => res.data);
};


export interface IUserDetailData {
    id: number,
    organ_id: number,
    is_super_admin: boolean,
    username: string,
    real_name: string,
    avatar: number,
    avatar_link?: string,
    status: number,
    roles: number[],
    groups: string[],
    company_name: string,
    dept_name: string,
    post_name: string,
    introduction: string,
    attach_files: number[],
    login_ip: string,
    last_login: string,
    is_change_password: boolean,
    last_change_password_at?: string,
    deleted_at?: string,
    created_at: string,
    updated_at: string,
    roles_info: DictItem[],
    attach_files_info: Attachment[]
}

export interface IUserDetail {
    detail: IUserDetailData
}

/**
 * 获取用户详情
 * @returns 
 */
export const getUserDetail = (data: { id: number }): Promise<CommonResponse<IUserDetail>> => {
    return axiosInstance.post('/organ/user/detail', data).then(res => res.data);
};

export interface IRolesSearchForm extends Pager {
    keywords?: string
}

export interface IRoleRegion {
    id: number,
    role_id: number,
    province_id: number,
    city_id: number,
    area_id: number,
    street_id: number,
    province_text: string,
    city_text: string,
    area_text: string,
    street_text: string
}

export interface IRoleMenu {
    id: number,
    pid: number,
    name: string,
    type: string,
    sign: string,
    status: number,
    sort: number,
    updated_at: string,
    created_at: string,
    pivot: {
        role_id: number,
        menu_id: number
    }
}

export interface IRolesListData {
    id: number,
    name: string,
    status: number,
    remark: string,
    updated_at: string,
    region: IRoleRegion[],
    menu: IRoleMenu[]
}

export interface IRolesList {
    list: IRolesListData[],
    total: number
}

/**
 * 角色列表
 * @returns 
 */
export const getRolesList = (data: IRolesSearchForm): Promise<CommonResponse<IRolesList>> => {
    return axiosInstance.post('/organ/role/list', data).then(res => res.data);
};


export interface IRolesAllList {
    list: DictItem[]
}

/**
 * 所有角色列表
 * @returns 
 */
export const getRolesAllList = (): Promise<CommonResponse<IRolesAllList>> => {
    return axiosInstance.post('/organ/role/all_list').then(res => res.data);
};

export interface IAddRoleForm {
    name: string,
    remark: string,
    region_list: {
        id: string,
        name: string
    }[],
    menu_ids: number[]
}

export interface IEditRoleForm extends IAddRoleForm {
    id: number,
}

/**
 * 添加角色
 * @param data 
 * @returns 
 */
export const toAddRole = (data: IAddRoleForm): Promise<CommonResponse> => {
    return axiosInstance.post('/organ/role/add', data).then(res => res.data);
};

/**
 * 编辑角色
 * @param data 
 * @returns 
 */
export const toEditRole = (data: IEditRoleForm): Promise<CommonResponse> => {
    return axiosInstance.post('/organ/role/edit', data).then(res => res.data);
};

export interface IRoleDetailData {
    id: number,
    name: string,
    status: number,
    remark: string,
    deleted_at?: string,
    created_at: string,
    updated_at: string,
    region: IRoleRegion[],
    menu: IRoleMenu[]
}

export interface IRoleDetail {
    detail: IRoleDetailData
}

/**
 * 获取角色详情
 * @returns 
 */
export const getRolesDetail = (data: { id: number }): Promise<CommonResponse<IRoleDetail>> => {
    return axiosInstance.post('/organ/role/detail', data).then(res => res.data);
};

export interface IRoleMenuTreeData {
    id: number,
    pid?: number,
    name: string,
    children: IRoleMenuTreeData[]
}

export interface IRoleMenuTree {
    list: IRoleMenuTreeData[]
}

/**
 * 获取角色权限菜单树
 * @returns 
 */
export const getRoleMenuTree = (): Promise<CommonResponse<IRoleMenuTree>> => {
    return axiosInstance.post('/organ/menu/list_tree').then(res => res.data);
};

export interface IRoleRegionTree {
    region_level: number,
    region_level_text: string,
    list: IRoleMenuTreeData[]
}

/**
 * 获取角色可分配地区树
 * @returns 
 */
export const getRoleRegionTree = (): Promise<CommonResponse<IRoleMenuTree>> => {
    return axiosInstance.get('/organ/perm/region_list').then(res => res.data);
};

/**
 * 角色删除
 * @param data 
 * @returns 
 */
export const toDelRole = (data: { id: number }): Promise<CommonResponse> => {
    return axiosInstance.post('/organ/role/del', data).then(res => res.data);
};
