import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';

// antd
import { Grid, Button, Form, Input, message, Select, Skeleton, Descriptions, Row, Col, Space, Divider, InputRef, Card } from 'antd';
import { DatePicker as DatePickerMobile } from 'antd-mobile';
import DatePicker from '../../../components/customAntd/DatePicker';
import { PickerDate } from 'antd-mobile/es/components/date-picker/util';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { getPreArbitrationTableContent, IFileIndexTableForm, IFileIndexTableListData, ILaborDisputeRecordTableData, ILaborDisputeRecordTableForm, IPreArbitrationCoverTableData, IPreArbitrationCoverTableForm, IProofOfDeliveryTableData, IProofOfDeliveryTableForm, IProofOfDeliveryTableFormDeliveryData, saveFileIndexTable, saveLaborDisputeRecordTable, savePreArbitrationCoverTable, saveProofOfDeliveryTable } from '../../../apis/preArbitration';
import { getDictList, IDictData } from '../../../apis/common';
import TextArea from 'antd/lib/input/TextArea';

const { useBreakpoint } = Grid;
const { Option } = Select;

interface CollectionCreateFormProps {
    data?: { id: number },
    onFinish?: () => void;
}

function ProofOfDeliveryTableFormModal({
    data,
    onFinish,
}: CollectionCreateFormProps) {
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 表单数据
    const [form] = Form.useForm<IProofOfDeliveryTableForm>();

    // 收到日期
    const [receiveAtVisible, setReceiveAtVisible] = useState(false);

    // 初始化加载
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        form.resetFields();

        let pre_worksheet_id = data?.id ?? Number(searchParams.get('id'));
        if (pre_worksheet_id) {
            form.setFieldValue('pre_worksheet_id', pre_worksheet_id);
            setLoading(true);

            getPreArbitrationTableContent({
                pre_worksheet_id,
                form_type: 10,
            }).then(res => {
                console.log(res);
                let data: IProofOfDeliveryTableData = res.data.detail!;

                data.delivery_data?.forEach(item => {
                    item.receive_at = item.receive_at ? dayjs(item.receive_at) as any : undefined;
                })

                form.setFieldsValue({
                    ...data,
                });
            }).finally(() => {
                setLoading(false);
            })
        }

    }, [data, searchParams]);

    // 提交
    const submit = async (print = false) => {

        try {
            await form.validateFields();
        } catch (e: any) {
            message.warning(e.errorFields[0].errors[0]);
            return;
        }

        setConfirmLoading(true);
        let _form = form.getFieldsValue(true);
        try {
            let data = JSON.parse(JSON.stringify(_form));

            data.delivery_data?.forEach((item: IProofOfDeliveryTableFormDeliveryData) => {
                item.receive_at = item.receive_at ? dayjs(item.receive_at).format('YYYY-MM-DD') : undefined;
            });

            let res = await saveProofOfDeliveryTable(data);
            message.success(res.msg);
            if (print) {
                setTimeout(() => {
                    window.open(`/preArbitration/proofOfDelivery/print?id=${_form.pre_worksheet_id}`, '_blank');
                }, 0);
            } else {
                if (onFinish) {
                    onFinish();
                } else {
                    navigate(-1);
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    const gutter = 16 + 8 * 2;

    const FormItem = (
        <Form
            form={form}
            layout="vertical"
            autoComplete="off"
        >
            {/* <Form.Item
                label="收件方"
                name="receiver"
            >
                <Input maxLength={100} />
            </Form.Item> */}

            <Form.Item
                label="机构名称"
                name="archive_name"
            >
                <Input maxLength={100} />
            </Form.Item>

            <Form.Item
                label="收件方（申请人）"
                name="plaintiff_name"
            >
                <Input maxLength={100} />
            </Form.Item>

            <Form.Item
                label="收件方（被申请人）"
                name="defendant_name"
            >
                <Input maxLength={100} />
            </Form.Item>

            <Form.Item
                label="送达地点"
                name="delivery_address"
            >
                <Input maxLength={200} />
            </Form.Item>

            <Form.List name="delivery_data">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }, index) => (
                            <Card>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3>({index + 1})</h3>
                                    <Button
                                        type='text'
                                        size='small'
                                        danger
                                        onClick={() => remove(name)}
                                    >删除</Button>
                                </div>
                                <Row gutter={gutter}>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            {...restField}
                                            label="送达文件"
                                            name={[name, 'delivery_file']}
                                        >
                                            <Input maxLength={100} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            {...restField}
                                            label="签发人"
                                            name={[name, 'signer']}
                                        >
                                            <Input maxLength={100} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            {...restField}
                                            label="送达人"
                                            name={[name, 'deliverer']}
                                        >
                                            <Input maxLength={100} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        {
                                            screens.xs ?
                                                <Form.Item
                                                    label="收到日期"
                                                    shouldUpdate={(prevValues, curValues) => prevValues.agent_birthday !== curValues.agent_birthday}
                                                >
                                                    <DatePickerMobile
                                                        visible={receiveAtVisible}
                                                        onClose={() => {
                                                            setReceiveAtVisible(false)
                                                        }}
                                                        value={(form.getFieldValue([name, 'receive_at']) as Dayjs)?.toDate()}
                                                        onConfirm={(value: PickerDate) => {
                                                            form.setFieldValue([name, 'receive_at'], dayjs(value));
                                                        }}
                                                    >
                                                        {value =>
                                                            <>
                                                                <Button
                                                                    style={{ marginRight: 10 }}
                                                                    onClick={() => {
                                                                        setReceiveAtVisible(true)
                                                                    }}
                                                                >选择</Button>
                                                                {value ? dayjs(value).format('YYYY年MM月DD日') : '请选择时间'}
                                                            </>
                                                        }
                                                    </DatePickerMobile>
                                                </Form.Item>
                                                : <Form.Item
                                                    label="收到日期"
                                                    name={[name, 'receive_at']}
                                                >
                                                    <DatePicker
                                                        format='YYYY-MM-DD'
                                                        style={{ width: '100%' }}
                                                    />
                                                </Form.Item>
                                        }
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            {...restField}
                                            label="接收人姓名"
                                            name={[name, 'receiver']}
                                        >
                                            <Input maxLength={10} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            {...restField}
                                            label="不能送达理由"
                                            name={[name, 'reason']}
                                        >
                                            <Input maxLength={100} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                添加记录
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>

            <Form.Item
                label="备注"
                name="remark"
            >
                <TextArea rows={3} />
            </Form.Item>

            <Form.Item
                label="其他需要说明的事项"
                name="other_reason"
            >
                <TextArea rows={3} />
            </Form.Item>

            <Form.Item>
                <Button block type='primary' size='large' loading={confirmLoading} onClick={() => submit()}>提交</Button>
            </Form.Item>
        </Form>
    )
    return (
        <div className={screens.xs ? 'inner-page mobile' : ''}>
            {
                loading
                    ? <Skeleton />
                    : <>
                        <Descriptions
                            size='small'
                            title="填写表单"
                            extra={<Button onClick={() => {
                                submit(true);
                            }}>预览打印</Button >}
                        ></Descriptions>
                        {FormItem}
                    </>
            }
        </div>
    );
}

export default ProofOfDeliveryTableFormModal;