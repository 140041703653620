import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useDebounce } from 'ahooks';

// apis
import { FileUpload, getDictList, IDictData, uploadFile } from '../../apis/common';

// interface
import { downloadTemplate, exportIOC, exportOrderList, getOrderList, IExportIOCForm, IImportExcelForm, IImportWordForm, importWorksheetExcel, importWorksheetWord, IOrderListData, IOrderListSearchForm } from '../../apis/order';
import { Attachment } from '../../apis/attachment';
import { getHasAuth, globalAuthSigns } from '../../utils/auth';

// antd
import { Space, Table, Dropdown, Menu, Select, Button, Modal, message, Card, Upload, UploadFile, Input, Form, Pagination, Empty, Grid, Cascader } from 'antd';
import { CloseOutlined, ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { UploadRequestOption } from "rc-upload/lib/interface";
import TextArea from 'antd/lib/input/TextArea';
import DatePicker from '../../components/customAntd/DatePicker';
import { Cascader as CascaderMobile } from 'antd-mobile';


import OrderDetailModal from './actions/orderDetail';
import { getRoleRegionTree, IRoleMenuTreeData } from '../../apis/auth';
import { ITransferConciliationForm, transferConciliation } from '../../apis/conciliation';
import { getAllUser, IAllUserData } from '../../apis/user';

const { Column } = Table;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { confirm } = Modal;
const { useBreakpoint } = Grid;

let tempFileList: UploadFile<any>[] = [];

function OrderList() {
    const screens = useBreakpoint();
    const navigate = useNavigate();

    // 权限检查
    // 组件全局权限引用
    const [hasWorksheetImportAuth, setHasWorksheetImportAuth] = useState(false);
    const [hasWorksheetHandleAuth, setHasWorksheetHandleAuth] = useState(false);
    const [hasWorksheetTransferAuth, setHasWorksheetTransferAuth] = useState(false);
    const [hasWorksheetIOCExportAuth, setHasWorksheetIOCExportAuth] = useState(false);
    useEffect(() => {
        getHasAuth([
            globalAuthSigns.worksheet_import,
            globalAuthSigns.worksheet_handle,
            globalAuthSigns.worksheet_handle_transfer,
            globalAuthSigns.worksheet_iocExport,
        ]).then(value => {
            setHasWorksheetImportAuth(value[0]);
            setHasWorksheetHandleAuth(value[1]);
            setHasWorksheetTransferAuth(value[2]);
            setHasWorksheetIOCExportAuth(value[3]);
        })
    }, []);

    // 搜索表单
    const [form, setForm] = useState<IOrderListSearchForm>({
        page: 1,
        size: 10
    });

    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState<(IOrderListData)[]>([]);
    const [mListData, setMListData] = useState<(IOrderListData)[]>([]);

    const [total, setTotal] = useState(0);
    const loadData = async (newForm?: IOrderListSearchForm) => {
        let tempForm = newForm ?? form;
        setLoading(true);
        try {
            let res = await getOrderList(tempForm);
            setTotal(res.data.total);
            setMListData(res.data.list);
            setListData((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== res.data.total) {
                    newListData = new Array(res.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((tempForm.page! - 1) * tempForm.size, res.data.list.length, ...res.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    // 分页
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        let newForm = {
            ...form,
            page,
            size: pageSize,
        };
        setForm(newForm);
        loadData(newForm);
    }

    const resetData = () => {
        let newForm = {
            page: 1,
            size: 20
        }
        setForm(newForm);
        loadData(newForm);
    }

    // 机构调解人员
    const handleOrganUserChange = (value: number) => {
        console.log(`selected ${value}`);
        setForm((form) => {
            let newForm = {
                ...form,
                organ_user_id: value,
            };
            loadData(newForm);
            return newForm;
        });
    };

    // 时间段类型
    const [timeType, setTimeType] = useState<IDictData>();
    useEffect(() => {
        getDictList(['global_time_type']).then(res => {
            setTimeType(res.data.global_time_type);
        });
    }, []);
    const handleTimeTypeChange = (value: number) => {
        console.log(`selected ${value}`);
        setForm((form) => {
            let newForm = {
                ...form,
                time_type: value,
            };
            loadData(newForm);
            return newForm;
        });
    };

    // 工单来源
    const [source, setSource] = useState<IDictData>();
    useEffect(() => {
        getDictList(['worksheet_source']).then(res => {
            setSource(res.data.worksheet_source);
        });
    }, []);
    const handleSourceChange = (value: number) => {
        console.log(`selected ${value}`);
        setForm((form) => {
            let newForm = {
                ...form,
                source: value,
            };
            loadData(newForm);
            return newForm;
        });
    };

    // 关键词搜索
    const keywordsDebouncedValue = useDebounce(form.keywords, { wait: 500 });
    useEffect(() => {
        loadData(form);
    }, [keywordsDebouncedValue]);
    const handleKeywordsChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        let newForm = {
            ...form,
            keywords: value.target.value
        };
        setForm(newForm);
    }


    // 接单时间段
    const handleReceiveChange = (_: any, formatString: [string, string]) => {
        if (!formatString[0] || !formatString[1]) {
            setForm((form) => {
                let newForm = {
                    ...form,
                    receive_at: undefined,
                };
                loadData(newForm);
                return newForm;
            });
        } else {
            setForm((form) => {
                let newForm = {
                    ...form,
                    receive_at: formatString,
                };
                loadData(newForm);
                return newForm;
            });
        }
    }

    // 导出数据
    const exportData = () => {
        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '确定要导出当前列表吗？',
            onOk() {
                setLoading(true);
                exportOrderList(form).then(res => {
                    setTimeout(() => {
                        window.open(res.data.full_link, '_blank');
                    }, 100);
                }).finally(() => setLoading(false));
            },
        });
    };

    // excel上传
    const [uploadExcelForm] = Form.useForm<IImportExcelForm>();
    const [isExcelModalOpen, setIsExcelModalOpen] = useState(false);
    const handleExcelOk = async () => {
        await uploadExcelForm.validateFields();
        if (attachments.length === 0) {
            message.warning('至少上传一个文件');
            return;
        }

        const _form = uploadExcelForm.getFieldsValue(true);
        if (_form.cur_id.length !== 4) {
            message.warning('地址请选择完整');
            return;
        }

        importWorksheetExcel({
            ..._form,
            file_id: attachments
                .filter(file => file.response)
                .map(file => {
                    let res: FileUpload | Attachment = file.response;
                    return (res as FileUpload).file_id || (res as Attachment).id;
                })[0],
            province_id: _form.cur_id ? _form.cur_id[0] : 0,
            city_id: _form.cur_id ? _form.cur_id[1] : 0,
            area_id: _form.cur_id ? _form.cur_id[2] : 0,
            street_id: _form.cur_id ? _form.cur_id[3] : 0,
            receive_at: (_form.receive_at as Dayjs).format('YYYY-MM-DD')
        }).then(res => {
            if (res.data.has_fail) {
                confirm({
                    title: '导入失败',
                    icon: <ExclamationCircleOutlined />,
                    content: (
                        <div>
                            <div>{res.data.tips}</div>
                            <div>是否下载失败结果文档</div>
                        </div>
                    ),
                    onOk() {
                        window.open(res.data.full_link, '_blank');
                    },
                });
            } else {
                Modal.info({
                    title: '导入结果',
                    content: res.data.tips,
                    onOk() {
                        resetExcelFileUploadList();
                        setIsExcelModalOpen(false);
                    },
                });
            }
        }).finally(() => {
            loadData();
        });
    };
    const handleExcelCancel = () => {
        resetExcelFileUploadList();
        setIsExcelModalOpen(false);
    };
    const resetExcelFileUploadList = () => {
        tempFileList = [];
        uploadExcelForm.resetFields();
        setAttachments([]);
    }

    // 导出IOC工单压缩包
    const [exportIOCForm] = Form.useForm<IExportIOCForm>();
    const [isIOCModalOpen, setIsIOCModalOpen] = useState(false);
    const handleIOCOk = async () => {
        await exportIOCForm.validateFields();

        setLoading(true);

        const _form = exportIOCForm.getFieldsValue(true);
        exportIOC({
            time_type: _form.time_type ?? undefined,
            province_id: _form.cur_id ? _form.cur_id[0] : undefined,
            city_id: _form.cur_id ? _form.cur_id[1] : undefined,
            area_id: _form.cur_id ? _form.cur_id[2] : undefined,
            street_id: _form.cur_id ? _form.cur_id[3] : undefined,
            receive_at: _form.receive_at_dayjs ? _form.receive_at_dayjs.map((e: Dayjs) => e.format('YYYY-MM-DD')) : undefined
        }).then(res => {
            setTimeout(() => {
                window.open(res.data.full_link, '_blank');
            }, 100);
        }).finally(() => {
            setLoading(false);
            handleIOCCancel();
        });
    };
    const handleIOCCancel = () => {
        exportIOCForm.resetFields();
        setIsIOCModalOpen(false);
    };

    // word批量上传
    const [uploadWordForm] = Form.useForm<IImportWordForm>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOk = async () => {
        await uploadWordForm.validateFields();
        if (attachments.length === 0) {
            message.warning('至少上传一个文件');
            return;
        }

        const _form = uploadWordForm.getFieldsValue(true);
        importWorksheetWord({
            ..._form,
            file_ids: attachments
                .filter(file => file.response)
                .map(file => {
                    let res: FileUpload | Attachment = file.response;
                    return (res as FileUpload).file_id || (res as Attachment).id;
                }),
            province_id: _form.cur_id ? _form.cur_id[0] : 0,
            city_id: _form.cur_id ? _form.cur_id[1] : 0,
            area_id: _form.cur_id ? _form.cur_id[2] : 0,
            street_id: _form.cur_id ? _form.cur_id[3] : 0,
        }).then(res => {
            Modal.info({
                title: '导入结果',
                content: res.data.tips,
                onOk() {
                    resetFileUploadList();
                    setIsModalOpen(false);
                },
            });
        }).finally(() => {
            loadData();
        });
    };
    const handleCancel = () => {
        resetFileUploadList();
        setIsModalOpen(false);
    };
    const resetFileUploadList = () => {
        tempFileList = [];
        uploadWordForm.resetFields();
        setAttachments([]);
    }

    // 上传附件
    const [attachments, setAttachments] = useState<UploadFile<any>[]>([]);
    const checkFileSize = (file: RcFile) => {
        if ((file.size / 1024 / 1024) > 50) {
            message.error(`${file.name} 文件超出限制大小`);
            return Upload.LIST_IGNORE;
        }
        return true;
    };
    const onFileChange = (info: UploadChangeParam<UploadFile<any>>) => {
        console.log(info.fileList, attachments);
        if (info.fileList.length < attachments.length) {
            // 删除
            tempFileList = info.fileList;
        } else {
            if (tempFileList.length === 0) {
                tempFileList = info.fileList;
            } else if (info.file.status === 'done') {
                // 状态改变
                tempFileList = [info.file, ...tempFileList.filter((item) => item.uid !== info.file.uid)];
            }
        }
        setAttachments(tempFileList);
    }
    const customRequest = async ({ onSuccess, onError, file, onProgress }: UploadRequestOption<any>) => {
        let res = await uploadFile({ file: file as Blob, type: 'default', uploadProgressCallback: onProgress });
        onSuccess!(res.data);
    }
    const onAttachmentDownload = (file: UploadFile<any>) => {
        console.log(file);
        let res: FileUpload | Attachment = file.response;
        window.open((res as FileUpload).full_link || (res as Attachment).attach_url_link, "_blank");
    }

    // 详情弹窗
    const [toDetailModal, setDetailModal] = useState<{
        isModalVisible: boolean,
        data: { id: number }
    }>();
    const toDetail = (id: number) => {
        if (screens.xs) {
            navigate(`/order/detail?id=${id}`);
        } else {
            setDetailModal({
                isModalVisible: true,
                data: { id: id }
            })
        }
    };
    const onDetailClose = () => {
        setDetailModal({
            ...toDetailModal!,
            isModalVisible: false
        })
    };

    // 下载导入模板
    const toDownload = async () => {
        let res = await downloadTemplate();
        window.open(res.data.full_link, '_blank');
    }

    interface Option {
        value: string | number;
        label: string;
        children?: Option[];
    }
    const [options, setOptions] = useState<Option[]>([]);
    useEffect(() => {
        let nodes: IRoleMenuTreeData[] = [];
        const build = (list: IRoleMenuTreeData[]): Option[] => {
            return list.map(item => ({
                label: item.name,
                value: item.id,
                children: item.children.length > 0 ? build(item.children) : undefined
            }));
        }
        getRoleRegionTree().then(res => {
            nodes.push(...res.data.list);
            setOptions(build(nodes));
        });
    }, []);

    // table选中
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    // 用户列表
    const [userList, setUserList] = useState<IAllUserData[]>([]);
    useEffect(() => {
        getAllUser().then(res => {
            setUserList(res.data.list)
        })
    }, []);

    // 转移工单
    const [transferForm] = Form.useForm<ITransferConciliationForm>();
    const [toTransferModal, setToTransferModal] = useState(false);
    const onTransferClose = () => {
        setToTransferModal(false);
    };
    const onTransferSubmit = async () => {
        await transferForm.validateFields();

        setLoading(true);
        try {
            let _form = transferForm.getFieldsValue(true);
            let res = await transferConciliation(_form);
            onTransferClose();
            message.success(res.msg);
            loadData();
            setSelectedRowKeys([]);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }
    const toTransfer = (ids: number[] = selectedRowKeys.map(id => Number(id))) => {
        if (ids.length === 0) {
            message.warning('请至少选择一项');
        }
        transferForm.resetFields();
        transferForm.setFieldsValue({
            worksheet_ids: ids
        })
        setToTransferModal(true);
    }

    return (
        <div className='address inner-page'>
            <div className='search'>
                <Select
                    allowClear
                    className="search__item"
                    showArrow
                    placeholder="调解人员筛选"
                    value={form.organ_user_id}
                    onChange={handleOrganUserChange}
                >
                    {userList?.map(option => (
                        <Option key={option.id} value={option.id}>[{option.username}]{option.real_name}</Option>
                    ))}
                </Select>
                <Select
                    allowClear
                    className="search__item"
                    showArrow
                    placeholder="时间段类型"
                    value={form.time_type}
                    onChange={handleTimeTypeChange}
                >
                    {timeType?.options.map(option => (
                        <Option key={option.value}>{option.name}</Option>
                    ))}
                </Select>
                <Select
                    allowClear
                    className="search__item"
                    showArrow
                    placeholder="工单来源"
                    value={form.source}
                    onChange={handleSourceChange}
                >
                    {source?.options.map(option => (
                        <Option key={option.value}>{option.name}</Option>
                    ))}
                </Select>
                <RangePicker
                    className="search__item"
                    style={{ 'width': '300px' }}
                    value={[
                        form.receive_at && form.receive_at[0] ? dayjs(form.receive_at[0]) : null,
                        form.receive_at && form.receive_at[1] ? dayjs(form.receive_at[1]) : null,
                    ]}
                    onChange={handleReceiveChange}
                    format='YYYY-MM-DD'
                    placeholder={['开始日期', '结束日期']}
                />
                <Input
                    className="search__item"
                    value={form.keywords}
                    onChange={handleKeywordsChange}
                    placeholder="订单号、姓名搜索"
                />

                <Button
                    className="search__button"
                    icon={<CloseOutlined />}
                    loading={loading}
                    onClick={() => resetData()}
                >
                    重置筛选
                </Button>

                <Dropdown.Button
                    className={`search__last-button ${screens.xs ? 'xs' : ''}`}
                    style={{ marginRight: screens.xs ? 15 : 0 }}
                    type="primary"
                    loading={false}
                    trigger={['click', 'hover']}
                    overlay={
                        <Menu
                            items={selectedRowKeys.length > 0 ? [
                                {
                                    key: 'transfer',
                                    disabled: !hasWorksheetTransferAuth,
                                    label: (
                                        <div>批量转移工单</div>
                                    ),
                                    onClick: () => toTransfer(),
                                },
                            ] : [
                                {
                                    key: 'download',
                                    disabled: !hasWorksheetImportAuth,
                                    label: (
                                        <div>下载导入模板</div>
                                    ),
                                    onClick: toDownload,
                                },
                                {
                                    key: 'excelImport',
                                    disabled: !hasWorksheetImportAuth,
                                    label: (
                                        <div>Excel导入</div>
                                    ),
                                    onClick: () => {
                                        setIsExcelModalOpen(true);
                                    }
                                },
                                {
                                    key: 'wordImport',
                                    disabled: !hasWorksheetImportAuth,
                                    label: (
                                        <div>Word批量导入</div>
                                    ),
                                    onClick: () => {
                                        setIsModalOpen(true);
                                    }
                                },
                                {
                                    key: 'export',
                                    label: (
                                        <div>导出报表</div>
                                    ),
                                    onClick: exportData,
                                },
                                {
                                    key: 'exportIOC',
                                    disabled: !hasWorksheetIOCExportAuth,
                                    label: (
                                        <div>导出IOC压缩包</div>
                                    ),
                                    onClick: () => {
                                        setIsIOCModalOpen(true);
                                    }
                                },
                            ].filter(item => !item.disabled)}
                        />
                    }>
                    {selectedRowKeys.length > 0 ? `已选择${selectedRowKeys.length}项` : '更多操作'}
                </Dropdown.Button>
            </div>
            {
                screens.xs
                    ? <div>
                        {
                            mListData.length > 0 ? mListData.map(item =>
                                <Dropdown
                                    trigger={['click']}
                                    menu={{
                                        items: [
                                            {
                                                key: 'detail',
                                                label: (
                                                    <div>查看详情</div>
                                                ),
                                                onClick: () => toDetail(item.id),
                                            },
                                            {
                                                key: 'transfer',
                                                disabled: !hasWorksheetTransferAuth,
                                                label: (
                                                    <div>转移</div>
                                                ),
                                                onClick: () => toTransfer([item.id]),
                                            },
                                        ]
                                    }}>
                                    <Card className='m-card'>
                                        <div className='m-card_item'>
                                            <div>时间：</div>
                                            <div>{`${item.receive_at} ${item.time_type_text}`}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>工单号：</div>
                                            <div>{item.order_no}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>省-市-区：</div>
                                            <div>{`${item.province_text}-${item.city_text}-${item.area_text}`}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>街道：</div>
                                            <div>{item.street_text}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>投诉：</div>
                                            <div>{item.name}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>调解状态：</div>
                                            <div>{item.status_text}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>导入时间：</div>
                                            <div>{item.created_at}</div>
                                        </div>
                                    </Card>
                                </Dropdown>
                            )
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                        }

                        <Pagination
                            current={form.page}
                            onChange={onPageChange}
                            showTotal={total => `共 ${total} 项`}
                            total={total}
                            simple
                        />
                    </div>
                    : <Table
                        dataSource={listData}
                        size='small'
                        loading={loading}
                        scroll={{ x: 800 }}
                        rowSelection={
                            {
                                selectedRowKeys,
                                onChange: onSelectChange
                            }
                        }
                        rowKey={(record) => record?.id}
                        pagination={{
                            position: ['bottomRight'],
                            size: "small",
                            total: total,
                            showTotal: total => `共 ${total} 项`,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            onChange: onPageChange
                        }}>
                        <Column
                            title="时间"
                            dataIndex="receive_at"
                            key="receive_at"
                            render={(_: any, record: IOrderListData | null) => (
                                record ? `${record.receive_at} ${record.time_type_text}` : ''
                            )}
                        />
                        <Column title="工单号" dataIndex="order_no" key="order_no" />
                        <Column
                            title="省-市-区"
                            dataIndex="province_text"
                            key="province_text"
                            render={(_: any, record: IOrderListData | null) => (
                                record ? `${record.province_text}-${record.city_text}-${record.area_text}` : ''
                            )}
                        />
                        <Column title="街道" dataIndex="street_text" key="street_text" />
                        <Column title="投诉" dataIndex="name" key="name" />
                        <Column title="调解状态" dataIndex="status_text" key="status_text" />
                        <Column title="导入时间" dataIndex="created_at" key="created_at" />
                        <Column
                            title="操作"
                            key="action"
                            fixed="right"
                            render={(_: any, record: IOrderListData | null) => (
                                <Space>
                                    <Dropdown.Button
                                        trigger={['click', 'hover']}
                                        size='small'
                                        type="primary"
                                        loading={loading}
                                        overlay={
                                            <Menu
                                                items={[
                                                    {
                                                        key: 'detail',
                                                        disabled: !record?.id,
                                                        label: (
                                                            <div>查看详情</div>
                                                        ),
                                                        onClick: () => toDetail(record?.id ?? 0),
                                                    },
                                                    {
                                                        key: 'transfer',
                                                        disabled: !hasWorksheetTransferAuth,
                                                        label: (
                                                            <div>转移</div>
                                                        ),
                                                        onClick: () => toTransfer([record!.id]),
                                                    },
                                                ]}
                                            />
                                        }>
                                        操作
                                    </Dropdown.Button>
                                </Space>
                            )}
                        />
                    </Table>
            }

            {/* excel import */}
            <Modal
                title="上传表格"
                confirmLoading={loading}
                open={isExcelModalOpen}
                onOk={handleExcelOk}
                onCancel={handleExcelCancel}
            >
                <Form
                    form={uploadExcelForm}
                >
                    <Form.Item
                        label="地区"
                        name="cur_id"
                        rules={[{ required: true, message: '请选择地址' }]}
                        trigger={screens.xs ? 'onConfirm' : undefined}
                    >
                        {
                            screens.xs
                                ? <CascaderMobile
                                    options={options as any}
                                >
                                    {(items, actions) => (
                                        <>
                                            <Button onClick={actions.open} style={{ marginRight: 10 }}>选择</Button>
                                            {
                                                items.every(item => item === null)
                                                    ? '请选择地址'
                                                    : items.map(item => item?.label ?? '请选择地址').join('-')
                                            }
                                        </>
                                    )}
                                </CascaderMobile>
                                : <Cascader options={options} placeholder="请选择地址" />
                        }
                    </Form.Item>
                    <Form.Item
                        label="接单日期"
                        name="receive_at"
                        rules={[{ required: true, message: '请选择接单日期' }]}
                    >
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        label="时间段"
                        name="time_type"
                        rules={[{ required: true, message: '请选择时间段类型' }]}

                    >
                        <Select showArrow>
                            {timeType?.options.map(option => (
                                <Option key={option.value}>{option.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Upload
                        multiple={false}
                        fileList={attachments}
                        beforeUpload={checkFileSize}
                        onChange={onFileChange}
                        customRequest={customRequest}
                        showUploadList={{ showDownloadIcon: true }}
                        onDownload={onAttachmentDownload}
                        accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                    >
                        <Button icon={<UploadOutlined />}>点击上传Excel文件</Button>
                    </Upload>
                </Form>
            </Modal>

            {/* words import */}
            <Modal title="批量上传文档" confirmLoading={loading} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    form={uploadWordForm}
                >
                    <Form.Item
                        label="地区"
                        name="cur_id"
                        rules={[{ required: true, message: '请选择地址' }]}
                        trigger={screens.xs ? 'onConfirm' : undefined}
                    >
                        {
                            screens.xs
                                ? <CascaderMobile
                                    options={options as any}

                                >
                                    {(items, actions) => (
                                        <>
                                            <Button onClick={actions.open} style={{ marginRight: 10 }}>选择</Button>
                                            {
                                                items.every(item => item === null)
                                                    ? '请选择地址'
                                                    : items.map(item => item?.label ?? '请选择地址').join('-')
                                            }
                                        </>
                                    )}
                                </CascaderMobile>
                                : <Cascader options={options} placeholder="请选择地址" />
                        }
                    </Form.Item>
                    <Form.Item
                        label="接单日期"
                        name="receive_at"
                        rules={[{ required: true, message: '请选择接单日期' }]}
                    >
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        label="时间段"
                        name="time_type"
                        rules={[{ required: true, message: '请选择时间段类型' }]}

                    >
                        <Select showArrow>
                            {timeType?.options.map(option => (
                                <Option key={option.value} value={option.value}>{option.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="具体情况"
                        name="specific_case"
                    >
                        <TextArea rows={2} placeholder="请填写具体情况" />
                    </Form.Item>

                    <Upload
                        multiple
                        fileList={attachments}
                        beforeUpload={checkFileSize}
                        onChange={onFileChange}
                        customRequest={customRequest}
                        showUploadList={{ showDownloadIcon: true }}
                        onDownload={onAttachmentDownload}
                        accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    >
                        <Button icon={<UploadOutlined />}>点击上传Word文档</Button>
                    </Upload>
                </Form>
            </Modal>

            <Modal title="导出IOC工单压缩包" confirmLoading={loading} open={isIOCModalOpen} onOk={handleIOCOk} onCancel={handleIOCCancel}>
                <Form
                    form={exportIOCForm}
                >
                    <Form.Item
                        label="地区"
                        name="cur_id"
                        trigger={screens.xs ? 'onConfirm' : undefined}
                    >
                        {
                            screens.xs
                                ? <CascaderMobile
                                    options={options as any}
                                >
                                    {(items, actions) => (
                                        <>
                                            <Button onClick={actions.open} style={{ marginRight: 10 }}>选择</Button>
                                            {
                                                items.every(item => item === null)
                                                    ? '请选择地址'
                                                    : items.map(item => item?.label ?? '请选择地址').join('-')
                                            }
                                        </>
                                    )}
                                </CascaderMobile>
                                : <Cascader options={options} placeholder="请选择地址" />
                        }
                    </Form.Item>
                    <Form.Item
                        label="接单日期"
                        name="receive_at_dayjs"
                    >
                        <RangePicker
                            format='YYYY-MM-DD'
                            placeholder={['开始日期', '结束日期']}
                        />
                    </Form.Item>
                    <Form.Item
                        label="时间段"
                        name="time_type"

                    >
                        <Select showArrow>
                            {timeType?.options.map(option => (
                                <Option key={option.value} value={option.value}>{option.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>

            {/* 详情弹窗 */}
            {
                toDetailModal ? <Modal
                    visible={toDetailModal.isModalVisible}
                    title={`工单详情`}
                    onCancel={onDetailClose}
                    width={1000}
                    footer={[
                        <Button key="back" onClick={onDetailClose}>
                            关闭
                        </Button>
                    ]}
                >
                    <OrderDetailModal data={toDetailModal.data}></OrderDetailModal>
                </Modal> : null
            }

            {/* 转移工单 */}
            {
                <Modal
                    title="转移工单"
                    open={toTransferModal}
                    onOk={onTransferSubmit}
                    onCancel={onTransferClose}
                    confirmLoading={loading}
                >
                    <Form form={transferForm} layout="vertical">
                        <Form.Item
                            label="选择处理人"
                            name="organ_user_id"
                            rules={[{ required: true, message: '请选择处理人' }]}
                        >
                            <Select showArrow>
                                {userList?.map(option => (
                                    <Option key={option.id} value={option.id}>[{option.username}]{option.real_name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="选择协助人"
                            name="organ_assist_user_id"
                        >
                            <Select showArrow>
                                {userList?.map(option => (
                                    <Option key={option.id} value={option.id}>[{option.username}]{option.real_name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>
            }
        </div>
    );
}

export default OrderList;