import { axiosInstance, CommonResponse } from ".";
import { Attachment } from "./attachment";
import { Pager } from "./common";


export interface IConciliationListData {
    id: number,
    file_id: number,
    batch_no: string,
    order_no: string,
    servicer_order_no: null,
    company_name: null,
    name: string,
    telephone_number: null,
    mobile: string,
    idcard: string,
    birthday: null,
    sex: number,
    form_type: null,
    age: number,
    event_level: null,
    event_begin_at: null,
    assigned_at: null,
    title: string,
    has_attachment: string,
    describe: string,
    specific_case: null,
    event_address: null,
    sued_company_name: null,
    sued_company_address: null,
    time_limit: null,
    dispatch_opinion: null,
    supplement_info: null,
    handle_opinion: null,
    staff_attitude: null,
    company_attitude: null,
    trail_result: null,
    trail_at: null,
    receive_at: string,
    status: number,
    gained_at: string,
    handle_at: null,
    order_at: null,
    time_type: number,
    user_type: number,
    service_user_id: null,
    assist_user_id: null,
    organ_user_id: number,
    organ_assist_user_id: null,
    company_business: null,
    company_scale: null,
    company_contact: null,
    company_contact_mobile: null,
    company_contact_position: null,
    lawsuit_type: null,
    lawsuit_money: null,
    visit_appointment_at: null,
    visit_practical_at: null,
    peacemaker: null,
    arrange_status: null,
    is_visit_conciliation: null,
    conciliation_result: null,
    province_id: number,
    city_id: number,
    area_id: number,
    street_id: number,
    is_old_order: number,
    is_repeat: false,
    is_visit: null,
    is_return_visit: null,
    upload_cloud_status: number,
    source: number,
    recently_edit_by: null,
    deleted_at: null,
    created_at: string,
    updated_at: string,
    time_type_text: string,
    province_text: string,
    city_text: string,
    area_text: string,
    street_text: string,
    lawsuit_type_text?: string,
    company_business_text?: string,
    company_scale_text?: string,
    status_text: string,
    service_user: null,
    organ_user: {
        id: number,
        real_name: string
    },
    organ_assist_user: null
}

export interface IConciliationList {
    list: IConciliationListData[],
    total: number
}

export interface IConciliationListSearchForm extends Pager {
    nav_type: number,
    receive_at?: string,
    keywords?: string
    keywords2?: string,
    status?: number,
    source?: number,
}

/**
 * 工单列表
 * @returns 
 */
export const getConciliationList = (data: IConciliationListSearchForm): Promise<CommonResponse<IConciliationList>> => {
    return axiosInstance.post('/organ/worksheet/my_list', data).then(res => res.data);
};


export interface IConciliationDetailData {
    id: number,
    receive_at: string,
    time_type: number,
    province_id: number,
    city_id: number,
    area_id: number,
    street_id: number,
    title: string,
    name: string,
    mobile: string,
    age: number,
    idcard: string,
    specific_case?: string,
    staff_attitude: string,
    company_attitude: string,
    trail_result: string,
    lawsuit_type?: number,
    lawsuit_money?: number,
    trail_at?: string,
    visit_appointment_at?: string,
    visit_practical_at?: string,
    peacemaker?: string,
    is_visit_conciliation?: boolean,
    conciliation_result?: string,
    time_type_text: string,
    province_text: string,
    city_text: string,
    area_text: string,
    street_text: string,
    lawsuit_type_text?: string,
    status?: string,
    status_text?: string,
    order_no: string,
    describe: string,
    attach_files: number[],
    attach_files_info: Attachment[],
    mediate_attach_files: number[],
    mediate_attach_files_info: Attachment[],
    question_type_text?: string,
}

export interface IConciliationDetail {
    detail: IConciliationDetailData
}

/**
 * 工单详情
 * @returns 
 */
export const getConciliationDetail = (data: { id: number }): Promise<CommonResponse<IConciliationDetail>> => {
    return axiosInstance.post('/organ/worksheet/my_detail', data).then(res => res.data);
};

export interface IEditConciliationDetailForm {
    id: number,
    title: string,
    name: string,
    lawsuit_type: number,
    status: string,

    age?: number,
    mobile?: string,
    idcard?: string,
    describe?: string,
    specific_case?: string,
    staff_attitude?: string,
    company_attitude?: string,
    trail_result?: string,
    company_business?: string,
    company_scale?: string,
    company_contact?: string,
    company_contact_position?: string,
    company_contact_mobile?: string,
    lawsuit_money?: number,
    trail_at?: string,
    visit_appointment_at?: string,
    visit_practical_at?: string,
    peacemaker?: string,
    is_visit_conciliation?: boolean,
    conciliation_result?: string,
    question_type?: number,
    mediate_attach_files: number[]
}


/**
 * 编辑工单详情
 * @param data 
 * @returns 
*/
export const editConciliationDetail = (data: IEditConciliationDetailForm): Promise<CommonResponse> => {
    return axiosInstance.post('/organ/worksheet/edit', data).then(res => res.data);
};

export interface IConciliationOrderTrailTemplateData {
    id: number,
    title: string,
    content: string
}

export interface IConciliationOrderTrailTemplate {
    list: IConciliationOrderTrailTemplateData[]
}

/**
 * 工单结果模板所有
 */
export const getConciliationOrderTrailTemplate = (data: {
    province_id: number,
    city_id: number,
    area_id: number,
    street_id: number
}): Promise<CommonResponse<IConciliationOrderTrailTemplate>> => {
    return axiosInstance.post('/organ/worksheet_trail_template/all', data).then(res => res.data);
};

/**
 * 获取工单
 * @param data 
 * @returns 
 */
export const getConciliationOrder = (): Promise<CommonResponse> => {
    return axiosInstance.post('/organ/worksheet/get_order').then(res => res.data);
};

export interface IMyConciliationStat {
    today: string,
    today_count: number,
    total_count: number
}

/**
 * 我的工单统计
 * @returns 
 */
export const getMyConciliationStat = (): Promise<CommonResponse<IMyConciliationStat>> => {
    return axiosInstance.post('/organ/worksheet/my_list_stat').then(res => res.data);
};


/**
 * 获取重复工单
 */
export const getConciliationRepeatOrder = (): Promise<CommonResponse> => {
    return axiosInstance.post('/organ/worksheet/get_repeat_order').then(res => res.data);
};

export interface IConciliationCommentLogsData {
    id: number,
    worksheet_id: number,
    organ_user_id: number,
    content: string,
    created_at: string,
    user_type: number,
    organ_user: {
        id: number,
        real_name: string
    }
}

export interface IConciliationCommentLogs {
    list: IConciliationCommentLogsData[]
}

/**
 * 回访记录
 * @returns 
 */
export const getConciliationCommentLogs = (data: { worksheet_id: number }): Promise<CommonResponse<IConciliationCommentLogs>> => {
    return axiosInstance.post('/organ/worksheet/return_visit', data).then(res => res.data);
};

export interface ICommitConciliationCommentForm {
    worksheet_id: number,
    content: string
}

/**
 * 添加回访记录
 * @returns 
 */
export const commitConciliationComment = (data: ICommitConciliationCommentForm): Promise<CommonResponse> => {
    return axiosInstance.post('/organ/worksheet/return_visit_add', data).then(res => res.data);
};

export interface ITransferConciliationForm {
    worksheet_ids: number[],
    organ_user_id: number,
    organ_assist_user_id: number
}

export const transferConciliation = (data: ITransferConciliationForm): Promise<CommonResponse> => {
    return axiosInstance.post('/organ/worksheet/transfer', data).then(res => res.data);
};