import { useEffect, useRef } from "react";
import { getAuthData, IAuthNode } from "../apis/common";

/// 获取用户权限
export const getHasAuth = async (fields: string[]): Promise<boolean[]> => {
    // DFS查找
    // 规定：子节点字段前缀是父节点字段，如：roles -> roles_add
    const search = (forest: IAuthNode[], finalNode: string): boolean => {
        let stack: IAuthNode[] = [];
        stack.push(...forest);

        while (stack.length > 0) {
            let item = stack.pop();
            if (item!.sign === finalNode) {
                // 找到返回
                return true;
            } else if (RegExp(`^${item!.sign}`).test(finalNode)) {
                // 优化：只有符合规定（含有前缀）的父节点的子节点入栈，减少搜索量
                stack = [...item!.children];
            }
        }

        // 无结果
        return false;
    };

    // 获取数据
    let res = await getAuthData();
    let auth = res.data.list;

    console.log('获取权限树：', res.data);
    // 获取结果
    let authRes = fields.map(field => search(auth.node, field));
    return authRes;
}

/// 获取用户权限（有未知bug，偶尔获取不到数据）
// export const useHasAuth = async (fields: string[]): Promise<boolean[]> => {
//     // effect调用引用
//     const fieldsRef = useRef(fields);

//     // DFS查找
//     // 规定：子节点字段前缀是父节点字段，如：roles -> roles_add
//     const search = (forest: IAuthNode[], finalNode: string): boolean => {
//         let stack: IAuthNode[] = [];
//         stack.push(...forest);

//         while (stack.length > 0) {
//             let item = stack.pop();
//             if (item!.sign === finalNode) {
//                 // 找到返回
//                 return true;
//             } else if (RegExp(`^${item!.sign}`).test(finalNode)) {
//                 // 优化：只有符合规定（含有前缀）的父节点的子节点入栈，减少搜索量
//                 stack = [...item!.children];
//             }
//         }

//         // 无结果
//         return false;
//     };

//     // promise订阅
//     const completeRef = useRef<(value: boolean[] | PromiseLike<boolean[]>) => void>();
//     let completer = new Promise<boolean[]>((resolve) => {
//         completeRef.current = resolve;
//     })

//     useEffect(() => {
//         // 获取数据
//         getAuthData().then(res => {
//             let auth = res.data.list;

//             console.log('获取权限树：', res.data);
//             // 获取结果
//             let authRes = fieldsRef.current.map(field => search(auth.node, field));
//             // 订阅通知完成
//             // completeRef.current在Promise实例化时已经同步赋值，这里可以放心直接使用
//             completeRef.current!(authRes);
//         });
//     }, []);

//     // 订阅promise结果
//     return await completer;
// }

/// 权限字典
export const globalAuthSigns = {
    role: 'role', // 角色管理
    role_add: 'role_add', // 角色管理添加
    role_edit: 'role_edit', // 角色管理编辑
    role_del: 'role_del', // 角色管理删除

    user: 'user', // 用户管理
    user_add: 'user_add', // 用户添加
    user_edit: 'user_edit', // 用户编辑
    user_del: 'user_del', // 用户删除

    worksheet: 'worksheet', // 业务管理
    worksheet_import: 'worksheet_import', // 批量导入
    worksheet_handle: 'worksheet_handle', // 操作管理
    worksheet_handle_transfer: 'worksheet_handle_transfer', //转移工单
    worksheet_handle_repeatOrder: 'worksheet_handle_repeatOrder', //获取重复工单
    worksheet_iocExport: 'worksheet_iocExport', // 导出IOC工单压缩包

    preWorksheet: 'preWorksheet', // 裁前管理
    preWorksheet_add: 'preWorksheet_add', // 登记
    preWorksheet_mediate: 'preWorksheet_mediate', // 调解
    preWorksheet_postpone: 'preWorksheet_postpone', // 延期
    preWorksheet_form: 'preWorksheet_form', // 填写表单
    preWorksheet_archived: 'preWorksheet_archived', // 归档
    preWorksheet_import: 'preWorksheet_import', // 导入
    preWorksheet_attachment: 'preWorksheet_attachment', // 补充附件
    preWorksheet_handle: 'preWorksheet_handle', // 操作管理
    preWorksheet_handle_exportZip: 'preWorksheet_handle_exportZip', // 导出
    preWorksheet_handle_transfer: 'preWorksheet_handle_transfer', // 转移工单
    preWorksheet_stat: 'preWorksheet_stat', // 统计管理
    preWorksheet_stat_organUser: 'preWorksheet_stat_organUser', // 调解员案件处理统计
    preWorksheet_stat_region: 'preWorksheet_stat_region', // 地区信息统计
    preWorksheet_handle_resetCaseNumber: 'preWorksheet_handle_resetCaseNumber', // 重置案号
    preWorksheet_stat_refuseAnalysis: 'preWorksheet_stat_refuseAnalysis', // 地区统计拒绝分析

    approval: 'approval', // 审批管理

}