import React, { useEffect, useState } from 'react';
import { useDebounce } from 'ahooks';
import { useNavigate } from 'react-router-dom';

// apis
import { getRolesList, IRolesListData, IRolesSearchForm, toDelRole } from '../../../apis/auth';

// antd
import { Space, Table, Dropdown, Menu, Button, Card, Input, Pagination, Empty, Grid, Modal, List, Tag, message } from 'antd';
import { CloseOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import RoleFormModal from './actions/roleForm';
import { getHasAuth, globalAuthSigns } from '../../../utils/auth';

const { Column } = Table;
const { confirm } = Modal;
const { useBreakpoint } = Grid;

function RoleList() {
    const screens = useBreakpoint();
    const navigate = useNavigate();

    // 权限检查
    // 组件全局权限引用
    const [hasRoleAddAuth, setHasRoleAddAuth] = useState(false);
    const [hasRoleEditAuth, setHasRoleEditAuth] = useState(false);
    const [hasRoleDelAuth, setHasRoleDelAuth] = useState(false);
    useEffect(() => {
        getHasAuth([
            globalAuthSigns.role_add,
            globalAuthSigns.role_edit,
            globalAuthSigns.role_del,
        ]).then(value => {
            setHasRoleAddAuth(value[0]);
            setHasRoleEditAuth(value[1]);
            setHasRoleDelAuth(value[2]);
        });
    }, []);

    // 搜索表单
    const [form, setForm] = useState<IRolesSearchForm>({
        page: 1,
        size: 10
    });

    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState<(IRolesListData)[]>([]);
    const [mListData, setMListData] = useState<(IRolesListData)[]>([]);

    const [total, setTotal] = useState(0);
    const loadData = async (newForm?: IRolesSearchForm) => {
        let tempForm = newForm ?? form;
        setLoading(true);
        try {
            let res = await getRolesList(tempForm);
            setTotal(res.data.total);
            setMListData(res.data.list);
            setListData((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== res.data.total) {
                    newListData = new Array(res.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((tempForm.page! - 1) * tempForm.size, res.data.list.length, ...res.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    // 分页
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        let newForm = {
            ...form,
            page,
            size: pageSize,
        };
        setForm(newForm);
        loadData(newForm);
    }

    const resetData = () => {
        let newForm = {
            page: 1,
            size: 20
        }
        setForm(newForm);
        loadData(newForm);
    }

    // 关键词搜索
    const keywordsDebouncedValue = useDebounce(form.keywords, { wait: 500 });
    useEffect(() => {
        loadData(form);
    }, [keywordsDebouncedValue]);
    const handleKeywordsChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        let newForm = {
            ...form,
            keywords: value.target.value
        };
        setForm(newForm);
    }

    const [toAddEditModal, setAddEditModal] = useState<{
        isModalVisible: boolean,
        data?: { id: number }
    }>();
    const onAddEditClose = () => {
        setAddEditModal({
            ...toAddEditModal!,
            isModalVisible: false
        })
    };
    const onAddEditFinish = () => {
        loadData();
        setAddEditModal({
            ...toAddEditModal!,
            isModalVisible: false
        })
    }

    // 添加
    const toAdd = () => {
        if (screens.xs) {
            navigate('/auth/roles/settings');
        } else {
            setAddEditModal({
                isModalVisible: true,
                data: undefined
            })
        }
    }

    // 编辑
    const toEdit = (id: number) => {
        if (screens.xs) {
            navigate('/auth/roles/settings?id=' + id);
        } else {
            setAddEditModal({
                isModalVisible: true,
                data: { id: id }
            })
        }
    }

    // 删除
    const toDel = (data: IRolesListData) => {
        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: `确定要删除角色 ${data.name} 吗？`,
            onOk: async () => {
                setLoading(true);
                try {
                    let res = await toDelRole({ id: data.id })
                    message.success(res.msg);
                    loadData();
                } catch (e) {
                    console.error(e);
                } finally {
                    setLoading(false);
                }
            },
        });
    }


    return (
        <div className='address inner-page'>
            <div className='search'>
                <Input
                    className="search__item"
                    value={form.keywords}
                    onChange={handleKeywordsChange}
                    placeholder="名称搜索"
                />

                <Button
                    className="search__button"
                    icon={<CloseOutlined />}
                    loading={loading}
                    onClick={() => resetData()}
                >
                    重置筛选
                </Button>

                {
                    hasRoleAddAuth &&
                    <Button
                        className={`search__last-button ${screens.xs ? 'xs' : ''}`}
                        icon={<PlusOutlined />}
                        loading={loading}
                        onClick={toAdd}
                    >
                        新增角色
                    </Button>
                }
            </div>
            {
                screens.xs
                    ? <div>
                        {
                            mListData.length > 0 ? mListData.map(item =>
                                <Dropdown
                                    trigger={['click']}
                                    menu={{
                                        items: [
                                            {
                                                key: 'edit',
                                                disabled: !hasRoleEditAuth,
                                                label: (
                                                    <div>编辑</div>
                                                ),
                                                onClick: () => toEdit(item.id),
                                            },
                                            {
                                                key: 'delete',
                                                disabled: !hasRoleDelAuth,
                                                label: (
                                                    <div>删除</div>
                                                ),
                                                onClick: () => toDel(item),
                                            },
                                        ]
                                    }}>
                                    <Card className='m-card'>
                                        <div className='m-card_item'>
                                            <div>角色名称：</div>
                                            <div>{item.name}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>数据范围：</div>
                                            <div>{item.region.map(item => <Tag color="blue">{item.province_text}-{item.city_text}-{item.area_text}-{item.street_text}</Tag>) ?? '无'}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>备注：</div>
                                            <div>{item.remark}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>更新时间：</div>
                                            <div>{item.updated_at}</div>
                                        </div>
                                    </Card>
                                </Dropdown>
                            )
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                        }

                        <Pagination
                            current={form.page}
                            onChange={onPageChange}
                            showTotal={total => `共 ${total} 项`}
                            total={total}
                            simple
                        />
                    </div>
                    : <Table
                        dataSource={listData}
                        size='small'
                        loading={loading}
                        scroll={{ x: 800 }}
                        pagination={{
                            position: ['bottomRight'],
                            size: "small",
                            total: total,
                            showTotal: total => `共 ${total} 项`,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            onChange: onPageChange
                        }}>
                        <Column title="角色名称" dataIndex="name" key="name" />
                        <Column title="数据范围" dataIndex="region" key="region"
                            render={(_: any, record: IRolesListData | null) => (
                                record?.region.map(item => <Tag color="blue">{item.province_text}-{item.city_text}-{item.area_text}-{item.street_text}</Tag>) ?? '无'
                            )}
                        />
                        <Column title="备注" dataIndex="remark" key="remark" />
                        <Column title="更新时间" dataIndex="updated_at" key="updated_at" />
                        <Column
                            title="操作"
                            key="action"
                            fixed="right"
                            render={(_: any, record: IRolesListData | null) => (
                                <Space>
                                    <Dropdown.Button
                                        trigger={['click', 'hover']}
                                        size='small'
                                        type="primary"
                                        loading={loading}
                                        overlay={
                                            <Menu
                                                items={[
                                                    {
                                                        key: 'edit',
                                                        disabled: !hasRoleEditAuth,
                                                        label: (
                                                            <div>编辑</div>
                                                        ),
                                                        onClick: () => toEdit(record!.id),
                                                    },
                                                    {
                                                        key: 'delete',
                                                        disabled: !hasRoleDelAuth,
                                                        label: (
                                                            <div>删除</div>
                                                        ),
                                                        onClick: () => toDel(record!),
                                                    },
                                                ]}
                                            />
                                        }>
                                        操作
                                    </Dropdown.Button>
                                </Space>
                            )}
                        />
                    </Table>
            }

            {/* 添加编辑弹窗 */}
            {
                toAddEditModal ? <Modal
                    maskClosable={false}
                    visible={toAddEditModal.isModalVisible}
                    title={`角色设置`}
                    onCancel={onAddEditClose}
                    width={1000}
                    footer={null}
                >
                    <RoleFormModal data={toAddEditModal.data} onFinish={onAddEditFinish}></RoleFormModal>
                </Modal> : null
            }
        </div>
    );
}

export default RoleList;