import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';


// apis
import { getApprovalList, IApprovalListData, IApprovalListSearchForm } from '../../apis/approval';
import { IAttachFilesDataInfo, INaturalPeople, IPreArbitrationCompany } from '../../apis/preArbitration';
import { getDictList, IDictData } from '../../apis/common';

// antd
import { Table, Image, Menu, Select, Button, Modal, message, Card, Upload, UploadFile, Input, Form, Pagination, Empty, Grid, Cascader, Divider } from 'antd';
import { CloseOutlined, DownloadOutlined } from '@ant-design/icons';
import DatePicker from '../../components/customAntd/DatePicker';
import { List, Popup } from 'antd-mobile';


const { Column } = Table;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { confirm } = Modal;
const { useBreakpoint } = Grid;

function ApprovalListPage() {
    const screens = useBreakpoint();
    const navigate = useNavigate();

    // 搜索表单
    const [form, setForm] = useState<IApprovalListSearchForm>({
        page: 1,
        size: 10,
    });

    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState<(IApprovalListData)[]>([]);
    const [mListData, setMListData] = useState<(IApprovalListData)[]>([]);

    // 初始化
    useEffect(() => {
        loadData();
        getDictList(['order_approval_status']).then(res => {
            setStatus(res.data.order_approval_status);
        });
    }, []);


    const [total, setTotal] = useState(0);
    const loadData = async (newForm?: IApprovalListSearchForm) => {
        let tempForm = newForm ?? form;
        setLoading(true);
        try {
            let res = await getApprovalList(tempForm);
            setTotal(res.data.total);
            setMListData(res.data.list);
            setListData((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== res.data.total) {
                    newListData = new Array(res.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((tempForm.page! - 1) * tempForm.size, res.data.list.length, ...res.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    // 分页
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        let newForm = {
            ...form,
            page,
            size: pageSize,
        };
        setForm(newForm);
        loadData(newForm);
    }

    const resetData = () => {
        let newForm = {
            page: 1,
            size: 20
        }
        setForm(newForm);
        loadData(newForm);
    }


    // 状态搜索
    const [status, setStatus] = useState<IDictData>();
    const handleStatusChange = (value: number[]) => {
        console.log(`selected ${value}`);
        setForm((form) => {
            let newForm = {
                ...form,
                status: value,
            };
            loadData(newForm);
            return newForm;
        });
    };

    // 添加第三方弹窗
    const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
    const [attachmentModalData, setAttachmentModalData] = useState<IAttachFilesDataInfo[]>();
    const showAttachmentModalModal = (data: IAttachFilesDataInfo[]) => {
        setAttachmentModalData(data);
        setAttachmentModalOpen(true);
    };
    const hideAttachmentModalModal = () => {
        setAttachmentModalOpen(false);
    };

    const attachmentComponent = useCallback(
        () => (
            attachmentModalData?.map(item => (
                <List header={item.name}>
                    {
                        item.files_info.map(e => (
                            <List.Item extra={
                                <Button type="text" icon={<DownloadOutlined />} onClick={() => window.open(e.attach_url_link, '_blank')} />
                            }>{e.attach_name}</List.Item>
                        ))
                    }
                </List>
            ))
        ),
        [attachmentModalData],
    )

    const navToConciliation = (id: number) => {
        if (screens.xs) {
            navigate(`/preArbitration/conciliation?id=${id}`);
        } else {
            navigate(`/preArbitration?conciliation=${id}`);
        }
    }

    return (
        <div className='address inner-page'>
            <div className='search'>
                <Select
                    mode='multiple'
                    allowClear
                    className="search__item"
                    showArrow
                    placeholder="审核状态"
                    value={form.status}
                    onChange={handleStatusChange}
                >
                    {status?.options.map(option => (
                        <Option key={option.value} value={option.value}>{option.name}</Option>
                    ))}
                </Select>

                <Button
                    className="search__button"
                    icon={<CloseOutlined />}
                    loading={loading}
                    onClick={() => resetData()}
                >
                    重置筛选
                </Button>
            </div>
            {
                screens.xs
                    ? <div>
                        {
                            mListData.length > 0 ? mListData.map(item =>
                                <Card className='m-card'>
                                    <div className='m-card_item'>
                                        <div>问题类型：</div>
                                        <div>{item.pre_worksheet?.question_type_text}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>案号：</div>
                                        <div>{item.pre_worksheet?.case_number}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>案由：</div>
                                        <div>{item.pre_worksheet?.case_cause}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>申请人：</div>
                                        <div>{(item.pre_worksheet?.plaintiff_default as INaturalPeople)?.real_name ?? (item.pre_worksheet?.plaintiff_default as IPreArbitrationCompany)?.company_name}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>被申请人：</div>
                                        <div>{(item.pre_worksheet?.defendant_default as INaturalPeople)?.real_name ?? (item.pre_worksheet?.defendant_default as IPreArbitrationCompany)?.company_name}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>第三人：</div>
                                        <div>{
                                            item?.pre_worksheet?.third_party?.map(item => {
                                                switch (item.subject_type) {
                                                    case 1:
                                                        return item.natural_people.real_name

                                                    case 2:
                                                        return item.company.company_name
                                                }
                                            }).join('，')
                                        }</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>案件类别：</div>
                                        <div>{item.pre_worksheet?.case_category_text}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>处理结果：</div>
                                        <div>{item.status_text}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>附件材料：</div>
                                        <div>{
                                            item.pre_worksheet?.mediate_attach_files_data_info && item.pre_worksheet?.mediate_attach_files_data_info.length > 0
                                                ? <div className='clickable-text' onClick={() => showAttachmentModalModal(item.pre_worksheet!.mediate_attach_files_data_info)}>查看附件</div>
                                                : '无'
                                        }</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>登记时间：</div>
                                        <div>{item.pre_worksheet?.created_at}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>结案时间：</div>
                                        <div>{item.pre_worksheet?.closing_date}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>拒绝理由：</div>
                                        <div>{item.refuse_reason}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>提交时间：</div>
                                        <div>{item.created_at}</div>
                                    </div>
                                </Card>
                            )
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                        }

                        <Pagination
                            current={form.page}
                            onChange={onPageChange}
                            showTotal={total => `共 ${total} 项`}
                            total={total}
                            simple
                        />
                    </div>
                    : <Table
                        dataSource={listData}
                        size='small'
                        loading={loading}
                        scroll={{ x: 800 }}
                        rowKey={(record) => record?.id}
                        pagination={{
                            position: ['bottomRight'],
                            size: "small",
                            total: total,
                            showTotal: total => `共 ${total} 项`,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            onChange: onPageChange
                        }}>
                        <Column title="问题类型" dataIndex={['pre_worksheet', 'question_type_text']} key="question_type_text" />
                        <Column title="案号" dataIndex={['pre_worksheet', "case_number"]} key="case_number" />
                        <Column title="案由" dataIndex={['pre_worksheet', 'case_cause']} key="case_cause" />
                        <Column title="申请人" dataIndex={['pre_worksheet', 'plaintiff_default']} key="plaintiff_default"
                            render={
                                (_: any, record: IApprovalListData | null) => (
                                    record && <div>{(record.pre_worksheet?.plaintiff_default as INaturalPeople)?.real_name ?? (record.pre_worksheet?.plaintiff_default as IPreArbitrationCompany)?.company_name}</div>
                                )
                            }
                        />
                        <Column title="被申请人" dataIndex={['pre_worksheet', 'defendant_default']} key="defendant_default"
                            render={
                                (_: any, record: IApprovalListData | null) => (
                                    record && <div>{(record.pre_worksheet?.defendant_default as INaturalPeople)?.real_name ?? (record.pre_worksheet?.defendant_default as IPreArbitrationCompany)?.company_name}</div>
                                )
                            }
                        />
                        <Column title="第三人" dataIndex={['pre_worksheet', 'third_party']} key="third_party" render={
                            (_: any, record: IApprovalListData | null) =>
                                record?.pre_worksheet?.third_party?.map(item => {
                                    switch (item.subject_type) {
                                        case 1:
                                            return item.natural_people.real_name

                                        case 2:
                                            return item.company.company_name
                                    }
                                }).join('，')
                        } />
                        <Column title="案件类别" dataIndex={['pre_worksheet', 'case_category_text']} key="case_category_text" />
                        <Column title="处理结果" dataIndex="status_text" key="status_text" />
                        <Column title="附件材料" dataIndex={['pre_worksheet', 'mediate_attach_files_data_info']} key="attach_files_data"
                            render={(value?: IAttachFilesDataInfo[]) => (
                                value && value.length > 0
                                    ? <div className='clickable-text' onClick={() => showAttachmentModalModal(value)}>查看附件</div>
                                    : '无'
                            )}
                        />
                        <Column title="登记时间" dataIndex={['pre_worksheet', 'created_at']} key="pre_created_at" />
                        <Column title="结案时间" dataIndex={['pre_worksheet', 'closing_date']} key="closing_date" />
                        <Column title="拒绝理由" dataIndex="refuse_reason" key="refuse_reason"
                            render={(value: string, record: IApprovalListData | null) => {
                                return record && value
                                    ? <div className='clickable-text' onClick={() => navToConciliation(record.pre_worksheet_id)}>{value}</div>
                                    : ''
                            }}
                        />
                        <Column title="提交时间" dataIndex="created_at" key="created_at" />
                    </Table>
            }

            {
                screens.xs
                    ? <Popup
                        visible={attachmentModalOpen}
                        showCloseButton
                        position='right'
                        onClose={hideAttachmentModalModal}
                        bodyStyle={{ overflowY: 'auto' }}
                    >
                        <div style={{ margin: '40px 20px' }}>
                            {attachmentComponent()}
                        </div>
                    </Popup>
                    : <Modal
                        visible={attachmentModalOpen}
                        title={`附件`}
                        maskClosable={false}
                        onCancel={hideAttachmentModalModal}
                        footer={null}
                    >
                        {attachmentComponent()}
                    </Modal>
            }
        </div>
    );
}

export default ApprovalListPage;