import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDebounce } from 'ahooks';

// apis
import { getDictList, IDictData } from '../../apis/common';

// interface
import { getHasAuth, globalAuthSigns } from '../../utils/auth';

// antd
import { Space, Table, Dropdown, Menu, Select, Button, Modal, Card, Input, Pagination, Empty, Grid, Alert, Form, message } from 'antd';
import { CloseOutlined, FileAddOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import DatePicker from '../../components/customAntd/DatePicker';

import ConciliationDetailModal from './actions/conciliationDetail';
import { getConciliationList, getConciliationOrder, getConciliationRepeatOrder, getMyConciliationStat, IConciliationListData, IConciliationListSearchForm, IMyConciliationStat, ITransferConciliationForm, transferConciliation } from '../../apis/conciliation';
import ConciliationDetailFormModal from './actions/conciliationForm';
import { getAllUser, IAllUserData } from '../../apis/user';

const { Column } = Table;
const { Option } = Select;
const { useBreakpoint } = Grid;
const { confirm } = Modal;

function ConciliationList() {
    const screens = useBreakpoint();
    const navigate = useNavigate();

    // 权限检查
    // 组件全局权限引用
    const [hasWorksheetHandleRepeatOrderAuth, setHasWorksheetHandleRepeatOrderAuth] = useState(false);
    const [hasWorksheetTransferAuth, setHasWorksheetTransferAuth] = useState(false);
    useEffect(() => {
        getHasAuth([
            globalAuthSigns.worksheet_handle_repeatOrder,
            globalAuthSigns.worksheet_handle_transfer,
        ]).then(value => {
            setHasWorksheetHandleRepeatOrderAuth(value[0]);
            setHasWorksheetTransferAuth(value[1]);
        })
    }, []);

    // 搜索表单
    const [form, setForm] = useState<IConciliationListSearchForm>({
        nav_type: 1,
        page: 1,
        size: 10
    });

    // 统计数据
    const [stat, setStat] = useState<IMyConciliationStat>();

    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState<(IConciliationListData)[]>([]);
    const [mListData, setMListData] = useState<(IConciliationListData)[]>([]);

    const [total, setTotal] = useState(0);
    const loadData = async (newForm?: IConciliationListSearchForm) => {
        let tempForm = newForm ?? form;
        setLoading(true);
        try {
            let res = await getConciliationList(tempForm);
            setTotal(res.data.total);
            setMListData(res.data.list);
            setListData((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== res.data.total) {
                    newListData = new Array(res.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((tempForm.page! - 1) * tempForm.size, res.data.list.length, ...res.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }

        getMyConciliationStat().then(res => {
            setStat(res.data);
        })
    }

    // 分页
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        let newForm = {
            ...form,
            page,
            size: pageSize,
        };
        setForm(newForm);
        loadData(newForm);
    }

    const resetData = () => {
        let newForm = {
            nav_type: 1,
            page: 1,
            size: 20
        }
        setForm(newForm);
        loadData(newForm);
    }

    // 工单类型
    const handleNavTypeChange = (value: number) => {
        console.log(`selected ${value}`);
        setForm((form) => {
            let newForm = {
                ...form,
                nav_type: value,
            };
            loadData(newForm);
            return newForm;
        });
    };

    // 来源
    const [source, setSource] = useState<IDictData>();
    const handleSourceChange = (value: number) => {
        console.log(`selected ${value}`);
        setForm((form) => {
            let newForm = {
                ...form,
                source: value,
            };
            loadData(newForm);
            return newForm;
        });
    };


    // 状态搜索
    const [status, setStatus] = useState<IDictData>();
    const handleStatusChange = (value: number) => {
        console.log(`selected ${value}`);
        setForm((form) => {
            let newForm = {
                ...form,
                source: value,
            };
            loadData(newForm);
            return newForm;
        });
    };

    useEffect(() => {
        getDictList(['worksheet_status', 'worksheet_source']).then(res => {
            setStatus(res.data.worksheet_status);
            setSource(res.data.worksheet_source);
        });
    }, []);

    // 时间搜索
    const handleReceiveAtChange = (value: dayjs.Dayjs | null, dateString: string) => {
        console.log(`date: ${value}`);
        setForm((form) => {
            let newForm = {
                ...form,
                receive_at: value!.format('YYYY-MM-DD'),
            };
            loadData(newForm);
            return newForm;
        });
    };

    // 关键词搜索
    const keywordsDebouncedValue = useDebounce(form.keywords, { wait: 500 });
    useEffect(() => {
        loadData(form);
    }, [keywordsDebouncedValue]);
    const handleKeywordsChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        let newForm = {
            ...form,
            keywords: value.target.value
        };
        setForm(newForm);
    }

    // 关键词搜索
    const keywords2DebouncedValue = useDebounce(form.keywords2, { wait: 500 });
    useEffect(() => {
        loadData(form);
    }, [keywords2DebouncedValue]);
    const handleKeywords2Change = (value: React.ChangeEvent<HTMLInputElement>) => {
        let newForm = {
            ...form,
            keywords2: value.target.value
        };
        setForm(newForm);
    }

    // 详情弹窗
    const [toDetailModal, setDetailModal] = useState<{
        isModalVisible: boolean,
        data: { id: number }
    }>();
    const toDetail = (id: number) => {
        if (screens.xs) {
            navigate(`/conciliation/detail?id=${id}`);
        } else {
            setDetailModal({
                isModalVisible: true,
                data: { id: id }
            })
        }
    };
    const onDetailClose = () => {
        setDetailModal({
            ...toDetailModal!,
            isModalVisible: false
        })
    };

    // 调解弹窗
    const [toConciliationModal, setConciliationModal] = useState<{
        isModalVisible: boolean,
        data: { id: number }
    }>();
    const toConciliation = (id: number) => {
        if (screens.xs) {
            navigate(`/conciliation/do?id=${id}`);
        } else {
            setConciliationModal({
                isModalVisible: true,
                data: { id: id }
            })
        }
    };
    const onConciliationClose = () => {
        setConciliationModal({
            ...toConciliationModal!,
            isModalVisible: false
        })
    };
    const onConciliationFinish = () => {
        loadData();
        setConciliationModal({
            ...toConciliationModal!,
            isModalVisible: false
        })
    }

    /**
     * 获取工单
     */
    const getOrder = async () => {
        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: `确定要获取新的工单吗？`,
            onOk: async () => {
                setLoading(true);
                try {
                    await getConciliationOrder();
                    loadData();
                } catch (e) {
                    console.error(e);
                } finally {
                    setLoading(false);
                }
            },
        });
    }

    /**
     * 获取重复工单
     */
    const getRepeat = async () => {
        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: `确定要获取重复工单吗？`,
            onOk: async () => {
                setLoading(true);
                try {
                    await getConciliationRepeatOrder();
                    loadData();
                } catch (e) {
                    console.error(e);
                } finally {
                    setLoading(false);
                }
            },
        });
    }

    const spanStyle = {
        'color': 'red'
    }

    // 用户列表
    const [userList, setUserList] = useState<IAllUserData[]>([]);
    useEffect(() => {
        if (hasWorksheetTransferAuth) {
            getAllUser().then(res => {
                setUserList(res.data.list)
            })
        }
    }, [hasWorksheetTransferAuth]);

    // 转移工单
    const [transferForm] = Form.useForm<ITransferConciliationForm>();
    const [toTransferModal, setToTransferModal] = useState(false);
    const onTransferClose = () => {
        setToTransferModal(false);
    };
    const onTransferSubmit = async () => {
        await transferForm.validateFields();

        setLoading(true);
        try {
            let _form = transferForm.getFieldsValue(true);
            let res = await transferConciliation(_form);
            onTransferClose();
            message.success(res.msg);
            loadData();
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }
    const toTransfer = (ids: number[]) => {
        if (ids.length === 0) {
            message.warning('请至少选择一项');
        }
        transferForm.resetFields();
        transferForm.setFieldsValue({
            worksheet_ids: ids
        })
        setToTransferModal(true);
    }

    return (
        <div className='address inner-page'>
            {
                stat && <Alert message={<div>{stat.today} 前 <span style={spanStyle}>{stat.today_count}</span> 单未解决，至今共处理 <span style={spanStyle}>{stat.total_count}</span> 个工单</div>} type="info" showIcon />
            }

            <div className='search'>
                <Select
                    allowClear={false}
                    className="search__item"
                    showArrow
                    placeholder="工单类型"
                    value={form.nav_type}
                    onChange={handleNavTypeChange}
                >
                    <Option key={1} value={1}>本人工单</Option>
                    <Option key={2} value={2}>协助工单</Option>
                </Select>
                <Select
                    allowClear
                    className="search__item"
                    showArrow
                    placeholder="信息来源"
                    value={form.source}
                    onChange={handleSourceChange}
                >
                    {source?.options.map(option => (
                        <Option key={option.value}>{option.name}</Option>
                    ))}
                </Select>

                <Select
                    allowClear
                    className="search__item"
                    showArrow
                    placeholder="工单状态"
                    value={form.status}
                    onChange={handleStatusChange}
                >
                    {status?.options.map(option => (
                        <Option key={option.value} value={option.value}>{option.name}</Option>
                    ))}
                </Select>
                <DatePicker
                    allowClear={false}
                    className="search__item"
                    value={form.receive_at ? dayjs(form.receive_at) : null}
                    onChange={handleReceiveAtChange} />
                <Input
                    className="search__item"
                    value={form.keywords}
                    onChange={handleKeywordsChange}
                    placeholder="姓名、电话号码搜索"
                />
                <Input
                    className="search__item"
                    value={form.keywords2}
                    onChange={handleKeywords2Change}
                    placeholder="工单描述、企业态度搜索"
                />

                <Button
                    className="search__button"
                    icon={<CloseOutlined />}
                    loading={loading}
                    onClick={() => resetData()}
                >
                    重置筛选
                </Button>

                {
                    hasWorksheetHandleRepeatOrderAuth &&
                    <Dropdown.Button
                        className={`search__last-button ${screens.xs ? 'xs' : ''}`}
                        style={{ marginRight: screens.xs ? 15 : 0 }}
                        type="primary"
                        loading={false}
                        trigger={['click', 'hover']}
                        overlay={
                            <Menu
                                items={[
                                    {
                                        key: 'order',
                                        label: (
                                            <div>获取重复工单</div>
                                        ),
                                        onClick: () => getRepeat()
                                    },
                                ]}
                            />
                        }>
                        更多操作
                    </Dropdown.Button>
                }

                <Button
                    className={`search__last-button ${screens.xs ? 'xs' : ''}`}
                    loading={loading}
                    type="primary"
                    onClick={getOrder}
                    icon={<FileAddOutlined />}
                >
                    获取工单
                </Button>
            </div>
            {
                screens.xs
                    ? <div>
                        {
                            mListData.length > 0 ? mListData.map(item =>
                                <Dropdown
                                    trigger={['click']}
                                    menu={{
                                        items: [
                                            {
                                                key: 'detail',
                                                label: (
                                                    <div>查看详情</div>
                                                ),
                                                onClick: () => toDetail(item.id),
                                            },
                                            {
                                                key: 'conciliation',
                                                label: (
                                                    <div>调解</div>
                                                ),
                                                onClick: () => toConciliation(item.id),
                                            },
                                            {
                                                key: 'transfer',
                                                disabled: !hasWorksheetTransferAuth,
                                                label: (
                                                    <div>转移</div>
                                                ),
                                                onClick: () => toTransfer([item.id]),
                                            },
                                        ]
                                    }}>
                                    <Card className='m-card'>
                                        <div className='m-card_item'>
                                            <div>时间：</div>
                                            <div>{`${item.receive_at} ${item.time_type_text}`}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>工单号：</div>
                                            <div>{item.order_no}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>省-市-区：</div>
                                            <div>{`${item.province_text}-${item.city_text}-${item.area_text}`}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>街道：</div>
                                            <div>{item.street_text}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>投诉：</div>
                                            <div>{item.name}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>调解状态：</div>
                                            <div>{item.status_text}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>导入时间：</div>
                                            <div>{item.created_at}</div>
                                        </div>
                                    </Card>
                                </Dropdown>
                            )
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                        }

                        <Pagination
                            current={form.page}
                            onChange={onPageChange}
                            showTotal={total => `共 ${total} 项`}
                            total={total}
                            simple
                        />
                    </div>
                    : <Table
                        dataSource={listData}
                        size='small'
                        loading={loading}
                        scroll={{ x: 800 }}
                        pagination={{
                            position: ['bottomRight'],
                            size: "small",
                            total: total,
                            showTotal: total => `共 ${total} 项`,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            onChange: onPageChange
                        }}>
                        <Column
                            title="时间"
                            dataIndex="receive_at"
                            key="receive_at"
                            render={(_: any, record: IConciliationListData | null) => (
                                record ? `${record.receive_at} ${record.time_type_text}` : ''
                            )}
                        />
                        <Column title="工单号" dataIndex="order_no" key="order_no" />
                        <Column
                            title="省-市-区"
                            dataIndex="province_text"
                            key="province_text"
                            render={(_: any, record: IConciliationListData | null) => (
                                record ? `${record.province_text}-${record.city_text}-${record.area_text}` : ''
                            )}
                        />
                        <Column title="街道" dataIndex="street_text" key="street_text" />
                        <Column title="投诉" dataIndex="name" key="name" />
                        <Column title="调解状态" dataIndex="status_text" key="status_text" />
                        <Column title="导入时间" dataIndex="created_at" key="created_at" />
                        <Column
                            title="操作"
                            key="action"
                            fixed="right"
                            render={(_: any, record: IConciliationListData | null) => (
                                <Space>
                                    <Dropdown.Button
                                        trigger={['click', 'hover']}
                                        size='small'
                                        type="primary"
                                        loading={loading}
                                        overlay={
                                            <Menu
                                                items={[
                                                    {
                                                        key: 'detail',
                                                        label: (
                                                            <div>查看详情</div>
                                                        ),
                                                        onClick: () => toDetail(record!.id),
                                                    },
                                                    {
                                                        key: 'conciliation',
                                                        label: (
                                                            <div>调解</div>
                                                        ),
                                                        onClick: () => toConciliation(record!.id),
                                                    },
                                                    {
                                                        key: 'transfer',
                                                        disabled: !hasWorksheetTransferAuth,
                                                        label: (
                                                            <div>转移</div>
                                                        ),
                                                        onClick: () => toTransfer([record!.id]),
                                                    },
                                                ]}
                                            />
                                        }>
                                        操作
                                    </Dropdown.Button>
                                </Space>
                            )}
                        />
                    </Table>
            }

            {/* 详情弹窗 */}
            {
                toDetailModal ? <Modal
                    visible={toDetailModal.isModalVisible}
                    title={`工单详情`}
                    onCancel={onDetailClose}
                    width={1000}
                    footer={[
                        <Button key="back" onClick={onDetailClose}>
                            关闭
                        </Button>
                    ]}
                >
                    <ConciliationDetailModal data={toDetailModal.data}></ConciliationDetailModal>
                </Modal> : null
            }

            {/* 工单调解 */}
            {
                toConciliationModal ? <Modal
                    visible={toConciliationModal.isModalVisible}
                    title={`工单调解`}
                    onCancel={onConciliationClose}
                    width={'95%'}
                    footer={null}
                >
                    <ConciliationDetailFormModal data={toConciliationModal.data} onFinish={onConciliationFinish}></ConciliationDetailFormModal>
                </Modal> : null
            }


            {/* 转移工单 */}
            <Modal
                title="转移工单"
                open={toTransferModal}
                onOk={onTransferSubmit}
                onCancel={onTransferClose}
                confirmLoading={loading}
            >
                <Form form={transferForm} layout="vertical">
                    <Form.Item
                        label="选择处理人"
                        name="organ_user_id"
                        rules={[{ required: true, message: '请选择处理人' }]}
                    >
                        <Select showArrow>
                            {userList?.map(option => (
                                <Option key={option.id} value={option.id}>[{option.username}]{option.real_name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="选择协助人"
                        name="organ_assist_user_id"
                    >
                        <Select showArrow>
                            {userList?.map(option => (
                                <Option key={option.id} value={option.id}>[{option.username}]{option.real_name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default ConciliationList;