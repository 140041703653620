import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// antd
import { Grid, Button, Col, Form, Input, message, Row, Select, Upload, Modal, InputNumber, Divider, Skeleton, List, Card, Carousel, Image, Radio } from 'antd';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { UploadRequestOption } from "rc-upload/lib/interface";
import { DownloadOutlined, CommentOutlined, UploadOutlined, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';

import { FileUpload, getDictList, IDictData, uploadFile } from '../../../apis/common';
import { Attachment } from '../../../apis/attachment';
import TextArea from 'antd/lib/input/TextArea';
import DatePicker from '../../../components/customAntd/DatePicker';
import { editConciliationDetail, getConciliationDetail, getConciliationOrderTrailTemplate, IConciliationDetailData, IConciliationOrderTrailTemplateData, IEditConciliationDetailForm } from '../../../apis/conciliation';
import dayjs from 'dayjs';
import ConciliationCommentLogsModal from './conciliationCommentLogs';

import cantPreviewImage from '@assets/images/common/cant_preview.png';
import noAttachmentImage from '@assets/images/common/no_attachment.png';

import './conciliationForm.scss';

const { Option } = Select;
const { useBreakpoint } = Grid;


interface CollectionCreateFormProps {
    data?: { id: number },
    onFinish?: () => void;
}

let tempFileList: UploadFile<any>[] = [];


function ConciliationDetailFormModal({
    data,
    onFinish,
}: CollectionCreateFormProps) {
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 表单数据
    const [form] = Form.useForm<IEditConciliationDetailForm>();

    const [detail, setDetail] = useState<IConciliationDetailData>();

    const [scale, setScale] = useState<IDictData>();
    const [business, setBusiness] = useState<IDictData>();
    const [lawsuitType, setLawsuitType] = useState<IDictData>();
    const [status, setStatus] = useState<IDictData>();
    const [questionType, setQuestionType] = useState<IDictData>();

    // 初始化加载
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        form.resetFields();
        getDictList(['scale', 'business', 'worksheet_lawsuit_type', 'worksheet_status']).then(res => {
            setScale(res.data.scale);
            setBusiness(res.data.business);
            setLawsuitType(res.data.worksheet_lawsuit_type);
            setStatus(res.data.worksheet_status);
        });

        getDictList(['worksheet_question_type']).then(res => {
            setQuestionType(res.data.worksheet_question_type);
        });

        setLoading(true);
        getConciliationDetail({ id: data?.id ?? Number(searchParams.get('id')) }).then(res => {
            let data = res.data.detail;
            setDetail(data);
            form.setFieldsValue({
                ...data,
                trail_at: data.trail_at ? dayjs(data.trail_at) as any : undefined,
                visit_appointment_at: data.visit_appointment_at ? dayjs(data.visit_appointment_at) as any : undefined,
                visit_practical_at: data.visit_practical_at ? dayjs(data.visit_practical_at) as any : undefined,
            });
            form.setFieldValue('receive_at', data.receive_at ? dayjs(data.receive_at) as any : undefined);

            // 附件
            if (data.mediate_attach_files_info) {
                setAttachments(data.mediate_attach_files_info.map(attachment => {
                    return {
                        uid: attachment.id.toString(),
                        name: attachment.attach_name ?? '未知文件',
                        response: attachment,
                        status: 'done'
                    }
                }));
            }

            setLoading(false);
        })
    }, [data, searchParams]);

    // 时间段类型
    const [timeType, setTimeType] = useState<IDictData>();
    useEffect(() => {
        getDictList(['global_time_type']).then(res => {
            setTimeType(res.data.global_time_type);
        });
    }, []);

    // 跟进模板
    const [template, setTemplate] = useState<IConciliationOrderTrailTemplateData[]>([]);
    useEffect(() => {
        if (detail) {
            getConciliationOrderTrailTemplate({
                province_id: detail.province_id,
                city_id: detail.city_id,
                area_id: detail.area_id,
                street_id: detail.street_id,
            }).then(res => {
                setTemplate(res.data.list);
            })
        }
    }, [detail]);

    // 上传统一方法
    const checkFileSize = (file: RcFile) => {
        if ((file.size / 1024 / 1024) > 50) {
            message.error(`${file.name} 文件超出限制大小`);
            return Upload.LIST_IGNORE;
        }

        return true;
    };
    const customRequest = (options: UploadRequestOption<any>) => {
        const { onSuccess, onError, file, onProgress } = options;
        uploadFile({ file: file as Blob, type: 'default' })
            .then(res => {
                onSuccess!(res.data);
            });
    }
    // 上传附件
    const [attachments, setAttachments] = useState<UploadFile<any>[]>([]);
    const onFileChange = (info: UploadChangeParam<UploadFile<any>>) => {
        console.log(info.fileList, attachments);
        if (info.file.status === 'done') {
            tempFileList = [info.file, ...tempFileList.filter((item) => item.uid !== info.file.uid)];
        } else {
            tempFileList = info.fileList;
        }
        setAttachments(tempFileList);
    }
    const onAttachmentDownload = (file: UploadFile<any>) => {
        console.log(file);
        let res: FileUpload | Attachment = file.response;
        window.open((res as FileUpload).full_link || (res as Attachment).attach_url_link, "_blank");
    }

    // 提交
    const submit = async () => {
        await form.validateFields();

        setConfirmLoading(true);
        let _form = form.getFieldsValue(true);
        try {
            let data = {
                ..._form,
                mediate_attach_files: attachments.length > 0 ? attachments
                    .filter(file => file.response)
                    .map(file => {
                        let res: FileUpload | Attachment = file.response;
                        return (res as FileUpload).file_id || (res as Attachment).id;
                    }) : undefined,
            }
            if (_form.receive_at) data.receive_at = (_form.receive_at as dayjs.Dayjs).format('YYYY-MM-DD');
            if (_form.trail_at) data.trail_at = (_form.trail_at as dayjs.Dayjs).format('YYYY-MM-DD');
            if (_form.visit_appointment_at) data.visit_appointment_at = (_form.visit_appointment_at as dayjs.Dayjs).format('YYYY-MM-DD');
            if (_form.visit_practical_at) data.visit_practical_at = (_form.visit_practical_at as dayjs.Dayjs).format('YYYY-MM-DD');

            let res = await editConciliationDetail(data);
            message.success(res.msg);
            if (onFinish) {
                onFinish();
            } else {
                navigate('/conciliation');
            }
        } catch (e) {
            console.error(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    // 回访记录
    const [toDetailModal, setDetailModal] = useState<{
        isModalVisible: boolean,
        data: { id: number }
    }>();
    const toDetail = () => {
        // if (screens.xs) {
        //     navigate(`/conciliation/detail?id=${id}`);
        // } else {
        setDetailModal({
            isModalVisible: true,
            data: { id: data?.id ?? Number(searchParams.get('id')) }
        })
        // }
    };
    const onDetailClose = () => {
        setDetailModal({
            ...toDetailModal!,
            isModalVisible: false
        })
    };

    // 附件相册
    const [attachmentIndex, setAttachmentIndex] = useState(0);
    const switchAttachmentIndex = (dis: number) => {
        if (!detail) return;

        let res = attachmentIndex + dis;
        if (res < 0) {
            res = detail.attach_files_info.length - 1;
        } else if (res >= detail.attach_files_info.length) {
            res = 0;
        }
        setAttachmentIndex(res);
    }

    const gutter = 16 + 8 * 2;

    const FormItem = (
        <Form
            form={form}
            layout="vertical"
            autoComplete="off"
        >
            <Form.Item
                label="回访记录"
            >
                <Button
                    block
                    type='primary'
                    size='large'
                    icon={<CommentOutlined />}
                    onClick={toDetail}
                >点击查看回访记录</Button>
            </Form.Item>
            <Row gutter={gutter}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="接单日期"
                        name="receive_at"
                    >
                        <DatePicker style={{ width: '100%' }} disabled />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="时间段"
                        name="time_type"
                    >
                        <Select showArrow disabled>
                            {timeType?.options.map(option => (
                                <Option key={option.value} value={option.value}>{option.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="所属地区"
                        shouldUpdate={(prevValues, curValues) => prevValues.province_text !== curValues.province_text}
                    >
                        {({ getFieldValue }) => {
                            let value = `${getFieldValue('province_text')}-${getFieldValue('city_text')}-${getFieldValue('area_text')}-${getFieldValue('street_text')}`;
                            return <Input value={value} disabled />;
                        }}
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={gutter}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="工单标题"
                        name="title"
                        rules={[{ required: true, message: '请填写工单标题' }]}
                    >
                        <Input maxLength={100} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="姓名"
                        name="name"
                        rules={[{ required: true, message: '请填写姓名' }]}
                    >
                        <Input maxLength={20} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="年龄"
                        name="age"
                    >
                        <InputNumber style={{ width: '100%' }} max={200} min={0} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="电话"
                        name="mobile"
                    >
                        <Input maxLength={20} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="身份证"
                        name="idcard"
                    >
                        <Input maxLength={18} />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item
                label="工单描述"
                name='describe'
            >
                <TextArea rows={3} />
            </Form.Item>

            <Form.Item
                label="附件"
            >
                {detail && detail.attach_files_info.length > 0
                    ? <List
                        className='order__attachment'
                        itemLayout="horizontal"
                        dataSource={detail.attach_files_info}
                        renderItem={item => (
                            <li>
                                <Card bodyStyle={{ padding: 10 }}>
                                    <div className='order__attachment__card'>
                                        <div>{item.attach_name}</div>
                                        <Button type="text" icon={<DownloadOutlined />} href={item.attach_url_link} target="_blank" />
                                    </div>
                                </Card>
                            </li>
                        )}
                    />
                    : '无'}
            </Form.Item>


            <Form.Item
                label="具体描述"
                name='specific_case'
            >
                <TextArea rows={3} />
            </Form.Item>

            <Form.Item
                label="员工态度"
                name='staff_attitude'
            >
                <TextArea rows={3} />
            </Form.Item>

            <Form.Item
                label="企业态度"
                name='company_attitude'
            >
                <TextArea rows={3} />
            </Form.Item>

            <Form.Item
                label="模板信息选择"
            >
                <Radio.Group defaultValue={''} onChange={(e) => {
                    form.setFieldValue('trail_result', e.target.value);
                }}>
                    <Radio value={''}>无</Radio>
                    {template?.map(option => (
                        <Radio value={option.content}>{option.title}</Radio>
                    ))}
                </Radio.Group>
            </Form.Item>

            <Form.Item
                label="跟进结果"
                name='trail_result'
            >
                <TextArea rows={3} />
            </Form.Item>

            <Divider></Divider>

            <Row gutter={gutter}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="所属行业"
                        name="company_business"
                    >
                        <Select showArrow>
                            {business?.options.map(option => (
                                <Option key={option.value}>{option.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="人员规模"
                        name="company_scale"
                    >
                        <Select showArrow>
                            {scale?.options.map(option => (
                                <Option key={option.value}>{option.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={gutter}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="企业联系人"
                        name="company_contact"
                    >
                        <Input maxLength={20} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="联系人岗位"
                        name="company_contact_position"
                    >
                        <Input maxLength={20} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="联系人电话"
                        name="company_contact_mobile"
                    >
                        <Input maxLength={20} />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={gutter}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="问题类别"
                        name="question_type"
                    >
                        <Select showArrow>
                            {questionType?.options.map(option => (
                                <Option key={option.value} value={option.value}>{option.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="员工诉求类别"
                        name="lawsuit_type"
                    >
                        <Select showArrow>
                            {lawsuitType?.options.map(option => (
                                <Option key={option.value}>{option.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="员工诉求金额"
                        name="lawsuit_money"
                    >
                        <InputNumber style={{ width: '100%' }} min={0} precision={3} />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={gutter}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="调解结果"
                        name="status"
                        rules={[{ required: true, message: '请填写调解结果' }]}
                    >
                        <Select showArrow>
                            {status?.options.map(option => (
                                <Option key={option.value} value={option.value}>{option.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="处理日期"
                        name="trail_at"
                    >
                        <DatePicker
                            style={{ 'width': '100%' }}
                            format='YYYY-MM-DD'
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="预约上门时间"
                        name="visit_appointment_at"
                    >
                        <DatePicker
                            style={{ 'width': '100%' }}
                            format='YYYY-MM-DD'
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="实际上门时间"
                        name="visit_practical_at"
                    >
                        <DatePicker
                            style={{ 'width': '100%' }}
                            format='YYYY-MM-DD'
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="上门调解人"
                        name="peacemaker"
                    >
                        <Input maxLength={20} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="是否上门调解成功"
                        name="is_visit_conciliation"
                    >
                        <Select showArrow>
                            <Option key={1} value={true}>是</Option>
                            <Option key={2} value={false}>否</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item
                label="上门调解结果"
                name='conciliation_result'
            >
                <TextArea rows={3} />
            </Form.Item>

            <Form.Item
                label="附件上传"
                name='attach_files'
            >
                <Upload
                    multiple
                    fileList={attachments}
                    beforeUpload={checkFileSize}
                    onChange={onFileChange}
                    customRequest={customRequest}
                    showUploadList={{ showDownloadIcon: true }}
                    onDownload={onAttachmentDownload}
                >
                    <Button icon={<UploadOutlined />}>点击上传附件</Button>
                </Upload>
            </Form.Item>

            <Form.Item>
                <Button block type='primary' size='large' loading={confirmLoading} onClick={submit}>提交</Button>
            </Form.Item>
        </Form>
    )

    return (
        <div className={screens.xs ? 'inner-page mobile' : ''}>
            {
                loading
                    ? <Skeleton />
                    : screens.xs
                        ? FormItem
                        : <Row gutter={gutter}>
                            <Col span={12}>
                                <div style={{
                                    width: "100%",
                                    height: "100%",
                                }}>
                                    <div className='attachment_fixed'>
                                        <h2>附件预览</h2>
                                        {
                                            detail?.attach_files_info && detail.attach_files_info.length > 0
                                                ? <Row>
                                                    <Col span={2}>
                                                        <div className='switch_button'>
                                                            <LeftCircleOutlined className='switch_button_item' onClick={() => switchAttachmentIndex(-1)} />
                                                            <h3>上一页</h3>
                                                        </div>
                                                    </Col>
                                                    <Col span={20}>
                                                        <Image
                                                            width={'100%'}
                                                            src={detail?.attach_files_info[attachmentIndex].attach_url_link}
                                                            fallback={cantPreviewImage}
                                                        />
                                                        <div className='bottom_text'>
                                                            <h3>({attachmentIndex + 1}/{detail?.attach_files_info.length}){detail?.attach_files_info[attachmentIndex].attach_name}</h3>
                                                            <Button type="text" icon={<DownloadOutlined />} href={detail?.attach_files_info[attachmentIndex].attach_url_link} target="_blank" />
                                                        </div>
                                                    </Col>
                                                    <Col span={2}>
                                                        <div className='switch_button'>
                                                            <RightCircleOutlined className='switch_button_item' onClick={() => switchAttachmentIndex(1)} />
                                                            <h3>下一页</h3>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                : <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Image
                                                        preview={false}
                                                        height={'50%'}
                                                        src={noAttachmentImage}
                                                    />
                                                </div>
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col span={12}>
                                {FormItem}
                            </Col>
                        </Row>
            }

            {/* 回访记录 */}
            {
                toDetailModal ? <Modal
                    visible={toDetailModal.isModalVisible}
                    title={`回访记录`}
                    onCancel={onDetailClose}
                    footer={[
                        <Button key="back" onClick={onDetailClose}>
                            关闭
                        </Button>
                    ]}
                >
                    <ConciliationCommentLogsModal data={toDetailModal.data}></ConciliationCommentLogsModal>
                </Modal> : null
            }
        </div>
    );
}

export default ConciliationDetailFormModal;