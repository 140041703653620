import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';

// antd
import { Grid, Button, Form, Input, message, Select, Skeleton, Descriptions, Row, Col, Space, Divider, InputRef } from 'antd';
import { DatePicker as DatePickerMobile } from 'antd-mobile';
import DatePicker from '../../../components/customAntd/DatePicker';
import { PickerDate } from 'antd-mobile/es/components/date-picker/util';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { getPreArbitrationTableContent, IFileIndexTableForm, IFileIndexTableListData, ILaborDisputeRecordTableData, ILaborDisputeRecordTableForm, IPreArbitrationCoverTableData, IPreArbitrationCoverTableForm, saveFileIndexTable, saveLaborDisputeRecordTable, savePreArbitrationCoverTable } from '../../../apis/preArbitration';
import { getDictList, IDictData } from '../../../apis/common';
import TextArea from 'antd/lib/input/TextArea';

const { useBreakpoint } = Grid;
const { Option } = Select;

interface CollectionCreateFormProps {
    data?: { id: number },
    onFinish?: () => void;
}

function LaborDisputeRecordTableFormModal({
    data,
    onFinish,
}: CollectionCreateFormProps) {
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // 日期
    const [receiveAtVisible, setReceiveAtVisible] = useState(false);


    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 表单数据
    const [form] = Form.useForm<ILaborDisputeRecordTableForm>();
    const [detail, setDetail] = useState<ILaborDisputeRecordTableData>();

    // 人员
    const [ruleList, setRoleList] = useState<string[]>(['调解员', '申请人', '被申请人']);
    const inputRef = useRef<InputRef>(null);
    const [fileName, setFileName] = useState('');
    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileName(event.target.value);
    };
    const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if (!fileName) return;
        setRoleList([fileName, ...ruleList]);
        setFileName('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    // 初始化加载
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        form.resetFields();

        let pre_worksheet_id = data?.id ?? Number(searchParams.get('id'));
        if (pre_worksheet_id) {
            form.setFieldValue('pre_worksheet_id', pre_worksheet_id);
            setLoading(true);

            getPreArbitrationTableContent({
                pre_worksheet_id,
                form_type: 4,
            }).then(res => {
                console.log(res);
                let data: ILaborDisputeRecordTableData = res.data.detail!;
                setDetail(data);

                form.setFieldsValue({
                    ...data,
                    mediate_time: data.mediate_time ? dayjs(data.mediate_time) as any : undefined,
                    record: (data.record && data.record.length > 0)
                        ? data.record
                        : [
                            {
                                role: "调解员",
                                content: "现进行权利义务告知。根据《广东省劳动人事争议处理办法》等相关规定，现将调解主要权利义务告知如下：劳动纠纷调解遵循合法、自愿、公平、及时、保密原则。调解参与人应当遵守调解纪律和秩序，尊重对方当事人和调解人员，理性、平和开展协商，当事人在调解过程中不得录音录像。当事人达成调解协议后应当诚信履行，可以在达成调解协议后依法申请仲裁审查确认或法院司法确认。"
                            },
                            {
                                role: "调解员",
                                content: "双方当事人对对方参与调解人员的身份有否异议？"
                            },
                            {
                                role: "申请人",
                                content: "没有。"
                            },
                            {
                                role: "被申请人",
                                content: "没有。"
                            },
                            {
                                role: "调解员",
                                content: "双方当事人是否愿意在本委员会（调解组织）主持下进行调解，并自觉履行调解协议？ "
                            },
                            {
                                role: "申请人",
                                content: "愿意。"
                            },
                            {
                                role: "被申请人",
                                content: "愿意。"
                            },
                            {
                                role: "调解员",
                                content: "双方当事人应在充分了解相关现行法律规定及自身权利义务的前提下，平等自愿进行协商，你们是否清楚？ "
                            },
                            {
                                role: "申请人",
                                content: "清楚。"
                            },
                            {
                                role: "被申请人",
                                content: "清楚。"
                            },
                            {
                                role: "调解员",
                                content: "请双方当事人分别发表和解意见。"
                            },
                        ]
                });
            }).finally(() => {
                setLoading(false);
            })
        }

    }, [data, searchParams]);

    // 提交
    const submit = async (print = false) => {
        try {
            await form.validateFields();
        } catch (e: any) {
            message.warning(e.errorFields[0].errors[0]);
            return;
        }

        setConfirmLoading(true);
        let _form = form.getFieldsValue(true);
        try {
            let data = {
                ..._form,
            }
            if (data.mediate_time) data.mediate_time = (data.mediate_time as dayjs.Dayjs).format('YYYY-MM-DD HH');

            let res = await saveLaborDisputeRecordTable(data);
            message.success(res.msg);

            if (print) {
                setTimeout(() => {
                    window.open(`/preArbitration/laborDisputeRecord/print?id=${_form.pre_worksheet_id}`, '_blank');
                }, 0);
            } else {
                if (onFinish) {
                    onFinish();
                } else {
                    navigate(-1);
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    const gutter = 16 + 8 * 2;

    const FormItem = (
        <Form
            form={form}
            layout="vertical"
            autoComplete="off"
        >
            <Form.Item
                label="其他到场人员"
                name="other_personnel"
            >
                <Input maxLength={100} />
            </Form.Item>

            <Row gutter={gutter}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="地点"
                        name="address"
                    >
                        <Input maxLength={100} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    {
                        screens.xs ?
                            <Form.Item
                                label="时间"
                                shouldUpdate={(prevValues, curValues) => prevValues.mediate_time !== curValues.mediate_time}
                            >
                                <DatePickerMobile
                                    precision='hour'
                                    visible={receiveAtVisible}
                                    onClose={() => {
                                        setReceiveAtVisible(false)
                                    }}
                                    value={(form.getFieldValue('mediate_time') as Dayjs)?.toDate()}
                                    onConfirm={(value: PickerDate) => {
                                        form.setFieldValue('mediate_time', dayjs(value));
                                    }}
                                >
                                    {value =>
                                        <>
                                            <Button
                                                style={{ marginRight: 10 }}
                                                onClick={() => {
                                                    setReceiveAtVisible(true)
                                                }}
                                            >选择</Button>
                                            {value ? dayjs(value).format('YYYY年MM月DD日HH时') : '请选择时间'}
                                        </>
                                    }
                                </DatePickerMobile>
                            </Form.Item>
                            : <Form.Item
                                label="时间"
                                name="mediate_time"
                            >
                                <DatePicker
                                    format='YYYY年MM月DD日HH时'
                                    showTime={{ format: 'HH时' }}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                    }
                </Col>
            </Row>

            <Form.Item
                label="头部信息"
                name="form_header"
            >
                <TextArea rows={3} />
            </Form.Item>

            <Form.List name="record">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <>
                                <Space style={{ alignItems: 'flex-start' }}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'role']}
                                        rules={[{ required: true, message: '请选择人员' }]}
                                    >
                                        <Select
                                            showArrow
                                            style={{ width: screens.xs ? '100%' : 300 }}
                                            options={ruleList.map(item => ({ label: item, value: item }))}
                                            dropdownRender={menu => (
                                                <>
                                                    {menu}
                                                    <Divider style={{ margin: '8px 0' }} />
                                                    <Space style={{ padding: '0 8px 4px' }}>
                                                        <Input
                                                            placeholder="人员称呼"
                                                            ref={inputRef}
                                                            value={fileName}
                                                            onChange={onNameChange}
                                                        />
                                                        <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                                            添加选项
                                                        </Button>
                                                    </Space>
                                                </>
                                            )}
                                        >
                                        </Select>
                                    </Form.Item>
                                    <MinusCircleOutlined style={{ color: 'red', fontSize: 16, marginTop: 8 }} onClick={() => remove(name)} />
                                </Space>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'content']}
                                >
                                    <TextArea rows={3} />
                                </Form.Item>
                            </>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                添加记录
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>

            <Form.Item
                label="申请人意见"
                name="plaintiff_opinion"
            >
                <TextArea rows={3} />
            </Form.Item>

            <Form.Item
                label="被申请人意见"
                name="defendant_opinion"
            >
                <TextArea rows={3} />
            </Form.Item>

            <Form.Item
                label="调解结果"
                name="mediate_result"
            >
                <TextArea rows={3} />
            </Form.Item>

            <Form.Item>
                <Button block type='primary' size='large' loading={confirmLoading} onClick={() => submit()}>提交</Button>
            </Form.Item>
        </Form>
    )

    const infoItem = () => {
        return (
            <>
                <Descriptions
                    size='small'
                    column={screens.xs ? 1 : 3}
                    title="人员签到"
                    style={{ marginBottom: 20 }}
                    extra={
                        <Button onClick={() => {
                            submit(true);
                        }}>预览打印</Button >
                    }>
                    <Descriptions.Item label="调解员">{detail?.mediator}</Descriptions.Item>
                    <Descriptions.Item label="申请人">{detail?.plaintiff_name}</Descriptions.Item>
                    <Descriptions.Item label="被申请人（授权委托人）">{detail?.defendant_name}</Descriptions.Item>
                </Descriptions>
            </>
        )
    };

    return (
        <div className={screens.xs ? 'inner-page mobile' : ''}>
            {
                loading
                    ? <Skeleton />
                    : <>
                        {detail && infoItem()}
                        {FormItem}
                    </>
            }
        </div>
    );
}

export default LaborDisputeRecordTableFormModal;