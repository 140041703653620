import React, { useRef, useState } from 'react';

// antd
import { Button, Form, Input, Modal } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { ILoginForm, login } from '../../apis/user';

import './login.scss';
import ReactSimpleVerify from '../../components/validator/validator';

/**
 * 登录表单
 */
function LoginForm() {
    // 路由
    const navigate = useNavigate();

    // 表单实例
    const [form] = Form.useForm<ILoginForm>();

    // 表单提交
    const [submitLoading, setSubmitLoading] = useState(false);
    const onSubmit = async (values: ILoginForm) => {
        console.log('Received values of form: ', values);
        try {
            setSubmitLoading(true);
            let res = await login(values);
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('account', values.username);
            navigate('/');
        } catch (e) {
            console.error(e);
        } finally {
            setSubmitLoading(false);
        }
    };

    
    // 验证码
    const verifyRef = useRef<ReactSimpleVerify>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        verifyRef.current?.reset();
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        // 流式布局
        <div className='login-form'>
            {/* 标题（登录方式） */}
            <div className='login-form__title'>账号密码登录</div>

            {/* 表单 */}
            <Form
                form={form}
                name="login"
                className="login-form__form"
                initialValues={{ agreement: true }}
            >
                {/* 账号 */}
                <Form.Item
                    name="username"
                    rules={[{ required: true, message: '请输入账号' }]}
                >
                    <Input prefix={<UserOutlined className="login-form__form__item-icon" size={50} />} placeholder="请输入账号" />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[{ required: true, message: '请输入密码' }]}
                >
                    <Input
                        prefix={<LockOutlined className="login-form__form__item-icon" />}
                        type="password"
                        placeholder="请输入密码"
                    />
                </Form.Item>

                {/* 间距 */}
                <div className='login-form__form__padding'></div>

                <Button
                    className='login-form__form__way-button'
                    type="link"
                    block
                    onClick={() => navigate('/registerOrganization')}
                >
                    机构入驻
                </Button>

                {/* 登录按钮 */}
                <Form.Item>
                    <Button
                        loading={submitLoading}
                        type="primary" block className="login-form__form__button"
                        onClick={() => {
                            form.validateFields().then((values) => {
                                showModal();
                            });
                        }}
                    >
                        立即登录
                    </Button>
                </Form.Item>
            </Form>

            <Modal title="验证码" footer={null} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div style={{ height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ReactSimpleVerify ref={verifyRef} success={() => {
                        form.validateFields().then((values) => {
                            setTimeout(() => {
                                handleOk();
                            }, 500);
                            onSubmit(values);
                        });
                    }}></ReactSimpleVerify>
                </div>
            </Modal>
        </div>
    );
}
export default LoginForm;