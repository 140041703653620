import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// antd
import { Skeleton, Checkbox } from 'antd';

import { getPreArbitrationTableContent, IPreArbitrationCoverTableData } from '../../../../apis/preArbitration';


function CoverTablePrint() {
    const tableMinHeight = "2.4em";

    const [searchParams, setSearchParams] = useSearchParams();

    // 表单数据
    const [detail, setDetail] = useState<IPreArbitrationCoverTableData>();

    // 初始化加载
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = "调解案卷";

        let pre_worksheet_id = Number(searchParams.get('id'));
        if (pre_worksheet_id) {
            setLoading(true);
            getPreArbitrationTableContent({
                pre_worksheet_id,
                form_type: 2,
            }).then(res => {
                console.log(res);
                let data: IPreArbitrationCoverTableData = res.data.detail!;
                setDetail(data);
            }).finally(() => {
                setLoading(false);
            })
        }

    }, [searchParams]);

    return (
        <div>
            {
                loading
                    ? <Skeleton />
                    : <table className='preArbitration_table' border={1} style={{ width: "707px", height: "1000px", margin: 'auto' }}>
                        <tr>
                            <td colSpan={5} style={{ position: 'relative' }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    height: '100%'
                                }}>
                                    <h2 style={{ fontWeight: 'bold' }}>{detail?.archive_name}</h2>
                                    <div
                                        style={{
                                            fontSize: 24,
                                            fontWeight: 'bold',
                                            margin: '30px 0'
                                        }}>调 解 案 卷</div>
                                    <div style={{
                                        position: 'absolute',
                                        right: 10,
                                        bottom: 10
                                    }}>（共<span style={{ margin: '0 15px' }}></span>页）</div>
                                </div>
                            </td>
                        </tr>
                        <tr style={{ height: tableMinHeight }}>
                            <td style={{ width: '15%' }} colSpan={2}>案号</td>
                            <td style={{ width: '35%' }}>{detail?.case_number}</td>
                            <td style={{ width: '15%' }}>案由</td>
                            <td style={{ width: '35%' }}>
                                <div style={{ maxHeight: tableMinHeight, overflow: 'hidden' }}>
                                    {detail?.case_cause}
                                </div>
                            </td>
                        </tr>
                        <tr style={{ height: 100 }}>
                            <td rowSpan={3}>当<br />事<br />人</td>
                            <td>申<br />请<br />人</td>
                            <td colSpan={3}>{detail?.plaintiff}</td>
                        </tr>
                        <tr style={{ height: 100 }}>
                            <td>被<br />申<br />请<br />人</td>
                            <td colSpan={3}>{detail?.defendant}</td>
                        </tr>
                        <tr style={{ height: 100 }}>
                            <td>第<br />三<br />人</td>
                            <td colSpan={3}>{detail?.third_party_data}</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>案<br />由</td>
                            <td colSpan={3}>{detail?.case_cause}</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>处<br />理<br />结<br />果</td>
                            <td colSpan={3}>
                                <div style={{ width: "100%", textAlign: 'start', lineHeight: "1.5em" }}>
                                    {
                                        detail?.processing_result?.map(e => {

                                            if (e.key === 1) {
                                                let label = e.label;

                                                if (e.selected && e.option) {
                                                    let index = e.option.findIndex(opt => opt.selected);
                                                    if (index !== -1) {
                                                        label = label.replace(/(\_+)/, `_${String(index + 1)}_`);
                                                    }
                                                }

                                                return (
                                                    <>
                                                        <div><Checkbox checked={e.selected} disabled />{label}</div>
                                                        {
                                                            e.option?.map(opt =>
                                                                <div>{opt.label}</div>
                                                            )
                                                        }
                                                    </>
                                                )
                                            } else {
                                                return <div><Checkbox checked={e.selected} disabled />{e.label}</div>;
                                            }

                                        })
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr style={{ height: 75 }}>
                            <td colSpan={2}>调解员</td>
                            <td>{detail?.mediator}</td>
                            <td>记录</td>
                            <td>{detail?.recorder}</td>
                        </tr>
                        <tr style={{ height: 75 }}>
                            <td colSpan={2}>收件<br />日期</td>
                            <td>{detail?.receive_at}</td>
                            <td>结案<br />日期</td>
                            <td>{detail?.closing_date}</td>
                        </tr>
                    </table>
            }
        </div >
    );
}

export default CoverTablePrint;