import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// antd
import { Grid, Button, Col, Form, Input, message, Row, Select, Upload, InputNumber, Divider, Skeleton, List, Card, Collapse, Image, Descriptions, Segmented, Radio, Checkbox } from 'antd';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { UploadRequestOption } from "rc-upload/lib/interface";
import { DownloadOutlined, UploadOutlined, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { DatePicker as DatePickerMobile, ImageViewer, ImageUploadItem, Space, Tabs, Empty, ErrorBlock } from 'antd-mobile';

import { FileUpload, getDictList, IDictData, uploadFile } from '../../../apis/common';
import { Attachment } from '../../../apis/attachment';
import TextArea from 'antd/lib/input/TextArea';
import dayjs, { Dayjs } from 'dayjs';

import cantPreviewImage from '@assets/images/common/cant_preview.png';
import noAttachmentImage from '@assets/images/common/no_attachment.png';
import { editPreArbitrationConciliationDetail, getPreArbitrationAgreementTemplate, getPreArbitrationDetail, getPreArbitrationFormUserList, getPreArbitrationRegionCaseRule, IAttachFilesData, IAttachFilesDataInfo, IEditPreArbitrationConciliationDetailForm, ILaborDisputeHandlingTableDataMediate, INaturalPeople, IPreArbitrationCompany, IPreArbitrationDetailData, IPreArbitrationFormUserListData, IPreArbitrationRegionCaseRuleForm, IProcessingResultOption } from '../../../apis/preArbitration';
import DatePicker from '../../../components/customAntd/DatePicker';
import { PickerDate } from 'antd-mobile/es/components/date-picker/util';
import { NewUpload } from '../../../components/uploader/uploader';
import { IConciliationOrderTrailTemplateData } from '../../../apis/conciliation';

const { Option } = Select;
const { useBreakpoint } = Grid;


interface CollectionCreateFormProps {
    data?: { id: number },
    onFinish?: () => void;
}

function PreArbitrationConciliationDetailFormModal({
    data,
    onFinish,
}: CollectionCreateFormProps) {
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 表单数据
    const [form] = Form.useForm<IEditPreArbitrationConciliationDetailForm>();

    // 调解不成日期
    const [mediateFailDateVisible, setMediateFailDateVisible] = useState(false);
    // 受理日期
    const [acceptanceDateVisible, setAcceptanceDateVisible] = useState(false);
    // 结案日期
    const [closingDateVisible, setClosingDateVisible] = useState(false);
    // 最后电话调解时间
    const [lastPhoneMediateDateVisible, setLastPhoneMediateDateVisible] = useState(false);
    // 现场调解时间
    const [sceneMediateDateVisible, setSceneMediateDateVisible] = useState(false);
    // 付款时间
    const [payDateVisible, setPayDateVisible] = useState(false);

    const [detail, setDetail] = useState<IPreArbitrationDetailData>();

    // 当前机构所在地区下所有人员列表
    const [staffs, setStaffs] = useState<IPreArbitrationFormUserListData[]>([]);
    useEffect(() => {
        getPreArbitrationFormUserList().then(res => {
            setStaffs(res.data.list);
        })
    }, []);

    // 初始化加载
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        form.resetFields();

        setLoading(true);
        getPreArbitrationDetail({ id: data?.id ?? Number(searchParams.get('id')) }).then(res => {
            let data = res.data.detail;
            setDetail(data);
            getCaseData({
                location: {
                    province_id: data.province_id,
                    city_id: data.city_id,
                    area_id: data.area_id,
                    street_id: data.street_id,
                }
            });

            // 附件
            if (data.mediate_attach_files_data_info) {
                let _attachments: Record<number, UploadFile<any>[]> = {};
                data.mediate_attach_files_data_info.forEach(item => {
                    _attachments[item.value] = item.files_info.map(file => {
                        return {
                            uid: file.id.toString(),
                            name: file.attach_name ?? '未知文件',
                            response: file,
                            status: 'done'
                        }
                    });
                });
                setAttachments(_attachments);
            } else {
                setAttachments({});
            }

            form.setFieldsValue({
                ...data,
                is_involve_people1: data.is_involve_people1 ?? false,
                is_involve_people2: data.is_involve_people2 ?? false,
                is_involve_people3: data.is_involve_people3 ?? false,
                is_involve_people4: data.is_involve_people4 ?? false,
                is_covid: data.is_covid ?? false,
                is_covid_control: data.is_covid_control ?? false,
                is_new_job: data.is_new_job ?? false,
                is_cate10: data.is_cate10 ?? false,
                is_cate11: data.is_cate11 ?? false,
                is_cate20: data.is_cate20 ?? false,
                is_cate21: data.is_cate21 ?? false,
                is_cate22: data.is_cate22 ?? false,
                is_cate30: data.is_cate30 ?? false,
                is_cate40: data.is_cate40 ?? false,
                is_cate50: data.is_cate50 ?? false,
                is_cate60: data.is_cate60 ?? false,
                is_cate70: data.is_cate70 ?? false,
                is_mediate_settle: data.is_mediate_settle ?? false,
                is_result10: data.is_result10 ?? false,
                is_result11: data.is_result11 ?? false,
                is_result12: data.is_result12 ?? false,
                is_result20: data.is_result20 ?? false,
                is_result30: data.is_result30 ?? false,
                mediate_fail_date: data.mediate_fail_date ? dayjs(data.mediate_fail_date) as any : undefined,
                acceptance_date: data.acceptance_date ? dayjs(data.acceptance_date) as any : undefined,
                scene_mediate_date: data.scene_mediate_date ? dayjs(data.scene_mediate_date) as any : undefined,
                last_phone_mediate_date: data.last_phone_mediate_date ? dayjs(data.last_phone_mediate_date) as any : undefined,
                pay_time: data.pay_time ? dayjs(data.pay_time) as any : undefined,
                closing_date: data.closing_date ? dayjs(data.closing_date) as any : undefined,
                mediate_type_list: data.mediate_type ? data.mediate_type.filter(e => e.selected).map(e => e.key) : undefined
            });


            setLoading(false);
        })
    }, [data, searchParams]);

    // 调解结果模板
    const [template, setTemplate] = useState<IConciliationOrderTrailTemplateData[]>([]);
    useEffect(() => {
        if (detail) {
            getPreArbitrationAgreementTemplate({
                province_id: detail.province_id,
                city_id: detail.city_id,
                area_id: detail.area_id,
                street_id: detail.street_id,
            }).then(res => {
                setTemplate(res.data.list);
            })
        }
    }, [detail]);

    // 承办环节
    const [handlingPhase, setHandlingPhase] = useState<IDictData>();
    // 调解方式
    const [mediateType, setMediateType] = useState<IDictData>();
    useEffect(() => {
        getDictList(['pre_worksheet_handling_phase', 'pre_worksheet_mediate_type']).then(res => {
            setHandlingPhase(res.data.pre_worksheet_handling_phase);
            setMediateType(res.data.pre_worksheet_mediate_type);
        });
    }, []);

    // 上传统一方法
    const checkFileSize = (file: RcFile) => {
        if ((file.size / 1024 / 1024) > 50) {
            message.error(`${file.name} 文件超出限制大小`);
            return Upload.LIST_IGNORE;
        }

        return true;
    };
    const customRequest = (options: UploadRequestOption<any>) => {
        const { onSuccess, onError, file, onProgress } = options;
        uploadFile({ file: file as Blob, type: 'pre_worksheet_info' })
            .then(res => {
                onSuccess!(res.data);
            });
    }

    // 上传附件
    const [attachments, setAttachments] = useState<Record<number, UploadFile<any>[]>>([]);
    const onFileChange = (info: UploadChangeParam<UploadFile<any>>, value: number) => {
        console.log(info.file, info.fileList, attachments);
        let tempFileList = attachments;
        tempFileList[value] = tempFileList[value] ?? [];

        if (tempFileList[value].length === 0) {
            tempFileList[value] = info.fileList;
        }
        if (info.file.status === 'done') {
            tempFileList[value] = [info.file, ...tempFileList[value].filter((item) => item.uid !== info.file.uid)];
        }
        if (info.file.status === 'removed') {
            tempFileList[value] = [...tempFileList[value].filter((item) => item.status !== 'removed')];
        }
        setAttachments(tempFileList);
        form.setFieldValue('mediate_attach_files_data', form.getFieldValue('mediate_attach_files_data'));
    }
    const onAttachmentDownload = (file: UploadFile<any>) => {
        console.log(file);
        let res: FileUpload | Attachment = file.response;
        window.open((res as FileUpload).full_link || (res as Attachment).attach_url_link, "_blank");
    }

    // 提交
    const submit = async () => {
        try {
            await form.validateFields();
        } catch (e: any) {
            message.warning(e.errorFields[0].errors[0]);
            return;
        }
        let _form = form.getFieldsValue(true);


        setConfirmLoading(true);
        try {
            let data = {
                ..._form,
            }
            if (_form.mediate_fail_date) data.mediate_fail_date = (_form.mediate_fail_date as dayjs.Dayjs).format('YYYY-MM-DD');
            if (_form.acceptance_date) data.acceptance_date = (_form.acceptance_date as dayjs.Dayjs).format('YYYY-MM-DD');
            if (_form.closing_date) data.closing_date = (_form.closing_date as dayjs.Dayjs).format('YYYY-MM-DD');
            if (_form.scene_mediate_date) data.scene_mediate_date = (_form.scene_mediate_date as dayjs.Dayjs).format('YYYY-MM-DD');
            if (_form.last_phone_mediate_date) data.last_phone_mediate_date = (_form.last_phone_mediate_date as dayjs.Dayjs).format('YYYY-MM-DD');
            if (_form.pay_time) data.pay_time = (_form.pay_time as dayjs.Dayjs).format('YYYY-MM-DD');
            // 处理旧数据
            if (data.mediate_type === null || data.mediate_type.length === 0) {
                data.mediate_type = mediateType?.options.map(e => ({ key: e.value, label: e.name, extra: null, selected: false })) as ILaborDisputeHandlingTableDataMediate[];
            }
            if (_form.mediate_type_list) data.mediate_type.forEach((e: ILaborDisputeHandlingTableDataMediate) => {
                if ((_form.mediate_type_list as number[]).includes(e.key)) {
                    e.selected = true;
                } else {
                    e.selected = false;
                }
            });
            (data.mediate_attach_files_data as IAttachFilesData[]).forEach((item) => {
                item.files = attachments[item.value]?.filter(file => file.response)
                    .map(file => {
                        let res: FileUpload | Attachment = file.response;
                        return (res as FileUpload).file_id || (res as Attachment).id;
                    }) ?? [];
            });

            let res = await editPreArbitrationConciliationDetail(data);
            message.success(res.msg);
            if (onFinish) {
                onFinish();
            } else {
                navigate('/preArbitration');
            }
        } catch (e) {
            console.error(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    // 附件预览
    const [attachmentSegmented, setAttachmentSegmented] = useState<string | number>();
    useEffect(() => {
        if (detail?.attach_files_data && detail?.attach_files_data.length) {
            setAttachmentSegmented(detail.attach_files_data[0].name)
        }
    }, [detail]);
    const [nowAttachment, setNowAttachment] = useState<IAttachFilesDataInfo>();
    useEffect(() => {
        setNowAttachment(
            detail?.attach_files_data_info?.find(item => item.name === attachmentSegmented)
        );
    }, [attachmentSegmented]);

    const [attachmentIndex, setAttachmentIndex] = useState(0);
    const switchAttachmentIndex = (dis: number) => {
        if (!detail) return;

        let res = attachmentIndex + dis;
        let length = nowAttachment?.files.length ?? 0;
        if (res < 0) {
            res = length - 1;
        } else if (res >= length) {
            res = 0;
        }
        setAttachmentIndex(res);
    }

    // 获取案号
    const getCaseData = async ({ location }: {
        location: IPreArbitrationRegionCaseRuleForm
    }) => {
        try {
            let res = await getPreArbitrationRegionCaseRule(location);
            if (!form.getFieldValue('processing_result')) {
                form.setFieldValue('processing_result', res.data.processing_result_option);
            }
            form.setFieldValue('mediate_attach_files_data', res.data.mediate_attachment_tags_data?.map(item => ({
                ...item,
                files: []
            })) ?? []);
        } catch (e) {
            console.error(e);
        }
    };

    const gutter = 16 + 8 * 2;

    const InfoItem = (
        detail
            ? <>
                <Descriptions column={screens.xs ? 1 : 3} bordered={!screens.xs} layout={screens ? 'horizontal' : "vertical"}>
                    <Descriptions.Item label="案件号">
                        {detail.case_number ?? '无'}
                    </Descriptions.Item>
                    <Descriptions.Item label="受理时间">
                        {detail.acceptance_date ?? '无'}
                    </Descriptions.Item>
                    <Descriptions.Item label="省/市/区/街道" span={2}>
                        {`${detail.province_text}/${detail.city_text}/${detail.area_text}/${detail.street_text}` ?? '无'}
                    </Descriptions.Item>
                    {/* <Descriptions.Item label="问题类别">
                        {detail.question_type_text ?? '无'}
                    </Descriptions.Item> */}
                    {/* <Descriptions.Item label="来源方式">
                        {detail.source_type_text ?? '无'}
                    </Descriptions.Item> */}
                    {/* <Descriptions.Item label="案件类别">
                        {detail.case_category_text ?? '无'}
                    </Descriptions.Item> */}
                    <Descriptions.Item label="案件类型">
                        {detail.type_text ?? '无'}
                    </Descriptions.Item>
                    <Descriptions.Item label="争议类型">
                        {detail.dispute_type_text ?? '无'}
                    </Descriptions.Item>
                    {/* <Descriptions.Item label="投诉类别" span={2}>
                        {detail.lawsuit_type_text ?? '无'}
                    </Descriptions.Item> */}
                    <Descriptions.Item label="涉及人数">
                        {detail.involve_people ?? '无'}
                    </Descriptions.Item>
                    {/* <Descriptions.Item label="涉案金额">
                        {detail.lawsuit_money ?? '无'}
                    </Descriptions.Item> */}
                    {/* <Descriptions.Item label="是否涉军">
                        {detail.is_soldier ?? '未知'}
                    </Descriptions.Item> */}
                    {/* <Descriptions.Item label="是否残疾" span={2}>
                        {detail.is_disability ?? '未知'}
                    </Descriptions.Item> */}
                    {/* <Descriptions.Item label="案由" span={2}>
                        {detail.case_cause ?? '无'}
                    </Descriptions.Item> */}
                </Descriptions>

                <Divider orientation='left'>相关人员</Divider>

                <Collapse defaultActiveKey={['1', '2', '3']}>
                    <Collapse.Panel key='1' header='申请人'>
                        <Space direction='vertical'>
                            {
                                detail.plaintiff_data.map(item => (
                                    detail.plaintiff_subject_type === 1
                                        ? <Descriptions column={screens.xs ? 1 : 3} bordered={!screens.xs} layout={screens ? 'horizontal' : "vertical"}>
                                            <Descriptions.Item label="申请人" span={3}>
                                                {(item as INaturalPeople)?.real_name ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="身份证">
                                                {(item as INaturalPeople)?.idcard ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="性别">
                                                {(item as INaturalPeople)?.sex_text ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="联系电话">
                                                {(item as INaturalPeople)?.mobile ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="文书送达地址" span={3}>
                                                {(item as INaturalPeople)?.effect_service_address ?? '无'}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        : <Descriptions column={screens.xs ? 1 : 3} bordered={!screens.xs} layout={screens ? 'horizontal' : "vertical"}>
                                            <Descriptions.Item label="申请人" span={2}>
                                                {(item as IPreArbitrationCompany)?.company_name ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="企业类型" span={1}>
                                                {(item as IPreArbitrationCompany)?.company_type_text ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="法人">
                                                {(item as IPreArbitrationCompany)?.legal_person ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="联系人">
                                                {(item as IPreArbitrationCompany)?.contact_name ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="联系电话">
                                                {(item as IPreArbitrationCompany)?.contact_mobile ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="地址" span={3}>
                                                {(item as IPreArbitrationCompany)?.address ?? '无'}
                                            </Descriptions.Item>
                                        </Descriptions>
                                ))
                            }
                        </Space>
                    </Collapse.Panel>
                    <Collapse.Panel key='2' header='被申请人'>
                        <Space direction='vertical'>
                            {
                                detail.defendant_data.map(item => (
                                    detail.defendant_subject_type === 1
                                        ? <Descriptions column={screens.xs ? 1 : 3} bordered={!screens.xs} layout={screens ? 'horizontal' : "vertical"}>
                                            <Descriptions.Item label="被申请人" span={3}>
                                                {(item as INaturalPeople)?.real_name ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="身份证">
                                                {(item as INaturalPeople)?.idcard ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="性别">
                                                {(item as INaturalPeople)?.sex_text ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="联系电话">
                                                {(item as INaturalPeople)?.mobile ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="文书送达地址" span={3}>
                                                {(item as INaturalPeople)?.effect_service_address ?? '无'}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        : <Descriptions column={screens.xs ? 1 : 3} bordered={!screens.xs} layout={screens ? 'horizontal' : "vertical"}>
                                            <Descriptions.Item label="被申请人" span={2}>
                                                {(item as IPreArbitrationCompany)?.company_name ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="企业类型" span={1}>
                                                {(item as IPreArbitrationCompany)?.company_type_text ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="法人">
                                                {(item as IPreArbitrationCompany)?.legal_person ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="联系人">
                                                {(item as IPreArbitrationCompany)?.contact_name ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="联系电话">
                                                {(item as IPreArbitrationCompany)?.contact_mobile ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="地址" span={3}>
                                                {(item as IPreArbitrationCompany)?.address ?? '无'}
                                            </Descriptions.Item>
                                        </Descriptions>
                                ))
                            }
                        </Space>
                    </Collapse.Panel>
                    <Collapse.Panel key='3' header='第三人'>
                        <Space direction='vertical'>
                            {
                                detail.third_party.map(item => (
                                    item.subject_type === 1
                                        ? <Descriptions column={screens.xs ? 1 : 3} bordered={!screens.xs} layout={screens ? 'horizontal' : "vertical"}>
                                            <Descriptions.Item label="被申请人" span={3}>
                                                {(item.natural_people as INaturalPeople)?.real_name ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="身份证">
                                                {(item.natural_people as INaturalPeople)?.idcard ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="性别">
                                                {(item.natural_people as INaturalPeople)?.sex_text ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="联系电话">
                                                {(item.natural_people as INaturalPeople)?.mobile ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="文书送达地址" span={3}>
                                                {(item.natural_people as INaturalPeople)?.effect_service_address ?? '无'}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        : <Descriptions column={screens.xs ? 1 : 3} bordered={!screens.xs} layout={screens ? 'horizontal' : "vertical"}>
                                            <Descriptions.Item label="被申请人" span={2}>
                                                {(item.company as IPreArbitrationCompany)?.company_name ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="企业类型" span={1}>
                                                {(item.company as IPreArbitrationCompany)?.company_type_text ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="法人">
                                                {(item.company as IPreArbitrationCompany)?.legal_person ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="联系人">
                                                {(item.company as IPreArbitrationCompany)?.contact_name ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="联系电话">
                                                {(item.company as IPreArbitrationCompany)?.contact_mobile ?? '无'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="地址" span={3}>
                                                {(item.company as IPreArbitrationCompany)?.address ?? '无'}
                                            </Descriptions.Item>
                                        </Descriptions>
                                ))
                            }
                        </Space>
                    </Collapse.Panel>

                </Collapse>
            </>
            : <Skeleton active />
    )

    const FormItem = (
        <Form
            form={form}
            layout="vertical"
            autoComplete="off"
        >
            {/* <Form.Item
                label="选择承办环节"
                name="handling_phase_dict"
            >
                <Select showArrow>
                    {handlingPhase?.options.map(option => (
                        <Option key={option.value} value={option.value}>{option.name}</Option>
                    ))}
                </Select>
            </Form.Item> */}

            <Form.Item
                label="案件号"
                name='case_number'
                rules={[{ required: true, message: '请填写案件号' }]}
            >
                <Input maxLength={100} placeholder='请填写案件号' />
            </Form.Item>


            <Form.Item
                label="案由"
                name='case_cause'
            >
                <Input maxLength={200} />
            </Form.Item>


            <Form.Item
                label="诉求"
                name='issue_appeal'
            >
                <TextArea rows={3} />
            </Form.Item>


            <Form.Item
                label="协助调解员"
                name="organ_user_mediate_id"
            >
                <Select showArrow>
                    {staffs?.map(option => (
                        <Option key={option.id} value={option.id}>{option.username}({option.real_name})</Option>
                    ))}
                </Select>
            </Form.Item>



            {/* <Form.Item
                label="办理情况"
                name='handling_situation'
            >
                <TextArea rows={3} />
            </Form.Item> */}

            <Row gutter={gutter}>
                {/* <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    {
                        screens.xs ?
                            <Form.Item
                                label="调解不成日期"
                                shouldUpdate={(prevValues, curValues) => prevValues.mediate_fail_date !== curValues.mediate_fail_date}
                            >
                                {({ getFieldValue, setFieldValue }) => {
                                    let mediate_fail_date = getFieldValue('mediate_fail_date');
                                    return (
                                        <DatePickerMobile
                                            visible={mediateFailDateVisible}
                                            onClose={() => {
                                                setMediateFailDateVisible(false)
                                            }}
                                            value={mediate_fail_date ? dayjs(mediate_fail_date).toDate() : null}
                                            onConfirm={(value: PickerDate) => {
                                                form.setFieldValue('mediate_fail_date', dayjs(value));
                                            }}
                                        >
                                            {value =>
                                                <>
                                                    <Button
                                                        style={{ marginRight: 10 }}
                                                        onClick={() => {
                                                            setMediateFailDateVisible(true)
                                                        }}
                                                    >选择</Button>
                                                    {value ? dayjs(value).format('YYYY年MM月DD日') : '请选择时间'}
                                                </>
                                            }
                                        </DatePickerMobile>
                                    );
                                }}
                            </Form.Item>
                            : <Form.Item
                                label="调解不成日期"
                                name="mediate_fail_date"
                            >
                                <DatePicker
                                    style={{ 'width': '100%' }}
                                    format='YYYY-MM-DD'
                                />
                            </Form.Item>
                    }
                </Col> */}
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    {
                        screens.xs ?
                            <Form.Item
                                label="受理日期"
                                shouldUpdate={(prevValues, curValues) => prevValues.acceptance_date !== curValues.acceptance_date}
                            >
                                {({ getFieldValue, setFieldValue }) => {
                                    let acceptance_date = getFieldValue('acceptance_date');
                                    return (
                                        <DatePickerMobile
                                            visible={acceptanceDateVisible}
                                            onClose={() => {
                                                setAcceptanceDateVisible(false)
                                            }}
                                            value={acceptance_date ? dayjs(acceptance_date).toDate() : null}
                                            onConfirm={(value: PickerDate) => {
                                                form.setFieldValue('acceptance_date', dayjs(value));
                                            }}
                                        >
                                            {value =>
                                                <>
                                                    <Button
                                                        style={{ marginRight: 10 }}
                                                        onClick={() => {
                                                            setAcceptanceDateVisible(true)
                                                        }}
                                                    >选择</Button>
                                                    {value ? dayjs(value).format('YYYY年MM月DD日') : '请选择时间'}
                                                </>
                                            }
                                        </DatePickerMobile>
                                    );
                                }}
                            </Form.Item>
                            : <Form.Item
                                label="受理日期"
                                name="acceptance_date"
                            >
                                <DatePicker
                                    style={{ 'width': '100%' }}
                                    format='YYYY-MM-DD'
                                />
                            </Form.Item>
                    }
                </Col>

            </Row>

            <Form.Item
                label="调解方式"
                name="mediate_type_list"
            >
                <Checkbox.Group
                    options={mediateType?.options.map(e => ({ label: e.name, value: e.value }))}
                />
            </Form.Item>

            <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => prevValues.mediate_type_list !== curValues.mediate_type_list}
            >
                {({ getFieldValue }) => {
                    let mediateType: number[] = getFieldValue('mediate_type_list');

                    return (
                        <div>
                            {
                                // 现场
                                mediateType?.find(e => e === 1) != undefined &&
                                <>
                                    {screens.xs ?
                                        <Form.Item
                                            label="现场调解时间"
                                            shouldUpdate={(prevValues, curValues) => prevValues.scene_mediate_date !== curValues.scene_mediate_date}
                                            required
                                        >
                                            {({ getFieldValue, setFieldValue }) => {
                                                let scene_mediate_date = getFieldValue('scene_mediate_date');
                                                return (
                                                    <DatePickerMobile
                                                        visible={sceneMediateDateVisible}
                                                        value={scene_mediate_date ? dayjs(scene_mediate_date).toDate() : null}
                                                        onClose={() => {
                                                            setSceneMediateDateVisible(false)
                                                        }}
                                                        onConfirm={(value: PickerDate) => {
                                                            setFieldValue('scene_mediate_date', dayjs(value));
                                                        }}
                                                    >
                                                        {value =>
                                                            <>
                                                                <Button
                                                                    style={{ marginRight: 10 }}
                                                                    onClick={() => {
                                                                        setSceneMediateDateVisible(true)
                                                                    }}
                                                                >选择</Button>
                                                                {value ? dayjs(value).format('YYYY年MM月DD日') : '请选择时间'}
                                                            </>
                                                        }
                                                    </DatePickerMobile>
                                                );
                                            }}

                                        </Form.Item>
                                        : <Form.Item
                                            label="现场调解时间"
                                            name="scene_mediate_date"
                                            rules={[{ required: true, message: '请选择现场调解时间' }]}
                                        >
                                            <DatePicker
                                                style={{ 'width': '100%' }}
                                                format='YYYY-MM-DD'
                                            />
                                        </Form.Item>
                                    }

                                </>
                            }


                            {
                                // 电话
                                mediateType?.find(e => e === 2) != undefined &&
                                <>
                                    {screens.xs ?
                                        <Form.Item
                                            label="最后电话调解时间"
                                            shouldUpdate={(prevValues, curValues) => prevValues.last_phone_mediate_date !== curValues.last_phone_mediate_date}
                                            required
                                        >
                                            {({ getFieldValue, setFieldValue }) => {
                                                let last_phone_mediate_date = getFieldValue('last_phone_mediate_date');

                                                return (
                                                    <DatePickerMobile
                                                        visible={lastPhoneMediateDateVisible}
                                                        value={last_phone_mediate_date ? dayjs(last_phone_mediate_date).toDate() : null}
                                                        onClose={() => {
                                                            setLastPhoneMediateDateVisible(false)
                                                        }}
                                                        onConfirm={(value: PickerDate) => {
                                                            setFieldValue('last_phone_mediate_date', dayjs(value));
                                                        }}
                                                    >
                                                        {value =>
                                                            <>
                                                                <Button
                                                                    style={{ marginRight: 10 }}
                                                                    onClick={() => {
                                                                        setLastPhoneMediateDateVisible(true)
                                                                    }}
                                                                >选择</Button>
                                                                {value ? dayjs(value).format('YYYY年MM月DD日') : '请选择时间'}
                                                            </>
                                                        }
                                                    </DatePickerMobile>
                                                );
                                            }}

                                        </Form.Item>
                                        : <Form.Item
                                            label="最后电话调解时间"
                                            name="last_phone_mediate_date"
                                            rules={[{ required: true, message: '请选择最后电话调解时间' }]}
                                        >
                                            <DatePicker
                                                style={{ 'width': '100%' }}
                                                format='YYYY-MM-DD'
                                            />
                                        </Form.Item>
                                    }

                                    <Form.Item
                                        label="电话调解申请人态度"
                                        name='phone_mediate_plaintiff_attitude'
                                        rules={[{ required: true, message: '请填写电话调解申请人态度' }]}
                                    >
                                        <TextArea rows={3} />
                                    </Form.Item>

                                    <Form.Item
                                        label="电话调解被申请人态度"
                                        name='phone_mediate_defendant_attitude'
                                        rules={[{ required: true, message: '请填写电话调解被申请人态度' }]}
                                    >
                                        <TextArea rows={3} />
                                    </Form.Item>
                                </>
                            }
                        </div>
                    );
                }}
            </Form.Item>

            <Divider></Divider>
            <Form.Item
                label="涉案金额"
                name="lawsuit_money"
            >
                <InputNumber min={0} precision={2} addonAfter="元" />
            </Form.Item>
            <Form.Item
                label="案件涉及劳动者人数"
                name="involve_people"
            >
                <InputNumber min={0} precision={0} addonAfter="人" />
            </Form.Item>
            <Form.Item
                label="涉及新就业形态"
                name="is_new_job"
            >
                <Radio.Group>
                    <Radio value={false}>否</Radio>
                    <Radio value={true}>是</Radio>
                </Radio.Group>
            </Form.Item>
            {/* <Collapse bordered={true}>
                <Collapse.Panel key='1' header='案件涉及劳动者选项'>
                    <Row gutter={gutter}>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="十人以上劳动（人事）争议"
                                name="is_involve_people1"
                            >
                                <Radio.Group>
                                    <Radio value={false}>否</Radio>
                                    <Radio value={true}>是</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="五十人以上劳动（人事）争议"
                                name="is_involve_people2"
                            >
                                <Radio.Group>
                                    <Radio value={false}>否</Radio>
                                    <Radio value={true}>是</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="涉及农民工（人）"
                                name="is_involve_people3"
                            >
                                <Radio.Group>
                                    <Radio value={false}>否</Radio>
                                    <Radio value={true}>是</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="涉及劳务派遣（人）"
                                name="is_involve_people4"
                            >
                                <Radio.Group>
                                    <Radio value={false}>否</Radio>
                                    <Radio value={true}>是</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider></Divider>
                    <Row gutter={gutter}>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="涉及劳动者感染新冠肺炎或隔离"
                                name="is_covid"
                            >
                                <Radio.Group>
                                    <Radio value={false}>否</Radio>
                                    <Radio value={true}>是</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="涉及疫情防控一线工作人员工资补贴、工伤"
                                name="is_covid_control"
                            >
                                <Radio.Group>
                                    <Radio value={false}>否</Radio>
                                    <Radio value={true}>是</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="涉及新就业形态"
                                name="is_new_job"
                            >
                                <Radio.Group>
                                    <Radio value={false}>否</Radio>
                                    <Radio value={true}>是</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Collapse.Panel>
                <Collapse.Panel key='2' header='劳动报酬与社保选项'>
                    <Row gutter={gutter}>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="劳动报酬"
                                name="is_cate10"
                            >
                                <Radio.Group>
                                    <Radio value={false}>否</Radio>
                                    <Radio value={true}>是</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="涉及农民工（人）"
                                name="is_cate11"
                            >
                                <Radio.Group>
                                    <Radio value={false}>否</Radio>
                                    <Radio value={true}>是</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider></Divider>
                    <Form.Item
                        label="社会保险"
                        name="is_cate20"
                    >
                        <Radio.Group>
                            <Radio value={false}>否</Radio>
                            <Radio value={true}>是</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Row gutter={gutter}>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="工伤保险"
                                name="is_cate21"
                            >
                                <Radio.Group>
                                    <Radio value={false}>否</Radio>
                                    <Radio value={true}>是</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="养老保险"
                                name="is_cate22"
                            >
                                <Radio.Group>
                                    <Radio value={false}>否</Radio>
                                    <Radio value={true}>是</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Collapse.Panel>
                <Collapse.Panel key='3' header='其他相关选项'>
                    <Row gutter={gutter}>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="工作时间及休假"
                                name="is_cate30"
                            >
                                <Radio.Group>
                                    <Radio value={false}>否</Radio>
                                    <Radio value={true}>是</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="解除、终止劳动合同"
                                name="is_cate40"
                            >
                                <Radio.Group>
                                    <Radio value={false}>否</Radio>
                                    <Radio value={true}>是</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="履行聘用合同"
                                name="is_cate50"
                            >
                                <Radio.Group>
                                    <Radio value={false}>否</Radio>
                                    <Radio value={true}>是</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="解除人事关系"
                                name="is_cate60"
                            >
                                <Radio.Group>
                                    <Radio value={false}>否</Radio>
                                    <Radio value={true}>是</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="其他"
                                name="is_cate70"
                            >
                                <Radio.Group>
                                    <Radio value={false}>否</Radio>
                                    <Radio value={true}>是</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) => prevValues.is_cate70 !== curValues.is_cate70}
                    >
                        {({ getFieldValue }) => {
                            let isShow: boolean = getFieldValue('is_cate70');
                            if (isShow) {
                                return (
                                    <Form.Item
                                        label="其他情况"
                                        name="cate70_reason"
                                        rules={[{ required: true, message: '请填写其他情况' }]}
                                    >
                                        <Input maxLength={100} />
                                    </Form.Item>
                                );
                            }
                        }}
                    </Form.Item>
                </Collapse.Panel>
            </Collapse> */}

            <Divider></Divider>

            <Row gutter={gutter}>
                {/* <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="调解结案涉及劳动者人数"
                        name="mediate_involve_people"
                    >
                        <InputNumber style={{ width: '50%' }} min={0} precision={0} addonAfter="人" />
                    </Form.Item>
                </Col> */}
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="调解成功金额"
                        name="involved_amount"
                    >
                        <InputNumber style={{ width: '50%' }} min={0} precision={2} addonAfter="元" />
                    </Form.Item>
                </Col>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => prevValues.involved_amount !== curValues.involved_amount}
                >
                    {({ getFieldValue }) => {
                        let involved_amount: number = getFieldValue('involved_amount');
                        if (involved_amount > 0) {
                            return (
                                <>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            label="付款情况"
                                            name="is_pay"
                                            rules={[{ required: true, message: '请选择付款情况' }]}
                                        >
                                            <Select showArrow>
                                                <Option key="1" value={true}>已付款</Option>
                                                <Option key="2" value={false}>未付款</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        {screens.xs ?
                                            <Form.Item
                                                label="付款时间"
                                                shouldUpdate={(prevValues, curValues) => prevValues.pay_time !== curValues.pay_time}
                                                required
                                            >
                                                {({ getFieldValue, setFieldValue }) => {
                                                    let pay_time = getFieldValue('pay_time');

                                                    return (
                                                        <DatePickerMobile
                                                            visible={payDateVisible}
                                                            value={pay_time ? dayjs(pay_time).toDate() : null}
                                                            onClose={() => {
                                                                setPayDateVisible(false)
                                                            }}
                                                            onConfirm={(value: PickerDate) => {
                                                                setFieldValue('pay_time', dayjs(value));
                                                            }}
                                                        >
                                                            {value =>
                                                                <>
                                                                    <Button
                                                                        style={{ marginRight: 10 }}
                                                                        onClick={() => {
                                                                            setPayDateVisible(true)
                                                                        }}
                                                                    >选择</Button>
                                                                    {value ? dayjs(value).format('YYYY年MM月DD日') : '请选择时间'}
                                                                </>
                                                            }
                                                        </DatePickerMobile>
                                                    );
                                                }}

                                            </Form.Item>
                                            : <Form.Item
                                                label="付款时间"
                                                name="pay_time"
                                                rules={[{ required: true, message: '请选择付款时间' }]}
                                            >
                                                <DatePicker
                                                    style={{ 'width': '100%' }}
                                                    format='YYYY-MM-DD'
                                                />
                                            </Form.Item>
                                        }
                                    </Col>


                                </>
                            );
                        } else if (involved_amount === 0) {
                            return <Col span={24}>
                                <Form.Item
                                    label="金额情况说明"
                                    name='pay_content'
                                    rules={[{ required: true, message: '请填写金额情况说明' }]}
                                >
                                    <TextArea rows={3} />
                                </Form.Item>
                            </Col>;
                        }
                    }}
                </Form.Item>
            </Row>


            {/* <Collapse bordered={true}>
                <Collapse.Panel key='1' header='协议相关选项'>
                    <Form.Item
                        label="达成调解协议及和解"
                        name="is_result10"
                    >
                        <Radio.Group>
                            <Radio value={false}>否</Radio>
                            <Radio value={true}>是</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Row gutter={gutter}>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="仲裁审查确认"
                                name="is_result11"
                            >
                                <Radio.Group>
                                    <Radio value={false}>否</Radio>
                                    <Radio value={true}>是</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="司法确认"
                                name="is_result12"
                            >
                                <Radio.Group>
                                    <Radio value={false}>否</Radio>
                                    <Radio value={true}>是</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        label="调解不成出具调解意见书"
                        name="is_result20"
                    >
                        <Radio.Group>
                            <Radio value={false}>否</Radio>
                            <Radio value={true}>是</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="其他"
                        name="is_result30"
                    >
                        <Radio.Group>
                            <Radio value={false}>否</Radio>
                            <Radio value={true}>是</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) => prevValues.is_result30 !== curValues.is_result30}
                    >
                        {({ getFieldValue }) => {
                            let isShow: boolean = getFieldValue('is_result30');
                            if (isShow) {
                                return (
                                    <Form.Item
                                        label="其他情况"
                                        name="result30_reason"
                                        rules={[{ required: true, message: '请填写其他情况' }]}
                                    >
                                        <Input maxLength={100} />
                                    </Form.Item>
                                );
                            }
                        }}
                    </Form.Item>
                </Collapse.Panel>
            </Collapse> */}

            <Divider></Divider>


            <Form.Item
                label="最终申请人意见"
                name='staff_attitude'
            >
                <TextArea rows={3} />
            </Form.Item>

            <Form.Item
                label="最终被申请人意见"
                name='company_attitude'
            >
                <TextArea rows={3} />
            </Form.Item>

            <Form.Item
                label="模板信息选择"
            >
                <Radio.Group defaultValue={''} onChange={(e) => {
                    form.setFieldValue('mediate_result', e.target.value);
                }}>
                    <Radio value={''}>无</Radio>
                    {template?.map(option => (
                        <Radio value={option.content}>{option.title}</Radio>
                    ))}
                </Radio.Group>
            </Form.Item>

            <Form.Item
                label="调解结果"
                name='mediate_result'
            >
                <TextArea rows={3} />
            </Form.Item>

            <Form.Item
                label="处理结果"
                shouldUpdate={(prevValues, curValues) => prevValues.processing_result !== curValues.processing_result}
            >
                {({ getFieldValue, setFieldValue }) => {
                    let processingResultOptions: IProcessingResultOption[] = getFieldValue('processing_result');
                    return (
                        <>
                            {
                                processingResultOptions?.map((item, index) => (
                                    <Form.Item
                                        shouldUpdate
                                    >
                                        <Checkbox checked={item.selected} onChange={(e) => {
                                            processingResultOptions.forEach(e => {
                                                e.selected = false;
                                                e.option?.forEach(subE => subE.selected = false);
                                            });
                                            setFieldValue('processing_result', processingResultOptions);
                                            setFieldValue(['processing_result', index, 'selected'], e.target.checked);
                                        }}>{item.label}</Checkbox>
                                        <div style={{ marginLeft: '2em' }}>
                                            {
                                                item.option && item.option.map((subItem, subIndex) => (
                                                    <div>
                                                        <Checkbox checked={subItem.selected} onChange={(e) => {
                                                            item.option!.forEach(e => e.selected = false);
                                                            if (e.target.checked) {
                                                                processingResultOptions.forEach(e => {
                                                                    e.selected = false;
                                                                    e.option?.forEach(subE => subE.selected = false);
                                                                });
                                                                setFieldValue('processing_result', processingResultOptions);
                                                                setFieldValue(['processing_result', index, 'selected'], e.target.checked);
                                                            }
                                                            setFieldValue(['processing_result', index, 'option'], item.option);
                                                            setFieldValue(['processing_result', index, 'option', subIndex, 'selected'], e.target.checked);
                                                        }}>{subItem.label}</Checkbox>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </Form.Item>
                                ))
                            }
                        </>
                    );
                }}
            </Form.Item>

            {/* <Form.Item label='附件上传'>
                <NewUpload></NewUpload>
            </Form.Item> */}
            <Row gutter={gutter}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    {
                        screens.xs ?
                            <Form.Item
                                label="结案时间"
                                shouldUpdate={(prevValues, curValues) => prevValues.closing_date !== curValues.closing_date}
                            >
                                {({ getFieldValue, setFieldValue }) => {
                                    let closing_date = getFieldValue('closing_date');

                                    return (
                                        <DatePickerMobile
                                            visible={closingDateVisible}
                                            onClose={() => {
                                                setClosingDateVisible(false)
                                            }}
                                            value={closing_date ? dayjs(closing_date).toDate() : null}
                                            onConfirm={(value: PickerDate) => {
                                                form.setFieldValue('closing_date', dayjs(value));
                                            }}
                                        >
                                            {value =>
                                                <>
                                                    <Button
                                                        style={{ marginRight: 10 }}
                                                        onClick={() => {
                                                            setClosingDateVisible(true)
                                                        }}
                                                    >选择</Button>
                                                    {value ? dayjs(value).format('YYYY年MM月DD日') : '请选择时间'}
                                                </>
                                            }
                                        </DatePickerMobile>
                                    );
                                }}
                            </Form.Item>
                            : <Form.Item
                                label="结案时间"
                                name="closing_date"
                            >
                                <DatePicker
                                    style={{ 'width': '100%' }}
                                    format='YYYY-MM-DD'
                                />
                            </Form.Item>
                    }
                </Col>
            </Row>

            <Divider orientation="left" plain>调解附件</Divider>
            <Form.Item
                noStyle
                shouldUpdate
            >
                {({ getFieldValue }) => {
                    let attachFilesData: IAttachFilesData[] = getFieldValue('mediate_attach_files_data') ?? [];
                    return (
                        <>
                            {
                                attachFilesData.map(item => (
                                    <Form.Item
                                        label={item.name}
                                    >
                                        <Upload
                                            multiple
                                            fileList={attachments[item.value]}
                                            beforeUpload={checkFileSize}
                                            onChange={(info) => onFileChange(info, item.value)}
                                            customRequest={customRequest}
                                            showUploadList={{ showDownloadIcon: true }}
                                            onDownload={onAttachmentDownload}
                                        >
                                            <Button icon={<UploadOutlined />}>点击上传附件</Button>
                                        </Upload>
                                    </Form.Item>
                                ))
                            }
                        </>
                    );
                }}
            </Form.Item>

            <Form.Item>
                <Button block type='primary' size='large' loading={confirmLoading} onClick={submit}>提交</Button>
            </Form.Item>
        </Form>
    );



    return (
        <div className={screens.xs ? 'inner-page mobile' : ''}>
            {
                loading
                    ? <Skeleton />
                    : screens.xs
                        ? <div>
                            {InfoItem}
                            {
                                detail?.attach_files_data_info && detail.attach_files_data_info.length > 0
                                    ? <>
                                        <Divider></Divider>
                                        <Tabs>
                                            {
                                                detail?.attach_files_data_info?.map(item => (
                                                    <Tabs.Tab title={item.name} key={item.value}>
                                                        <Space wrap>
                                                            {
                                                                item.files_info.length > 0
                                                                    ? item.files_info.map((file, index) => (
                                                                        <Image src={file.attach_url_link} width={100} height={100} onClick={() => {
                                                                            ImageViewer.Multi.show({ images: item.files_info.map(e => e.attach_url_link), defaultIndex: index });
                                                                        }} />
                                                                    ))
                                                                    : '暂无资料'
                                                            }
                                                        </Space>
                                                    </Tabs.Tab>
                                                ))
                                            }
                                        </Tabs>
                                    </>
                                    : <ErrorBlock status='empty' title="无登记附件资料" description={null} />
                            }
                            <Divider></Divider>
                            {FormItem}
                        </div>
                        : <Row gutter={gutter}>
                            <Col span={12}>
                                <div style={{
                                    width: "100%",
                                    height: "100%",
                                }}>
                                    <div className='attachment_fixed'>
                                        <h2>附件预览</h2>
                                        {
                                            detail?.attach_files_data && attachmentSegmented &&
                                            <Segmented
                                                size="large"
                                                options={detail?.attach_files_data_info?.map(item => item.name)}
                                                value={attachmentSegmented}
                                                onChange={(value) => {
                                                    setAttachmentIndex(0);
                                                    setAttachmentSegmented(value);
                                                }}
                                                style={{ marginBottom: 15 }}
                                            />
                                        }
                                        {
                                            nowAttachment && nowAttachment.files_info.length > 0
                                                ? <Row>
                                                    <Col span={2}>
                                                        <div className='switch_button'>
                                                            <LeftCircleOutlined className='switch_button_item' onClick={() => switchAttachmentIndex(-1)} />
                                                            <h3>上一页</h3>
                                                        </div>
                                                    </Col>
                                                    <Col span={20}>
                                                        <Image
                                                            width={'100%'}
                                                            src={nowAttachment?.files_info[attachmentIndex].attach_url_link}
                                                            fallback={cantPreviewImage}
                                                        />
                                                        <div className='bottom_text'>
                                                            <h3>({attachmentIndex + 1}/{nowAttachment?.files.length}){nowAttachment?.files_info[attachmentIndex].attach_name}</h3>
                                                            <Button type="text" icon={<DownloadOutlined />} href={nowAttachment?.files_info[attachmentIndex].attach_url_link} target="_blank" />
                                                        </div>
                                                    </Col>
                                                    <Col span={2}>
                                                        <div className='switch_button'>
                                                            <RightCircleOutlined className='switch_button_item' onClick={() => switchAttachmentIndex(1)} />
                                                            <h3>下一页</h3>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                : <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Image
                                                        preview={false}
                                                        height={'50%'}
                                                        src={noAttachmentImage}
                                                    />
                                                </div>
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col span={12}>
                                {InfoItem}
                                <Divider orientation='left'>调解表单</Divider>
                                {FormItem}
                            </Col>
                        </Row>
            }
        </div>
    );
}

export default PreArbitrationConciliationDetailFormModal;