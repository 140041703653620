import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// antd
import { Grid, Button, Form, Input, message, Select, Skeleton, Space, Divider, InputRef } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { getPreArbitrationTableContent, IFileIndexTableForm, saveFileIndexTable } from '../../../apis/preArbitration';
import { getDictList, IDictData } from '../../../apis/common';

const { useBreakpoint } = Grid;
const { Option } = Select;

interface CollectionCreateFormProps {
    data?: { id: number },
    onFinish?: () => void;
}

function FileIndexTableFormModal({
    data,
    onFinish,
}: CollectionCreateFormProps) {
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 表单数据
    const [form] = Form.useForm<IFileIndexTableForm>();

    // 材料
    const [mediateAttachmentTag, setMediateAttachmentTag] = useState<string[]>([]);
    const inputRef = useRef<InputRef>(null);
    const [fileName, setFileName] = useState('');
    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileName(event.target.value);
    };
    const addItem = (e: React.MouseEvent<HTMLAnchorElement>, name: number) => {
        e.preventDefault();
        if (!fileName) return;
        setMediateAttachmentTag([fileName, ...mediateAttachmentTag]);
        form.setFieldValue(['list', name, 'name'], fileName);
        setFileName('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };


    // 初始化加载
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        form.resetFields();

        let pre_worksheet_id = data?.id ?? Number(searchParams.get('id'));
        if (pre_worksheet_id) {
            form.setFieldValue('pre_worksheet_id', pre_worksheet_id);
            setLoading(true);

            getDictList([
                'mediate_attachment_tag',
                'attachment_tag',
                'postpone_attachment_tag'
            ]).then(res => {
                let temp = [
                    ...res.data.mediate_attachment_tag!.options.map(item => item.name),
                    ...res.data.attachment_tag!.options.map(item => item.name),
                    ...res.data.postpone_attachment_tag!.options.map(item => item.name),
                ];
                setMediateAttachmentTag(Array.from(new Set(temp)));
            });

            getPreArbitrationTableContent({
                pre_worksheet_id,
                form_type: 1,
            }).then(res => {
                console.log(res);
                form.setFieldsValue({
                    ...res.data
                });
            }).finally(() => {
                setLoading(false);
            })
        }

    }, [data, searchParams]);

    // 提交
    const submit = async () => {
        try {
            await form.validateFields();
        } catch (e: any) {
            message.warning(e.errorFields[0].errors[0]);
            return;
        }

        setConfirmLoading(true);
        let _form = form.getFieldsValue(true);
        try {
            let data = {
                ..._form,
            }

            let res = await saveFileIndexTable(data);
            message.success(res.msg);
            if (onFinish) {
                onFinish();
            } else {
                navigate(-1);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    const FormItem = (
        <Form
            form={form}
            layout="vertical"
            autoComplete="off"
        >
            <Form.List
                name="list"
                rules={[
                    {
                        validator: async (_, names) => {
                            if (!names || names.length < 1) {
                                return Promise.reject(new Error('请至少添加一项'));
                            }
                        },
                    },
                ]}
            >
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <Space>
                                <Form.Item
                                    {...restField}
                                    label="材料名称"
                                    name={[name, 'name']}
                                    rules={[{ required: true, message: '请填写材料名称' }]}
                                >
                                    <Select
                                        showArrow
                                        style={{ width: 500 }}
                                        options={mediateAttachmentTag.map(item => ({ label: item, value: item }))}
                                        dropdownRender={menu => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: '8px 0' }} />
                                                <Space style={{ padding: '0 8px 4px' }}>
                                                    <Input
                                                        placeholder="请输入材料名称"
                                                        ref={inputRef}
                                                        value={fileName}
                                                        onChange={onNameChange}
                                                    />
                                                    <Button type="text" icon={<PlusOutlined />} onClick={(e) => addItem(e as any, name)}>
                                                        添加选项
                                                    </Button>
                                                </Space>
                                            </>
                                        )}
                                    >
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    label="页次"
                                    name={[name, 'pagination']}
                                >
                                    <Input maxLength={10} />
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)} />
                            </Space>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                添加材料
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>

            <Form.Item>
                <Button block type='primary' size='large' loading={confirmLoading} onClick={submit}>提交</Button>
            </Form.Item>
        </Form>
    )


    return (
        <div className={screens.xs ? 'inner-page mobile' : ''}>
            {
                loading
                    ? <Skeleton />
                    : <>
                        {FormItem}
                    </>
            }
        </div>
    );
}

export default FileIndexTableFormModal;