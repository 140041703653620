import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// antd
import { Skeleton, Checkbox } from 'antd';

import { getPreArbitrationTableContent, ILaborDisputeHandlingTableData, ILaborDisputeHandlingTableDataMediate, ILaborDisputeHandlingTableForm, INaturalPeople, IPreArbitrationCompany, saveLaborDisputeHandlingTable } from '../../../../apis/preArbitration';
import dayjs from 'dayjs';


function LaborDisputeHandlingTablePrint() {
    const tableMinHeight = "2.4em";

    const [searchParams, setSearchParams] = useSearchParams();

    // 表单数据
    const [detail, setDetail] = useState<ILaborDisputeHandlingTableData>();

    // 初始化加载
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = "劳动人事争议调解登记处理表";

        let pre_worksheet_id = Number(searchParams.get('id'));
        if (pre_worksheet_id) {
            setLoading(true);
            getPreArbitrationTableContent({
                pre_worksheet_id,
                form_type: 3,
            }).then(res => {
                console.log(res);
                let data: ILaborDisputeHandlingTableData = res.data.detail!;
                setDetail(data);
            }).finally(() => {
                setLoading(false);
            })
        }

    }, [searchParams]);

    return (
        <div>
            {
                loading
                    ? <Skeleton />
                    : <div style={{ width: "707px", margin: 'auto', textAlign: 'center' }}>
                        <h2 style={{ margin: '20px 0' }}>劳动人事争议调解登记处理表</h2>
                        <div style={{ width: "100%", display: 'flex', justifyContent: 'space-between', fontSize: 16 }}>
                            <div style={{ flex: 1, textAlign: 'start' }}>案号：{detail?.case_number}</div>
                            <div style={{ flex: 1, textAlign: 'end' }}>登记时间：{detail?.closing_date_format}</div>
                        </div>
                        <table className='preArbitration_table' border={1} style={{ width: "707px", margin: 'auto' }}>
                            <tr>
                                <td rowSpan={2} style={{ width: 20 }}>当事人</td>
                                {
                                    detail?.plaintiff_subject_type === 1 &&
                                    <>
                                        <td>姓名</td>
                                        <td>{(detail?.plaintiff_default as INaturalPeople).real_name}</td>
                                        <td>性别</td>
                                        <td>{(detail?.plaintiff_default as INaturalPeople).sex_text}</td>
                                        <td>身份证号码</td>
                                        <td>{(detail?.plaintiff_default as INaturalPeople).idcard}</td>
                                    </>
                                }
                            </tr>
                            <tr>
                                {
                                    detail?.plaintiff_subject_type === 1 &&
                                    <>
                                        <td>确认有效送达地址</td>
                                        <td colSpan={3}>{(detail?.plaintiff_default as INaturalPeople).effect_service_address}</td>
                                        <td>联系电话</td>
                                        <td>{(detail?.plaintiff_default as INaturalPeople).mobile}</td>
                                    </>
                                }
                            </tr>
                            <tr>
                                <td rowSpan={3}>用人单位</td>
                                {
                                    detail?.defendant_subject_type === 2 &&
                                    <>
                                        <td>全称</td>
                                        <td colSpan={5} style={{ height: tableMinHeight }}>{(detail?.defendant_default as IPreArbitrationCompany).company_name}</td>
                                    </>
                                }
                            </tr>
                            <tr>
                                {
                                    detail?.defendant_subject_type === 2 &&
                                    <>
                                        <td>地址</td>
                                        <td colSpan={5}>{(detail?.defendant_default as IPreArbitrationCompany).address}</td>
                                    </>
                                }
                            </tr>
                            <tr>
                                {
                                    detail?.defendant_subject_type === 2 &&
                                    <>
                                        <td>法定代表人</td>
                                        <td>{(detail?.defendant_default as IPreArbitrationCompany).legal_person}</td>
                                        <td>联系人</td>
                                        <td>{(detail?.defendant_default as IPreArbitrationCompany).contact_name}</td>
                                        <td>联系电话</td>
                                        <td>{(detail?.defendant_default as IPreArbitrationCompany).contact_mobile}</td>
                                    </>
                                }
                            </tr>
                            <tr>
                                <td>请求事项及事实理由</td>
                                <td colSpan={6}>
                                    <div>
                                        <div>（建议列明具体事项、金额，简要说明事实理由。）</div>

                                        <div style={{ margin: '50px 0', whiteSpace: 'pre-wrap', textAlign: 'start' }}>{detail?.fact_reason}</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>收件人</td>
                                <td colSpan={2}>{detail?.receiver}</td>
                                <td>收件日期</td>
                                <td colSpan={2}>{detail?.receive_at}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>案件承办人</td>
                                <td colSpan={2}>{detail?.undertaker}</td>
                                <td>承办日期</td>
                                <td colSpan={2}>{detail?.undertake_at}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>调解方式</td>
                                <td colSpan={5}>
                                    <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                        {detail?.mediate_type?.map(e => (
                                            <div><Checkbox checked={e.selected} disabled />{e.label}
                                                {
                                                    e.key === 3 &&
                                                    <span style={{ textDecorationLine: 'underline' }}>__{e.selected ? e.extra : ''}__</span>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>调解过程基本情况</td>
                                <td colSpan={5} style={{ textAlign: 'start' }}>
                                    <div style={{ minHeight: 500, whiteSpace: 'pre-wrap', lineHeight: '1.5em' }}>
                                        {detail?.mediate_basic_situation}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>调解结案初步意见</td>
                                <td colSpan={5}>
                                    <div style={{ minHeight: 300, whiteSpace: 'pre-wrap', textIndent: '2em', textAlign: 'start', lineHeight: "1.5em" }}>
                                        <div>
                                            <Checkbox checked={false} disabled /><strong>一、调解成功</strong>
                                        </div>
                                        {/* <div>于{detail?.last_mediate_date_format ?? '_____年___月___日'}双方当事人达成调解协议（详见调解协议），调解机构出具《调解协议书》。</div>
                                        <div>建议转&nbsp;&nbsp;&nbsp;&nbsp;<Checkbox checked={false} disabled /><strong>仲裁审查确认；</strong><Checkbox checked={false} disabled /><strong>司法确认。</strong></div> */}
                                        <div style={{ whiteSpace: 'pre-wrap' }}>{detail?.mediate_success_content}</div>

                                        <div style={{ marginTop: 30 }}>
                                            <Checkbox checked={false} disabled /><strong>二、调解不成功</strong>
                                        </div>
                                        <div>双方当事人因存在下列第______项情形，调解不成功，调解机构出具《调解意见书》。</div>
                                        <div>（一）当事人自行和解：</div>
                                        <div>
                                            <Checkbox checked={false} disabled />和解协议附后；
                                        </div>
                                        <div>
                                            <Checkbox checked={false} disabled />口头协议内容记录附后；
                                        </div>
                                        <div>
                                            <Checkbox checked={false} disabled />调解员已于_____年___月___日___时向_______致电_____________(电话号码)，核实情况为：
                                        </div>

                                        <div style={{ marginTop: 30 }}>
                                            （二）（申请人、被申请人）于_____年___月___日表示拒绝或者退出调解。
                                        </div>
                                        <div>（三）自调解组织收到调解申请之日起15日内或者双方当事人同意延长的期限内未达成调解协议。</div>
                                        <div>（四）无法继续调解的其他情形______________________________________。</div>


                                        <div style={{ marginTop: 50, display: 'flex', justifyContent: 'space-between' }}>
                                            <span>调解员：</span>
                                            <span style={{ marginRight: 50 }}>日期：{detail?.closing_date_format ?? '____________________'}</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>调解结案审批意见</td>
                                <td colSpan={5}>
                                    <div style={{ minHeight: 100, whiteSpace: 'pre-wrap', textAlign: 'start', }}>
                                        <div>
                                            同意{detail?.case_number}于 {detail?.closing_date_format ?? <span><span style={{ marginLeft: 50 }}>年</span><span style={{ marginLeft: 50 }}>月</span><span style={{ marginLeft: 50 }}>日</span></span>}  结案。
                                        </div>
                                        <div style={{ marginTop: 50 }}>
                                            <span>审批人签名：</span>
                                            <span style={{ marginLeft: 200 }}>日期：</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
            }
        </div>
    );
}

export default LaborDisputeHandlingTablePrint;