import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// antd
import { Skeleton } from 'antd';

import { getPreArbitrationTableContent, ILaborDisputeMediationAgreementData, ILaborDisputeMediationAgreementTableForm, IPreArbitrationCoverTableData, IPreArbitrationCoverTableForm, saveLaborDisputeMediationAgreementTable, savePreArbitrationCoverTable } from '../../../../apis/preArbitration';
import dayjs from 'dayjs';

function LaborDisputeMediationAgreementTablePrint() {
    const [searchParams, setSearchParams] = useSearchParams();

    // 表单数据
    const [detail, setDetail] = useState<ILaborDisputeMediationAgreementData>();

    // 初始化加载
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let pre_worksheet_id = Number(searchParams.get('id'));
        if (pre_worksheet_id) {
            setLoading(true);
            getPreArbitrationTableContent({
                pre_worksheet_id,
                form_type: 6,
            }).then(res => {
                console.log(res);
                let data: ILaborDisputeMediationAgreementData = res.data.detail!;
                setDetail(data);
            }).finally(() => {
                setLoading(false);
            })
        }

    }, [searchParams]);

    return (
        <div>
            {
                loading
                    ? <Skeleton />
                    : <div style={{ width: "707px", height: 1000, margin: 'auto' }}>
                        <h2 style={{ margin: '20px 0', textAlign: 'center' }}>劳动人事争议调解协议书</h2>
                        <div style={{ fontSize: 16 }}>
                            <div>申请人：{detail?.plaintiff_name}</div>
                            <div>委托代理人：{detail?.plaintiff_agent}</div>
                            <div>被申请人：{detail?.defendant_name}</div>
                            <div>委托代理人：{detail?.defendant_agent}</div>
                            <div style={{ textIndent: '2em' }}>

                                <div>当事人因____<span style={{ textDecorationLine: 'underline' }}>{detail?.reason}</span>_____发生争议，在调解组织主持下，进行了自愿、平等协商，自愿达成如下协议：</div>
                                {
                                    detail?.mediate_result
                                        ? <>
                                            {detail?.mediate_result.split('\n').map(str => (<div>{str}</div>))}
                                        </>
                                        : <>
                                            <div>
                                                一、双方协商一致，被申请人于____年__月__日前一次性支付申请人____年__月__日到____年__月__日工资合计共________元。
                                            </div>
                                            <div>
                                                二、申、被双方就本案以及劳动关系存续期间产生的任何争议均已终结，任何一方不得以任何理由向任何部门或对方主张权利，
                                            </div>
                                            <div style={{ fontWeight: 'bold' }}>
                                                履行方式与时间：{detail?.content1 ?? '被申请人按照调解协议签订支付时间通过银行转账支付该笔款项'}
                                            </div>
                                        </>
                                }
                                <div style={{ whiteSpace: 'pre-wrap' }}>
                                    {detail?.additional_clause}
                                </div>
                                <div>本调解协议自双方当事人签名或者盖章并经调解员签名、调解组织盖章后生效。调解协议对双方当事人具有约束力，当事人应当履行。</div>
                                <div>本协议一式__<span style={{ textDecorationLine: 'underline' }}>{detail?.part}</span>__份，当事人、调解组织各持一份。</div>
                                <div style={{ margin: '50px 0' }}>申请人（签名并按指模或盖章）：</div>
                                <div style={{ margin: '50px 0' }}>被申请人（签名并按指模或盖章）：</div>
                            </div>
                            <div style={{ textAlign: 'end', marginRight: 100 }}>
                                <div style={{ margin: '30px 100px' }}>调解员（签名）：</div>
                                <div style={{ marginRight: 50 }}>
                                    <div>（调解组织印章）</div>
                                    <div><span>年</span><span style={{ marginLeft: 50 }}>月</span><span style={{ marginLeft: 50 }}>日</span></div>
                                </div>
                            </div>
                            <div style={{ marginTop: 50 }}>注：生效的调解协议对双方当事人具有约束力，当事人应当履行。双方当事人可以自调解协议生效之日起15日内共同向有管辖权的劳动纠纷仲裁机构提出审查确认申请。双方当事人未提出审查确认申请，一方当事人在约定的期限内不履行调解协议的，另一方当事人可以依法申请仲裁。当事人也可以依法向人民法院申请司法确认。</div>
                        </div>
                    </div>
            }
        </div>
    );
}

export default LaborDisputeMediationAgreementTablePrint;