import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { ChangeEvent, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDebounce, useDebounceFn } from 'ahooks';

// apis
import { getDictList, IDictData, Pager, uploadFile } from '../../apis/common';
import { getAllUser, IAllUserData } from '../../apis/user';
import { archivedPreArbitration, downloadPreArbitrationBlankTable, downloadPreArbitrationTemplate, exportPreArbitrationList, exportZip, getMyPreArbitrationStat, getPreArbitrationList, getPreArbitrationSelectRegion, getUpPayList, IImportPreArbitrationForm, importPreArbitration, IMyPreArbitrationStat, INaturalPeople, IPreArbitrationCompany, IPreArbitrationListData, IPreArbitrationListSearchForm, IPreArbitrationSelectRegionData, IResetCaseNumberForm, IRresetCaseNumberResData, ITransferPreArbitrationForm, IUpPayListData, resetCaseNumber, transferPreArbitration, updateMediateStatus } from '../../apis/preArbitration';
import { getHasAuth, globalAuthSigns } from '../../utils/auth';

// antd
import { Space, Table, Dropdown, Menu, Select, Button, Modal, Card, Input, Pagination, Empty, Grid, message, Form, Cascader, Alert, Tooltip } from 'antd';
import { CloseOutlined, BellFilled, FileAddOutlined, AreaChartOutlined } from '@ant-design/icons';
import DatePicker from '../../components/customAntd/DatePicker';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ActionSheet, Cascader as CascaderMobile, CascaderOption, ImageUploader, ImageUploadItem, Popup } from 'antd-mobile';

import PreArbitrationFormModal from './actions/preArbitrationForm';
import PreArbitrationConciliationDetailFormModal from './conciliation/preArbitrationConciliationForm';
import PreArbitrationTablesModal from './tables/tableList';
import PreArbitrationPostponeFormModal from './actions/preArbitrationPostponeForm';
import PreArbitrationPlaintiffFormModal from './actions/preArbitrationPlaintiffForm';
import PreArbitrationDefendantFormModal from './actions/preArbitrationDefendantForm';
import PreArbitrationThirdPartyFormModal from './actions/preArbitrationThirdPartyForm';
import { Action } from 'antd-mobile/es/components/action-sheet';
import PreArbitrationAttachmentFormModal from './actions/preArbitrationAttachmentForm';
import PreArbitrationStatisticsListModal from './actions/preArbitrationStatistics';
import PreArbitrationRegionStatisticsListModal from './actions/preArbitrationRegionStatistics';
import PreArbitrationRefuseAnalysisListModal from './actions/preArbitrationRefuseAnalysis';

const { Column } = Table;
const { Option } = Select;
const { useBreakpoint } = Grid;
const { RangePicker } = DatePicker;
const { confirm } = Modal;

function PreArbitrationList() {
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        let conciliation_id = Number(searchParams.get('conciliation'));
        if (conciliation_id) {
            toConciliation(conciliation_id);
        }
    }, [searchParams]);

    // 组件全局权限引用
    const [hasPreWorksheetAddAuth, setHasPreWorksheetAddAuth] = useState(false);
    const [hasPreWorksheetMediateAuth, setHasPreWorksheetMediateAuth] = useState(false);
    const [hasPreWorksheetPostponeAuth, setHasPreWorksheetPostponeAuth] = useState(false);
    const [hasPreWorksheetFormAuth, setHasPreWorksheetFormAuth] = useState(false);
    const [hasPreWorksheetArchivedAuth, setHasPreWorksheetArchivedAuth] = useState(false);
    const [hasPreWorksheetImportAuth, setHasPreWorksheetImportAuth] = useState(false);
    const [hasPreWorksheetAttachmentAuth, setHasPreWorksheetAttachmentAuth] = useState(false);
    const [hasPreWorksheetHandleAuth, setHasPreWorksheetHandleAuth] = useState(false);
    const [hasPreWorksheetHandleTransferAuth, setHasPreWorksheetHandleTransferAuth] = useState(false);
    const [hasPreWorksheetStatOrganUserAuth, setHasPreWorksheetStatOrganUserAuth] = useState(false);
    const [hasPreWorksheetStatRegionAuth, setHasPreWorksheetStatRegionAuth] = useState(false);
    const [hasPreWorksheetExportZipAuth, setHasPreWorksheetExportZipAuth] = useState(false);
    const [hasPreWorksheetHandleResetCaseNumberAuth, setHasPreWorksheetHandleResetCaseNumberAuth] = useState(false);
    const [hasPreWorksheetStatRefuseAnalysisAuth, setHasPreWorksheetStatRefuseAnalysisAuth] = useState(false);
    useEffect(() => {
        getHasAuth([
            globalAuthSigns.preWorksheet_add,
            globalAuthSigns.preWorksheet_mediate,
            globalAuthSigns.preWorksheet_postpone,
            globalAuthSigns.preWorksheet_form,
            globalAuthSigns.preWorksheet_archived,
            globalAuthSigns.preWorksheet_import,
            globalAuthSigns.preWorksheet_attachment,
            globalAuthSigns.preWorksheet_handle,
            globalAuthSigns.preWorksheet_handle_transfer,
            globalAuthSigns.preWorksheet_stat_organUser,
            globalAuthSigns.preWorksheet_stat_region,
            globalAuthSigns.preWorksheet_handle_exportZip,
            globalAuthSigns.preWorksheet_handle_resetCaseNumber,
            globalAuthSigns.preWorksheet_stat_refuseAnalysis
        ]).then(value => {
            setHasPreWorksheetAddAuth(value[0]);
            setHasPreWorksheetMediateAuth(value[1]);
            setHasPreWorksheetPostponeAuth(value[2]);
            setHasPreWorksheetFormAuth(value[3]);
            setHasPreWorksheetArchivedAuth(value[4]);
            setHasPreWorksheetImportAuth(value[5]);
            setHasPreWorksheetAttachmentAuth(value[6]);
            setHasPreWorksheetHandleAuth(value[7]);
            setHasPreWorksheetHandleTransferAuth(value[8]);
            setHasPreWorksheetStatOrganUserAuth(value[9]);
            setHasPreWorksheetStatRegionAuth(value[10]);
            setHasPreWorksheetExportZipAuth(value[11]);
            setHasPreWorksheetHandleResetCaseNumberAuth(value[12]);
            setHasPreWorksheetStatRefuseAnalysisAuth(value[13]);
        });
    }, []);

    // 搜索表单
    const [form, setForm] = useState<IPreArbitrationListSearchForm>({
        page: 1,
        size: 10,
        is_my_case: true,
        status: 10,
        street_ids: []
    });

    // 统计数据
    const [stat, setStat] = useState<IMyPreArbitrationStat>();

    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState<(IPreArbitrationListData)[]>([]);
    const [mListData, setMListData] = useState<(IPreArbitrationListData)[]>([]);
    const [mActions, setMActions] = useState<{
        visible: boolean,
        actions: Action[]
    }>({
        visible: false,
        actions: []
    });

    const [total, setTotal] = useState(0);
    const loadData = async (newForm?: IPreArbitrationListSearchForm) => {
        let tempForm = newForm ?? form;
        setLoading(true);
        try {
            let res = await getPreArbitrationList(tempForm);
            setTotal(res.data.total);
            setMListData(res.data.list.map(item => ({
                ...item,
                action: false
            })));
            setListData((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== res.data.total) {
                    newListData = new Array(res.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((tempForm.page! - 1) * tempForm.size, res.data.list.length, ...res.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }

        // 临时：关闭统计功能
        // getMyPreArbitrationStat().then(res => {
        //     setStat(res.data);
        // })
    }

    // 分页
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        let newForm = {
            ...form,
            page,
            size: pageSize,
        };
        setForm(newForm);
        loadData(newForm);
    }

    const resetData = () => {
        let newForm = {
            nav_type: 1,
            page: 1,
            size: 20,
            status: 10,
            is_my_case: true,
            street_ids: []
        }
        setForm(newForm);
        loadData(newForm);
    }

    // 街道筛选
    // const handleStreetMobileChange = (value: string[], extend: CascaderValueExtend) => {
    //     if (extend.items.length !== 4) {
    //         message.warning('地址请选择完整');
    //         return;
    //     }

    //     handleStreetChange(extend.items.map(item => Number(item!.value)));
    // }
    const handleStreetChange = (value: any[]) => {
        console.log(value);

        setForm((form) => {
            let newForm = {
                ...form,
                street: value,
                street_ids: value.map(item => item[3]),
            };
            loadData(newForm);
            return newForm;
        });
    }


    // 诉讼类别
    const [lawsuitType, setLawsuitType] = useState<IDictData>();
    const handleLawsuitTypeChange = (value: number) => {
        console.log(`selected ${value}`);
        setForm((form) => {
            let newForm = {
                ...form,
                lawsuit_type: value,
            };
            loadData(newForm);
            return newForm;
        });
    };


    // 接单时间段
    const handleReceiveChange = (_: any, formatString: [string, string]) => {
        if (!formatString[0] || !formatString[1]) {
            setForm((form) => {
                let newForm = {
                    ...form,
                    acceptance_date: undefined,
                };
                loadData(newForm);
                return newForm;
            });
        } else {
            setForm((form) => {
                let newForm = {
                    ...form,
                    acceptance_date: formatString,
                };
                loadData(newForm);
                return newForm;
            });
        }
    }

    // 结案日期
    const handleClosingDateChange = (_: any, formatString: [string, string]) => {
        if (!formatString[0] || !formatString[1]) {
            setForm((form) => {
                let newForm = {
                    ...form,
                    closing_date: undefined,
                };
                loadData(newForm);
                return newForm;
            });
        } else {
            setForm((form) => {
                let newForm = {
                    ...form,
                    closing_date: formatString,
                };
                loadData(newForm);
                return newForm;
            });
        }
    }

    // 问题类别
    const [questionType, setQuestionType] = useState<IDictData>();
    const handleQuestionTypeChange = (value: number) => {
        console.log(`selected ${value}`);
        setForm((form) => {
            let newForm = {
                ...form,
                question_type: value,
            };
            loadData(newForm);
            return newForm;
        });
    };

    // 我的案件
    const handleIsMyCaseChange = (value: boolean) => {
        console.log(`selected ${value}`);
        setForm((form) => {
            let newForm = {
                ...form,
                is_my_case: value,
            };
            loadData(newForm);
            return newForm;
        });
    };

    // 案件类别
    const [caseCategory, setCaseCategory] = useState<IDictData>();
    const handleQCaseCategoryChange = (value: number) => {
        console.log(`selected ${value}`);
        setForm((form) => {
            let newForm = {
                ...form,
                case_category: value,
            };
            loadData(newForm);
            return newForm;
        });
    };

    // 列表排序
    const handleOrderByChange = (values: string) => {
        if (values && values.split(" ").length === 2) {
            setForm((form) => {
                let newForm = {
                    ...form,
                    sort: values.split(" ")[0],
                    order: values.split(" ")[1],
                };
                loadData(newForm);
                return newForm;
            });
        } else {
            setForm((form) => {
                let newForm = {
                    ...form,
                    sort: undefined,
                    order: undefined,
                };
                loadData(newForm);
                return newForm;
            });
        }

    };

    // 状态搜索
    const [status, setStatus] = useState<IDictData>();
    const handleStatusChange = (value: number) => {
        console.log(`selected ${value}`);
        setForm((form) => {
            let newForm = {
                ...form,
                status: value,
            };
            loadData(newForm);
            return newForm;
        });
    };

    // 案件类型
    const [type, setType] = useState<IDictData>();
    const handleTypeChange = (value: number) => {
        console.log(`selected ${value}`);
        setForm((form) => {
            let newForm = {
                ...form,
                type: value,
            };
            loadData(newForm);
            return newForm;
        });
    };

    // 争议类型
    const [disputeType, setDisputeType] = useState<IDictData>();
    const handleDisputeTypeChange = (value: number) => {
        console.log(`selected ${value}`);
        setForm((form) => {
            let newForm = {
                ...form,
                dispute_type: value,
            };
            loadData(newForm);
            return newForm;
        });
    };

    useEffect(() => {
        getDictList(['pre_worksheet_status', 'worksheet_question_type', 'global_time_type', 'worksheet_lawsuit_type', 'pre_worksheet_case_category', 'pre_worksheet_type', 'pre_worksheet_dispute_type']).then(res => {
            setStatus(res.data.pre_worksheet_status);
            setQuestionType(res.data.worksheet_question_type);
            setLawsuitType(res.data.worksheet_lawsuit_type);
            setCaseCategory(res.data.pre_worksheet_case_category);
            setType(res.data.pre_worksheet_type);
            setDisputeType(res.data.pre_worksheet_dispute_type);
        });
    }, []);

    // 关键词搜索
    const keywordsDebouncedValue = useDebounce(form.keywords, { wait: 500 });
    // 案号搜索
    const caseNumberDebouncedValue = useDebounce(form.case_number, { wait: 500 });
    // 调解员搜索
    const mediatorDebouncedValue = useDebounce(form.mediator, { wait: 500 });
    useEffect(() => {
        loadData(form);
    }, [keywordsDebouncedValue, caseNumberDebouncedValue, mediatorDebouncedValue]);
    const handleKeywordsChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        let newForm = {
            ...form,
            keywords: value.target.value
        };
        setForm(newForm);
    }
    const handleCaseNumberChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        let newForm = {
            ...form,
            case_number: value.target.value
        };
        setForm(newForm);
    }
    const handleMediatorChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        let newForm = {
            ...form,
            mediator: value.target.value
        };
        setForm(newForm);
    }

    // 导出数据
    const exportData = () => {
        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '确定要导出当前列表吗？',
            onOk() {
                setLoading(true);
                exportPreArbitrationList(form).then(res => {
                    setTimeout(() => {
                        window.open(res.data.full_link, '_blank');
                    }, 100);
                }).finally(() => setLoading(false));
            },
        });
    };

    // 表单弹窗
    const [toAddEditModal, setToAddEditModal] = useState<{
        isModalVisible: boolean,
        data?: { id: number }
    }>();
    const toAdd = () => {
        if (screens.xs) {
            navigate(`/preArbitration/form`);
        } else {
            setToAddEditModal({
                isModalVisible: true,
            });
        }
    };
    const toEdit = (id: number) => {
        if (screens.xs) {
            navigate(`/preArbitration/form?id=${id}`);
        } else {
            setToAddEditModal({
                isModalVisible: true,
                data: { id }
            });
        }
    };
    const onDetailClose = () => {
        setToAddEditModal({
            ...toAddEditModal!,
            isModalVisible: false
        })
    };
    const onAddEditFinish = () => {
        loadData();
        setToAddEditModal({
            ...toAddEditModal!,
            isModalVisible: false
        })
    }

    // 表单弹窗
    const [toStatisticsModal, setToStatisticsModal] = useState<{
        isModalVisible: boolean,
    }>();
    const toStatistics = () => {
        if (screens.xs) {
            navigate(`/preArbitration/statistics`);
        } else {
            setToStatisticsModal({
                isModalVisible: true,
            });
        }
    };
    const onStatisticsClose = () => {
        setToStatisticsModal({
            isModalVisible: false
        })
    };

    // 地区统计信息
    const [toRegionStatisticsModal, setToRegionStatisticsModal] = useState<{
        isModalVisible: boolean,
    }>();
    const toRegionStatistics = () => {
        if (screens.xs) {
            navigate(`/preArbitration/regionStatistics`);
        } else {
            setToRegionStatisticsModal({
                isModalVisible: true,
            });
        }
    };
    const onRegionStatisticsClose = () => {
        setToRegionStatisticsModal({
            isModalVisible: false
        })
    };

    // 地区统计拒绝分析
    const [toRefuseAnalysisModal, setToRefuseAnalysisModal] = useState<{
        isModalVisible: boolean,
    }>();
    const toRefuseAnalysis = () => {
        if (screens.xs) {
            navigate(`/preArbitration/refuseAnalysis`);
        } else {
            setToRefuseAnalysisModal({
                isModalVisible: true,
            });
        }
    };
    const onRefuseAnalysisClose = () => {
        setToRefuseAnalysisModal({
            isModalVisible: false
        })
    };

    // 调解弹窗
    const [toConciliationModal, setConciliationModal] = useState<{
        isModalVisible: boolean,
        data: { id: number }
    }>();
    const toConciliation = (id: number) => {
        updateMediateStatus({ pre_worksheet_id: id });
        if (screens.xs) {
            navigate(`/preArbitration/conciliation?id=${id}`);
        } else {
            setConciliationModal({
                isModalVisible: true,
                data: { id: id }
            })
        }
    };
    const onConciliationClose = () => {
        loadData();
        setConciliationModal({
            ...toConciliationModal!,
            isModalVisible: false
        })
    };
    const onConciliationFinish = () => {
        loadData();
        setConciliationModal({
            ...toConciliationModal!,
            isModalVisible: false
        })
    }

    // 添加表单
    const [tablesModal, setTablesModal] = useState<{
        isModalVisible: boolean,
        data: { id: number }
    }>();
    const toTables = (id: number) => {
        if (screens.xs) {
            navigate(`/preArbitration/tables?id=${id}`);
        } else {
            setTablesModal({
                isModalVisible: true,
                data: { id: id }
            })
        }
    }
    const onTablesClose = () => {
        setTablesModal({
            ...tablesModal!,
            isModalVisible: false
        })
    };

    // 申请人编辑弹窗
    const [toEditPlaintiffModal, setToEditPlaintiffModal] = useState<{
        isModalVisible: boolean,
        type: number,
        data: { id: number }
    }>();
    const toEditPlaintiff = (id: number, type: number) => {
        updateMediateStatus({ pre_worksheet_id: id });
        if (screens.xs) {
            switch (type) {
                case 1:
                    navigate(`/preArbitration/plaintiff?id=${id}`);
                    break;

                case 2:
                    navigate(`/preArbitration/defendant?id=${id}`);
                    break;

                case 3:
                    navigate(`/preArbitration/thirdParty?id=${id}`);
                    break;

                default:
                    break;
            }
        } else {
            setToEditPlaintiffModal({
                isModalVisible: true,
                type,
                data: { id: id }
            });
        }
    };
    const onEditPlaintiffClose = () => {
        loadData();
        setToEditPlaintiffModal({
            ...toEditPlaintiffModal!,
            isModalVisible: false
        })
    };
    const onEditPlaintiffFinish = () => {
        loadData();
        setToEditPlaintiffModal({
            ...toEditPlaintiffModal!,
            isModalVisible: false
        })
    }


    // 用户列表
    const [userList, setUserList] = useState<IAllUserData[]>([]);
    useEffect(() => {
        if (hasPreWorksheetHandleTransferAuth) {
            getAllUser().then(res => {
                setUserList(res.data.list)
            })
        }
    }, [hasPreWorksheetHandleTransferAuth]);

    // 转移工单
    const [transferForm] = Form.useForm<ITransferPreArbitrationForm>();
    const [toTransferModal, setToTransferModal] = useState(false);
    const onTransferClose = () => {
        setToTransferModal(false);
    };
    const onTransferSubmit = async () => {
        await transferForm.validateFields();

        setLoading(true);
        try {
            let _form = transferForm.getFieldsValue(true);
            let res = await transferPreArbitration(_form);
            onTransferClose();
            message.success(res.msg);
            loadData();
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }
    const toTransfer = (ids: number[] = selectedRowKeys.map(id => Number(id))) => {
        if (ids.length === 0) {
            message.warning('请至少选择一项');
        }
        transferForm.resetFields();
        transferForm.setFieldsValue({
            pre_worksheet_ids: ids
        })
        setToTransferModal(true);
    }

    // 下载导入模板
    const toDownload = async () => {
        let res = await downloadPreArbitrationTemplate();
        window.open(res.data.full_link, '_blank');
    }

    // 空白登记表单下载
    const [downloadBlankTableForm] = Form.useForm<{ area_id: number, type: number }>();
    const [toDownloadBlankTableModal, setToDownloadBlankTableModal] = useState(false);
    const onDownloadBlankTableClose = () => {
        setToDownloadBlankTableModal(false);
    };
    const onDownloadBlankTableSubmit = async () => {
        await downloadBlankTableForm.validateFields();

        setLoading(true);
        try {
            let _form = downloadBlankTableForm.getFieldsValue(true);
            let res = await downloadPreArbitrationBlankTable(_form);
            setTimeout(() => {
                window.open(res.data.full_link, '_blank');
            }, 100);
            onDownloadBlankTableClose();
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }
    const toDownloadBlankTable = () => {
        setToDownloadBlankTableModal(true);
    }

    // 导入数据
    const inputRef = React.createRef<HTMLInputElement>();
    const upload = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        setLoading(true);
        try {
            let file = await uploadFile({ file: event.target.files![0] as Blob, type: 'pre_worksheet' });
            importDataForm.setFieldValue('file_id', file.data.file_id);
            let importResult = await importPreArbitration(importDataForm.getFieldsValue(true));
            if (importResult.data.has_fail) {
                confirm({
                    title: '导入失败',
                    icon: <ExclamationCircleOutlined />,
                    content: '是否下载失败结果文档',
                    onOk() {
                        window.open(importResult.data.full_link, '_blank');
                    },
                });
            } else {
                onImportDataClose();
                message.success(importResult.msg);
                loadData();
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };
    const [importDataForm] = Form.useForm<IImportPreArbitrationForm>();
    const [toImportDataModal, setToImportDataModal] = useState(false);
    const onImportDataClose = () => {
        setToImportDataModal(false);
    };
    const onImportDataSubmit = async () => {
        await importDataForm.validateFields();

        let _form = importDataForm.getFieldsValue(true);
        console.log(_form);
        if (!_form.province_id || !_form.city_id || !_form.area_id || !_form.street_id) {
            message.warning('请先选择处理区域');
            return;
        }

        if (inputRef.current) {
            inputRef.current.value = '';
            inputRef.current.click();
        }
    }
    const toImportData = () => {
        setToImportDataModal(true);
    }

    // 省/市/区/街道
    interface Option {
        value: string | number;
        label: string;
        children?: Option[];
    }
    const [pcaOptions, setPcaOptions] = useState<Option[]>([]);
    const [options, setOptions] = useState<Option[]>([]);
    useEffect(() => {
        let nodes: IPreArbitrationSelectRegionData[] = [];
        const build = (list: IPreArbitrationSelectRegionData[], maxDepth: number, depth: number = 1,): Option[] => {
            return list.map(item => ({
                label: item.name,
                value: item.id,
                children: item.children.length > 0 && depth < maxDepth ? build(item.children, maxDepth, depth + 1) : undefined
            }));
        }
        getPreArbitrationSelectRegion().then(res => {
            nodes.push(...res.data.list);
            setOptions(build(nodes, 4));
            setPcaOptions(build(nodes, 3));
            console.log(pcaOptions);

            try {
                let province = nodes[0];
                let city = province.children[0];
                let area = city.children[0];
                let street = area.children[0];
                let locations = [province.id, city.id, area.id, street.id];
                // 初始筛选
                setForm((data) => {
                    let newData = {
                        ...data,
                        street: locations,
                        street_ids: [street.id]
                    }
                    loadData(newData);
                    return newData;
                })

                downloadBlankTableForm.setFieldValue('cur_id', locations.slice(0, 3));
                importDataForm.setFieldValue('cur_id', locations);
                onCascaderChange(locations);
                onImportCascaderChange(locations);
            } catch (e) {
                message.error('无可用地区');
            }
        });
    }, []);
    type CascaderValueExtend = {
        items: (CascaderOption | null)[]
        isLeaf: boolean
    }
    // 下载空白模板
    const onCascaderMobileConfirm = (value: string[], extend: CascaderValueExtend) => {
        if (extend.items.length !== 3) {
            message.warning('地址请选择完整');
            return;
        }

        onCascaderChange(extend.items.map(item => Number(item!.value)));
    }
    const onCascaderChange = (value: any[]) => {

        let location = {
            province_id: value[0],
            city_id: value[1],
            area_id: value[2],
        };
        console.log(location);
        downloadBlankTableForm.setFieldsValue(location);
    }

    // 导入数据
    const onImportCascaderMobileConfirm = (value: string[], extend: CascaderValueExtend) => {
        if (extend.items.length !== 4) {
            message.warning('地址请选择完整');
            return;
        }

        onImportCascaderChange(extend.items.map(item => Number(item!.value)));
    }
    const onImportCascaderChange = (value: any[]) => {
        let location = {
            province_id: value[0],
            city_id: value[1],
            area_id: value[2],
            street_id: value[3]
        };
        importDataForm.setFieldsValue(location);
    }


    // 延期
    const [toPostponeModal, setPostponeModal] = useState<{
        isModalVisible: boolean,
        data: { id: number, expireAt?: string }
    }>();
    const toPostpone = (id: number, expireAt?: string) => {
        if (screens.xs) {
            navigate(`/preArbitration/postpone?id=${id}&expire=${expireAt}`);
        } else {
            setPostponeModal({
                isModalVisible: true,
                data: { id, expireAt }
            })
        }
    };
    const onPostponeClose = () => {
        setPostponeModal({
            ...toPostponeModal!,
            isModalVisible: false
        })
    };
    const onPostponeFinish = () => {
        loadData();
        setPostponeModal({
            ...toPostponeModal!,
            isModalVisible: false
        })
    }

    const toArchived = (id: number) => {
        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: `归档后不可再进行编辑操作！确定要归档吗？`,
            onOk: async () => {
                setLoading(true);
                try {
                    let res = await archivedPreArbitration({ pre_worksheet_id: id })
                    message.success(res.msg);
                    loadData();
                } catch (e) {
                    console.error(e);
                } finally {
                    setLoading(false);
                }
            },
        });
    }

    // 添加表单
    const [attachmentModal, setAttachmentModal] = useState<{
        isModalVisible: boolean,
        data: { id: number }
    }>();
    const toAttachment = (id: number) => {
        if (screens.xs) {
            navigate(`/preArbitration/attachment?id=${id}`);
        } else {
            setAttachmentModal({
                isModalVisible: true,
                data: { id: id }
            })
        }
    }
    const onAttachmentClose = () => {
        setAttachmentModal({
            ...attachmentModal!,
            isModalVisible: false
        })
    };

    // table选中
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const spanStyle = {
        'color': 'red'
    }

    // 批量导出压缩包
    const toBatchExportZip = (ids: number[] = selectedRowKeys.map(id => Number(id))) => {
        if (ids.length === 0) {
            message.warning('请至少选择一项导出');
            return;
        }
        exportZip({ ids }).then(res => {
            window.open(
                res.data.full_link, '_blank'
            );
        });
    };

    // 重置案号
    const [resetCaseNumberForm] = Form.useForm<IResetCaseNumberForm>();
    const [toResetCaseNumberModal, setToResetCaseNumberModal] = useState(false);
    const onResetCaseNumberModalClose = () => {
        setToResetCaseNumberModal(false);
    };

    // 案号列表
    const [_listData, _setListData] = useState<(IRresetCaseNumberResData)[]>([]);
    const [_loading, _setLoading] = useState(false);

    const [_form, _setForm] = useState<IResetCaseNumberForm>({
        ...resetCaseNumberForm.getFieldsValue(true),
        step: 1,
    });
    const _loadData = async (newForm?: IResetCaseNumberForm) => {
        let tempForm = newForm ?? _form;
        _setLoading(true);
        try {
            let res = await resetCaseNumber(tempForm);
            _setListData([...res.data.list]);
        } catch (e) {
            console.error(e);
        } finally {
            _setLoading(false);
        }
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);

        resetCaseNumber({
            ..._form,
            step: 2
        }).then(res => {
            message.success(res.msg);
        }).finally(() => {
            onResetCaseNumberModalClose();
            loadData();
        });
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const onResetCaseNumberSubmit = async () => {
        await resetCaseNumberForm.validateFields();

        let _form = {
            ...resetCaseNumberForm.getFieldsValue(true),
            step: 1,
            page: 1,
            size: 10,
        }

        _setForm(_form);

        setLoading(true);
        try {
            await _loadData(_form);

            showModal();
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }
    const toResetCaseNumber = () => {
        resetCaseNumberForm.resetFields();
        setToResetCaseNumberModal(true);
    }
    const onResetCaseNumberCascaderConfirm = (value: string[], extend: CascaderValueExtend) => {
        if (extend.items.length !== 4) {
            message.warning('地址请选择完整');
            return;
        }

        onCascaderChange(extend.items.map(item => Number(item!.value)));
    }
    const onResetCaseNumberCascaderChange = (value: any[]) => {
        let location = {
            province_id: value[0],
            city_id: value[1],
            area_id: value[2],
            street_id: value[3],
        };
        resetCaseNumberForm.setFieldsValue(location);
    }

    // 未付款列表弹窗
    const [isUpPayModalOpen, setIsUpPayModalOpen] = useState(false);
    const showUpPayModal = () => {
        setIsUpPayModalOpen(true);
    };
    const handleUpPayCancel = () => {
        setIsUpPayModalOpen(false);
    };
    const [upPayForm, setUpPayForm] = useState<Pager>({ size: 10, page: 1 });
    const [upPayListData, setUpPayListData] = useState<(IUpPayListData)[]>([]);
    const [mUpPayListData, setMUpPayListData] = useState<(IUpPayListData)[]>([]);
    const [upPayTotal, setUpPayTotal] = useState(0);
    const [upPayLoading, setUpPayLoading] = useState(false);
    const loadUpPayData = async (newForm?: Pager, isForce: boolean = false) => {
        let tempForm = newForm ?? upPayForm;
        let flag = false;
        setUpPayLoading(true);
        try {
            let res = await getUpPayList({ ...tempForm, is_force: isForce });
            setUpPayTotal(res.data.total);
            setMUpPayListData(res.data.list);
            setUpPayListData((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== res.data.total) {
                    newListData = new Array(res.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((tempForm.page! - 1) * tempForm.size, res.data.list.length, ...res.data.list);
                console.log('结果', newListData);
                return newListData;
            });
            if (res.data.total > 0) flag = true;
        } catch (e) {
            console.error(e);
        } finally {
            setUpPayLoading(false);
        }

        if (flag) return true;
    }
    const onUpPayPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        let newForm = {
            ...form,
            page,
            size: pageSize,
        };
        setUpPayForm(newForm);
        loadUpPayData(newForm, true);
    }
    const loadAndShowUpPay = (isForce: boolean = false) => {
        loadUpPayData(upPayForm, isForce).then(res => {
            if (res === true || isForce) {
                showUpPayModal();
            }
        });
    }
    useEffect(() => {
        loadAndShowUpPay();
    }, []);

    return (
        <div className='address inner-page'>
            {
                stat && <Alert message={<div>{stat.today} 前 <span style={spanStyle}>{stat.today_count}</span> 单未解决，至今共处理 <span style={spanStyle}>{stat.total_count}</span> 个工单</div>} type="info" showIcon />
            }

            <div className='search' style={{ position: 'relative' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <Cascader
                            multiple
                            value={form.street}
                            style={{ minWidth: '250px' }}
                            className="search__item"
                            options={options}
                            placeholder="街道筛选"
                            onChange={handleStreetChange}
                            allowClear={false}
                            showCheckedStrategy={Cascader.SHOW_CHILD}
                        />
                        <RangePicker
                            className="search__item"
                            style={{ minWidth: '250px' }}
                            value={[
                                form.acceptance_date && form.acceptance_date[0] ? dayjs(form.acceptance_date[0]) : null,
                                form.acceptance_date && form.acceptance_date[1] ? dayjs(form.acceptance_date[1]) : null,
                            ]}
                            onChange={handleReceiveChange}
                            format='YYYY-MM-DD'
                            placeholder={['开始日期', '结束日期']}
                        />
                        <Select
                            className="search__item"
                            showArrow
                            value={form.is_my_case}
                            onChange={handleIsMyCaseChange}
                        >
                            <Option key={1} value={true}>我的案件</Option>
                            <Option key={2} value={false}>全部案件</Option>
                        </Select>
                        {/* <Select
                        allowClear
                        className="search__item"
                        showArrow
                        placeholder="问题类别"
                        value={form.question_type}
                        onChange={handleQuestionTypeChange}
                    >
                        {questionType?.options.map(option => (
                            <Option key={option.value} value={option.value}>{option.name}</Option>
                        ))}
                    </Select>
                    <Select
                        allowClear
                        className="search__item"
                        showArrow
                        placeholder="诉讼类别"
                        value={form.lawsuit_type}
                        onChange={handleLawsuitTypeChange}
                    >
                        {lawsuitType?.options.map(option => (
                            <Option key={option.value} value={option.value}>{option.name}</Option>
                        ))}
                    </Select>
                    <Select
                        allowClear
                        className="search__item"
                        showArrow
                        placeholder="案件类别"
                        value={form.case_category}
                        onChange={handleQCaseCategoryChange}
                    >
                        {caseCategory?.options.map(option => (
                            <Option key={option.value} value={option.value}>{option.name}</Option>
                        ))}
                    </Select> */}
                        <Select
                            allowClear
                            className="search__item"
                            showArrow
                            placeholder="案件类型"
                            value={form.type}
                            onChange={handleTypeChange}
                        >
                            {type?.options.map(option => (
                                <Option key={option.value} value={option.value}>{option.name}</Option>
                            ))}
                        </Select>
                        <Select
                            allowClear
                            className="search__item"
                            showArrow
                            placeholder="争议类型"
                            value={form.dispute_type}
                            onChange={handleDisputeTypeChange}
                        >
                            {disputeType?.options.map(option => (
                                <Option key={option.value} value={option.value}>{option.name}</Option>
                            ))}
                        </Select>
                        <Select
                            allowClear
                            className="search__item"
                            showArrow
                            placeholder="工单状态"
                            value={form.status}
                            onChange={handleStatusChange}
                        >
                            {status?.options.map(option => (
                                <Option key={option.value} value={option.value}>{option.name}</Option>
                            ))}
                        </Select>
                    </div>

                    <Tooltip title="未付款列表">
                        <Button
                            icon={<BellFilled style={{ color: 'orange', fontSize: 24 }} />}
                            type="text"
                            onClick={() => loadAndShowUpPay(true)}
                        ></Button>
                    </Tooltip>
                </div>

                <div>
                    <Input
                        className="search__item"
                        value={form.keywords}
                        onChange={handleKeywordsChange}
                        placeholder="姓名、电话号码搜索"
                    />
                    <Input
                        className="search__item"
                        value={form.case_number}
                        onChange={handleCaseNumberChange}
                        placeholder="案号搜索"
                    />
                    <Input
                        className="search__item"
                        value={form.mediator}
                        onChange={handleMediatorChange}
                        placeholder="调解员搜索"
                    />
                    <Select
                        className="search__item"
                        showArrow
                        value={form.sort && form.order ? `${form.sort} ${form.order}` : undefined}
                        onChange={handleOrderByChange}
                        placeholder="列表排序"
                        allowClear
                    >
                        <Option key={1} value={`case_number asc`}>案号升序</Option>
                        <Option key={2} value={`case_number desc`}>案号降序</Option>
                        <Option key={3} value={`acceptance_date asc`}>受理时间升序</Option>
                        <Option key={4} value={`acceptance_date desc`}>受理时间降序</Option>
                    </Select>
                    <RangePicker
                        className="search__item"
                        style={{ 'width': '300px' }}
                        value={[
                            form.closing_date && form.closing_date[0] ? dayjs(form.closing_date[0]) : null,
                            form.closing_date && form.closing_date[1] ? dayjs(form.closing_date[1]) : null,
                        ]}
                        onChange={handleClosingDateChange}
                        format='YYYY-MM-DD'
                        placeholder={['结案时间开始', '结案时间结束']}
                    />
                    <Button
                        className="search__button"
                        icon={<CloseOutlined />}
                        loading={loading}
                        onClick={() => resetData()}
                    >
                        重置筛选
                    </Button>



                    <Dropdown.Button
                        className={`search__last-button ${screens.xs ? 'xs' : ''}`}
                        style={{ marginRight: screens.xs ? 15 : 0 }}
                        type="primary"
                        loading={false}
                        trigger={['click']}
                        overlay={
                            <Menu
                                items={selectedRowKeys.length > 0 ? [
                                    {
                                        key: 'transfer',
                                        disabled: !hasPreWorksheetHandleTransferAuth,
                                        label: (
                                            <div>批量转移工单</div>
                                        ),
                                        onClick: () => toTransfer(),
                                    }, {
                                        key: 'batchExportZip',
                                        disabled: !hasPreWorksheetExportZipAuth,
                                        label: (
                                            <div>批量导出压缩包</div>
                                        ),
                                        onClick: () => toBatchExportZip(),
                                    }
                                ] : [
                                    {
                                        key: 'template',
                                        label: (
                                            <div>下载导入模板</div>
                                        ),
                                        onClick: () => toDownload()
                                    },
                                    {
                                        key: 'import',
                                        disabled: !hasPreWorksheetImportAuth,
                                        label: (
                                            <>
                                                <div>导入数据</div>
                                                <input
                                                    ref={inputRef}
                                                    style={{ display: 'none' }}
                                                    type="file"
                                                    onChange={upload}
                                                    accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                                                />
                                            </>
                                        ),
                                        onClick: () => toImportData()
                                    },
                                    {
                                        key: 'download',
                                        label: (
                                            <div>空白登记表单下载</div>
                                        ),
                                        onClick: () => toDownloadBlankTable()
                                    },
                                    {
                                        key: 'export',
                                        label: (
                                            <div>导出报表</div>
                                        ),
                                        onClick: exportData,
                                    },
                                    {
                                        key: 'reset',
                                        disabled: !hasPreWorksheetHandleResetCaseNumberAuth,
                                        label: (<div>重置案号</div>),
                                        onClick: toResetCaseNumber
                                    }
                                ]}
                            />
                        }>
                        {selectedRowKeys.length > 0 ? `已选择${selectedRowKeys.length}项` : '更多操作'}
                    </Dropdown.Button>
                    {
                        hasPreWorksheetAddAuth &&
                        <Button
                            className={`search__last-button ${screens.xs ? 'xs' : ''}`}
                            loading={loading}
                            type="primary"
                            onClick={toAdd}
                            icon={<FileAddOutlined />}
                        >
                            登记案件
                        </Button>
                    }
                    {
                        hasPreWorksheetStatOrganUserAuth &&
                        <Button
                            className={`search__last-button ${screens.xs ? 'xs' : ''}`}
                            type="primary"
                            onClick={toStatistics}
                            icon={<AreaChartOutlined />}
                        >
                            调解员案件处理统计
                        </Button>
                    }
                    {
                        hasPreWorksheetStatRegionAuth &&
                        <Button
                            className={`search__last-button ${screens.xs ? 'xs' : ''}`}
                            type="primary"
                            onClick={toRegionStatistics}
                            icon={<AreaChartOutlined />}
                        >
                            地区统计信息
                        </Button>
                    }
                    {
                        hasPreWorksheetStatRefuseAnalysisAuth &&
                        <Button
                            className={`search__last-button ${screens.xs ? 'xs' : ''}`}
                            type="primary"
                            onClick={toRefuseAnalysis}
                            icon={<AreaChartOutlined />}
                        >
                            地区统计拒绝分析
                        </Button>
                    }
                </div>
            </div>
            <ActionSheet
                visible={mActions.visible}
                actions={mActions.actions}
                onClose={() => setMActions({
                    visible: false,
                    actions: []
                })}
            />
            {
                screens.xs
                    ? <div>
                        {
                            mListData.length > 0 ? mListData.map((item, index) =>
                                <Card className="m-card" onClick={() => {
                                    setMActions({
                                        visible: true,
                                        actions: [
                                            {
                                                text: '调解',
                                                key: 'conciliation',
                                                disabled: !item.can_mediate || !hasPreWorksheetMediateAuth,
                                                onClick: () => toConciliation(item.id),
                                            },
                                            {
                                                key: 'tables',
                                                disabled: !hasPreWorksheetFormAuth,
                                                text: "填写表单",
                                                onClick: () => toTables(item.id),
                                            },
                                            {
                                                key: 'plaintiff',
                                                text: "编辑申请人",
                                                disabled: !hasPreWorksheetAddAuth,
                                                onClick: () => toEditPlaintiff(item.id, 1),
                                            },
                                            {
                                                key: 'defendant',
                                                text: "编辑被申请人",
                                                disabled: !hasPreWorksheetAddAuth,
                                                onClick: () => toEditPlaintiff(item.id, 2),
                                            },
                                            {
                                                key: 'thirdParty',
                                                text: "编辑第三人",
                                                disabled: !hasPreWorksheetAddAuth,
                                                onClick: () => toEditPlaintiff(item.id, 3),
                                            },
                                            {
                                                key: 'postpone',
                                                disabled: !hasPreWorksheetPostponeAuth,
                                                text: "延期",
                                                onClick: () => toPostpone(item.id, item.expire_at),
                                            },
                                            {
                                                key: 'transfer',
                                                disabled: !hasPreWorksheetHandleTransferAuth,
                                                text: "转移",
                                                onClick: () => toTransfer([item.id]),
                                            },
                                            {
                                                key: 'archived',
                                                text: "归档",
                                                disabled: !item.can_archived || !hasPreWorksheetArchivedAuth,
                                                onClick: () => toArchived(item.id),
                                            },
                                            {
                                                key: 'attachment',
                                                disabled: !hasPreWorksheetAttachmentAuth,
                                                text: "补充登记附件",
                                                onClick: () => toAttachment(item.id),
                                            },
                                        ],
                                    })
                                }}>
                                    <div className='m-card_item'>
                                        <div>案件类型：</div>
                                        <div>{item.type_text}</div>
                                    </div>
                                    <div className={`m-card_item ${item.is_remain_expire ? 'red-row' : ''}`}>
                                        <div>案号：</div>
                                        <div>{item.case_number}</div>
                                    </div>
                                    {/* <div className={`m-card_item`}>
                                        <div>工单号：</div>
                                        <div>{item.order_no}</div>
                                    </div> */}
                                    <div className='m-card_item'>
                                        <div>街道：</div>
                                        <div>{item.street_text}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>申请人：</div>
                                        <div>{(item.plaintiff_default as INaturalPeople)?.real_name ?? (item.plaintiff_default as IPreArbitrationCompany)?.company_name}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>被申请人：</div>
                                        <div>{(item.defendant_default as INaturalPeople)?.real_name ?? (item.defendant_default as IPreArbitrationCompany)?.company_name}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>第三人：</div>
                                        <div>{
                                            item.third_party?.map(item => {
                                                switch (item.subject_type) {
                                                    case 1:
                                                        return item.natural_people.real_name

                                                    case 2:
                                                        return item.company.company_name
                                                }
                                            }).join('，')
                                        }</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>调解员：</div>
                                        <div>{item.mediator}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>受理时间：</div>
                                        <div>{item.acceptance_date}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>申请人态度：</div>
                                        {
                                            item.phone_mediate_plaintiff_attitude
                                                ? <div
                                                    className='clickable-text'
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        Modal.info({
                                                            title: '申请人态度',
                                                            content: item.phone_mediate_plaintiff_attitude,
                                                        })
                                                    }}
                                                >
                                                    <div style={{ maxWidth: '10em', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.phone_mediate_plaintiff_attitude}</div>
                                                </div>
                                                : <div>无</div>
                                        }
                                    </div>
                                    <div className='m-card_item'>
                                        <div>被申请人态度：</div>
                                        {
                                            item.phone_mediate_defendant_attitude
                                                ? <div
                                                    className='clickable-text'
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        Modal.info({
                                                            title: '被申请人态度',
                                                            content: item.phone_mediate_defendant_attitude,
                                                        })
                                                    }}
                                                >
                                                    <div style={{ maxWidth: '10em', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.phone_mediate_defendant_attitude}</div>
                                                </div>
                                                : <div>无</div>
                                        }
                                    </div>
                                    <div className='m-card_item'>
                                        <div>处理结果：</div>
                                        <div>{item.status_text}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>结案时间：</div>
                                        <div>{item.closing_date ?? '无'}</div>
                                    </div>
                                </Card>
                            )
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                        }

                        <Pagination
                            current={form.page}
                            onChange={onPageChange}
                            showTotal={total => `共 ${total} 项`}
                            total={total}
                            simple
                        />
                    </div>
                    : <Table
                        dataSource={listData}
                        size='small'
                        loading={loading}
                        scroll={{ x: 800 }}
                        rowClassName={(record, index) => {
                            return record?.is_remain_expire ? 'red-row' : '';
                        }}
                        rowSelection={
                            {
                                selectedRowKeys,
                                onChange: onSelectChange
                            }
                        }
                        rowKey={(record) => record?.id}
                        pagination={{
                            position: ['bottomRight'],
                            size: "small",
                            total: total,
                            showTotal: total => `共 ${total} 项`,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            onChange: onPageChange
                        }}>
                        <Column title="案件类型" dataIndex="type_text" key="type_text" />
                        <Column title="案号" dataIndex="case_number" key="case_number" />
                        {/* <Column title="工单号" dataIndex="order_no" key="order_no" /> */}
                        <Column title="街道" dataIndex="street_text" key="street_text" />
                        <Column title="申请人" dataIndex="plaintiff_default" key="plaintiff_default"
                            render={
                                (_: any, record: IPreArbitrationListData | null) => (
                                    record &&
                                    <div
                                        className={hasPreWorksheetAddAuth ? 'clickable-text' : ''}
                                        onClick={hasPreWorksheetAddAuth ? () => toEditPlaintiff(record.id, 1) : undefined}
                                    >
                                        {(record.plaintiff_default as INaturalPeople)?.real_name ?? (record.plaintiff_default as IPreArbitrationCompany)?.company_name ?? '无   '}
                                    </div>
                                )
                            }
                        />
                        <Column title="被申请人" dataIndex="defendant_default" key="defendant_default"
                            render={
                                (_: any, record: IPreArbitrationListData | null) => (
                                    record &&
                                    <div
                                        className={hasPreWorksheetAddAuth ? 'clickable-text' : ''}
                                        onClick={hasPreWorksheetAddAuth ? () => toEditPlaintiff(record.id, 2) : undefined}
                                    >
                                        {(record.defendant_default as INaturalPeople)?.real_name ?? (record.defendant_default as IPreArbitrationCompany)?.company_name ?? '无'}
                                    </div>
                                )
                            }
                        />
                        <Column title="第三人" dataIndex="third_party" key="third_party" render={
                            (_: any, record: IPreArbitrationListData | null) => (
                                record &&
                                <div
                                    className={hasPreWorksheetAddAuth ? 'clickable-text' : ''}
                                    onClick={hasPreWorksheetAddAuth ? () => toEditPlaintiff(record.id, 3) : undefined}
                                >
                                    {
                                        record.third_party && record.third_party.length > 0
                                            ? record.third_party.map(item => {
                                                switch (item.subject_type) {
                                                    case 1:
                                                        return item.natural_people.real_name

                                                    case 2:
                                                        return item.company.company_name
                                                }
                                            }).join('，')
                                            : '无'
                                    }
                                </div>
                            )
                        } />
                        <Column title="调解员" dataIndex="recorder_text" key="recorder_text" />
                        <Column title="受理时间" dataIndex="acceptance_date" key="acceptance_date" />
                        <Column title="申请人态度" dataIndex="phone_mediate_plaintiff_attitude" key="phone_mediate_plaintiff_attitude"
                            render={
                                (_: any, record: IPreArbitrationListData | null) => (
                                    record?.phone_mediate_plaintiff_attitude ?
                                        <div
                                            className='clickable-text'
                                            onClick={() => Modal.info({
                                                title: '申请人态度',
                                                content: record.phone_mediate_plaintiff_attitude,
                                            })}
                                        >
                                            <div style={{ maxWidth: '10em', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{record.phone_mediate_plaintiff_attitude}</div>
                                        </div>
                                        : '无'
                                )
                            }
                        />
                        <Column title="被申请人态度" dataIndex="phone_mediate_defendant_attitude" key="phone_mediate_defendant_attitude"
                            render={
                                (_: any, record: IPreArbitrationListData | null) => (

                                    record?.phone_mediate_defendant_attitude ?
                                        <div
                                            className='clickable-text'
                                            onClick={() => Modal.info({
                                                title: '被申请人态度',
                                                content: record.phone_mediate_defendant_attitude,
                                            })}
                                        >
                                            <div style={{ maxWidth: '10em', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{record.phone_mediate_defendant_attitude}</div>
                                        </div>
                                        : '无'
                                )
                            }
                        />
                        <Column title="处理结果" dataIndex="status_text" key="status_text" />
                        <Column title="结案时间" dataIndex="closing_date" key="closing_date" />
                        <Column
                            width={100}
                            title="操作"
                            key="action"
                            fixed="right"
                            render={(_: any, record: IPreArbitrationListData | null) => (
                                <Space>
                                    <Dropdown.Button
                                        trigger={['click']}
                                        size='small'
                                        type="primary"
                                        loading={loading}
                                        overlay={
                                            <Menu
                                                items={[
                                                    {
                                                        key: 'conciliation',
                                                        disabled: !record?.can_mediate || !hasPreWorksheetMediateAuth,
                                                        label: (
                                                            <div>调解</div>
                                                        ),
                                                        onClick: () => toConciliation(record!.id),
                                                    },
                                                    {
                                                        key: 'tables',
                                                        disabled: !hasPreWorksheetFormAuth,
                                                        label: (
                                                            <div>填写表单</div>
                                                        ),
                                                        onClick: () => toTables(record!.id),
                                                    },
                                                    {
                                                        key: 'postpone',
                                                        disabled: !hasPreWorksheetPostponeAuth,
                                                        label: (
                                                            <div>延期</div>
                                                        ),
                                                        onClick: () => toPostpone(record!.id, record!.expire_at),
                                                    },
                                                    {
                                                        key: 'transfer',
                                                        disabled: !hasPreWorksheetHandleTransferAuth,
                                                        label: (
                                                            <div>转移</div>
                                                        ),
                                                        onClick: () => toTransfer([record!.id]),
                                                    },
                                                    {
                                                        key: 'archived',
                                                        disabled: !record?.can_archived || !hasPreWorksheetArchivedAuth,
                                                        label: (
                                                            <div>归档</div>
                                                        ),
                                                        onClick: () => toArchived(record!.id),
                                                    }, {
                                                        key: 'attachment',
                                                        disabled: !hasPreWorksheetAttachmentAuth,
                                                        label: (
                                                            <div>补充登记附件</div>
                                                        ),
                                                        onClick: () => toAttachment(record!.id),
                                                    }
                                                ]}
                                            />
                                        }>
                                        操作
                                    </Dropdown.Button>
                                </Space>
                            )}
                        />
                    </Table>
            }

            {/* 调解员案件处理统计 */}
            {
                toStatisticsModal ? <Modal
                    width={1300}
                    visible={toStatisticsModal.isModalVisible}
                    title={`调解员案件处理统计`}
                    maskClosable={false}
                    onCancel={onStatisticsClose}
                    footer={[
                        <Button key="back" onClick={onStatisticsClose}>
                            关闭
                        </Button>
                    ]}
                >
                    <PreArbitrationStatisticsListModal></PreArbitrationStatisticsListModal>
                </Modal> : null
            }

            {/* 地区统计信息 */}
            {
                toRegionStatisticsModal ? <Modal
                    width={1300}
                    visible={toRegionStatisticsModal.isModalVisible}
                    title={`地区统计信息`}
                    maskClosable={false}
                    onCancel={onRegionStatisticsClose}
                    footer={[
                        <Button key="back" onClick={onRegionStatisticsClose}>
                            关闭
                        </Button>
                    ]}
                >
                    <PreArbitrationRegionStatisticsListModal></PreArbitrationRegionStatisticsListModal>
                </Modal> : null
            }

            {/* 拒绝分析 */}
            {
                toRefuseAnalysisModal ? <Modal
                    width={1300}
                    visible={toRefuseAnalysisModal.isModalVisible}
                    title={`地区统计拒绝分析`}
                    maskClosable={false}
                    onCancel={onRefuseAnalysisClose}
                    footer={[
                        <Button key="back" onClick={onRefuseAnalysisClose}>
                            关闭
                        </Button>
                    ]}
                >
                    <PreArbitrationRefuseAnalysisListModal></PreArbitrationRefuseAnalysisListModal>
                </Modal> : null
            }

            {/* 表单弹窗 */}
            {
                toAddEditModal ? <Modal
                    width={1000}
                    visible={toAddEditModal.isModalVisible}
                    title={`案件登记`}
                    maskClosable={false}
                    onCancel={onDetailClose}
                    footer={[
                        <Button key="back" onClick={onDetailClose}>
                            关闭
                        </Button>
                    ]}
                >
                    <PreArbitrationFormModal data={toAddEditModal.data} onFinish={onAddEditFinish}></PreArbitrationFormModal>
                </Modal> : null
            }

            {/* 工单调解 */}
            {
                toConciliationModal ? <Modal
                    visible={toConciliationModal.isModalVisible}
                    title={`工单调解`}
                    onCancel={onConciliationClose}
                    width={'95%'}
                    footer={null}
                >
                    <PreArbitrationConciliationDetailFormModal data={toConciliationModal.data} onFinish={onConciliationFinish}></PreArbitrationConciliationDetailFormModal>
                </Modal> : null
            }

            {/* 涉案人员编辑 */}
            {
                toEditPlaintiffModal &&
                <Modal
                    visible={toEditPlaintiffModal.isModalVisible}
                    title={toEditPlaintiffModal.type === 1
                        ? `申请人`
                        : toEditPlaintiffModal.type === 2
                            ? '被申请人'
                            : '第三人'}
                    onCancel={onEditPlaintiffClose}
                    footer={null}
                >
                    {
                        toEditPlaintiffModal.type === 1 &&
                        <PreArbitrationPlaintiffFormModal data={toEditPlaintiffModal.data} onFinish={onEditPlaintiffFinish}></PreArbitrationPlaintiffFormModal>
                    }
                    {
                        toEditPlaintiffModal.type === 2 &&
                        <PreArbitrationDefendantFormModal data={toEditPlaintiffModal.data} onFinish={onEditPlaintiffFinish}></PreArbitrationDefendantFormModal>
                    }
                    {
                        toEditPlaintiffModal.type === 3 &&
                        <PreArbitrationThirdPartyFormModal data={toEditPlaintiffModal.data} onFinish={onEditPlaintiffFinish}></PreArbitrationThirdPartyFormModal>
                    }
                </Modal>
            }

            {/* 填写表单 */}
            {
                tablesModal ? <Modal
                    visible={tablesModal.isModalVisible}
                    title={`填写表单`}
                    onCancel={onTablesClose}
                    width={800}
                    footer={null}
                >
                    <PreArbitrationTablesModal data={tablesModal.data}></PreArbitrationTablesModal>
                </Modal> : null
            }

            {/* 补充登记附件 */}
            {
                attachmentModal ? <Modal
                    visible={attachmentModal.isModalVisible}
                    title={`补充登记附件`}
                    onCancel={onAttachmentClose}
                    width={800}
                    footer={null}
                >
                    <PreArbitrationAttachmentFormModal data={attachmentModal.data} onFinish={onAttachmentClose}></PreArbitrationAttachmentFormModal>
                </Modal> : null
            }

            {/* 延期 */}
            {
                toPostponeModal ? <Modal
                    visible={toPostponeModal.isModalVisible}
                    title={`工单延期`}
                    onCancel={onPostponeClose}
                    footer={null}
                >
                    <PreArbitrationPostponeFormModal data={toPostponeModal.data} onFinish={onPostponeFinish}></PreArbitrationPostponeFormModal>
                </Modal> : null
            }

            {
                <Modal
                    title="空白登记表单下载"
                    open={toDownloadBlankTableModal}
                    onOk={onDownloadBlankTableSubmit}
                    onCancel={onDownloadBlankTableClose}
                    confirmLoading={loading}
                >
                    <Form form={downloadBlankTableForm} layout="vertical">
                        <Form.Item
                            label="处理地区"
                            name="cur_id"
                            rules={[{ required: true, message: '请选择处理地区' }]}
                            trigger={screens.xs ? 'onConfirm' : undefined}
                        >
                            {
                                screens.xs
                                    ? <CascaderMobile
                                        options={pcaOptions as any}
                                        onConfirm={onCascaderMobileConfirm}
                                    >
                                        {(items, actions) => (
                                            <>
                                                <Button onClick={actions.open} style={{ marginRight: 10 }}>选择</Button>
                                                {
                                                    items.every(item => item === null)
                                                        ? '请选择处理地区'
                                                        : items.map(item => item?.label ?? '请选择处理地区').join('-')
                                                }
                                            </>
                                        )}
                                    </CascaderMobile>
                                    : <Cascader options={pcaOptions} placeholder="请选择处理地区" onChange={onCascaderChange} />
                            }
                        </Form.Item>
                        <Form.Item
                            label="选择案件类型"
                            name="type"
                        >
                            <Select showArrow allowClear>
                                {type?.options?.map(option => (
                                    <Option key={option.value} value={option.value}>{option.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>
            }

            {
                <Modal
                    title="导入数据"
                    open={toImportDataModal}
                    onOk={onImportDataSubmit}
                    onCancel={onImportDataClose}
                    confirmLoading={loading}
                >
                    <Form form={importDataForm} layout="vertical">
                        <Form.Item
                            label="处理地区"
                            name="cur_id"
                            rules={[{ required: true, message: '请选择处理地区' }]}
                            trigger={screens.xs ? 'onConfirm' : undefined}
                        >
                            {
                                screens.xs
                                    ? <CascaderMobile
                                        options={options as any}
                                        onConfirm={onImportCascaderMobileConfirm}
                                    >
                                        {(items, actions) => (
                                            <>
                                                <Button onClick={actions.open} style={{ marginRight: 10 }}>选择</Button>
                                                {
                                                    items.every(item => item === null)
                                                        ? '请选择处理地区'
                                                        : items.map(item => item?.label ?? '请选择处理地区').join('-')
                                                }
                                            </>
                                        )}
                                    </CascaderMobile>
                                    : <Cascader options={options} placeholder="请选择处理地区" onChange={onImportCascaderChange} />
                            }
                        </Form.Item>
                    </Form>
                </Modal>
            }

            {/* 转移工单 */}
            <Modal
                title="转移工单"
                open={toTransferModal}
                onOk={onTransferSubmit}
                onCancel={onTransferClose}
                confirmLoading={loading}
            >
                <Form form={transferForm} layout="vertical">
                    <Form.Item
                        label="选择处理人"
                        name="organ_user_id"
                        rules={[{ required: true, message: '请选择处理人' }]}
                    >
                        <Select showArrow>
                            {userList?.map(option => (
                                <Option key={option.id} value={option.id}>[{option.username}]{option.real_name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="选择协助人"
                        name="organ_assist_user_id"
                    >
                        <Select showArrow>
                            {userList?.map(option => (
                                <Option key={option.id} value={option.id}>[{option.username}]{option.real_name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="重置案号"
                open={toResetCaseNumberModal}
                onOk={onResetCaseNumberSubmit}
                onCancel={onResetCaseNumberModalClose}
                confirmLoading={loading}
            >
                <Form form={resetCaseNumberForm} layout="vertical">
                    <Form.Item
                        label="省/市/区/街道"
                        name="cur_id"
                        rules={[{ required: true, message: '请选择省/市/区/街道' }]}
                        trigger={screens.xs ? 'onConfirm' : undefined}
                    >
                        {
                            screens.xs
                                ? <CascaderMobile
                                    options={options as any}
                                    onConfirm={onResetCaseNumberCascaderConfirm}
                                >
                                    {(items, actions) => (
                                        <>
                                            <Button onClick={actions.open} style={{ marginRight: 10 }}>选择</Button>
                                            {
                                                items.every(item => item === null)
                                                    ? '请选择处理街道'
                                                    : items.map(item => item?.label ?? '请选择处理街道').join('-')
                                            }
                                        </>
                                    )}
                                </CascaderMobile>
                                : <Cascader options={options} placeholder="请选择处理街道" onChange={onResetCaseNumberCascaderChange} />
                        }
                    </Form.Item>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Form.Item
                            label="开始案号"
                            name="begin_case_number"
                            rules={[{ required: true, message: '请填写开始案号' }]}
                            style={{ width: '100%' }}
                        >
                            <Input maxLength={100} />
                        </Form.Item>

                        <div style={{ margin: '0 10px' }}>至</div>

                        <Form.Item
                            label="结束案号"
                            name="end_case_number"
                            rules={[{ required: true, message: '请填写结束案号' }]}
                            style={{ width: '100%' }}
                        >
                            <Input maxLength={100} />
                        </Form.Item>
                    </div>

                    <Form.Item
                        label="重置的开始案号"
                        name="reset_case_number"
                        rules={[{ required: true, message: '请填写重置的开始案号' }]}

                    >
                        <Input maxLength={100} />
                    </Form.Item>
                </Form>

                <Modal width={1000} title="变更确认" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    {
                        screens.xs
                            ? <div>
                                {
                                    _listData.length > 0 ? _listData.map(item =>
                                        <Card className='m-card'>
                                            <div className='m-card_item'>
                                                <div>原案号：</div>
                                                <div>{item.case_number}</div>
                                            </div>
                                            <div className='m-card_item'>
                                                <div>新案号：</div>
                                                <div>{item.new_case_number}</div>
                                            </div>
                                            <div className='m-card_item'>
                                                <div>受理时间：</div>
                                                <div>{item.acceptance_date}</div>
                                            </div>
                                        </Card>
                                    )
                                        : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                                }
                            </div>
                            : <Table
                                dataSource={_listData}
                                size='small'
                                loading={_loading}
                                scroll={{ x: 800 }}
                                rowKey={(record) => record?.id}
                                pagination={{
                                    position: ['bottomRight'],
                                    size: "small",
                                    showTotal: total => `共 ${total} 项`,
                                    showSizeChanger: true,
                                    showQuickJumper: true,
                                }}>
                                <Table.Column title="原案号" dataIndex="case_number" key="case_number" />
                                <Table.Column title="新案号" dataIndex="new_case_number" key="new_case_number" />
                                <Table.Column title="受理时间" dataIndex="acceptance_date" key="acceptance_date" />
                            </Table>
                    }
                </Modal>
            </Modal>

            <Modal
                width={1000}
                title="未付款列表"
                open={isUpPayModalOpen}
                onCancel={handleUpPayCancel}
                footer={null}
            >
                {
                    screens.xs
                        ? <div>
                            {
                                mUpPayListData.length > 0 ? mUpPayListData.map(item =>
                                    <Card className='m-card' loading={upPayLoading} onClick={
                                        () => toConciliation(item.id)
                                    }>
                                        <div className='m-card_item'>
                                            <div>申请人：</div>
                                            <div>{item.plaintiff_text}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>被申请人：</div>
                                            <div>{item.defendant_text}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>调解成功金额：</div>
                                            <div>{item.involved_amount}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>付款日期：</div>
                                            <div>{item.pay_time}</div>
                                        </div>
                                    </Card>
                                )
                                    : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                            }

                            <Pagination
                                current={upPayForm.page}
                                onChange={onUpPayPageChange}
                                showTotal={total => `共 ${total} 项`}
                                total={upPayTotal}
                                simple
                            />
                        </div>
                        : <Table
                            dataSource={upPayListData}
                            size='small'
                            loading={upPayLoading}
                            scroll={{ x: 800 }}
                            rowKey={(record) => record?.id}
                            pagination={{
                                position: ['bottomRight'],
                                size: "small",
                                total: upPayTotal,
                                showTotal: total => `共 ${total} 项`,
                                showSizeChanger: true,
                                showQuickJumper: true,
                                onChange: onUpPayPageChange
                            }}>
                            <Column title="申请人" dataIndex="plaintiff_text" key="plaintiff_text" />
                            <Column title="被申请人" dataIndex="defendant_text" key="defendant_text" />
                            <Column title="调解成功金额" dataIndex="involved_amount" key="involved_amount" />
                            <Column title="付款日期" dataIndex="pay_time" key="pay_time" />
                            <Column
                                width={100}
                                title="操作"
                                key="action"
                                fixed="right"
                                render={(_: any, record: IUpPayListData | null) => (
                                    <Space>
                                        <Button type='primary' size='small' onClick={() => toConciliation(record!.id)}>跳转调解</Button>
                                    </Space>
                                )}
                            />
                        </Table>
                }
            </Modal>
        </div>
    );
}

export default PreArbitrationList;