import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// antd
import { Skeleton } from 'antd';

import { getPreArbitrationTableContent, ILaborDisputeRecordTableData } from '../../../../apis/preArbitration';
import dayjs from 'dayjs';

function LaborDisputeRecordTablePrint() {
    const [searchParams, setSearchParams] = useSearchParams();

    // 表单数据
    const [detail, setDetail] = useState<ILaborDisputeRecordTableData>();

    // 初始化加载
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = "劳动人事争议调解情况记录";

        let pre_worksheet_id = Number(searchParams.get('id'));
        if (pre_worksheet_id) {
            setLoading(true);

            getPreArbitrationTableContent({
                pre_worksheet_id,
                form_type: 4,
            }).then(res => {
                console.log(res);
                let data: ILaborDisputeRecordTableData = res.data.detail!;
                setDetail(data);
            }).finally(() => {
                setLoading(false);
            })
        }

    }, [searchParams]);

    return (
        <div>
            {
                loading
                    ? <Skeleton />
                    : <div style={{ width: "707px", margin: 'auto', textAlign: 'start' }}>
                        <div style={{ position: 'relative', height: "1000px", fontSize: 18, lineHeight: "1.7em" }}>
                            <h2 style={{ margin: '20px 0', textAlign: 'center' }}>劳动人事争议调解情况记录</h2>

                            <div>人员签到</div>
                            <div>调解员：{detail?.mediator}</div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ flex: 1 }}>申请人：{detail?.plaintiff_name}</div>
                                <div style={{ flex: 1, marginLeft: 30 }}>被申请人：{detail?.defendant_name}</div>
                            </div>
                            <div>其他到场人员：{detail?.other_personnel}</div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ flex: 1 }}>地点：{detail?.address}</div>
                                <div style={{ flex: 1, marginLeft: 30 }}>时间：{detail?.mediate_time_format}</div>
                            </div>

                            <div style={{ textIndent: '2em', textDecorationLine: 'underline', margin: "20px 0" }}>
                                {detail?.form_header}
                            </div>

                            <div style={{ margin: "20px 0" }}>
                                {detail?.record?.map((e, i) => (
                                    <div style={{ margin: i === 0 ? '20px 0' : '' }}>{e.role}：<span style={{ textDecorationLine: 'underline' }}>{e.content}</span></div>
                                ))}
                            </div>

                            <div style={{ position: 'absolute', bottom: 0 }}>
                                <div>当事人签名：</div>
                                <div style={{ width: "707px", textAlign: 'center' }}>-1-</div>
                            </div>
                        </div>


                        <div style={{ position: 'relative', height: "1000px", fontSize: 18, lineHeight: "1.7em" }}>
                            <div style={{ margin: '10px 0' }}>申请人意见：
                                <span style={{ textDecorationLine: 'underline' }}>{detail?.plaintiff_opinion}</span>
                            </div>
                            <div style={{ margin: '10px 0' }}>被申请人意见：
                                <span style={{ textDecorationLine: 'underline' }}>{detail?.defendant_opinion}</span>
                            </div>
                            <div style={{ margin: '10px 0' }}>调解结果：<span style={{ textDecorationLine: 'underline' }}>{detail?.mediate_result}</span></div>
                            <div>
                                <strong>当事人声明：</strong>1.调解过程中，不存在欺诈、胁迫、重大误解或者乘人之危的情形，不违背本人真实意思；2.本人陈述情况真实，如有虚假，愿意承担一切法律责任。
                            </div>

                            <div style={{ margin: '45px 0' }}>当事人（签名、盖章或按指模）：</div>
                            <div style={{ margin: '45px 0' }}>调解员（签名）：</div>
                            <div style={{ margin: '45px 0' }}>记录人（签名）：</div>

                            <div style={{ textAlign: 'end' }}><span>年</span><span style={{ marginLeft: 50 }}>月</span><span style={{ marginLeft: 50 }}>日</span></div>

                            <div style={{ position: 'absolute', bottom: 0 }}>
                                <div>当事人签名：</div>
                                <div style={{ width: "707px", textAlign: 'center' }}>-2-</div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
}

export default LaborDisputeRecordTablePrint;