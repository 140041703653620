import { axiosInstance, CommonResponse } from ".";
import { Pager } from "./common";
import { IAttachFilesData, IAttachFilesDataInfo, INaturalPeople, IPreArbitrationCompany, IThirdPartyCompany, IThirdPartyNaturalPeople } from "./preArbitration";

export interface IApprovalListSearchForm extends Pager {
    status?: number[]
}

export interface IApprovalListData {
    id: number,
    no: string,
    pre_worksheet_id: number,
    status: number,
    cur_approver: number,
    created_at: string,
    refuse_reason?: string,
    status_text: string,
    pre_worksheet?: {
        id: number,
        organ_id?: number,
        question_type: number,
        question_type_text: string,
        case_number: string,
        case_cause: string,
        plaintiff_subject_type: number,
        defendant_subject_type: number,
        plaintiff_default?: INaturalPeople | IPreArbitrationCompany,
        defendant_default?: INaturalPeople | IPreArbitrationCompany,
        third_party: (IThirdPartyCompany | IThirdPartyNaturalPeople)[],
        case_category: number,
        case_category_text: string,
        status: number,
        type: number,
        mediate_attach_files_data: IAttachFilesData[],
        mediate_attach_files_data_info: IAttachFilesDataInfo[],
        created_at: string,
        closing_date?: string,
        time_type_text?: string,
        organ?: null
    }
}

export interface IApprovalList {
    list: IApprovalListData[],
    total: number
}

/**
 * 审批管理
 * @returns 
 */
export const getApprovalList = (data: IApprovalListSearchForm): Promise<CommonResponse<IApprovalList>> => {
    return axiosInstance.post('/organ/approval/list', data).then(res => res.data);
};
