import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// antd
import { Grid, Button, Col, Form, Input, message, Row, Select, Upload, Modal, InputNumber, Divider, Skeleton, List, Card, Carousel, Image, Descriptions, Radio, Checkbox } from 'antd';
import { DatePicker as DatePickerMobile } from 'antd-mobile';

import DatePicker from '../../../components/customAntd/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { getPreArbitrationTableContent, ILaborDisputeHandlingTableData, ILaborDisputeHandlingTableDataMediate, ILaborDisputeHandlingTableForm, INaturalPeople, IPreArbitrationCompany, saveLaborDisputeHandlingTable } from '../../../apis/preArbitration';
import { PickerDate } from 'antd-mobile/es/components/date-picker/util';
import TextArea from 'antd/lib/input/TextArea';

const { useBreakpoint } = Grid;


interface CollectionCreateFormProps {
    data?: { id: number },
    onFinish?: () => void;
}

function LaborDisputeHandlingTableFormModal({
    data,
    onFinish,
}: CollectionCreateFormProps) {
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 表单数据
    const [form] = Form.useForm<ILaborDisputeHandlingTableForm>();

    const [detail, setDetail] = useState<ILaborDisputeHandlingTableData>();

    // 初始化加载
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        form.resetFields();

        let pre_worksheet_id = data?.id ?? Number(searchParams.get('id'));
        if (pre_worksheet_id) {
            form.setFieldValue('pre_worksheet_id', pre_worksheet_id);
            setLoading(true);
            getPreArbitrationTableContent({
                pre_worksheet_id,
                form_type: 3,
            }).then(res => {
                console.log(res);
                let data: ILaborDisputeHandlingTableData = res.data.detail!;
                setDetail(data);
                form.setFieldsValue({
                    fact_reason: data.fact_reason,
                    receiver: data.receiver,
                    undertaker: data.undertaker,
                    receive_at: data.receive_at ? dayjs(data.receive_at) as any : undefined,
                    undertake_at: data.undertake_at ? dayjs(data.undertake_at) as any : undefined,
                    mediate_type: data.mediate_type,
                    mediate_basic_situation: data.mediate_basic_situation,
                    mediate_success_content: data.mediate_success_content
                });

                // json数据处理 - 调解方式
                let mediate_type = data.mediate_type?.filter(item => item.selected);
                let mediate_selected_type = mediate_type?.filter(item => item.selected).map(item => item.key);
                if (mediate_selected_type) form.setFieldValue('mediate_selected_type', mediate_selected_type);
                if (mediate_selected_type?.includes(3)) form.setFieldValue('mediate_other_type', mediate_type?.find(item => item.key === 3)?.extra);
            }).finally(() => {
                setLoading(false);
            })
        }

    }, [data, searchParams]);

    // 接件日期
    const [receiveAtVisible, setReceiveAtVisible] = useState(false);

    // 结案日期
    const [undertakeAtVisible, setUndertakeAtVisible] = useState(false);

    // 提交
    const submit = async (print = false) => {

        try {
            await form.validateFields();
        } catch (e: any) {
            message.warning(e.errorFields[0].errors[0]);
            return;
        }

        setConfirmLoading(true);
        let _form = form.getFieldsValue(true);
        try {
            let data = {
                ..._form,
            }
            if (data.receive_at) data.receive_at = (data.receive_at as dayjs.Dayjs).format('YYYY-MM-DD');
            if (data.undertake_at) data.undertake_at = (data.undertake_at as dayjs.Dayjs).format('YYYY-MM-DD');
            if (data.mediate_selected_type) {
                data.mediate_type?.forEach((item: ILaborDisputeHandlingTableDataMediate) => {
                    if ((data.mediate_selected_type as number[])?.includes(item.key)) {
                        item.selected = true;
                        if (item.key === 3) {
                            item.extra = data.mediate_other_type
                        }
                    } else {
                        item.selected = false;
                    }
                });
            }

            let res = await saveLaborDisputeHandlingTable(data);
            message.success(res.msg);
            if (print) {
                setTimeout(() => {
                    window.open(`/preArbitration/laborDisputeHandling/print?id=${_form.pre_worksheet_id}`, '_blank');
                }, 0);
            } else {
                if (onFinish) {
                    onFinish();
                } else {
                    navigate(-1);
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    const gutter = 16 + 8 * 2;

    const FormItem = (
        <Form
            form={form}
            layout="vertical"
            autoComplete="off"
        >
            <Form.Item
                label="请求事项及事实理由"
                name="fact_reason"
            >
                <TextArea rows={3} placeholder="建议列明具体事项、金额，简要说明事实理由。" />
            </Form.Item>

            <Row gutter={gutter}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="收件人"
                        name="receiver"
                    >
                        <Input maxLength={10} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    {
                        screens.xs ?
                            <Form.Item
                                label="收件日期"
                                shouldUpdate={(prevValues, curValues) => prevValues.receive_at !== curValues.receive_at}
                            >
                                <DatePickerMobile
                                    visible={receiveAtVisible}
                                    onClose={() => {
                                        setReceiveAtVisible(false)
                                    }}
                                    value={(form.getFieldValue('receive_at') as Dayjs)?.toDate()}
                                    onConfirm={(value: PickerDate) => {
                                        form.setFieldValue('receive_at', dayjs(value));
                                    }}
                                >

                                    {value =>
                                        <>
                                            <Button
                                                style={{ marginRight: 10 }}
                                                onClick={() => {
                                                    setReceiveAtVisible(true)
                                                }}
                                            >选择</Button>
                                            {value ? dayjs(value).format('YYYY年MM月DD日') : '请选择时间'}
                                        </>
                                    }
                                </DatePickerMobile>
                            </Form.Item>
                            : <Form.Item
                                label="收件日期"
                                name="receive_at"
                            >
                                <DatePicker
                                    format='YYYY-MM-DD'
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                    }
                </Col>
            </Row>

            <Row gutter={gutter}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="案件承办人"
                        name="undertaker"
                    >
                        <Input maxLength={10} />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    {
                        screens.xs ?
                            <Form.Item
                                label="承办时间"
                                shouldUpdate={(prevValues, curValues) => prevValues.undertake_at !== curValues.undertake_at}
                            >
                                <DatePickerMobile
                                    visible={undertakeAtVisible}
                                    onClose={() => {
                                        setUndertakeAtVisible(false)
                                    }}
                                    value={(form.getFieldValue('undertake_at') as Dayjs)?.toDate()}
                                    onConfirm={(value: PickerDate) => {
                                        form.setFieldValue('undertake_at', dayjs(value));
                                    }}
                                >

                                    {value =>
                                        <>
                                            <Button
                                                style={{ marginRight: 10 }}
                                                onClick={() => {
                                                    setUndertakeAtVisible(true)
                                                }}
                                            >选择</Button>
                                            {value ? dayjs(value).format('YYYY年MM月DD日') : '请选择时间'}
                                        </>
                                    }
                                </DatePickerMobile>
                            </Form.Item>
                            : <Form.Item
                                label="承办时间"
                                name="undertake_at"
                            >
                                <DatePicker
                                    format='YYYY-MM-DD'
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                    }
                </Col>
            </Row>

            <Form.Item
                label="调解方式"
                name="mediate_selected_type"
            >
                <Checkbox.Group
                    options={[
                        { label: '现场', value: 1 },
                        { label: '电话', value: 2 },
                        { label: '其他', value: 3 },
                    ]}
                />
            </Form.Item>
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => prevValues.mediate_selected_type !== curValues.mediate_selected_type}
            >
                {({ getFieldValue }) => {
                    let mediateType: number[] = getFieldValue('mediate_selected_type') ?? [];
                    if (mediateType.includes(3)) {
                        return (
                            <Form.Item
                                label="其他调解方式"
                                name="mediate_other_type"
                            >
                                <Input maxLength={100} />
                            </Form.Item>
                        );
                    }
                }}
            </Form.Item>

            <Form.Item
                label="调解过程基本情况"
                name="mediate_basic_situation"
            >
                <TextArea rows={3} />
            </Form.Item>

            <Form.Item
                label="调解成功内容编辑"
                name="mediate_success_content"
            >
                <TextArea rows={3} />
            </Form.Item>

            <Form.Item>
                <Button block type='primary' size='large' loading={confirmLoading} onClick={() => submit()}>提交</Button>
            </Form.Item>
        </Form>
    )

    const getInfoComponentByType = (type: number, data: INaturalPeople | IPreArbitrationCompany) => {
        if (type === 1) {
            return (
                <Descriptions size='small' title="当事人" style={{ marginBottom: 20 }}>
                    <Descriptions.Item label="姓名">{(data as INaturalPeople).real_name}</Descriptions.Item>
                    <Descriptions.Item label="性别">{(data as INaturalPeople).sex_text}</Descriptions.Item>
                    <Descriptions.Item label="身份证号码">{(data as INaturalPeople).idcard}</Descriptions.Item>
                    <Descriptions.Item label="确认文书送达地址" span={2}>{(data as INaturalPeople).effect_service_address}</Descriptions.Item>
                    <Descriptions.Item label="联系电话">{(data as INaturalPeople).mobile}</Descriptions.Item>
                </Descriptions>
            );
        }
        if (type === 2) {
            return (
                <Descriptions size='small' title="用人单位" style={{ marginBottom: 20 }}>
                    <Descriptions.Item label="全称" span={3}>{(data as IPreArbitrationCompany).company_name}</Descriptions.Item>
                    <Descriptions.Item label="地址" span={3}>{(data as IPreArbitrationCompany).address}</Descriptions.Item>
                    <Descriptions.Item label="法定代表人">{(data as IPreArbitrationCompany).legal_person}</Descriptions.Item>
                    <Descriptions.Item label="联系人">{(data as IPreArbitrationCompany).contact_name}</Descriptions.Item>
                    <Descriptions.Item label="联系电话">{(data as IPreArbitrationCompany).contact_mobile}</Descriptions.Item>
                </Descriptions>
            )
        }
    }

    return (
        <div className={screens.xs ? 'inner-page mobile' : ''}>
            {
                loading
                    ? <Skeleton />
                    : <>
                        <Descriptions
                            size='small'
                            title="表单信息"
                            extra={<Button onClick={() => {
                                submit(true);
                            }}>预览打印</Button >}
                        ></Descriptions>
                        {
                            detail && detail.plaintiff_subject_type && detail.plaintiff_default &&
                            getInfoComponentByType(detail.plaintiff_subject_type, detail.plaintiff_default)
                        }
                        {
                            detail && detail.defendant_subject_type && detail.defendant_default &&
                            getInfoComponentByType(detail.defendant_subject_type, detail.defendant_default)
                        }
                        {FormItem}
                    </>
            }
        </div>
    );
}

export default LaborDisputeHandlingTableFormModal;