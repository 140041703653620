import React, { useEffect, useState } from 'react';
import { useDebounce } from 'ahooks';
import { useNavigate } from 'react-router-dom';

// apis
import { getUserList, IUserListData, toDelUser } from '../../apis/auth';
import { getHasAuth, globalAuthSigns } from '../../utils/auth';

// interface
import { IOrderListSearchForm } from '../../apis/order';
import { Attachment } from '../../apis/attachment';

// antd
import { Space, Table, Dropdown, Menu, Button, Card, Input, Pagination, Empty, Grid, Modal, List, message } from 'antd';
import { CloseOutlined, PlusOutlined, SettingOutlined, DownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import AccountFormModal from './actions/accountForm';

import './userList.scss';

const { confirm } = Modal;
const { Column } = Table;
const { useBreakpoint } = Grid;

function UserList() {
    const screens = useBreakpoint();
    const navigate = useNavigate();

    // 权限检查
    // 组件全局权限引用
    const [hasUserAddAuth, setHasUserAddAuth] = useState(false);
    const [hasUserEditAuth, setHasUserEditAuth] = useState(false);
    const [hasUserDelAuth, setHasUserDelAuth] = useState(false);
    const [hasRoleAuth, setHasRoleAuth] = useState(false);
    useEffect(() => {
        getHasAuth([
            globalAuthSigns.user_add,
            globalAuthSigns.user_edit,
            globalAuthSigns.user_del,
            globalAuthSigns.role,
        ]).then(value => {
            setHasUserAddAuth(value[0]);
            setHasUserEditAuth(value[1]);
            setHasUserDelAuth(value[2]);
            setHasRoleAuth(value[3]);
        });
    }, []);

    // 搜索表单
    const [form, setForm] = useState<IOrderListSearchForm>({
        page: 1,
        size: 10
    });

    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState<(IUserListData)[]>([]);
    const [mListData, setMListData] = useState<(IUserListData)[]>([]);

    const [total, setTotal] = useState(0);
    const loadData = async (newForm?: IOrderListSearchForm) => {
        let tempForm = newForm ?? form;
        setLoading(true);
        try {
            let res = await getUserList(tempForm);
            setTotal(res.data.total);
            setMListData(res.data.list);
            setListData((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== res.data.total) {
                    newListData = new Array(res.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((tempForm.page! - 1) * tempForm.size, res.data.list.length, ...res.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    // 分页
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        let newForm = {
            ...form,
            page,
            size: pageSize,
        };
        setForm(newForm);
        loadData(newForm);
    }

    const resetData = () => {
        let newForm = {
            page: 1,
            size: 20
        }
        setForm(newForm);
        loadData(newForm);
    }

    // 关键词搜索
    const keywordsDebouncedValue = useDebounce(form.keywords, { wait: 500 });
    useEffect(() => {
        loadData(form);
    }, [keywordsDebouncedValue]);
    const handleKeywordsChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        let newForm = {
            ...form,
            keywords: value.target.value
        };
        setForm(newForm);
    }

    const [toAddEditModal, setAddEditModal] = useState<{
        isModalVisible: boolean,
        data?: { id: number }
    }>();
    const onAddEditClose = () => {
        setAddEditModal({
            ...toAddEditModal!,
            isModalVisible: false
        })
    };
    const onAddEditFinish = () => {
        loadData();
        setAddEditModal({
            ...toAddEditModal!,
            isModalVisible: false
        })
    }

    // 添加
    const toAdd = () => {
        if (screens.xs) {
            navigate('/auth/account');
        } else {
            setAddEditModal({
                isModalVisible: true,
                data: undefined
            })
        }
    }

    // 编辑
    const toEdit = (id: number) => {
        if (screens.xs) {
            navigate('/auth/account?id=' + id);
        } else {
            setAddEditModal({
                isModalVisible: true,
                data: { id: id }
            })
        }
    }

    // 删除
    const toDel = (data: IUserListData) => {
        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: `确定要删除账号 ${data.username} 吗？`,
            onOk: async () => {
                setLoading(true);
                try {
                    let res = await toDelUser({ id: data.id })
                    message.success(res.msg);
                    loadData();
                } catch (e) {
                    console.error(e);
                } finally {
                    setLoading(false);
                }
            },
        });
    }

    // 显示附件弹窗
    const [attachmentModalForm, setAttachmentModalForm] = useState<{
        isModalVisible: boolean,
        data: { value: Attachment[] }
    }>();
    const showAttachment = (value: Attachment[], e?: any) => {
        e?.stopPropagation();
        setAttachmentModalForm({
            isModalVisible: true,
            data: { value }
        })
    }

    return (
        <div className='address inner-page'>
            <div className='search'>
                <Input
                    className="search__item"
                    value={form.keywords}
                    onChange={handleKeywordsChange}
                    placeholder="姓名、账号搜索"
                />

                <Button
                    className="search__button"
                    icon={<CloseOutlined />}
                    loading={loading}
                    onClick={() => resetData()}
                >
                    重置筛选
                </Button>

                {
                    hasRoleAuth && <Button
                        type='primary'
                        className={`search__last-button ${screens.xs ? 'xs' : ''}`}
                        style={{ marginRight: 10 }}
                        icon={<SettingOutlined />}
                        loading={loading}
                        onClick={() => navigate('/auth/roles')}
                    >
                        角色设置
                    </Button>
                }

                {
                    hasUserAddAuth && <Button
                        className={`search__last-button ${screens.xs ? 'xs' : ''}`}
                        icon={<PlusOutlined />}
                        loading={loading}
                        onClick={toAdd}
                    >
                        新增账号
                    </Button>
                }

            </div>
            {
                screens.xs
                    ? <div>
                        {
                            mListData.length > 0 ? mListData.map(item =>
                                <Dropdown
                                    trigger={['click']}
                                    menu={{
                                        items: [
                                            {
                                                key: 'edit',
                                                disabled: !hasUserEditAuth,
                                                label: (
                                                    <div>编辑</div>
                                                ),
                                                onClick: () => toEdit(item.id),
                                            },
                                            {
                                                key: 'delete',
                                                disabled: !hasUserDelAuth,
                                                label: (
                                                    <div>删除</div>
                                                ),
                                                onClick: () => toDel(item),
                                            },
                                        ]
                                    }}>
                                    <Card className='m-card'>
                                        <div className='m-card_item'>
                                            <div>姓名：</div>
                                            <div>{item.real_name}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>账号：</div>
                                            <div>{item.username}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>认证类别：</div>
                                            <div>{item.group_info.length > 0 ? item.group_info.join('，') : '无'}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>单位：</div>
                                            <div>{item.company_name ?? '无'}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>部门：</div>
                                            <div>{item.dept_name ?? '无'}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>岗位：</div>
                                            <div>{item.post_name ?? '无'}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>角色：</div>
                                            <div>{item.roles_info.length > 0 ? item.roles_info.map(item => item.name).join('，') : '无'}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>附件：</div>
                                            <div>
                                                {
                                                    item.attach_files_info && item.attach_files_info.length > 0
                                                        ? <Button
                                                            size='small'
                                                            type='link'
                                                            onClick={(e) => showAttachment(item.attach_files_info!, e)}
                                                        >查看</Button>
                                                        : '无'
                                                }
                                            </div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>备注说明：</div>
                                            <div>{item.introduction ?? '无'}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>创建时间：</div>
                                            <div>{item.created_at}</div>
                                        </div>
                                    </Card>
                                </Dropdown>
                            )
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                        }

                        <Pagination
                            current={form.page}
                            onChange={onPageChange}
                            showTotal={total => `共 ${total} 项`}
                            total={total}
                            simple
                        />
                    </div>
                    : <Table
                        dataSource={listData}
                        size='small'
                        loading={loading}
                        scroll={{ x: 800 }}
                        pagination={{
                            position: ['bottomRight'],
                            size: "small",
                            total: total,
                            showTotal: total => `共 ${total} 项`,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            onChange: onPageChange
                        }}>
                        <Column title="姓名" dataIndex="real_name" key="real_name" />
                        <Column title="账号" dataIndex="username" key="username" />
                        <Column title="认证类别" dataIndex="group_info" key="group_info"
                            render={(_: any, record: IUserListData | null) => (
                                record?.group_info.map(item => item.name).join('，')
                            )}
                        />
                        <Column title="单位" dataIndex="company_name" key="company_name" />
                        <Column title="部门" dataIndex="dept_name" key="dept_name" />
                        <Column title="岗位" dataIndex="post_name" key="post_name" />
                        <Column title="角色" dataIndex="roles_info" key="roles_info"
                            render={(_: any, record: IUserListData | null) => (
                                record?.roles_info?.map(item => item.name).join('，')
                            )}
                        />
                        <Column title="附件" dataIndex="attach_files_info" key="attach_files_info"
                            render={(value: Attachment[] | null, record: IUserListData) => (
                                value?.length ?? 0 > 0
                                    ? <Button size='small' onClick={() => showAttachment(value!)}>查看</Button>
                                    : '无'
                            )} />
                        <Column title="备注说明" dataIndex="introduction" key="introduction" />
                        <Column title="创建时间" dataIndex="created_at" key="created_at" />
                        <Column
                            title="操作"
                            key="action"
                            fixed="right"
                            render={(_: any, record: IUserListData | null) => (
                                <Space>
                                    <Dropdown.Button
                                        trigger={['click', 'hover']}
                                        size='small'
                                        type="primary"
                                        loading={loading}
                                        overlay={
                                            <Menu
                                                items={[
                                                    {
                                                        key: 'edit',
                                                        disabled: !hasUserEditAuth,
                                                        label: (
                                                            <div>编辑</div>
                                                        ),
                                                        onClick: () => toEdit(record!.id),
                                                    },
                                                    {
                                                        key: 'delete',
                                                        disabled: !hasUserDelAuth,
                                                        label: (
                                                            <div>删除</div>
                                                        ),
                                                        onClick: () => toDel(record!),
                                                    },
                                                ]}
                                            />
                                        }>
                                        操作
                                    </Dropdown.Button>
                                </Space>
                            )}
                        />
                    </Table>
            }

            {/* 添加编辑弹窗 */}
            {
                toAddEditModal ? <Modal
                    maskClosable={false}
                    visible={toAddEditModal.isModalVisible}
                    title={`账号设置`}
                    onCancel={onAddEditClose}
                    width={1000}
                    footer={null}
                >
                    <AccountFormModal data={toAddEditModal.data} onFinish={onAddEditFinish}></AccountFormModal>
                </Modal> : null
            }

            <Modal
                title="附件列表"
                open={attachmentModalForm?.isModalVisible}
                onCancel={() => {
                    setAttachmentModalForm({
                        ...attachmentModalForm!,
                        isModalVisible: false,
                    })
                }}
                footer={
                    <Button key="back" onClick={() => {
                        setAttachmentModalForm({
                            ...attachmentModalForm!,
                            isModalVisible: false,
                        })
                    }}>关闭</Button>
                }
            >
                {
                    attachmentModalForm && attachmentModalForm.data.value.length > 0
                        ? <List
                            className='record__attachment'
                            itemLayout="horizontal"
                            dataSource={attachmentModalForm!.data.value}
                            renderItem={item => (
                                <li>
                                    <Card bodyStyle={{ padding: 10 }}>
                                        <div className='record__attachment__card'>
                                            <div>{item.attach_name}</div>
                                            <Button type="text" icon={<DownloadOutlined />} href={item.attach_url_link} target="_blank" />
                                        </div>
                                    </Card>
                                </li>
                            )}
                        />
                        : <></>
                }
            </Modal>
        </div>
    );
}

export default UserList;